import { useEffect, useState } from "react";
import { ArrowDownwardRounded, ArrowUpwardRounded, BusinessRounded, PersonRounded } from "@mui/icons-material";
import SourceItem from "./SourceItem";
import { PositionEntry } from "./PEPCardV2";
import { format, formatDistanceStrict } from "date-fns";
import { snakeCaseToProperCase } from "@/utils";
import WrappedValue from "./WrappedValue";
import { useParchaApi } from "@/hooks/useParchaApi";

import RiskCardV3 from "./RiskCardV3";

const PEPCardV3 = ({
  apiKey,
  verifiedPepHits,
  input,
  agentInstanceId,
  jobId,
  endpointUrl,
  agentKey,
  commandId,
  commandInstanceId,
  displayOnlyMatches,
  showAllContent,
  allCollapsed,
  displayType,
  showVendorId,
}: {
  apiKey: string;
  verifiedPepHits: any;
  input: any;
  agentInstanceId: string;
  jobId: string;
  endpointUrl: string;
  agentKey: string;
  commandId: string;
  commandInstanceId: string;
  displayOnlyMatches: boolean;
  showAllContent: boolean;
  allCollapsed: boolean;
  displayType: boolean;
  showVendorId: boolean;
}) => {
  const parchaApi = useParchaApi();
  const [feedbackInputs, setFeedbackInputs] = useState<any[]>([]);
  const [isLoadingFeedback, setIsLoadingFeedback] = useState(true);

  useEffect(() => {
    const getFeedbackInput = async () => {
      let feedbackInputs;
      if (apiKey) {
        feedbackInputs = await parchaApi.getFeedbackInputsByKeyFromAPI(endpointUrl, jobId, "pep", apiKey);
      } else {
        feedbackInputs = await parchaApi.getFeedbackInputsByKey(endpointUrl, jobId, "pep");
      }

      if (feedbackInputs) {
        setFeedbackInputs(feedbackInputs);
      }
      setIsLoadingFeedback(false);
    };
    getFeedbackInput();
  }, [apiKey]);

  const handleSubmitFeedback = async (isValid: boolean, rowId: string, comment: string) => {
    if (apiKey) {
      await parchaApi.sendFeedbackToAPI(
        endpointUrl,
        jobId,
        "pep",
        isValid,
        undefined,
        {
          world_check_id: rowId,
          endpoint: {
            agentName: agentKey,
            endpointUrl,
          },
          job_id: jobId,
          job_url: window.location.href.replace(/([?&])api_key=[^&]*(&|$)/, "$1"),
          feedback_key: `${agentInstanceId}${commandInstanceId}`,
          agent_instance_id: agentInstanceId,
          command_instance_id: commandInstanceId,
          command_id: commandId,
          agent_key: agentKey,
          command_result: {
            content: {
              output: {
                verified_pep_hits: verifiedPepHits,
              },
            },
          },
        },
        comment,
        rowId,
        apiKey,
      );
    } else {
      await parchaApi.sendFeedback(
        endpointUrl,
        jobId,
        "pep",
        isValid,
        undefined,
        {
          world_check_id: rowId,
          endpoint: {
            agentName: agentKey,
            endpointUrl,
          },
          job_id: jobId,
          job_url: window.location.href.replace(/([?&])api_key=[^&]*(&|$)/, "$1"),
          feedback_key: `${agentInstanceId}${commandInstanceId}`,
          agent_instance_id: agentInstanceId,
          command_instance_id: commandInstanceId,
          command_id: commandId,
          agent_key: agentKey,
          command_result: {
            content: {
              output: {
                verified_pep_hits: verifiedPepHits,
              },
            },
          },
        },
        comment,
        rowId,
      );
    }
    setFeedbackInputs([
      ...feedbackInputs.filter((feedback) => feedback.row_id !== rowId),
      {
        score: isValid,
        data: {
          world_check_id: rowId,
        },
        row_id: rowId,
        comment,
        created_at: new Date(),
      },
    ]);
  };

  const groupedByWorldCheckId = feedbackInputs.reduce((acc, feedback) => {
    const rowId = feedback.row_id;

    if (!acc[rowId] || new Date(acc[rowId].created_at) < new Date(feedback.created_at)) {
      acc[rowId] = feedback;
    }
    return acc;
  }, {});

  const filteredHits = verifiedPepHits.filter((hit: any) => (displayOnlyMatches ? hit.escalate_for_review : true));

  if (filteredHits.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-2">
      {filteredHits.map((hit: any, index: number) => (
        <RiskCardV3
          type="pep"
          key={`pep-hit-${hit.reference_id}-${index}`}
          hit={hit}
          input={input}
          handleSubmitFeedback={handleSubmitFeedback}
          feedback={groupedByWorldCheckId[hit.reference_id]}
          expandByDefault={index === 0 && !allCollapsed}
          isLoadingFeedback={isLoadingFeedback}
          renderContent={() => (
            <PEPEventCard pepReview={hit.profile_review} pepProfile={hit} showAllContent={showAllContent} />
          )}
          showAllContent={showAllContent}
          displayType={displayType}
          showVendorId={showVendorId}
        />
      ))}
    </div>
  );
};

type PEPEventCardProps = {
  pepReview: {
    pep_level: {
      level: number | null;
      reason: string | null;
    } | null;
    is_foreign: boolean;
    match_rating: {
      match: string;
      reason: string;
    } | null;
    country_match: {
      exact_match: boolean;
      partial_match: boolean;
      mismatch?: boolean;
      countries: (string | { country_name: string })[];
      explanation: string;
    };
    first_name_match: {
      exact_match: boolean;
      partial_match: boolean;
      mismatch?: boolean;
      name: string;
      explanation: string;
    };
    last_name_match: {
      exact_match: boolean;
      partial_match: boolean;
      mismatch?: boolean;
      name: string;
      explanation: string;
    };
    age_match: {
      exact_match: boolean;
      partial_match: boolean;
      mismatch?: boolean;
      age: string | number;
      explanation: string;
    };
    full_name: string;
    risk_rating: string;
    weblinks?:
      | {
          url: string | null;
          date: string | null;
          has_photo: boolean | null;
          scanned_website: {
            webpage_text: string | null;
            webpage_title: string | null;
            is_valid_url: boolean | null;
          };
          pep_metadata?: {
            id: string;
            matching_name_found?: boolean | null;
            matching_name?: string | null;
            summary_of_text_content?: string | null;
            source_url?: string | null;
            associated_countries?: string | null;
            professional_affiliations_found?: string | null;
          };
          metadata?: {
            id: string;
            matching_name_found?: boolean | null;
            matching_name?: string | null;
            summary_of_text_content?: string | null;
            source_url?: string | null;
            associated_countries?: string | null;
            professional_affiliations_found?: string | null;
          };
        }[]
      | null;
  } | null;
  pepProfile: {
    full_name: string;
    gender: string | null;
    pep_title: string;
    pep_status: string;
    pep_type: string;
    age_as_of_today: number;
    date_of_birth: string;
    associated_countries: (
      | {
          original_country_input: string;
          country_name: string;
          alpha_2_country_code: string;
          alpha_3_country_code: string;
          numeric_country_code: string;
        }
      | string
    )[];
    associated_addesses: {
      street_1: string;
      street_2: string;
      city: string;
      state: string;
      postal_code: string;
      country_code: string;
    }[];
    roles: {
      start_date: string;
      end_date: string;
      pep_type: string;
      title: string;
      status: string;
    }[];
    reference_id: string;
    aliases: string[] | null;
    identification_info: string;
    biography_info: string;
    known_associates: {
      association_type: string;
      name: string;
      entity_type: "ORGANISATION" | "INDIVIDUAL";
      category: string;
    }[];
    weblinks?:
      | {
          url: string | null;
          date: string | null;
          has_photo: boolean | null;
          scanned_website: {
            webpage_text: string | null;
            webpage_title: string | null;
            is_valid_url: boolean | null;
          };
          pep_metadata?: {
            id: string;
            matching_name_found?: boolean | null;
            matching_name?: string | null;
            summary_of_text_content?: string | null;
            source_url?: string | null;
            associated_countries?: string | null;
            professional_affiliations_found?: string | null;
          };
          metadata?: {
            id: string;
            matching_name_found?: boolean | null;
            matching_name?: string | null;
            summary_of_text_content?: string | null;
            source_url?: string | null;
            associated_countries?: string | null;
            professional_affiliations_found?: string | null;
            estimated_minimum_age?: number | null;
            year_of_minimum_age?: number | null;
            age_reasoning?: string | null;
            age_estimation_confidence?: string | null;
            birth_year?: number | null;
          };
        }[]
      | null;
  };
  showAllContent: boolean;
};

const PEPEventCard = ({ pepReview, pepProfile, showAllContent }: PEPEventCardProps) => {
  const [weblinksCount, setWeblinksCount] = useState(
    showAllContent ? pepProfile?.weblinks?.length : Math.min(3, pepProfile?.weblinks?.length ?? 0),
  );

  useEffect(() => {
    if (showAllContent && pepProfile?.weblinks) {
      setWeblinksCount(pepProfile.weblinks.length);
    }
  }, [showAllContent, pepProfile?.weblinks]);

  const weblinks = pepProfile?.weblinks?.slice(0, weblinksCount);

  const currentPositions = pepProfile?.roles?.filter((role) => role.end_date === null || role.end_date === "Present");
  const pastPositions = pepProfile?.roles?.filter((role) => role.end_date !== null && role.end_date !== "Present");

  return (
    <div className="flex flex-col gap-y-5 text-xs">
      <div className="grid grid-cols-2 gap-5">
        <div className="flex flex-col gap-y-5">
          {currentPositions?.length > 0 && (
            <div className="flex flex-col gap-y-4">
              {currentPositions?.length > 0 && (
                <div className="flex flex-col gap-y-1">
                  <span className="font-semibold">Current Position{currentPositions.length > 1 ? "s" : ""}</span>
                  <div className="flex flex-col gap-y-2">
                    {currentPositions.map((position, index) => (
                      <PositionEntry
                        key={`pep-position-entry-${position.title}-${index}`}
                        position={position.title}
                        startDate={
                          position.start_date
                            ? format(new Date(Number(position.start_date.split("-")[0]), 0), "yyyy")
                            : null
                        }
                        endDate={
                          position.end_date
                            ? position.end_date === "Present"
                              ? "Present"
                              : format(new Date(Number(position.end_date.split("-")[0]), 0), "yyyy")
                            : null
                        }
                        duration={
                          position.start_date && position.end_date
                            ? position.end_date === "Present"
                              ? formatDistanceStrict(new Date(position.start_date), new Date())
                              : formatDistanceStrict(new Date(position.start_date), new Date(position.end_date))
                            : null
                        }
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
          {pastPositions?.length > 0 && (
            <div className="flex flex-col gap-y-4">
              {pastPositions?.length > 0 && (
                <div className="flex flex-col gap-y-1">
                  <span className="font-semibold">Past Position{pastPositions.length > 1 ? "s" : ""}</span>
                  <div className="flex flex-col gap-y-2">
                    {pastPositions.map((position, index) => (
                      <PositionEntry
                        key={`pep-past-position-entry-${position.title}-${index}`}
                        position={position.title}
                        startDate={
                          position.start_date
                            ? format(new Date(Number(position.start_date.split("-")[0]), 0), "yyyy")
                            : "Not available"
                        }
                        endDate={
                          position.end_date
                            ? position.end_date === "Present"
                              ? "Present"
                              : format(new Date(Number(position.end_date.split("-")[0]), 0), "yyyy")
                            : "Not available"
                        }
                        duration={
                          position.start_date && position.end_date
                            ? position.end_date === "Present"
                              ? formatDistanceStrict(new Date(Number(position.start_date.split("-")[0]), 0), new Date())
                              : formatDistanceStrict(
                                  new Date(Number(position.start_date.split("-")[0]), 0),
                                  new Date(Number(position.end_date.split("-")[0]), 0),
                                )
                            : null
                        }
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
          {pepProfile?.known_associates && pepProfile.known_associates?.length > 0 && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Known Associations</span>
              <div className="flex flex-col gap-y-2 text-slate-700">
                {pepProfile.known_associates.map((associate, index) => {
                  return (
                    <div className="flex items-center gap-x-2" key={`pep-known-associate-${associate.name}-${index}`}>
                      {associate.entity_type === "ORGANISATION" ? (
                        <BusinessRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                      ) : (
                        <PersonRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                      )}
                      <div className="text-slate-500">
                        <span className="text-sm text-slate-700">{associate.name}</span> /{" "}
                        <span>{snakeCaseToProperCase(associate.association_type)}</span> /{" "}
                        <span>{snakeCaseToProperCase(associate.category)}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {pepProfile?.biography_info && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Biography Information</span>
              <div className="flex flex-col gap-y-2 text-slate-700">
                {pepProfile.biography_info.split(".").map((bioInfo) => (
                  <span className="break-keep">{bioInfo}</span>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-y-5">
          {pepProfile?.reference_id && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Vendor ID</span>
              <div className="flex flex-col gap-y-2 text-slate-700">{pepProfile.reference_id.split("_").at(-1)}</div>
            </div>
          )}
          <div className="flex flex-col gap-y-1">
            <span className="font-semibold">PEP Metadata</span>
            <div className="flex gap-x-2 items-center">
              {pepReview?.is_foreign && <WrappedValue text="Foreign" level="normal" className="rounded-full" />}
              {pepReview?.pep_level && (
                <WrappedValue
                  text={`Level ${pepReview?.pep_level.level}`}
                  level={
                    (pepReview?.pep_level.level ?? 0) < 2
                      ? "failure"
                      : (pepReview?.pep_level.level ?? 0) < 4
                        ? "caution"
                        : "success"
                  }
                  className="rounded-full"
                />
              )}
              {pepReview?.pep_level && (
                <WrappedValue
                  text={`${pepReview?.risk_rating
                    .replace("_", " ")
                    .toLowerCase()
                    .replace(/\b\w/g, (char) => char.toUpperCase())}`}
                  level={
                    pepReview?.risk_rating === "high_risk"
                      ? "failure"
                      : pepReview?.risk_rating === "medium_risk"
                        ? "caution"
                        : pepReview?.risk_rating === "low_risk"
                          ? "success"
                          : pepReview?.risk_rating === "no_risk"
                            ? "normal"
                            : "normal"
                  }
                  className="rounded-full"
                />
              )}
            </div>
          </div>
          {pepProfile?.aliases && pepProfile.aliases.length > 0 && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Aliases</span>
              <div className="flex gap-x-2 items-center text-slate-700">{pepProfile.aliases.join(", ")}</div>
            </div>
          )}
          {pepProfile?.gender && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Gender</span>
              <div className="flex gap-x-2 items-center text-slate-700">{pepProfile.gender}</div>
            </div>
          )}
          {pepProfile?.associated_countries?.length > 0 && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Related Locations</span>
              <div className="flex gap-x-2 items-center text-slate-700">
                {pepProfile.associated_countries
                  .map((country) =>
                    typeof country === "object" && country.country_name ? country.country_name : country,
                  )
                  .join(", ")}
              </div>
            </div>
          )}
          {pepProfile?.identification_info && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Identification Information</span>
              <div className="flex flex-col gap-y-2 text-slate-700">
                {pepProfile.identification_info.split(".").map((idInfo) => (
                  <span>{idInfo}</span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {weblinks && weblinks.length > 0 && (
        <>
          <h4 className="text-sm font-semibold">Sources ({weblinks.length})</h4>
          <div className="flex flex-col gap-y-2">
            {weblinks
              ?.sort(
                (a, b) =>
                  (b.metadata?.summary_of_text_content || "").length -
                  (a.metadata?.summary_of_text_content || "").length,
              )
              ?.slice(0, weblinksCount)
              ?.map((weblink, index) => (
                <SourceItem
                  key={`pep-source-item-${weblink.url}-${index}`}
                  url={weblink.url ?? undefined}
                  sourceName={weblink.url ? new URL(weblink.url).hostname : undefined}
                  hasPhoto={weblink.has_photo ?? undefined}
                  text={weblink.scanned_website?.webpage_text ?? undefined}
                  title={weblink.scanned_website?.webpage_title ?? undefined}
                  summary={weblink.metadata?.summary_of_text_content ?? undefined}
                  wasRemoved={Boolean(weblink.scanned_website && !weblink.scanned_website.is_valid_url)}
                  who={weblink.metadata?.matching_name ?? undefined}
                  when={weblink.date ?? undefined}
                  where={weblink.metadata?.associated_countries}
                  age={
                    weblink.metadata?.age_estimation_confidence != "low" && weblink.metadata?.birth_year
                      ? `Born in ${weblink.metadata?.birth_year}`
                      : weblink.metadata?.age_estimation_confidence != "low" &&
                          weblink.metadata?.estimated_minimum_age &&
                          weblink.metadata?.year_of_minimum_age
                        ? `${weblink.metadata?.estimated_minimum_age} or older as of ${weblink.metadata?.year_of_minimum_age}`
                        : undefined
                  }
                  ageReasoning={weblink.metadata?.age_reasoning}
                />
              ))}
          </div>
          {!showAllContent && (
            <div className="flex flex-col gap-2 mb-4">
              {weblinksCount && weblinksCount > Math.min(3, weblinks.length) && (
                <button
                  type="button"
                  className="box-border inline-flex items-center gap-x-1.5 rounded-lg px-3 py-2 w-full text-center justify-center border border-solid border-slate-300 hover:bg-slate-50"
                  onClick={() => setWeblinksCount(Math.min(3, weblinks.length))}
                >
                  <div className="flex items-center gap-x-1 print:hidden">
                    Hide Sources
                    <ArrowUpwardRounded sx={{ fontSize: "1rem" }} />
                  </div>
                </button>
              )}
              {weblinksCount && weblinksCount < weblinks.length && (
                <button
                  type="button"
                  className="box-border inline-flex items-center gap-x-1.5 rounded-lg px-3 py-2 w-full text-center justify-center border border-solid border-slate-300 hover:bg-slate-50"
                  onClick={() => setWeblinksCount(Math.min(weblinksCount + 5, weblinks.length))}
                >
                  <div className="flex items-center gap-x-1 print:hidden">
                    Show More Sources
                    <ArrowDownwardRounded sx={{ fontSize: "1rem" }} />
                  </div>
                </button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PEPCardV3;
