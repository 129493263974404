//@ts-nocheck
import { useState, useEffect } from "react";
import { ArrowDownwardRounded, ArrowUpwardRounded } from "@mui/icons-material";
import SourceItem from "./SourceItem";

type AdverseMediaArticleCollection = {
  articles: {
    id: string;
    summary: string;
    text_content: string;
    title: string;
    url: string | null;
    year: string;
  }[];
  showAllContent?: boolean;
};

const RemovedAdverseMediaArticles = (entry: AdverseMediaArticleCollection, showAllContent = false) => {
  const [showAllArticles, setShowAllArticles] = useState(showAllContent);

  useEffect(() => {
    if (showAllContent) {
      setShowAllArticles(true);
    }
  }, [showAllContent]);

  return (
    <div className="flex flex-col gap-y-3 text-xs">
      {entry.articles?.length > 0 && showAllArticles
        ? entry.articles.map((article) => (
            <SourceItem
              key={article.id}
              when={article.year}
              url={article.url}
              title={article.title}
              summary={article.summary}
              text={article.text_content}
            />
          ))
        : null}
      {entry?.articles?.length > 0 && (
        <button
          type="button"
          disabled={!entry?.articles?.length}
          className="box-border inline-flex items-center gap-x-1.5 rounded-lg px-3 py-2 w-full text-center justify-center border border-solid border-slate-300"
          onClick={() => setShowAllArticles(!showAllArticles)}
        >
          {showAllArticles ? (
            <>
              {`Hide ${entry?.articles?.length} article${entry?.articles?.length === 1 ? "" : "s"}`}
              <ArrowUpwardRounded sx={{ fontSize: "1rem" }} className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </>
          ) : (
            <>
              <span>{`Show ${entry?.articles?.length ?? 0} Article${entry?.articles?.length === 1 ? "" : "s"}`}</span>
              <ArrowDownwardRounded sx={{ fontSize: "1rem" }} className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default RemovedAdverseMediaArticles;
