import React, { ReactNode } from "react";
import clsx from "clsx";
import { CheckCircleRounded } from "@mui/icons-material";

const IDDAlert: React.FC<{
  level: "success" | "failure" | "caution" | "normal" | "partial";
  message: ReactNode;
  type: "adverse_media" | "pep" | "source_of_wealth" | "sanctions";
}> = ({ level, message, type }) => {
  if (!message) return null;

  return (
    <div
      className={clsx("relative z-20 rounded-lg p-4 w-full break-words", {
        "bg-red-50": level === "failure",
        "bg-green-50": level === "success",
        "bg-yellow-50": level === "caution",
        "bg-orange-50": level === "partial",
      })}
    >
      <div
        className={clsx("text-xs flex justify-between items-center gap-2", {
          "text-red-700": level === "failure",
          "text-green-700": level === "success",
          "text-yellow-700": level === "caution",
          "text-orange-700": level === "partial",
        })}
      >
        <div className="flex items-center gap-2">
          <CheckCircleRounded sx={{ fontSize: "1rem" }} className="w-4 h-4 text-green-600" />
          {message}
        </div>
        <div className="flex items-center py-0.5 px-1 w-fit text-xs shrink-0 rounded-full border border-green-200 print:inline-flex">
          <span className="capitalize text-xs text-white bg-green-600 px-2 py-1 rounded-full truncate">
            {type === "pep" ? "PEP" : type.replaceAll("_", " ")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default IDDAlert;
