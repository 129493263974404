import React from "react";
import { Language, CheckCircle, Cancel, InfoOutlined } from "@mui/icons-material";
import { ScannedWebsiteWithAnalysis, WebRelevanceCheckResult } from "@parcha-src/types";
import Tooltip from "@mui/material/Tooltip";

interface WebRelevanceResultListProps {
  results: ScannedWebsiteWithAnalysis[];
}

const WebRelevanceResultList: React.FC<WebRelevanceResultListProps> = ({ results }) => {
  const clipUrl = (url: string, maxLength: number = 30) => {
    if (!url) return "";
    if (url.length <= maxLength) return url;
    return url.substring(0, maxLength - 3) + "...";
  };

  return (
    <div className="w-full bg-white border border-slate-200 rounded-lg overflow-hidden mt-4">
      <div className="overflow-x-auto">
        <table className="w-full table-auto">
          <thead className="bg-indigo-100">
            <tr>
              <th className="px-4 py-2 text-left w-1/6">Website</th>
              <th className="px-4 py-2 text-left w-2/3">Summary</th>
              <th className="px-4 py-2 text-left w-1/6">Relevance</th>
            </tr>
          </thead>
          <tbody>
            {results.map((result, index) => (
              <tr key={index} className={index % 2 === 0 ? "bg-slate-50" : "bg-white"}>
                <td className="px-4 py-2 w-1/6">
                  <div className="flex flex-col">
                    <span className="font-medium">{result.scanned_website.webpage_title}</span>
                    <a
                      href={result.scanned_website.webpage_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:text-blue-700 text-sm"
                    >
                      {clipUrl(result.scanned_website.webpage_url)}
                    </a>
                  </div>
                </td>
                <td className="px-4 py-2 w-2/3">
                  <div className="text-sm">
                    {result.analysis.extracted_metadata?.short_summary || "No summary available"}
                  </div>
                </td>
                <td className="px-4 py-2 w-1/6">
                  {result.analysis.is_relevant ? (
                    <Tooltip title={result.analysis.reason_for_relevance} arrow>
                      <span className="inline-flex items-center px-2.5 py-1.5 rounded-full text-xs truncate font-medium bg-green-600 text-white cursor-help">
                        <CheckCircle className="mr-1" fontSize="small" />
                        Relevant
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip title={result.analysis.reason_against_relevance} arrow>
                      <span className="inline-flex items-center px-2.5 py-1.5 rounded-full text-xs truncate font-medium bg-red-600 text-white cursor-help">
                        <Cancel className="mr-1" fontSize="small" />
                        Not Relevant
                      </span>
                    </Tooltip>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WebRelevanceResultList;
