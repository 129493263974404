import { useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";
import DocumentVerificationView from "@/components/DocumentVerificationView";
import UserContext from "@/contexts/UserContext";
import { useParchaApi } from "@/hooks/useParchaApi";
import { NewJobMetadata } from "@/types";
import { CHECK_ID_TO_TITLE } from "@/constants/vars";
import DocumentValidation from "@/components/TailwindComponents/DocumentValidation";
import Skeleton from "@/components/TailwindComponents/Skeleton";
import ParchaLoadingScreen from "@/components/V2_Home_Page/ParchaLoadingScreen";
import { FactCheckRounded, RefreshRounded } from "@mui/icons-material";

const DocumentVerificationForm = () => {
  const [view, setView] = useState<"upload" | "starting_job" | "results">("upload");
  const [checkRunResponse, setCheckRunResponse] = useState<{ id: string } | null>(null);
  const [jobMetadata, setJobMetadata] = useState<NewJobMetadata | null>(null);
  const { agentKey } = useParams();
  const { endpoints } = useContext(UserContext);
  const parchaApi = useParchaApi();

  const endpoint = endpoints?.find((e) => e.agentKey === agentKey);

  if (!agentKey && !endpoint) return null;

  const onRunFileCheckHandler = async (checkRunResponse: { id: string }) => {
    if (!endpoint) return;

    setView("starting_job");
    setCheckRunResponse(checkRunResponse);

    const response = await parchaApi
      .getJobMetadata(endpoint.endpointUrl, checkRunResponse.id)
      .catch((error) => {
        console.error("Error getting job metadata:", error);
      })
      .finally(() => {});

    const jobMetadata = response;
    setJobMetadata(jobMetadata);
    setView("results");
  };

  const getDocumentUrl = () => {
    if (!jobMetadata) return null;

    const selfAttestedData = jobMetadata.job.input_payload.self_attested_data;
    const documentUrlPrefix = `${window.location.protocol}//${endpoint?.endpointUrl}/getDocument?case_id=${jobMetadata.job.input_payload.id}&expired_url=`;

    const documents = [
      ...(selfAttestedData.incorporation_documents || []),
      ...(selfAttestedData.proof_of_address_documents || []),
      ...(selfAttestedData.ein_documents || []),
      ...(selfAttestedData.business_ownership_documents || []),
    ];

    if (documents.length === 0) return null;

    return `${documentUrlPrefix}${encodeURIComponent(documents[0].url)}`;
  };

  const getDocumentName = () => {
    if (!jobMetadata) return null;

    const selfAttestedData = jobMetadata.job.input_payload.self_attested_data;

    const documents = [
      ...(selfAttestedData.incorporation_documents || []),
      ...(selfAttestedData.proof_of_address_documents || []),
      ...(selfAttestedData.ein_documents || []),
      ...(selfAttestedData.business_ownership_documents || []),
    ];

    if (documents.length === 0) return null;

    return documents[0].file_name;
  };

  const getJobName = () => {
    if (!jobMetadata) return "";

    return jobMetadata.job.input_payload.self_attested_data?.registered_business_name ?? getDocumentName();
  };

  const renderStartingJob = () => {
    return (
      <div className="flex h-full text-xs h-full">
        <div className="w-1/4 h-full border-r bg-white overflow-y-auto">
          <div className="p-4">
            <div className="mb-4 flex flex-wrap items-center justify-between gap-x-4">
              <Skeleton width="w-3/4" height="h-4" shape="rounded" />
            </div>
            <div className="space-y-4 pb-12">
              <Skeleton width="w-full" height="h-[10rem]" shape="rounded" />
              <Skeleton width="w-full" height="h-[10rem]" shape="rounded" />
              <Skeleton width="w-full" height="h-[10rem]" shape="rounded" />
              <Skeleton width="w-full" height="h-[10rem]" shape="rounded" />
            </div>
          </div>
        </div>
        <div className="h-full flex flex-1 mb-4">
          <div className="flex flex-col h-[calc(100%-3.5rem)] w-full gap-y-4 w-3/4 rounded-md">
            <div className="h-full pt-10">
              <Skeleton width="w-3/4" height="h-full" shape="rounded" additionalClasses="mx-auto"></Skeleton>
            </div>
          </div>

          <div className="h-full flex flex-col gap-y-4 w-1/4 bg-slate-100 border-l border-solid border-slate-200 pt-10">
            <Skeleton width="w-1/2" height="h-[10rem]" shape="rounded" additionalClasses="mx-auto" />
            <Skeleton width="w-1/2" height="h-[10rem]" shape="rounded" additionalClasses="mx-auto" />
            <Skeleton width="w-1/2" height="h-[10rem]" shape="rounded" additionalClasses="mx-auto" />
            <Skeleton width="w-1/2" height="h-[10rem]" shape="rounded" additionalClasses="mx-auto" />
          </div>
        </div>
      </div>
    );
  };

  const instance = jobMetadata?.kyb || jobMetadata?.kyc;

  const selectedDocumentType = instance
    ? Object.values(instance)[0]?.check_results?.filter((result: any) => result.command_id !== "finish_plan")?.[0]
        ?.command_id ?? ""
    : "";

  return (
    <>
      {!endpoint && <ParchaLoadingScreen />}
      {endpoint && view === "upload" && (
        <DocumentVerificationView endpoint={endpoint} onRunFileCheckHandler={onRunFileCheckHandler} />
      )}
      {endpoint && view === "starting_job" && renderStartingJob()}
      {endpoint && view === "results" && jobMetadata && checkRunResponse && (
        <>
          <div className="absolute top-[0.5rem] left-[calc(100%-23.5rem)] flex items-center space-x-2">
            <Link
              to={`/jobs/${agentKey}/${jobMetadata.job.id}`}
              className="text-sm text-slate-600 inline-flex items-center p-2 border border-slate-200 group rounded-md hover:border-slate-400"
            >
              <FactCheckRounded
                sx={{ fontSize: "1rem" }}
                className="mr-1 text-slate-500 group-hover:text-text-slate-600"
              />
              <span className="group-hover:text-slate-600">View Job Details</span>
            </Link>
            <button
              onClick={() => {
                setView("upload");
                setJobMetadata(null);
                setCheckRunResponse(null);
              }}
              className="bg-brand-purple text-white text-sm p-2 rounded-md hover:bg-brand-purple group"
            >
              <RefreshRounded sx={{ fontSize: "1rem" }} className="mr-1 text-white group-hover:text-white" />
              Check Another Document
            </button>
          </div>
          <DocumentValidation
            agentInstanceId={Object.keys(jobMetadata.kyb)[0]}
            jobId={checkRunResponse.id}
            endpointUrl={endpoint.endpointUrl}
            documentUrl={getDocumentUrl()}
            documentName={getDocumentName()}
            title={getJobName()}
            agentType={endpoint.agentConfig?.agent_type}
            checkId={selectedDocumentType}
            checkName={`${
              CHECK_ID_TO_TITLE[selectedDocumentType as keyof typeof CHECK_ID_TO_TITLE]
            } Document Verification`}
            onCloseHandler={() => {}}
          />
        </>
      )}
    </>
  );
};

export default DocumentVerificationForm;
