import { useState, FormEvent, useRef, useEffect, FC, ChangeEvent, MouseEvent } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Chip,
  CircularProgress,
  Alert,
  SelectChangeEvent,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { Tenant, Endpoint } from "../../types";
import BusinessIcon from "@mui/icons-material/Business";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import { validateEmail, validateDomain } from "@parcha-src/utils/validation";
import InputWithOverlappingLabel from "../TailwindComponents/InputWithOverlappingLabel";

interface AddTenantModalProps {
  open: boolean;
  onClose: () => void;
  onAddTenant: (tenant: Tenant, adminUser: { email: string }) => void;
  isLoading: boolean;
  error: string | null;
  availableAgents: Endpoint[];
}

const AddTenantModal: FC<AddTenantModalProps> = ({ open, onClose, onAddTenant, isLoading, error, availableAgents }) => {
  const initialTenantData: Tenant = {
    name: "",
    selfProvisioningDomains: [],
    customAttributes: { agents: [] },
    id: "",
    domains: [],
    createdTime: null,
  } as Tenant;

  const initialAdminUser = { name: "", email: "" };

  const [tenantData, setTenantData] = useState<Tenant>(initialTenantData);
  const [adminUser, setAdminUser] = useState(initialAdminUser);
  const [formError, setFormError] = useState<string | null>(null);
  const [domainError, setDomainError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [showGradient, setShowGradient] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  // Reset form when dialog is closed or opened
  useEffect(() => {
    if (!open) {
      // Reset form when dialog is closed
      setTenantData(initialTenantData);
      setAdminUser(initialAdminUser);
      setFormError(null);
      setDomainError(null);
      setEmailError(null);
    }
  }, [open]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTenantData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAgentChange = (event: SelectChangeEvent<string[]>) => {
    setTenantData((prev) => ({
      ...prev,
      customAttributes: { ...prev.customAttributes, agents: event.target.value as string[] },
    }));
  };

  const handleDeleteAgent = (agentKeyToDelete: string) => (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const newSelected = tenantData.customAttributes.agents.filter((agentKey) => agentKey !== agentKeyToDelete);
    setTimeout(() => {
      handleAgentChange({
        target: { value: newSelected },
      } as SelectChangeEvent<string[]>);
    }, 0);
  };

  const checkScroll = () => {
    const el = scrollRef.current;
    if (el) {
      const hasMoreContent = el.scrollHeight > el.clientHeight;
      const isAtBottom = Math.abs(el.scrollHeight - el.clientHeight - el.scrollTop) < 2;
      setShowGradient(hasMoreContent && !isAtBottom);
    }
  };

  useEffect(() => {
    checkScroll();
  }, [tenantData.customAttributes.agents]);

  const handleDomainChange = (e: ChangeEvent<HTMLInputElement>) => {
    const domains = e.target.value.split(",").map((d) => d.trim());
    const invalidDomains = domains.filter((d) => d && !validateDomain(d));

    if (invalidDomains.length > 0) {
      setDomainError(`Invalid domain(s): ${invalidDomains.join(", ")}`);
    } else {
      setDomainError(null);
    }

    setTenantData((prev) => ({ ...prev, selfProvisioningDomains: domains }));
  };

  const handleAdminEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    if (email && !validateEmail(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError(null);
    }
    setAdminUser({ ...adminUser, email });
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (
      !tenantData.name ||
      tenantData.selfProvisioningDomains.length === 0 ||
      !adminUser.email ||
      tenantData.customAttributes.agents.length === 0
    ) {
      setFormError("Please fill in all required fields.");
      return;
    }
    setFormError(null);
    onAddTenant(tenantData, adminUser);
  };

  // Custom close handler to ensure we call the parent's onClose
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          minWidth: "500px",
          width: "500px",
          maxWidth: "90vw",
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: 1,
            borderColor: (theme) => theme.palette.divider,
          }}
        >
          <BusinessIcon sx={{ marginRight: "8px" }} />
          Add New Tenant
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 20,
              top: 20,
              height: 20,
              width: 20,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <InputWithOverlappingLabel
            fieldName="name"
            label="Tenant Name"
            value={tenantData.name}
            onChangeHandler={handleInputChange}
            required={true}
            disabled={isLoading}
            className="w-full mt-8"
          />

          <InputWithOverlappingLabel
            fieldName="selfProvisioningDomains"
            label="Self Provisioning Domains"
            value={tenantData.selfProvisioningDomains.join(", ")}
            onChangeHandler={handleDomainChange}
            required={true}
            disabled={isLoading}
            className="w-full mt-6"
            labelClassName={domainError ? "text-red-500" : ""}
            inputClassName={domainError ? "ring-red-500" : ""}
          />
          {domainError && <p className="mt-1 text-xs text-red-500">{domainError}</p>}

          <InputWithOverlappingLabel
            fieldName="adminEmail"
            label="Admin Email"
            value={adminUser.email}
            onChangeHandler={handleAdminEmailChange}
            required={true}
            disabled={isLoading}
            className="w-full mt-6"
            labelClassName={emailError ? "text-red-500" : ""}
            inputClassName={emailError ? "ring-red-500" : ""}
          />
          {emailError && <p className="mt-1 text-xs text-red-500">{emailError}</p>}

          <FormControl fullWidth margin="dense" required disabled={isLoading} sx={{ mt: 2 }}>
            <InputLabel id="agent-keys-label">Agent Keys</InputLabel>
            <Select
              labelId="agent-keys-label"
              multiple
              input={<OutlinedInput label="Agent Keys" />}
              value={tenantData.customAttributes.agents}
              onChange={handleAgentChange}
              renderValue={(selected) => (
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    height: "fit-content",
                    maxHeight: "8rem",
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Box
                    ref={scrollRef}
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                      width: "100%",
                      height: "100%",
                      maxHeight: "8rem",
                      overflow: "auto",
                      paddingBottom: "0.5rem",
                    }}
                    onScroll={checkScroll}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {(selected as string[]).map((agentKey) => {
                      const agent = availableAgents.find((a) => a.agentKey === agentKey);
                      return (
                        <Chip
                          key={agentKey}
                          label={agent?.agentName || agentKey}
                          color={agent ? "primary" : "default"}
                          variant="outlined"
                          onDelete={handleDeleteAgent(agentKey)}
                          onClick={(e) => e.stopPropagation()}
                          deleteIcon={
                            <CancelIcon
                              onMouseDown={(e) => e.stopPropagation()}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                              sx={{
                                color: "#5D5FEF",
                                backgroundColor: "transparent",
                                "&:hover": {
                                  color: "#5D5FEF",
                                },
                                fontSize: "18px",
                                padding: "2px",
                              }}
                            />
                          }
                          sx={{
                            ...(!agent ? { opacity: 0.6 } : { cursor: "default" }),
                            "& .MuiChip-deleteIcon": {
                              color: "#5D5FEF",
                              opacity: 0.8,
                              backgroundColor: "transparent",
                              "&:hover": {
                                opacity: 1,
                              },
                            },
                          }}
                        />
                      );
                    })}
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      height: "1.5rem",
                      background: "linear-gradient(to bottom, transparent, rgba(255,255,255,0.9))",
                      pointerEvents: "none",
                      opacity: showGradient ? 1 : 0,
                      transition: "opacity 0.2s",
                    }}
                  />
                </Box>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: "20rem",
                    marginTop: "1rem",
                    width: "12rem",
                  },
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
            >
              {availableAgents.map((agent) => (
                <MenuItem key={agent.agentKey} value={agent.agentKey}>
                  {`${agent.agentKey} | ${agent.agentName}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
          {formError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {formError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions sx={{ borderTop: 1, borderColor: "divider", padding: 2 }}>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : "Add Tenant"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddTenantModal;
