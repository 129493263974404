import { Fragment, useEffect, useState } from "react";
import {
  DynamicFeedRounded,
  MoveToInboxRounded,
  HourglassTopRounded,
  Check,
  ErrorRounded,
  CopyAllRounded,
  ArrowUpward,
  ArrowDownward,
  ExpandLessRounded,
  ExpandMoreRounded,
} from "@mui/icons-material";
import { useParchaApi } from "@/hooks/useParchaApi";
import { useContext } from "react";
import UserContext from "@/contexts/UserContext";
import { useSearchParams } from "react-router-dom";
import SelectMenu from "../ui/SelectMenu";
import { format } from "date-fns";

interface BatchData {
  id: string;
  batch_name: string;
  timestamp: string;
  total_rows: number;
  processed_rows: number;
  processing_status: "pending" | "processing" | "processed_successfully" | "processing_failed";
  created_at: string;
  failed_ids: string[] | null;
}

interface Endpoint {
  agentKey: string;
  agentName: string;
  endpointUrl: string;
}

// Add sorting types
type SortField = "batch_name" | "total_rows" | "processed_rows" | "processing_status" | "created_at" | "failed_ids";
type SortDirection = "asc" | "desc";

const JobBatchesView = () => {
  const [batches, setBatches] = useState<BatchData[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedEndpoint, setSelectedEndpoint] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [sortField, setSortField] = useState<SortField>("created_at");
  const [sortDirection, setSortDirection] = useState<SortDirection>("desc");
  const [expandedBatchId, setExpandedBatchId] = useState<string | null>(null);
  const [toast, setToast] = useState<{ message: string; visible: boolean }>({
    message: "",
    visible: false,
  });

  const parchaApi = useParchaApi();
  const userContext = useContext(UserContext);
  const endpoints = userContext?.endpoints as Endpoint[] | undefined;

  // Set initial endpoint from URL or first available endpoint
  useEffect(() => {
    if (!endpoints || endpoints.length === 0) return;

    const agentKeyParam = searchParams.get("agent_key");

    // Ensure we have a valid endpoint on initial load
    const validEndpoint = endpoints.find((e) => e.agentKey === agentKeyParam) || endpoints[0];

    if (!validEndpoint) return;

    // Set initial state
    setSelectedEndpoint(validEndpoint.agentKey);

    // Update URL if needed
    if (!agentKeyParam || agentKeyParam === "undefined" || agentKeyParam !== validEndpoint.agentKey) {
      const newParams = new URLSearchParams(searchParams);
      newParams.set("agent_key", validEndpoint.agentKey);
      setSearchParams(newParams);
    }
  }, [endpoints]);

  // Fetch batches when endpoint changes
  useEffect(() => {
    if (!selectedEndpoint || !endpoints?.length) return;

    const endpoint = endpoints.find((e) => e.agentKey === selectedEndpoint);
    if (!endpoint?.endpointUrl) return;

    const fetchBatches = async () => {
      setIsLoading(true);
      try {
        const batches = await parchaApi.getJobBatches(endpoint.endpointUrl, endpoint.agentKey);
        setBatches(batches);
      } finally {
        setIsLoading(false);
      }
    };
    fetchBatches();
  }, [selectedEndpoint, endpoints]);

  useEffect(() => {
    if (selectedEndpoint) {
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev);
        newParams.set("agent_key", selectedEndpoint);
        return newParams;
      });
    }
  }, [selectedEndpoint, setSearchParams]);

  const getStatusBadge = (status: BatchData["processing_status"]) => {
    const statusStyles = {
      pending: "bg-slate-100 text-slate-800",
      processing: "bg-indigo-100 text-brand-purple",
      processed_successfully: "bg-green-100 text-green-800",
      processing_failed: "bg-red-100 text-red-800",
    } as const;

    const statusIcons = {
      pending: <MoveToInboxRounded sx={{ fontSize: "1rem" }} />,
      processing: <HourglassTopRounded sx={{ fontSize: "1rem" }} />,
      processed_successfully: <Check sx={{ fontSize: "1rem" }} />,
      processing_failed: <ErrorRounded sx={{ fontSize: "1rem" }} />,
    };

    return (
      <span
        className={`w-fit truncate capitalize px-2 py-1 rounded-md text-xs flex items-center gap-1 ${statusStyles[status]}`}
      >
        {statusIcons[status]}
        {status.replace("_", " ")}
      </span>
    );
  };

  const EmptyState = () => (
    <div className="text-center py-12">
      <DynamicFeedRounded sx={{ fontSize: "3rem" }} className="text-slate-300 mb-4" />
      <h3 className="text-lg font-medium text-slate-700 mb-2">No Batches Found</h3>
      <p className="text-sm text-slate-500">There are currently no job batches for this agent.</p>
    </div>
  );

  const TableSkeleton = () => (
    <div className="min-w-full bg-white">
      <div className="bg-slate-50 h-10 mb-4" /> {/* Header skeleton */}
      {[...Array(5)].map((_, index) => (
        <div key={index} className="animate-pulse flex items-center space-x-4 py-3 border-b border-slate-200">
          <div className="w-[0.25rem] h-4 bg-slate-200 rounded" /> {/* ID */}
          <div className="w-[25%] h-4 bg-slate-200 rounded" /> {/* Batch Name */}
          <div className="w-[10%] h-4 bg-slate-200 rounded" /> {/* Total Rows */}
          <div className="w-[17%] h-4 bg-slate-200 rounded" /> {/* Rows Queued */}
          <div className="w-[10%] h-4 bg-slate-200 rounded" /> {/* Failed IDs */}
          <div className="w-1/4 h-4 bg-slate-200 rounded" /> {/* Status */}
          <div className="w-[20%] h-4 bg-slate-200 rounded" /> {/* Created At */}
        </div>
      ))}
    </div>
  );

  const sortedBatches = [...batches].sort((a, b) => {
    const direction = sortDirection === "asc" ? 1 : -1;

    switch (sortField) {
      case "batch_name":
        return direction * a.batch_name.localeCompare(b.batch_name);
      case "total_rows":
        return direction * (a.total_rows - b.total_rows);
      case "processed_rows":
        return direction * (a.processed_rows - b.processed_rows);
      case "processing_status":
        const statusA = a.processing_status || "pending";
        const statusB = b.processing_status || "pending";
        return direction * statusA.localeCompare(statusB);
      case "created_at":
        return direction * (new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
      case "failed_ids":
        const aLength = a.failed_ids?.length || 0;
        const bLength = b.failed_ids?.length || 0;
        return direction * (aLength - bLength);
      default:
        return 0;
    }
  });

  // Add sort handler
  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const SortableHeader = ({ field, label }: { field: SortField; label: string }) => (
    <button className="flex items-center gap-1 hover:text-slate-900" onClick={() => handleSort(field)}>
      {label}
      {sortField === field &&
        (sortDirection === "asc" ? (
          <ArrowUpward sx={{ fontSize: "0.875rem" }} />
        ) : (
          <ArrowDownward sx={{ fontSize: "0.875rem" }} />
        ))}
    </button>
  );

  const Toast = () => {
    if (!toast.visible) return null;

    return (
      <div className="text-sm fixed top-2 left-1/2 -translate-x-1/2 bg-green-200 text-green-800 px-4 py-2 rounded-md shadow-lg flex items-center gap-2 animate-fade-in-up">
        <Check sx={{ fontSize: "1rem" }} />
        {toast.message}
      </div>
    );
  };

  const showToast = (message: string) => {
    setToast({ message, visible: true });
    setTimeout(() => {
      setToast({ message: "", visible: false });
    }, 5000);
  };

  return (
    <div className="p-6 h-screen">
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-xl font-semibold flex items-center gap-2">
          <DynamicFeedRounded sx={{ fontSize: "1.5rem" }} />
          IDD Batches
        </h1>

        {/* Add SelectMenu for endpoints */}
        <div className="flex items-center gap-2 text-xs text-slate-900">
          {!endpoints ? (
            <div className="h-7 w-32 bg-slate-200 rounded-md animate-pulse" />
          ) : (
            endpoints.length > 0 && (
              <SelectMenu
                options={endpoints.map((endpoint) => ({
                  value: endpoint.agentKey,
                  label: endpoint.agentName,
                }))}
                onChange={(value) => {
                  setSelectedEndpoint(value);
                }}
                selectedValue={selectedEndpoint || ""}
              />
            )
          )}
        </div>
      </div>
      <div className="h-full">
        {isLoading ? (
          <TableSkeleton />
        ) : batches.length > 0 ? (
          <div className="min-w-full bg-white relative h-[calc(100vh-8rem)]">
            <table className="min-w-full table-fixed">
              <thead className="bg-slate-50 sticky top-0 z-10">
                <tr>
                  <th className="max-w-[0.25rem] px-6 py-4 text-left text-xs font-semibold text-slate-700">ID</th>
                  <th className="w-[25%] px-6 py-4 text-left text-xs font-semibold text-slate-700">
                    <SortableHeader field="batch_name" label="Name" />
                  </th>
                  <th className="w-[10%] px-6 py-4 text-left text-xs font-semibold text-slate-700">
                    <SortableHeader field="total_rows" label="Total Rows" />
                  </th>
                  <th className="w-[11%] px-6 py-4 text-left text-xs font-semibold text-slate-700">
                    <SortableHeader field="processed_rows" label="Rows Queued" />
                  </th>
                  <th className="w-[10%] px-6 py-4 text-left text-xs font-semibold text-slate-700">
                    <SortableHeader field="failed_ids" label="Failed IDs" />
                  </th>
                  <th className="w-1/4 px-6 py-4 text-left text-xs font-semibold text-slate-700">
                    <SortableHeader field="processing_status" label="Processing Status" />
                  </th>
                  <th className="w-[20%] px-6 py-4 text-xs font-semibold text-slate-700">
                    <div className="flex justify-end">
                      <SortableHeader field="created_at" label="Created At" />
                    </div>
                  </th>
                </tr>
              </thead>
            </table>
            <div className="overflow-y-auto h-full">
              <table className="min-w-full table-fixed">
                <tbody className="divide-y divide-slate-200">
                  {sortedBatches.map((batch, index) => (
                    <Fragment key={`${batch.id}-${index}`}>
                      <tr key={`${batch.id}-${index}`}>
                        <td className="max-w-[0.25rem] px-6 py-4 text-left text-xs text-slate-700">
                          <button
                            className="text-slate-500 hover:text-slate-700"
                            onClick={() => {
                              navigator.clipboard.writeText(batch.id);
                              showToast("Copied to clipboard");
                            }}
                          >
                            <CopyAllRounded sx={{ fontSize: "1rem" }} />
                          </button>
                        </td>
                        <td className="w-[25%] px-6 py-4 text-left text-sm text-brand-purple truncate">
                          <a
                            href={`/cases-overview?agent_key=${selectedEndpoint}&batch_id=${batch.id}`}
                            className="truncate hover:underline pointer-cursor"
                          >
                            {batch.batch_name.length > 30
                              ? `${batch.batch_name.slice(0, 8)}...${batch.batch_name.slice(-22)}`
                              : batch.batch_name}
                          </a>
                        </td>
                        <td className="w-[10%] px-6 py-4 text-left text-sm text-slate-700">{batch.total_rows}</td>
                        <td className="w-[11%] px-6 py-4 text-left text-sm text-slate-700">{batch.processed_rows}</td>
                        <td className="w-[10%] px-6 py-4 text-left text-sm text-slate-700">
                          {batch.failed_ids?.length && batch.failed_ids?.length > 0 ? (
                            <>
                              <button
                                className="text-slate-500 hover:text-slate-700 flex items-center gap-1"
                                onClick={() => {
                                  if (expandedBatchId === batch.id) {
                                    setExpandedBatchId(null);
                                  } else {
                                    setExpandedBatchId(batch.id);
                                  }
                                }}
                              >
                                {batch.failed_ids?.length}
                                {expandedBatchId === batch.id ? (
                                  <ExpandLessRounded sx={{ fontSize: "1rem" }} />
                                ) : (
                                  <ExpandMoreRounded sx={{ fontSize: "1rem" }} />
                                )}
                              </button>
                            </>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="w-1/4 px-6 py-4 text-left text-xs text-slate-700">
                          {getStatusBadge(batch.processing_status || "pending")}
                        </td>
                        <td className="w-[20%] px-6 py-4 text-xs text-slate-700">
                          <div className="flex justify-end">
                            {format(new Date(batch.created_at), "MMM. d, yyyy - h:mm a")}
                          </div>
                        </td>
                      </tr>
                      {expandedBatchId === batch.id &&
                        batch.failed_ids?.map((failed_id, index) => (
                          <tr key={`${failed_id}-${index}-${batch.id}`} className="bg-slate-50">
                            <td colSpan={1} className="px-6 py-4 text-left text-xs text-slate-700">
                              <button
                                className="text-slate-500 hover:text-slate-700"
                                onClick={() => {
                                  navigator.clipboard.writeText(failed_id);
                                  showToast("Copied to clipboard");
                                }}
                              >
                                <CopyAllRounded sx={{ fontSize: "1rem" }} />
                              </button>
                            </td>
                            <td colSpan={6} className="px-6 py-4 text-left text-xs text-slate-700">
                              {failed_id}
                            </td>
                          </tr>
                        ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="h-[90%] flex items-center justify-center bg-white border border-slate-200 rounded-lg p-6">
            <EmptyState />
          </div>
        )}
      </div>
      <Toast />
    </div>
  );
};

export default JobBatchesView;
