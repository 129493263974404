import { useEffect, useState } from "react";
import { ArrowDownwardRounded, ArrowUpwardRounded, BusinessRounded, PersonRounded } from "@mui/icons-material";
import SourceItem from "./SourceItem";
import { PositionEntry } from "./PEPCardV2";
import { format, formatDistanceStrict } from "date-fns";
import { snakeCaseToProperCase } from "@/utils";
import WrappedValue from "./WrappedValue";
import { useParchaApi } from "@/hooks/useParchaApi";
import BusinessRiskCardV1 from "./BusinessRiskCardV1";

const BusinessPEPCardV1 = ({
  apiKey,
  verifiedPepHits,
  input,
  agentInstanceId,
  jobId,
  endpointUrl,
  agentKey,
  commandId,
  commandInstanceId,
  displayOnlyMatches,
  showAllContent,
  allCollapsed,
  displayType,
  showVendorId,
}: {
  apiKey: string;
  verifiedPepHits: any;
  input: any;
  agentInstanceId: string;
  jobId: string;
  endpointUrl: string;
  agentKey: string;
  commandId: string;
  commandInstanceId: string;
  displayOnlyMatches: boolean;
  showAllContent: boolean;
  allCollapsed: boolean;
  displayType: boolean;
  showVendorId: boolean;
}) => {
  const parchaApi = useParchaApi();
  const [feedbackInputs, setFeedbackInputs] = useState<any[]>([]);
  const [isLoadingFeedback, setIsLoadingFeedback] = useState(true);

  useEffect(() => {
    const getFeedbackInput = async () => {
      let feedbackInputs;
      if (apiKey) {
        feedbackInputs = await parchaApi.getFeedbackInputsByKeyFromAPI(endpointUrl, jobId, "pep", apiKey);
      } else {
        feedbackInputs = await parchaApi.getFeedbackInputsByKey(endpointUrl, jobId, "pep");
      }

      if (feedbackInputs) {
        setFeedbackInputs(feedbackInputs);
      }
      setIsLoadingFeedback(false);
    };
    getFeedbackInput();
  }, [apiKey]);

  const handleSubmitFeedback = async (isValid: boolean, rowId: string, comment: string) => {
    if (apiKey) {
      await parchaApi.sendFeedbackToAPI(
        endpointUrl,
        jobId,
        "pep",
        isValid,
        undefined,
        {
          world_check_id: rowId,
          endpoint: {
            agentName: agentKey,
            endpointUrl,
          },
          job_id: jobId,
          job_url: window.location.href.replace(/([?&])api_key=[^&]*(&|$)/, "$1"),
          feedback_key: `${agentInstanceId}${commandInstanceId}`,
          agent_instance_id: agentInstanceId,
          command_instance_id: commandInstanceId,
          command_id: commandId,
          agent_key: agentKey,
          command_result: {
            content: {
              output: {
                verified_pep_hits: verifiedPepHits,
              },
            },
          },
        },
        comment,
        rowId,
        apiKey,
      );
    } else {
      await parchaApi.sendFeedback(
        endpointUrl,
        jobId,
        "pep",
        isValid,
        undefined,
        {
          world_check_id: rowId,
          endpoint: {
            agentName: agentKey,
            endpointUrl,
          },
          job_id: jobId,
          job_url: window.location.href.replace(/([?&])api_key=[^&]*(&|$)/, "$1"),
          feedback_key: `${agentInstanceId}${commandInstanceId}`,
          agent_instance_id: agentInstanceId,
          command_instance_id: commandInstanceId,
          command_id: commandId,
          agent_key: agentKey,
          command_result: {
            content: {
              output: {
                verified_pep_hits: verifiedPepHits,
              },
            },
          },
        },
        comment,
        rowId,
      );
    }
    setFeedbackInputs([
      ...feedbackInputs.filter((feedback) => feedback.row_id !== rowId),
      {
        score: isValid,
        data: {
          world_check_id: rowId,
        },
        row_id: rowId,
        comment,
        created_at: new Date(),
      },
    ]);
  };

  const groupedByWorldCheckId = feedbackInputs.reduce((acc, feedback) => {
    const rowId = feedback.row_id;

    if (!acc[rowId] || new Date(acc[rowId].created_at) < new Date(feedback.created_at)) {
      acc[rowId] = feedback;
    }
    return acc;
  }, {});

  const filteredHits = verifiedPepHits.filter((hit: any) => (displayOnlyMatches ? hit.escalate_for_review : true));

  if (filteredHits.length === 0) {
    return <div className="flex flex-col gap-y-2 text-slate-500 text-sm italic">No PEP hits found</div>;
  }

  return (
    <div className="flex flex-col gap-y-2">
      {filteredHits.map((hit: any, index: number) => (
        <BusinessRiskCardV1
          type="pep"
          key={`pep-hit-${hit.reference_id}-${index}`}
          hit={hit}
          input={input}
          handleSubmitFeedback={handleSubmitFeedback}
          feedback={groupedByWorldCheckId[hit.reference_id]}
          expandByDefault={index === 0 && !allCollapsed}
          isLoadingFeedback={isLoadingFeedback}
          renderContent={() => (
            <BusinessPEPEventCard pepReview={hit.profile_review} pepProfile={hit} showAllContent={showAllContent} />
          )}
          showAllContent={showAllContent}
          displayType={displayType}
        />
      ))}
    </div>
  );
};

type PEPEventCardProps = {
  pepReview: {
    pep_level: {
      level: number | null;
      reason: string | null;
    } | null;
    is_foreign: boolean;
    match_rating: {
      match: string;
      reason: string;
    } | null;
    business_name_match: {
      exact_match: boolean;
      partial_match: boolean;
      mismatch?: boolean;
      name: string;
      explanation: string;
    };
    full_name: string;
    risk_rating: string;
    weblinks?:
      | {
          url: string | null;
          date: string | null;
          has_photo: boolean | null;
          scanned_website: {
            webpage_text: string | null;
            webpage_title: string | null;
            is_valid_url: boolean | null;
          };
          pep_metadata?: {
            id: string;
            matching_name_found?: boolean | null;
            matching_name?: string | null;
            summary_of_text_content?: string | null;
            source_url?: string | null;
            associated_countries?: string | null;
            professional_affiliations_found?: string | null;
          };
          metadata?: {
            id: string;
            matching_name_found?: boolean | null;
            matching_name?: string | null;
            summary_of_text_content?: string | null;
            source_url?: string | null;
            associated_countries?: string | null;
            professional_affiliations_found?: string | null;
          };
        }[]
      | null;
  } | null;
  pepProfile: {
    full_name: string;
    gender: string | null;
    pep_title: string;
    pep_status: string;
    pep_type: string;
    age_as_of_today: number;
    date_of_birth: string;
    associated_countries: (
      | {
          original_country_input: string;
          country_name: string;
          alpha_2_country_code: string;
          alpha_3_country_code: string;
          numeric_country_code: string;
        }
      | string
    )[];
    associated_addresses: {
      street_1: string;
      street_2: string;
      city: string;
      state: string;
      postal_code: string;
      country_code: string;
    }[];
    reference_id: string;
    aliases: string[] | null;
    native_character_names: string[] | null;
    identification_info: string;
    biography_info: string;
    known_associates: {
      association_type: string;
      name: string;
      entity_type: "ORGANISATION" | "INDIVIDUAL";
      category: string;
    }[];
    weblinks?:
      | {
          url: string | null;
          date: string | null;
          has_photo: boolean | null;
          scanned_website: {
            webpage_text: string | null;
            webpage_title: string | null;
            is_valid_url: boolean | null;
          };
          pep_metadata?: {
            id: string;
            matching_name_found?: boolean | null;
            matching_name?: string | null;
            summary_of_text_content?: string | null;
            source_url?: string | null;
            associated_countries?: string | null;
            professional_affiliations_found?: string | null;
          };
          metadata?: {
            id: string;
            matching_name_found?: boolean | null;
            matching_name?: string | null;
            summary_of_text_content?: string | null;
            source_url?: string | null;
            associated_countries?: string | null;
            professional_affiliations_found?: string | null;
          };
        }[]
      | null;
  };
  showAllContent: boolean;
};

const BusinessPEPEventCard = ({ pepReview, pepProfile, showAllContent }: PEPEventCardProps) => {
  const [weblinksCount, setWeblinksCount] = useState(
    showAllContent ? pepProfile?.weblinks?.length : Math.min(3, pepProfile?.weblinks?.length ?? 0),
  );

  useEffect(() => {
    if (showAllContent && pepProfile?.weblinks) {
      setWeblinksCount(pepProfile.weblinks.length);
    }
  }, [showAllContent, pepProfile?.weblinks]);

  const weblinks = pepProfile?.weblinks;

  return (
    <div className="flex flex-col gap-y-5 text-xs">
      <div className="grid grid-cols-2 gap-5">
        <div className="flex flex-col gap-y-5">
          {pepProfile?.known_associates && pepProfile.known_associates?.length > 0 && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Known Associations</span>
              <div className="flex flex-col gap-y-2 text-slate-700">
                {pepProfile.known_associates.map((associate, index) => {
                  return (
                    <div className="flex items-center gap-x-2" key={`pep-known-associate-${associate.name}-${index}`}>
                      {associate.entity_type === "ORGANISATION" ? (
                        <BusinessRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                      ) : (
                        <PersonRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                      )}
                      <div className="text-slate-500">
                        <span className="text-sm text-slate-700">{associate.name}</span> /{" "}
                        <span>{snakeCaseToProperCase(associate.association_type)}</span> /{" "}
                        <span>{snakeCaseToProperCase(associate.category)}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {pepProfile?.biography_info && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Biography Information</span>
              <div className="flex flex-col gap-y-2 text-slate-700">
                {pepProfile.biography_info.split(".").map((bioInfo) => (
                  <span className="break-keep">{bioInfo}</span>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-y-5">
          {pepProfile?.reference_id && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Vendor ID</span>
              <div className="flex flex-col gap-y-2 text-slate-700">{pepProfile.reference_id.split("_").at(-1)}</div>
            </div>
          )}
          <div className="flex flex-col gap-y-1">
            <span className="font-semibold">PEP Metadata</span>
            <div className="flex gap-x-2 items-center">
              {pepReview?.is_foreign && <WrappedValue text="Foreign" level="normal" className="rounded-full" />}
              {pepReview?.pep_level && (
                <WrappedValue
                  text={`Level ${pepReview?.pep_level.level}`}
                  level={
                    (pepReview?.pep_level.level ?? 0) < 2
                      ? "failure"
                      : (pepReview?.pep_level.level ?? 0) < 4
                        ? "caution"
                        : "success"
                  }
                  className="rounded-full"
                />
              )}
              {pepReview?.pep_level && (
                <WrappedValue
                  text={`${pepReview?.risk_rating
                    .replace("_", " ")
                    .toLowerCase()
                    .replace(/\b\w/g, (char) => char.toUpperCase())}`}
                  level={
                    pepReview?.risk_rating === "high_risk"
                      ? "failure"
                      : pepReview?.risk_rating === "medium_risk"
                        ? "caution"
                        : pepReview?.risk_rating === "low_risk"
                          ? "success"
                          : pepReview?.risk_rating === "no_risk"
                            ? "normal"
                            : "normal"
                  }
                  className="rounded-full"
                />
              )}
            </div>
          </div>
          {pepProfile?.aliases && pepProfile.aliases.length > 0 && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Aliases</span>
              <div className="flex flex-col gap-y-1 text-slate-700">
                {pepProfile.aliases.map((alias, index) => (
                  <div key={index} className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>{alias}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {pepProfile?.aliases && pepProfile.aliases.length > 0 && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Native Character Names</span>
              <div className="flex flex-col gap-y-1 text-slate-700">
                {pepProfile.native_character_names?.map((name, index) => (
                  <div key={index} className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>{name}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {pepProfile?.associated_addresses?.length > 0 && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Related Addresses</span>
              <div className="flex flex-col gap-y-1 text-slate-700">
                {pepProfile.associated_addresses.map((address) => (
                  <div className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>
                      {[address.street_1, address.city, address.state, address.country_code, address.postal_code]
                        .filter(Boolean)
                        .join(", ")}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {pepProfile?.associated_countries?.length > 0 && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Related Countries</span>
              <div className="flex flex-col gap-y-1 text-slate-700">
                {pepProfile.associated_countries.map((country: any, index: number) => (
                  <div key={index} className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>
                      {country !== null && typeof country === "object" && "country_name" in country
                        ? country.country_name
                        : country}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {pepProfile?.identification_info && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Identification Information</span>
              <div className="flex flex-col gap-y-2 text-slate-700">
                {pepProfile.identification_info.split(".").map((idInfo) => (
                  <span>{idInfo}</span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {weblinks && weblinks.length > 0 && (
        <>
          <h4 className="text-sm font-semibold">Sources ({weblinks.length})</h4>
          <div className="flex flex-col gap-y-2">
            {weblinks
              ?.sort(
                (a, b) =>
                  (b.metadata?.summary_of_text_content || "").length -
                  (a.metadata?.summary_of_text_content || "").length,
              )
              ?.slice(0, weblinksCount)
              ?.map((weblink, index) => (
                <SourceItem
                  key={`pep-source-item-${weblink.url}-${index}`}
                  url={weblink.url ?? undefined}
                  sourceName={weblink.url ? new URL(weblink.url).hostname : undefined}
                  hasPhoto={weblink.has_photo ?? undefined}
                  text={weblink.scanned_website?.webpage_text ?? undefined}
                  title={weblink.scanned_website?.webpage_title ?? undefined}
                  summary={weblink.metadata?.summary_of_text_content ?? undefined}
                  wasRemoved={!weblink.scanned_website?.is_valid_url ?? undefined}
                  who={weblink.metadata?.matching_name ?? undefined}
                  when={weblink.date ?? undefined}
                  where={weblink.metadata?.associated_countries}
                />
              ))}
          </div>
          {!showAllContent && (
            <div className="flex flex-col gap-2 mb-4">
              {weblinksCount && weblinksCount > Math.min(3, weblinks.length) && (
                <button
                  type="button"
                  className="box-border inline-flex items-center gap-x-1.5 rounded-lg px-3 py-2 w-full text-center justify-center border border-solid border-slate-300 hover:bg-slate-50"
                  onClick={() => setWeblinksCount(Math.min(3, weblinks.length))}
                >
                  <div className="flex items-center gap-x-1 print:hidden">
                    Hide Sources
                    <ArrowUpwardRounded sx={{ fontSize: "1rem" }} />
                  </div>
                </button>
              )}
              {weblinksCount && weblinksCount < weblinks.length && (
                <button
                  type="button"
                  className="box-border inline-flex items-center gap-x-1.5 rounded-lg px-3 py-2 w-full text-center justify-center border border-solid border-slate-300 hover:bg-slate-50"
                  onClick={() => setWeblinksCount(Math.min(weblinksCount + 5, weblinks.length))}
                >
                  <div className="flex items-center gap-x-1 print:hidden">
                    Show More Sources
                    <ArrowDownwardRounded sx={{ fontSize: "1rem" }} />
                  </div>
                </button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BusinessPEPCardV1;
