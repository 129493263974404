import { ReactNode } from "react";

const ValueContainer = ({ label, value }: { label: string; value: ReactNode }) => {
  return (
    <div className="bg-slate-50 py-3 px-5 rounded-md flex flex-col gap-y-1 text-slate-700 justify-center">
      <span className="font-semibold">{label}</span>
      <span>{value}</span>
    </div>
  );
};

export default ValueContainer;
