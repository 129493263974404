//@ts-nocheck
import { useParchaApi } from "@/hooks/useParchaApi";
import { Fragment, useState, useEffect } from "react";
import { twJoin } from "tailwind-merge";
import { Close, AutoAwesomeRounded, ExpandMoreRounded, ErrorRounded, NoteAddRounded } from "@mui/icons-material";
import { Agent, Endpoint } from "@/types";
import { Dialog, Menu, Transition } from "@headlessui/react";
import BusinessRegistrationForm from "./DocumentForms/BusinessRegistrationForm";
import ProofOfAddressForm from "./DocumentForms/ProofOfAddressForm";
import EINDocumentForm from "./DocumentForms/EINDocumentForm";
import {
  INCORPORATION_DOCUMENT_CHECK_ID,
  PROOF_OF_ADDRESS_CHECK_ID,
  EIN_DOCUMENT_CHECK_ID,
  CHECK_ID_TO_TITLE,
} from "@/constants/vars";
import Skeleton from "./TailwindComponents/Skeleton";
import DocumentRequestInfo from "./TailwindComponents/DocumentRequestInfo";

type DocumentRequestModalProps = {
  agent: Agent | null;
  endpoint: Endpoint | null;
  show: boolean;
  setShow: (show: boolean) => void;
  validationCheckIds: (keyof typeof DOCUMENT_TYPES)[];
};

const DOCUMENT_TYPES = {
  [INCORPORATION_DOCUMENT_CHECK_ID]: "Incorporation",
  [PROOF_OF_ADDRESS_CHECK_ID]: "Proof of Address",
  [EIN_DOCUMENT_CHECK_ID]: "EIN Document",
};

const DocumentRequestModal = ({ agent, endpoint, show, setShow, validationCheckIds }: DocumentRequestModalProps) => {
  const [selectedDocumentType, setSelectedDocumentType] = useState<string>("kyb.incorporation_document_verification");
  const [view, setView] = useState<"set-info" | "starting_job" | "request_created">("set-info");
  const [requestId, setRequestId] = useState<string | null>(null);

  const [enableVisualVerification, setEnableVisualVerification] = useState<boolean>(false);
  const [enableFraudCheck, setEnableFraudCheck] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [formErrors, setFormErrors] = useState<string[]>([]);

  const parchaApi = useParchaApi();

  const [formData, setFormData] = useState({
    business_name: "",
    registered_business_name: "",
    business_registration_number: "",
    incorporation_date: "",
    address_of_incorporation: {
      street_1: null,
      street_2: null,
      city: null,
      state: null,
      country_code: null,
      postal_code: null,
    },
    validity_period: 90,
  });

  useEffect(() => {
    if (selectedDocumentType === "" && validationCheckIds.length > 0) {
      setSelectedDocumentType(validationCheckIds[0]);
    }
  }, [validationCheckIds]);

  useEffect(() => {
    const selectedAgentStep = agent?.steps?.find((step) => step.command_id === selectedDocumentType);
    const dataLoaderArgs = selectedAgentStep?.data_loader_args;

    if (dataLoaderArgs) {
      setEnableVisualVerification(Boolean(dataLoaderArgs.enable_visual_verification));
      setEnableFraudCheck(Boolean(dataLoaderArgs.enable_fraud_detection));
    }
  }, [selectedDocumentType]);

  useEffect(() => {
    if (!show) {
      // Reset form data when modal is closed
      setFormData({
        business_name: "",
        registered_business_name: "",
        business_registration_number: "",
        tin_number: "",
        incorporation_date: "",
        address_of_incorporation: {
          street_1: "",
          street_2: "",
          city: "",
          state: "",
          country_code: "",
          postal_code: "",
        },
        address_of_operation: {
          street_1: "",
          street_2: "",
          city: "",
          state: "",
          country_code: "",
          postal_code: "",
        },
        validity_period: 90,
      });
      setFormErrors([]);
      setError(null);
      setView("set-info");
      setSelectedDocumentType("");
      setEnableVisualVerification(false);
      setEnableFraudCheck(false);
    }
  }, [show]);

  const generateRequest = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!endpoint) return;

    setView("starting_job");
    const requestId = `PL-${crypto.randomUUID()}`;

    const request = await parchaApi.createDocumentRequest(
      endpoint.endpointUrl,
      endpoint.agentKey,
      requestId,
      selectedDocumentType,
      {
        enable_visual_verification:
          selectedDocumentType === INCORPORATION_DOCUMENT_CHECK_ID ? enableVisualVerification : undefined,
        enable_fraud_check: enableFraudCheck,
        validity_period: selectedDocumentType === PROOF_OF_ADDRESS_CHECK_ID ? formData.validity_period : undefined,
      },
      "waiting_for_response",
    );
    setRequestId(request.id);
    // update request with form data

    await parchaApi.updateCase(endpoint.endpointUrl, request.id, "kyb", request.applicant_id, endpoint.agentKey, {
      ...formData,
      validity_period: undefined,
    });
    setView("request_created");
    resetFormData();
  };

  const resetFormData = () => {
    setFormData({
      business_name: "",
      registered_business_name: "",
      business_registration_number: "",
      incorporation_date: "",
      address_of_incorporation: {
        street_1: null,
        street_2: null,
        city: null,
        state: null,
        country_code: null,
        postal_code: null,
      },
      validity_period: 90,
    });
    setFormErrors([]);
  };

  const renderInfoForm = () => {
    switch (selectedDocumentType) {
      case INCORPORATION_DOCUMENT_CHECK_ID:
        return <BusinessRegistrationForm formData={formData} setFormData={setFormData} formErrors={formErrors} />;
      case PROOF_OF_ADDRESS_CHECK_ID:
        return <ProofOfAddressForm formData={formData} setFormData={setFormData} formErrors={formErrors} />;
      case EIN_DOCUMENT_CHECK_ID:
        return <EINDocumentForm formData={formData} setFormData={setFormData} formErrors={formErrors} />;
      default:
        return null;
    }
  };

  const handleOnClose = () => {
    setShow(false);
  };

  const renderStartingJob = () => {
    return (
      <div className="flex h-full text-xs">
        <div className="w-1/4 h-full  border-r bg-white overflow-y-auto">
          <div className="p-4">
            <div className="mb-4 flex flex-wrap items-center justify-between gap-x-4">
              <Skeleton width="w-3/4" height="h-4" shape="rounded" />
            </div>
            <div className="space-y-4 pb-12">
              <Skeleton width="w-full" height="h-[10rem]" shape="rounded" />
              <Skeleton width="w-full" height="h-[10rem]" shape="rounded" />
              <Skeleton width="w-full" height="h-[10rem]" shape="rounded" />
              <Skeleton width="w-full" height="h-[10rem]" shape="rounded" />
            </div>
          </div>
        </div>
        <div className="h-full flex flex-1 mb-4">
          <div className="flex flex-col h-[calc(100%-3.5rem)] w-full gap-y-4 w-3/4 rounded-md">
            <div className="h-full pt-10 relative">
              <Skeleton width="w-3/4" height="h-full" shape="rounded" additionalClasses="mx-auto"></Skeleton>
            </div>
          </div>

          <div className="h-full flex flex-col gap-y-4 w-1/4 bg-slate-100 border-l border-solid border-slate-200 pt-10">
            <Skeleton width="w-1/2" height="h-[10rem]" shape="rounded" additionalClasses="mx-auto" />
            <Skeleton width="w-1/2" height="h-[10rem]" shape="rounded" additionalClasses="mx-auto" />
            <Skeleton width="w-1/2" height="h-[10rem]" shape="rounded" additionalClasses="mx-auto" />
            <Skeleton width="w-1/2" height="h-[10rem]" shape="rounded" additionalClasses="mx-auto" />
          </div>
        </div>
      </div>
    );
  };

  const renderRequestCreated = () => {
    if (!endpoint || !requestId) return null;

    return <DocumentRequestInfo endpoint={endpoint} requestId={requestId} />;
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={handleOnClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-20 w-screen overflow-y-auto h-screen">
          <div className="flex w-full h-full min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="flex flex-col relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all h-full w-full">
                <div className="sm:flex sm:items-center sm:justify-between border-b border-slate-300 p-4">
                  <Dialog.Title as="h3" className="flex items-center gap-x-2 text-base font-semibold text-slate-900">
                    <div className="rounded-full bg-indigo-100 flex items-center justify-center p-2">
                      <NoteAddRounded sx={{ fontSize: "1.5rem" }} className="text-brand-purple" />
                    </div>
                    Document Request
                  </Dialog.Title>
                  <div>
                    <button
                      className="ml-auto inline-flex items-center border border-solid border-slate-200 px-2 py-1 rounded-md text-sm"
                      onClick={handleOnClose}
                    >
                      <Close sx={{ fontSize: "1rem" }} className="mr-1" />
                      <span>Close</span>
                    </button>
                  </div>
                </div>
                {view === "set-info" || view === "starting_job" ? (
                  <div className="mx-auto max-w-4xl w-full py-10 px-20 overflow-y-auto">
                    {error && (
                      <div className="flex items-center gap-2 bg-red-50 text-sm text-red-700 p-4 rounded-md">
                        <ErrorRounded className="text-red-600" sx={{ fontSize: "1rem" }} />
                        {error}
                      </div>
                    )}
                    <div className="px-4 flex-1 flex flex-col gap-y-10 mt-10">
                      <div>
                        <label htmlFor="document-type" className="block text-sm font-semibold text-slate-700">
                          Select document type to verify
                        </label>
                        <Menu as="div" className="relative inline-block text-left">
                          <div>
                            <Menu.Button className="flex w-56 gap-x-1.5 rounded-md bg-white p-2 text-xs md:text-md text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50">
                              <span className="flex-1 line-clamp-1 text-left">
                                {DOCUMENT_TYPES[selectedDocumentType as keyof typeof DOCUMENT_TYPES]}
                              </span>
                              <ExpandMoreRounded
                                sx={{ fontSize: "1rem" }}
                                className="-mr-1 h-4 w-4 text-slate-400"
                                aria-hidden="true"
                              />
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="h-fit w-full max-h-[70vh] overflow-y-auto absolute left-0 z-50 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                {validationCheckIds.map((checkId) => {
                                  return (
                                    <Menu.Item key={DOCUMENT_TYPES[checkId as keyof typeof DOCUMENT_TYPES]}>
                                      <button
                                        onClick={() => setSelectedDocumentType(checkId)}
                                        className={twJoin(
                                          selectedDocumentType === checkId
                                            ? "bg-slate-100 text-slate-900"
                                            : "text-slate-700",
                                          "block px-4 py-2 text-xs w-full text-left hover:bg-slate-100 hover:text-slate-900 disabled:cursor-not-allowed disabled:bg-opacity-50 disabled:text-slate-500",
                                        )}
                                      >
                                        {DOCUMENT_TYPES[checkId as keyof typeof DOCUMENT_TYPES]}
                                      </button>
                                    </Menu.Item>
                                  );
                                })}
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                      <div className="flex flex-col gap-y-3">
                        <div className="flex items-center">
                          <label htmlFor="provide-business-info" className="block text-sm font-semibold text-slate-900">
                            Provide business information to validate in the document(s)
                          </label>
                        </div>
                        {renderInfoForm()}
                      </div>
                      <div>
                        <p className="block text-sm font-semibold text-slate-900">
                          Enhance your verification by selecting below options
                        </p>
                        <div className="mt-2 space-y-2">
                          {![PROOF_OF_ADDRESS_CHECK_ID, EIN_DOCUMENT_CHECK_ID].includes(selectedDocumentType) && (
                            <div className="flex items-center">
                              <input
                                id="vision-model"
                                name="vision-model"
                                type="checkbox"
                                checked={enableVisualVerification}
                                onChange={() => setEnableVisualVerification(!enableVisualVerification)}
                                className="h-4 w-4 rounded border-2 border-slate-400 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label htmlFor="vision-model" className="ml-2 block text-sm text-slate-900">
                                Visual verification
                              </label>
                            </div>
                          )}
                          <div className="flex items-center">
                            <input
                              id="anti-tampering"
                              name="anti-tampering"
                              type="checkbox"
                              checked={enableFraudCheck}
                              onChange={() => setEnableFraudCheck(!enableFraudCheck)}
                              className="h-4 w-4 rounded border-2 border-slate-400 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label htmlFor="anti-tampering" className="ml-2 block text-sm text-slate-900">
                              Anti-tampering and fraud detection
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="justify-self-end sm:mt-10 sm:flex sm:flex-row-reverse px-4">
                      <button
                        type="button"
                        className="inline-flex gap-x-1 items-center w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto disabled:cursor-not-allowed disabled:bg-opacity-50"
                        onClick={generateRequest}
                        disabled={
                          formErrors.length > 0 ||
                          ([INCORPORATION_DOCUMENT_CHECK_ID, EIN_DOCUMENT_CHECK_ID].includes(selectedDocumentType) &&
                            !formData.registered_business_name) ||
                          (selectedDocumentType === PROOF_OF_ADDRESS_CHECK_ID && !formData.business_name)
                        }
                      >
                        <AutoAwesomeRounded sx={{ fontSize: "1.25rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                        Generate Request
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50 sm:mt-0 sm:w-auto"
                        onClick={handleOnClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : view === "starting_job" ? (
                  renderStartingJob()
                ) : view === "request_created" && agent && endpoint ? (
                  renderRequestCreated()
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DocumentRequestModal;
