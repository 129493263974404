import { twJoin } from "tailwind-merge";
import { format } from "date-fns";
import OneRowInfoValue from "./OneRowInfoValue";
import TwoRowInfoValue from "./TwoRowInfoValue";
import AddressValue from "./AddressValue";
import WrappedValue from "./WrappedValue";
import WebsiteValue from "./WebsiteValue";

type AddressProps = {
  street1: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  countryCode: string | null;
  county?: string | null;
} | null;

type CannabisRegistrationCardProps = {
  level: "success" | "failure" | "caution" | "normal";
  name: string;
  licenseId: string;
  cnbId: string | null;
  status: string;
  activities: string[];
  issuedDate: string | null;
  expirationDate: string | null;
  lastUpdated: string | null;
  address: AddressProps;
  cnbUrl: string | null;
  companyName: string | null;
  companyId: string | null;
  country: string | null;
  state: string | null;
};

const CannabisRegistrationCard = ({
  level,
  name,
  licenseId,
  cnbId,
  status,
  activities,
  issuedDate,
  expirationDate,
  lastUpdated,
  address,
  cnbUrl,
  companyName,
  companyId,
  country,
  state,
}: CannabisRegistrationCardProps) => {
  const statusLevel = status === "ACTIVE" ? "success" : "failure";

  return (
    <div
      className={twJoin(
        "w-full h-auto flex flex-col mt-2 gap-2 border rounded-lg text-sm font-slate-900",
        statusLevel === "success" ? "border-green-300" : "border-red-300",
      )}
    >
      <div
        className={twJoin(
          "flex justify-between rounded-t-md p-4 border-b",
          statusLevel === "success"
            ? "bg-[#DCFCE7] border-b-green-300"
            : statusLevel === "failure"
              ? "bg-red-100 border-b-red-300"
              : "bg-slate-100 border-slate-300",
        )}
      >
        <div className="flex flex-col justify-center">
          <span className="font-medium">{name}</span>
          {(country || state) && (
            <span className="text-sm text-slate-600">{[state, country].filter(Boolean).join(", ")}</span>
          )}
        </div>
        <div className="flex flex-col justify-center items-end">
          <span className="text-sm">
            <WrappedValue level={statusLevel} text={status} />
          </span>
        </div>
      </div>

      <div className="grid grid-cols-2 grid-rows-1 gap-y-4 gap-x-4 py-3 px-4">
        <OneRowInfoValue label="Registration ID" value={licenseId} />
        {cnbId && <OneRowInfoValue label="CNB ID" value={cnbId} />}

        <OneRowInfoValue label="Activities" value={activities?.length ? activities.join(", ") : "Not Provided"} />
        {(companyName || companyId) && (
          <OneRowInfoValue
            label="Company Info"
            value={
              <div className="flex flex-col">
                <span>{companyName || "Not Provided"}</span>
                {companyId && <span className="text-xs text-slate-500">ID: {companyId}</span>}
              </div>
            }
          />
        )}

        <div className="col-span-2 grid grid-cols-2 gap-4">
          {address && (
            <TwoRowInfoValue
              label="Address"
              value={
                <AddressValue
                  street1={address.street1 || ""}
                  city={address.city || ""}
                  state={address.state || ""}
                  postalCode={address.postalCode || ""}
                  countryCode={address.countryCode || ""}
                />
              }
            />
          )}

          {(issuedDate || expirationDate || lastUpdated) && (
            <TwoRowInfoValue
              label="Registration Dates"
              value={
                <div className="flex flex-col gap-1">
                  <span>
                    Issued:{" "}
                    {issuedDate ? format(new Date(issuedDate?.replace(/-/g, "/")), "MMM. d, yyyy") : "Not Provided"}
                  </span>
                  <span>
                    Expires:{" "}
                    {expirationDate
                      ? format(new Date(expirationDate?.replace(/-/g, "/")), "MMM. d, yyyy")
                      : "Not Provided"}
                  </span>
                  <span className="text-xs text-slate-500">
                    Last Updated:{" "}
                    {lastUpdated ? format(new Date(lastUpdated?.replace(/-/g, "/")), "MMM. d, yyyy") : "Not Provided"}
                  </span>
                </div>
              }
            />
          )}
        </div>

        {cnbUrl && <TwoRowInfoValue label="CNB URL" value={<WebsiteValue url={cnbUrl} />} />}
      </div>
    </div>
  );
};

export default CannabisRegistrationCard;
