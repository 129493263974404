import { AddressValueProps } from "./AddressValue";
import WrappedValue from "./WrappedValue";
import ValueContainer from "./ValueContainer";
import PDFThumbnail from "./PDFThumbnail";
import { UIDocument } from "@/types";

type EINCardProps = {
  addressOfOperation: AddressValueProps;
  businessName: string;
  documents: UIDocument[];
  einNumber: string;
  level: "success" | "failure" | "normal" | undefined;
  handleDocumentValidationClick?: (checkResult: any, document: any) => void;
  checkResult?: any;
};

const EINCard = ({
  addressOfOperation = {
    street1: null,
    street2: null,
    city: null,
    state: null,
    countryCode: null,
    postalCode: null,
  },
  businessName,
  documents,
  einNumber,
  level,
  handleDocumentValidationClick,
  checkResult,
}: EINCardProps) => {
  if (!documents?.length) return null;

  const { street1, street2, city, state, countryCode, postalCode } = addressOfOperation;
  const addressLine1 = `${street1 ?? ""}${street2 ? `, ${street2}` : ""}`;
  const addressLine2 = `${city ?? ""}${state ? `, ${state}` : ""}${countryCode ? ` ${countryCode}` : ""}${
    postalCode ? ` ${postalCode}` : ""
  }`;
  const document = documents.length > 0 ? documents[0] : null;

  return (
    <div className="grid grid-cols-2 gap-4 text-xs w-[40rem]">
      <div className="flex flex-col gap-y-2">
        {document?.documentType && <ValueContainer label="Document Type" value={document.documentType} />}
        <ValueContainer label="Valid Document" value={documents[0]?.isValidDocument ? "Yes" : "No"} />
        <ValueContainer label="EIN" value={<WrappedValue text={einNumber} level={level} />} />
        <ValueContainer label="Registered Business Name" value={businessName} />
        {addressLine1 && (
          <ValueContainer
            label="Address of Operation"
            value={
              <>
                <p>{addressLine1}</p>
                <p>{addressLine2}</p>
              </>
            }
          />
        )}
      </div>
      {document && (
        <div className="flex flex-col gap-y-2 h-full">
          <div className="h-full">
            <PDFThumbnail
              documentURL={document.url}
              documentName={document.fileName}
              onDocumentNameClick={() =>
                handleDocumentValidationClick?.(checkResult, {
                  file_name: document.fileName,
                  url: document.url,
                })
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EINCard;
