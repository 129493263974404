export default function SelectMenu({
  options,
  selectedValue,
  onChange,
  className = "",
}: {
  options: { value: string; label: string }[];
  onChange: (value: string) => void;
  selectedValue: string;
  className?: string;
}) {
  return (
    <select
      value={selectedValue}
      onChange={(e) => onChange(e.target.value)}
      className={`block w-fit overflow-visible rounded-md border-0 py-[0.38rem] text-slate-900 ring-1 ring-inset ring-slate-300 focus:ring-2 focus:ring-brand-purple sm:text-xs truncate ${className}`}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}
