import { UIDocument, ProofOfAddressDocument } from "@/types";
import { AddressValueProps } from "./AddressValue";
import { isAddressProvided, snakeCaseToProperCase } from "@/utils";
import PDFThumbnail from "./PDFThumbnail";
import ValueContainer from "./ValueContainer";

type KYBProofOfAddressCardProps = {
  businessName: string;
  address: AddressValueProps;
  proofOfAddressDocuments: (UIDocument & ProofOfAddressDocument)[];
  checkResult?: any;
  handleDocumentValidationClick?: (checkResult: any, document: any) => void;
};

const KYBProofOfAddressCard = ({
  address,
  businessName,
  proofOfAddressDocuments,
  checkResult,
  handleDocumentValidationClick,
}: KYBProofOfAddressCardProps) => {
  if (!businessName || !businessName.length) {
    return null;
  }

  const { street1, street2, city, state, countryCode, postalCode } = address;
  const addressLine1 = `${street1 ?? ""}${street2 ? `, ${street2}` : ""}`;
  const addressLine2 = `${city ?? ""}${state ? `, ${state}` : ""}${countryCode ? ` ${countryCode}` : ""}${
    postalCode ? ` ${postalCode}` : ""
  }`;

  const document = proofOfAddressDocuments.length > 0 ? proofOfAddressDocuments[0] : null;

  return (
    <div className="grid grid-cols-2 gap-y-4 gap-x-2 text-xs w-[40rem]">
      <div className="flex flex-col gap-y-2 h-full">
        <ValueContainer label="Business Name" value={businessName} />
        {isAddressProvided({
          street1: address.street1 ?? "",
          street2: address.street2 ?? "",
          city: address.city ?? "",
          state: address.state ?? "",
          countryCode: address.countryCode ?? "",
          postalCode: address.postalCode ?? "",
        }) && (
          <ValueContainer
            label="Address of Operation"
            value={
              <>
                <p>{addressLine1}</p>
                <p>{addressLine2}</p>
              </>
            }
          />
        )}
        {document && <ValueContainer label="Document Type" value={snakeCaseToProperCase(document.documentType)} />}
      </div>
      <div className="flex flex-col gap-y-2 min-h-[12rem] h-full">
        {document && (
          <div className="h-full">
            <PDFThumbnail
              documentURL={document.url}
              documentName={document.fileName}
              onDocumentNameClick={() =>
                handleDocumentValidationClick?.(checkResult, {
                  file_name: document.fileName,
                  url: document.url,
                })
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default KYBProofOfAddressCard;
