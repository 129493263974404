import { useNavigate, useParams, LoaderFunctionArgs } from "react-router-dom";
import { useParchaApi } from "@/hooks/useParchaApi";
import { AgentJobInput } from "@/types";
import { useState, useEffect } from "react";
import ParchaLoadingScreen from "./V2_Home_Page/ParchaLoadingScreen";

export const loader = async ({ params }: LoaderFunctionArgs) => {
  // This loader function now only returns the params
  return params;
};

const PersonaLoader: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { agentKey, caseId } = useParams();
  const parchaApi = useParchaApi();

  useEffect(() => {
    const loadData = async () => {
      try {
        if (agentKey && agentKey.includes("bridge") && caseId) {
          const customerAgents = await parchaApi.getCustomerAgents();
          const { endpoints } = customerAgents;
          const endpoint = endpoints.find((e: { agentKey: string }) => e.agentKey === agentKey);

          if (endpoint) {
            const response = await parchaApi.getJobsByCaseId(endpoint.endpointUrl, caseId, agentKey);
            if (response && response.jobs && response.jobs.length === 0) {
              const kybSchema = await parchaApi.getKybSchemaFromPersona(endpoint.endpointUrl, caseId);

              const inputPayload: AgentJobInput = {
                agent_key: agentKey,
                kyb_schema: kybSchema,
                run_in_parallel: true,
              };
              const job_response = await parchaApi.enqueueAgent(endpoint.endpointUrl, inputPayload, "kyb");

              if (job_response.job_id) {
                navigate(`/jobs/${agentKey}/${job_response.job_id.replace(/-/g, "")}`);
                return;
              }
            } else if (response && response.jobs && response.jobs.length > 0) {
              navigate(`/jobs/${agentKey}/${response.jobs[0].id.replace(/-/g, "")}`);
              return;
            }
          }
        }

        // If we reach here, something went wrong
        setError("Unable to load case data. Please try again.");
      } catch (err) {
        console.error(err);
        setError("An error occurred while loading the case data.");
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [agentKey, caseId, parchaApi, navigate]);

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <ParchaLoadingScreen message="Loading case data from Persona" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <p className="text-lg font-medium text-red-500">{error}</p>
      </div>
    );
  }

  return null;
};

export default PersonaLoader;
