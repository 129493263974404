import { ThumbUpOutlined, ThumbDownOutlined, ThumbUpRounded, ThumbDownRounded } from "@mui/icons-material";
import { twMerge } from "tailwind-merge";
import { Feedback } from "@/types";

const baseButtonStyles =
  "flex justify-center items-center border border-solid border-slate-300 rounded-md p-2.5 w-4 h-4";
const loadingButtonStyles = "bg-slate-100 animate-pulse flex items-center justify-center";
const iconStyles = { fontSize: "1rem" };

type FeedbackButtonProps = {
  onClick: (e: React.MouseEvent) => void;
  className: string;
  icon: React.ReactNode;
};

const FeedbackButton = ({ onClick, className, icon }: FeedbackButtonProps) => (
  <button
    className={twMerge(baseButtonStyles, className)}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick(e);
    }}
  >
    {icon}
  </button>
);

type FeedbackButtonsProps = {
  className?: string;
  isLoadingFeedback: boolean;
  isFail: boolean;
  flaggedSelected: boolean;
  setFlaggedSelected: (value: boolean) => void;
  feedback?: Feedback;
  handleSubmitFeedback: (isValid: boolean, rowId: string, comment: string) => Promise<void>;
  rowId: string;
  setFeedbackComment: (value: string) => void;
  setShowFeedbackComment: (value: boolean) => void;
};

const FeedbackButtons = ({
  isLoadingFeedback,
  isFail,
  flaggedSelected,
  setFlaggedSelected,
  feedback,
  handleSubmitFeedback,
  rowId,
  setFeedbackComment,
  setShowFeedbackComment,
}: FeedbackButtonsProps) => {
  const getFlagButtonStyles = () => {
    if (flaggedSelected || feedback?.score === false) {
      return "border-slate-300";
    }

    return "hover:border-slate-400 border-slate-300 text-slate-500";
  };

  const getCheckButtonStyles = () => {
    if (feedback?.score) {
      return flaggedSelected ? "border-slate-300 text-slate-300" : "border-slate-300";
    }

    return "hover:border-slate-400 border-slate-300 text-slate-500";
  };

  const handleCheckClick = () => {
    setFlaggedSelected(false);
    setFeedbackComment("");
    setShowFeedbackComment(false);
    handleSubmitFeedback(true, rowId, "");
  };

  if (isLoadingFeedback) {
    return (
      <div className="ignore-in-pdf print:hidden flex items-center gap-x-2">
        <div className={twMerge(baseButtonStyles, loadingButtonStyles)}>
          <ThumbUpOutlined sx={iconStyles} className="text-slate-300" />
        </div>
        <div className={twMerge(baseButtonStyles, loadingButtonStyles)}>
          <ThumbDownOutlined sx={iconStyles} className="text-slate-300" />
        </div>
      </div>
    );
  }

  return (
    <div className="ignore-in-pdf print:hidden flex items-center gap-x-2">
      <FeedbackButton
        onClick={handleCheckClick}
        className={getCheckButtonStyles()}
        icon={feedback?.score ? <ThumbUpRounded sx={iconStyles} /> : <ThumbUpOutlined sx={iconStyles} />}
      />
      <FeedbackButton
        onClick={() => setFlaggedSelected(!flaggedSelected)}
        className={getFlagButtonStyles()}
        icon={
          flaggedSelected || feedback?.score === false ? (
            <ThumbDownRounded sx={iconStyles} />
          ) : (
            <ThumbDownOutlined sx={iconStyles} />
          )
        }
      />
    </div>
  );
};

export default FeedbackButtons;
