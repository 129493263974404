import { twMerge } from "tailwind-merge";

const StrengthIndicator = ({
  matchRating,
  hideLabel = false,
  greenColor = false,
}: {
  matchRating: string;
  hideLabel?: boolean;
  greenColor?: boolean;
}) => {
  const getStrengthLabel = (matchRating: string) => {
    switch (matchRating) {
      case "strong_match":
        return "Strong Match";
      case "partial_match":
        return "Partial Match";
      case "weak_match":
        return "Weak Match";
      case "no_match":
        return "No Match";
      case "unknown":
        return "Unknown";
      default:
        return "";
    }
  };

  return (
    <div className="flex gap-x-2 items-center text-xs">
      {matchRating !== "unknown" && (
        <div className="flex items-center w-[3.75rem] h-2 overflow-hidden gap-x-[0.0875rem] shrink-0">
          <div
            className={twMerge(
              "grow h-full rounded-l-full",
              matchRating === "no_match"
                ? "bg-slate-200"
                : matchRating === "unknown"
                  ? "bg-slate-200"
                  : greenColor
                    ? "bg-green-300"
                    : "bg-yellow-500",
            )}
          ></div>
          <div
            className={twMerge(
              "grow h-full",
              ["partial_match", "strong_match"].includes(matchRating)
                ? greenColor
                  ? "bg-green-500"
                  : "bg-orange-500"
                : "bg-slate-200",
            )}
          ></div>
          <div
            className={twMerge(
              "grow h-full rounded-r-full",
              matchRating === "strong_match" ? (greenColor ? "bg-green-700" : "bg-red-500") : "bg-slate-200",
            )}
          ></div>
        </div>
      )}
      {!hideLabel && (
        <span
          className={twMerge(
            "font-semibold truncate",
            matchRating === "strong_match"
              ? greenColor
                ? "text-green-700"
                : "text-red-700"
              : matchRating === "partial_match"
                ? greenColor
                  ? "text-green-700"
                  : "text-orange-600"
                : matchRating === "no_match" || matchRating === "unknown"
                  ? "text-slate-500"
                  : greenColor
                    ? "text-green-700"
                    : "text-yellow-600",
          )}
        >
          {getStrengthLabel(matchRating)}
        </span>
      )}
    </div>
  );
};

export default StrengthIndicator;
