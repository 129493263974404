// @ts-nocheck
import { DocsPlayground } from "../../libs/flash_check/src/components/DocsPlayground";
import "../../libs/flash_check/src/components/DocsPlayground.css";
import { getSessionToken } from "@descope/react-sdk";
import { useSearchParams } from "react-router-dom";
import { twJoin } from "tailwind-merge";
import { CheckRounded, ErrorRounded, HourglassTopRounded, WarningRounded } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { format } from "date-fns";
import ParchaApi from "@parcha-src/libs/ParchaApi";
import { AgentRun } from "@parcha-src/types";
import { DocumentFile } from "../../libs/flash_check/src/types/flash";
import ParchaLoadingScreen from "./V2_Home_Page/ParchaLoadingScreen";

const getEndpointUrl = () => {
  const envTier = import.meta.env.VITE_ENV_TIER || "development";
  return envTier === "development"
    ? "localhost:8001"
    : envTier === "staging"
      ? "staging.parcha.ai"
      : envTier === "coinbase"
        ? "coinbase.parcha.ai"
        : envTier === "sales-demo"
          ? "sales-demo.parcha.ai"
          : "demo.parcha.ai";
};

type DocumentType = "business_proof_of_address" | "incorporation" | "ein" | "individual_proof_of_address";

interface JobHistoryResponse {
  items: ExtendedAgentRun[];
  total: number;
  offset: number;
  limit?: number;
}

interface CheckResult {
  command_id: string;
  command_name: string;
  payload: {
    company_name?: string;
    individual_name?: string;
    type: string;
  };
}

interface ExtendedAgentRun extends AgentRun {
  check_results?: CheckResult[];
}

type BadgeConfig = {
  text: string;
  class: string;
};

const DOCUMENT_BADGES: Record<string, BadgeConfig> = {
  "kyb.proof_of_address_verification": { text: "Business Proof of Address", class: "bg-purple-100 text-purple-800" },
  "kyb.ein_document_verification": { text: "EIN", class: "bg-indigo-100 text-indigo-800" },
  "kyb.incorporation_document_verification": { text: "Incorporation", class: "bg-indigo-100 text-indigo-800" },
  "kyc.proof_of_address_verification": { text: "Individual Proof of Address", class: "bg-green-100 text-green-800" },
} as const;

const COMMAND_TO_TAB_TYPE: Record<string, DocumentType> = {
  "kyb.proof_of_address_verification": "business_proof_of_address",
  "kyb.ein_document_verification": "ein",
  "kyb.incorporation_document_verification": "incorporation",
  "kyc.proof_of_address_verification": "individual_proof_of_address",
};

export default function FlashCheckPlayground() {
  const sessionToken = getSessionToken();

  const [searchParams, setSearchParams] = useSearchParams();
  const [document, setDocument] = useState<DocumentFile | null>(null);

  const [selectedTab, setSelectedTab] = useState<DocumentType>("business_proof_of_address");
  const [jobHistory, setJobHistory] = useState<ExtendedAgentRun[]>([]);
  const [selectedJobId, setSelectedJobId] = useState<string | null>(searchParams.get("jobId"));
  const [isLoading, setIsLoading] = useState(false);

  const [response, setResponse] = useState<CheckResult | null>(null);

  const selectedJob = jobHistory.find((job) => job.id === selectedJobId);
  const selectedCheckResult = selectedJob?.check_results?.[0];

  // Update selected tab based on selected job's document type
  useEffect(() => {
    if (selectedCheckResult?.command_id) {
      const documentType = COMMAND_TO_TAB_TYPE[selectedCheckResult.command_id];
      if (documentType) {
        setSelectedTab(documentType);
      }
    }
  }, [selectedCheckResult]);

  useEffect(() => {
    if (!searchParams.get("jobId")) {
      setSelectedJobId(null);
      setResponse(null);
      setDocument(null);
    }
  }, [searchParams]);

  // Update response when initialResponse changes
  useEffect(() => {
    if (selectedCheckResult) {
      setResponse(selectedCheckResult);
    }
  }, [selectedCheckResult]);

  // Fetch job history and handle initial job selection
  const fetchJobHistory = async () => {
    setIsLoading(true); // Always set loading state when fetching
    const api = new ParchaApi(() => {});
    try {
      const response: JobHistoryResponse = await api.getAgentJobHistory(
        getEndpointUrl(),
        ["public-docs-v1", "public-docs-kyc-v1"],
        100,
        0,
        true,
      );
      if (response?.items?.length > 0) {
        setJobHistory(response.items);
        // Only select a job if there's a valid jobId in the URL or if we're updating after a new submission
        const urlJobId = searchParams.get("jobId");
        if (urlJobId && response.items.some((job) => job.id === urlJobId)) {
          setSelectedJobId(urlJobId);
        }
      }
    } catch (error) {
      console.error("Error fetching job history:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchJobHistory();
  }, []); // Only run on mount

  const handleResponse = async (jobResponse: any) => {
    const isIndividualPOA = selectedTab === "individual_proof_of_address";

    // Get the check result from the job's check_results array
    const checkResult = jobResponse.check_results?.[0];

    // Create a new job entry from the job response
    const newJob: ExtendedAgentRun = {
      id: jobResponse.id,
      job_id: jobResponse.id,
      case_id: "",
      status: jobResponse.status,
      progress: 100,
      created_at: jobResponse.created_at,
      started_at: jobResponse.started_at,
      completed_at: jobResponse.completed_at,
      owner_id: jobResponse.owner_id,
      recommendation: jobResponse.recommendation,
      check_results: jobResponse.check_results,
      loading_message: null,
      token_count: null,
      agent_key: jobResponse.agent_id,
      status_messages: [],
      input_payload: jobResponse.input_payload,
    };

    // Add the new job to the top of the history
    setJobHistory((prev) => [newJob, ...prev]);

    // Update response state with the check result
    setResponse(checkResult);

    // Select the new job
    if (newJob.id) {
      setSelectedJobId(newJob.id);
      setSearchParams({ jobId: newJob.id.replace(/-/g, "") });
    }
  };

  const handleJobSelect = (jobId: string | null) => {
    setSelectedJobId(jobId);
    if (jobId) {
      setSearchParams({ jobId: jobId.replace(/-/g, "") });
    } else {
      setSearchParams({});
    }
  };

  const handleReset = () => {
    setSelectedJobId(null);
    setResponse(null);
    setDocument(null);
    setSearchParams({});
  };

  const getStatusColor = (status: string, recommendation: string | null | undefined) => {
    if (status === "complete") {
      return recommendation === "Passed" ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700";
    }
    if (status === "in progress") return "bg-indigo-100 text-indigo-700";
    if (status === "failed") return "bg-red-100 text-red-700";
    return "bg-slate-100 text-slate-700";
  };

  const getStatusIcon = (status: string, recommendation: string | null | undefined): JSX.Element | null => {
    return status === "complete" ? (
      recommendation === "Passed" ? (
        <CheckRounded sx={{ fontSize: "1rem" }} className="text-green-700" aria-hidden="true" />
      ) : (
        <WarningRounded sx={{ fontSize: "1rem" }} className="text-red-700" aria-hidden="true" />
      )
    ) : status === "in progress" ? (
      <HourglassTopRounded sx={{ fontSize: "1rem" }} className="text-indigo-700" aria-hidden="true" />
    ) : status === "failed" || status === "error" ? (
      <ErrorRounded sx={{ fontSize: "1rem" }} className="text-red-700" aria-hidden="true" />
    ) : null;
  };

  const getDocumentTypeBadge = (commandId: string): BadgeConfig => {
    return DOCUMENT_BADGES[commandId] || { text: "DOC", class: "bg-slate-100 text-slate-800" };
  };

  return (
    <div className="h-full w-full flex flex-col">
      {/* Content Area with Sidebar and Main Content */}
      <div className="flex-1 flex overflow-hidden">
        {/* Sidebar - Only show if there are jobs */}
        {jobHistory.length > 0 && (
          <div className="w-[20rem] border-r border-slate-200 bg-white overflow-hidden flex flex-col">
            <div className="flex-1 overflow-y-auto">
              {jobHistory.map((job) => {
                const isIndividualPOA = job.check_results?.[0]?.command_id === "kyc.proof_of_address_verification";
                const displayName = isIndividualPOA
                  ? job.check_results?.[0]?.payload?.individual_name || "-- Not available -- "
                  : job.check_results?.[0]?.payload?.company_name || "-- Not available --";

                const documentBadge = job.check_results?.[0]?.command_id
                  ? getDocumentTypeBadge(job.check_results[0].command_id)
                  : { text: "DOC", class: "bg-slate-100 text-slate-800" };

                return (
                  <button
                    key={job.id}
                    onClick={() => handleJobSelect(job.id || null)}
                    className={twJoin(
                      "w-full p-3 text-left border-b border-slate-100 hover:bg-slate-50 transition-colors",
                      selectedJobId === job.id ? "bg-slate-50" : "bg-white",
                    )}
                  >
                    <div className="flex flex-col gap-2">
                      <div className="flex items-center justify-between">
                        <span className="text-sm font-semibold text-slate-900">{displayName}</span>

                        <span
                          className={twJoin(
                            "flex items-center gap-1 px-2 py-1 rounded-full text-xs font-semibold",
                            getStatusColor(job.status, job.recommendation || null),
                          )}
                        >
                          {getStatusIcon(job.status, job.recommendation || null)}
                          {job.status === "complete" ? (job?.check_results?.[0]?.passed ? "Pass" : "Fail") : job.status}
                        </span>
                      </div>
                      <span className="text-xs text-slate-500">
                        {format(new Date(job.created_at + "Z"), "MMM d, h:mm a")}
                      </span>
                      <div>
                        <span className="text-xs text-slate-500">
                          {getDocumentTypeBadge(job.check_results?.[0]?.command_id).text}
                        </span>
                      </div>
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        )}

        {/* Main Content */}
        <div className="h-[calc(100vh-3.75rem)] flex-1 flex flex-col overflow-y-auto">
          <DocsPlayground
            key={selectedJobId}
            endpointUrl={getEndpointUrl()}
            document={document}
            setDocument={setDocument}
            agentKey={selectedTab === "individual_proof_of_address" ? "public-docs-kyc-v1" : "public-docs-v1"}
            apiKey={sessionToken}
            type={selectedTab}
            initialResponse={selectedJob}
            response={response}
            setResponse={setResponse}
            playgroundMode={false}
            onResponse={handleResponse}
            onReset={handleReset}
            sampleDocumentUrls={{
              individual_proof_of_address:
                "https://parcha-ai-public-assets.s3.us-east-2.amazonaws.com/sample-docs/customer-poai.pdf",
              business_proof_of_address:
                "https://parcha-ai-public-assets.s3.us-east-2.amazonaws.com/sample-docs/parcha-poa.pdf",
              ein: "https://parcha-ai-public-assets.s3.us-east-2.amazonaws.com/sample-docs/parcha_ein.pdf",
              incorporation: "https://parcha-ai-public-assets.s3.us-east-2.amazonaws.com/sample-docs/parcha-inc.pdf",
            }}
          />
        </div>

        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-white/50 backdrop-blur-sm">
            <ParchaLoadingScreen message="Loading Real-Time Document Validation..." size="large" />
          </div>
        )}
      </div>
    </div>
  );
}
