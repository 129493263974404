import { useState, useEffect, useMemo, useRef, MouseEvent, FC } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Chip,
  CircularProgress,
  Typography,
  DialogContentText,
  SelectChangeEvent,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { Tenant, Endpoint } from "../../types";
import ParchaApi from "../../libs/ParchaApi";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";

interface EditTenantAgentsModalProps {
  open: boolean;
  onClose: () => void;
  onUpdateTenant: (updatedTenant: Tenant) => void;
  tenant: Tenant;
  parchaApi: ParchaApi;
  availableAgents: Endpoint[];
}

const EditTenantAgentsModal: FC<EditTenantAgentsModalProps> = ({
  open,
  onClose,
  onUpdateTenant,
  tenant,
  parchaApi,
  availableAgents,
}) => {
  const [selectedAgents, setSelectedAgents] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const scrollRef = useRef<HTMLDivElement>(null);
  const [showGradient, setShowGradient] = useState(false);

  const checkScroll = () => {
    const el = scrollRef.current;
    if (el) {
      const hasMoreContent = el.scrollHeight > el.clientHeight;
      const isAtBottom = Math.abs(el.scrollHeight - el.clientHeight - el.scrollTop) < 2;
      setShowGradient(hasMoreContent && !isAtBottom);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setSelectedAgents(tenant.customAttributes.agents || []);
    setIsLoading(false);
  }, [open, tenant, availableAgents]);

  useEffect(() => {
    checkScroll();
  }, [selectedAgents]);

  const handleAgentChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedAgents(event.target.value as string[]);
  };

  const isChanged = useMemo(() => {
    if (!tenant) return false;
    const currentAgents = new Set(tenant.customAttributes.agents || []);
    const newAgents = new Set(selectedAgents);
    return currentAgents.size !== newAgents.size || [...currentAgents].some((agent) => !newAgents.has(agent));
  }, [tenant, selectedAgents]);

  const handleSubmit = async () => {
    if (isChanged) {
      setConfirmDialogOpen(true);
    }
  };

  const handleConfirmUpdate = async () => {
    setConfirmDialogOpen(false);
    setIsLoading(true);
    try {
      if (!tenant) throw new Error("Tenant data is not available");
      const updatedTenant = {
        ...tenant,
        customAttributes: { ...tenant.customAttributes, agents: selectedAgents },
      };
      await parchaApi.updateTenantAgents(updatedTenant);
      onUpdateTenant(updatedTenant);
      onClose();
    } catch (error) {
      console.error("Error updating tenant agents:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelUpdate = () => {
    setConfirmDialogOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            minWidth: "500px",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: 1,
            borderColor: (theme) => theme.palette.divider,
          }}
        >
          <EditIcon sx={{ marginRight: "8px" }} />
          Edit Agents: {tenant?.name || "Loading..."}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 20,
              top: 20,
              height: 20,
              width: 20,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ minWidth: "400px", pt: 2 }}>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="7rem">
              <CircularProgress />
            </Box>
          ) : (
            <Box paddingY="1.25rem">
              <Typography variant="body1" gutterBottom>
                Select the Agents for this tenant:
              </Typography>
              <FormControl fullWidth margin="dense" sx={{ mt: 2 }}>
                <InputLabel id="agent-keys-label">Agent Keys</InputLabel>
                <Select
                  labelId="agent-keys-label"
                  multiple
                  input={<OutlinedInput label="Agent Keys" />}
                  value={selectedAgents}
                  onChange={handleAgentChange}
                  renderValue={(selected) => {
                    const handleDelete = (agentKeyToRemove: string) => (event: MouseEvent) => {
                      event.preventDefault();
                      event.stopPropagation();

                      const newSelected = (selected as string[]).filter((agentKey) => agentKey !== agentKeyToRemove);
                      setTimeout(() => {
                        return handleAgentChange({
                          target: { value: newSelected },
                        } as SelectChangeEvent<string[]>);
                      }, 0);
                    };

                    return (
                      <Box
                        sx={{
                          position: "relative",
                          width: "100%",
                          height: "fit-content",
                          maxHeight: "8rem",
                        }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Box
                          ref={scrollRef}
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                            width: "100%",
                            height: "100%",
                            maxHeight: "8rem",
                            overflow: "auto",
                            paddingBottom: "0.5rem",
                          }}
                          onScroll={checkScroll}
                          onClick={(e) => e.stopPropagation()}
                        >
                          {(selected as string[]).map((agentKey) => {
                            const agent = availableAgents?.find((a) => a.agentKey === agentKey);
                            return (
                              <Chip
                                key={agentKey}
                                label={agent?.agentName || agentKey}
                                color={agent ? "primary" : "default"}
                                variant="outlined"
                                onDelete={handleDelete(agentKey)}
                                onClick={(e) => e.stopPropagation()}
                                deleteIcon={
                                  <CancelIcon
                                    onMouseDown={(e) => e.stopPropagation()}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }}
                                    sx={{
                                      color: "#5D5FEF",
                                      backgroundColor: "transparent",
                                      "&:hover": {
                                        color: "#5D5FEF",
                                      },
                                      fontSize: "18px",
                                      padding: "2px",
                                    }}
                                  />
                                }
                                sx={{
                                  ...(!agent ? { opacity: 0.6 } : { cursor: "default" }),
                                  "& .MuiChip-deleteIcon": {
                                    color: "#5D5FEF",
                                    opacity: 0.8,
                                    backgroundColor: "transparent",
                                    "&:hover": {
                                      opacity: 1,
                                    },
                                  },
                                }}
                              />
                            );
                          })}
                        </Box>
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: "1.5rem",
                            background: "linear-gradient(to bottom, transparent, rgba(255,255,255,0.9))",
                            pointerEvents: "none",
                            opacity: showGradient ? 1 : 0,
                            transition: "opacity 0.2s",
                          }}
                        />
                      </Box>
                    );
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "20rem",
                        marginTop: "0.25rem",
                        width: "12rem",
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                >
                  {availableAgents?.map((agent) => (
                    <MenuItem key={agent.agentKey} value={agent.agentKey}>
                      {`${agent.agentKey} | ${agent.agentName}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ borderTop: 1, borderColor: "divider", padding: 2 }}>
          <Button onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={isLoading || !isChanged} variant="contained">
            Update Agents
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDialogOpen}
        onClose={handleCancelUpdate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Agent Keys Update"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to update the agent keys for {tenant?.name || "Loading..."}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelUpdate}>Cancel</Button>
          <Button onClick={handleConfirmUpdate} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditTenantAgentsModal;
