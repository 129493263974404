import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import { useParchaApi } from "@parcha-src/hooks/useParchaApi";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { styled } from "@mui/material/styles";
import { UserInvite } from "@parcha-src/types";
import { validateEmail } from "@parcha-src/utils/validation";
import InputWithOverlappingLabel from "../TailwindComponents/InputWithOverlappingLabel";

interface InviteUserModalProps {
  open: boolean;
  onClose: () => void;
  onInvite: (users: UserInvite[]) => Promise<void>;
  agentKey: string;
}

const InviteUserModal: React.FC<InviteUserModalProps> = ({ open, onClose, onInvite, agentKey }) => {
  const [email, setEmail] = useState("");
  const [invites, setInvites] = useState<UserInvite[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [emailError, setEmailError] = useState("");
  const parchaApi = useParchaApi();

  useEffect(() => {
    setHasChanges(invites.length > 0);
  }, [invites]);

  const handleClose = () => {
    if (hasChanges) {
      setShowConfirmation(true);
    } else {
      resetAndClose();
    }
  };

  const handleConfirmClose = () => {
    resetAndClose();
  };

  const resetAndClose = () => {
    setShowConfirmation(false);
    setInvites([]);
    setEmail("");
    setHasChanges(false);
    onClose();
  };

  const handleCancelClose = () => {
    setShowConfirmation(false);
  };

  const handleAddUser = () => {
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }
    if (email && !invites.some((invite) => invite.email === email)) {
      setInvites([...invites, { email, role: "reader", agentKey }]);
      setEmail("");
      setEmailError("");
    }
  };

  const handleRemoveUser = (email: string) => {
    setInvites(invites.filter((invite) => invite.email !== email));
  };

  const handleRoleChange = (email: string, newRole: string) => {
    setInvites(invites.map((invite) => (invite.email === email ? { ...invite, role: newRole } : invite)));
  };

  const handleInvite = async () => {
    setIsSubmitting(true);
    try {
      await onInvite(invites);
      resetAndClose();
    } catch (error) {
      console.error("Error inviting users:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSelectChange = (email: string, value: string) => {
    if (value === "remove") {
      handleRemoveUser(email);
    } else {
      handleRoleChange(email, value);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: 1,
            borderColor: (theme) => theme.palette.divider,
          }}
        >
          <PersonAddIcon sx={{ marginRight: "8px" }} />
          Invite User
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 20,
              top: 20,
              height: 20,
              width: 20,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", alignItems: "flex-start", marginTop: 6, marginBottom: 4 }}>
            <div className="flex-grow mr-1 w-full">
              <InputWithOverlappingLabel
                fieldName="email"
                label="Email"
                value={email}
                onChangeHandler={(e) => {
                  setEmail(e.target.value);
                  setEmailError("");
                }}
                disabled={isSubmitting}
                onKeyDown={(e) => e.key === "Enter" && handleAddUser()}
                required={true}
                className="w-full"
                labelClassName={emailError ? "text-red-500" : ""}
                inputClassName={emailError ? "ring-red-500" : ""}
              />
              {emailError && <p className="mt-1 text-xs text-red-500">{emailError}</p>}
            </div>
            <IconButton onClick={handleAddUser} disabled={isSubmitting || !email}>
              <AddIcon className="text-brand-purple" />
            </IconButton>
          </Box>
          {invites.length > 0 && (
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Members that will be added:
            </Typography>
          )}
          <List>
            {invites.map((invite) => (
              <ListItem key={invite.email}>
                <ListItemText primary={invite.email} />
                <ListItemSecondaryAction>
                  <FormControl>
                    <Select
                      value={invite.role}
                      onChange={(e) => handleSelectChange(invite.email, e.target.value as string)}
                      disabled={isSubmitting}
                      variant="standard"
                      sx={{
                        "&:before": { borderBottom: "none" },
                        "&:after": { borderBottom: "none" },
                        "&:hover:not(.Mui-disabled):before": { borderBottom: "none" },
                        "& .MuiSelect-select": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="developer">Developer</MenuItem>
                      <MenuItem value="analyst">Analyst</MenuItem>
                      <MenuItem value="reader">Reader</MenuItem>
                      <Divider />
                      <MenuItem value="remove" sx={{ color: "error.main" }}>
                        <DeleteOutlineIcon sx={{ mr: 1 }} />
                        Remove Access
                      </MenuItem>
                    </Select>
                  </FormControl>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions sx={{ borderTop: 1, borderColor: "divider", padding: 2 }}>
          <Button onClick={handleInvite} variant="contained" disabled={isSubmitting || invites.length === 0}>
            {isSubmitting ? "Submitting..." : "Add Members"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dismiss Invite confirmation dialog */}
      <Dialog open={showConfirmation} onClose={handleCancelClose}>
        <DialogTitle>Discard invite?</DialogTitle>
        <DialogContent>Are you sure you want to discard this invite?</DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClose}>Cancel</Button>
          <Button onClick={handleConfirmClose} variant="contained" color="error">
            Discard
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InviteUserModal;
