import { formatDistanceToNowStrict } from "date-fns";
import { jobStatusToVerb, getJobTimestamp } from "@/utils";
import StatusIndicator from "./StatusIndicator";
import { AgentRun } from "@/types";

interface JobStatusDisplayProps {
  job: AgentRun | null;
  hideTimestamp?: boolean;
  className?: string;
}

const JobStatusDisplay: React.FC<JobStatusDisplayProps> = ({ job, hideTimestamp = false, className = "" }) => {
  if (!job || !job.status) return null;

  return (
    <div className={`flex items-center gap-2 text-slate-500 text-xs md:text-sm ${className}`}>
      <div>
        <StatusIndicator status={job.status} />
      </div>
      <div>
        <span className="capitalize">{jobStatusToVerb(job.status)}</span>
        {!hideTimestamp && (
          <span className="hidden md:inline-block ml-1">
            {formatDistanceToNowStrict(new Date(`${getJobTimestamp(job)}Z`), { addSuffix: true })}
          </span>
        )}
      </div>
    </div>
  );
};

export default JobStatusDisplay;
