import { useState } from "react";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import InputWithOverlappingLabel from "../TailwindComponents/InputWithOverlappingLabel";
import { AddressType } from "@/types";

interface BusinessRegistrationFormProps {
  formData: {
    registered_business_name: string | null;
    business_registration_number: string | null;
    incorporation_date: string | null;
    address_of_incorporation: AddressType | null;
  };
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  formErrors: string[];
}

const BusinessRegistrationForm: React.FC<BusinessRegistrationFormProps> = ({ formData, setFormData, formErrors }) => {
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const [address, setAddress] = useState<string>("");
  const libraries: "places"[] = ["places"];

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_KEY,
    libraries,
  });

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceSelectionChange = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const addressComponents: AddressType = {
        street_1: null,
        street_2: null,
        city: null,
        country_code: null,
        postal_code: null,
        state: null,
      };
      // Loop through the address components of the selected place
      place.address_components?.forEach((component) => {
        const types = component.types;
        if (types.includes("street_number")) {
          addressComponents.street_1 = `${component.long_name}${
            addressComponents.street_1 ? ` ${addressComponents.street_1}` : ""
          }`;
        }
        if (types.includes("route")) {
          addressComponents.street_1 = `${addressComponents.street_1 || ""} ${component.long_name}`;
        }
        if (types.includes("subpremise")) {
          addressComponents.street_2 = component.long_name;
        }
        if (types.includes("locality")) {
          addressComponents.city = component.long_name;
        }
        if (types.includes("administrative_area_level_1")) {
          addressComponents.state = component.short_name;
        }
        if (types.includes("country")) {
          addressComponents.country_code = component.short_name;
        }
        if (types.includes("postal_code")) {
          addressComponents.postal_code = component.long_name;
        }
      });

      setFormData({ ...formData, address_of_incorporation: addressComponents });
      setAddress(
        `${addressComponents.street_1 || ""}${addressComponents.street_2 ? ` ${addressComponents.street_2}` : ""}${
          addressComponents.city ? ` ${addressComponents.city}` : ""
        }${addressComponents.state ? `, ${addressComponents.state}` : ""}${
          addressComponents.postal_code ? ` ${addressComponents.postal_code}` : ""
        }`.trim(),
      );
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  return isLoaded ? (
    <form>
      <div className="grid grid-cols-4 gap-3">
        <InputWithOverlappingLabel
          fieldName="registered_business_name"
          label="Registered Business Name"
          id="registered_business_name"
          name="registered_business_name"
          onChangeHandler={(e) => setFormData({ ...formData, registered_business_name: e.target.value })}
          className="col-span-3"
          value={formData.registered_business_name || ""}
        />
        <InputWithOverlappingLabel
          fieldName="business_registration_number"
          label="Registration Number"
          id="business_registration_number"
          name="business_registration_number"
          className="grow"
          onChangeHandler={(e) => setFormData({ ...formData, business_registration_number: e.target.value })}
          value={formData.business_registration_number || ""}
        />
        <div className="col-span-3">
          <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceSelectionChange}>
            <InputWithOverlappingLabel
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              value={address}
              onChangeHandler={(e) => setAddress(e.target.value)}
              label="Incorporation Address"
              id="address"
              name="address"
              fieldName="address"
              className="col-span-4"
            />
          </Autocomplete>
        </div>
        <InputWithOverlappingLabel
          label="Date of Incorporation"
          fieldName="incorporation_date"
          value={formData.incorporation_date || ""}
          placeholder="mm/dd/yyyy"
          onChangeHandler={(e) => setFormData({ ...formData, incorporation_date: e.target.value })}
          onFocus={(e) => {
            e.currentTarget.type = "date";
            e.currentTarget.min = "1900-01-01";
            e.currentTarget.max = new Date().toISOString().split("T")[0];
          }}
          onBlur={(e) => {
            e.currentTarget.type = "text";

            const selectedDate = new Date(e.target.value);
            const minDate = new Date("1900-01-01");
            const maxDate = new Date();

            if (selectedDate >= minDate && selectedDate <= maxDate) {
              return;
            }

            setFormData((prevFormData: any) => ({
              ...prevFormData,
              incorporation_date: "",
            }));
          }}
        />
      </div>
      {formErrors.length > 0 && (
        <div className="text-red-600 text-sm mt-4">
          <span className="font-semibold">Please correct the following errors:</span>
          <ul className="list-disc list-outside ml-6">
            {formErrors.map((error, index) => (
              <li className="list-item" key={index}>
                {error}
              </li>
            ))}
          </ul>
        </div>
      )}
    </form>
  ) : (
    <div>Loading...</div>
  );
};

export default BusinessRegistrationForm;
