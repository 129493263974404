import WrappedValue from "./WrappedValue";
import { format, formatDistanceStrict } from "date-fns";
import {
  BusinessCenterRounded,
  PersonSearchRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  ArrowUpwardRounded,
  ArrowDownwardRounded,
} from "@mui/icons-material";
import { useState } from "react";
import SourceItem from "./SourceItem";

type PEPV2PotentialHitProps = {
  pepProfile: {
    is_potential_hit: boolean | undefined;
    full_name: string;
    pep_title: string;
    pep_status: string;
    pep_type: string;
    age_as_of_today: number;
    date_of_birth: string;
    associated_countries: (string | { country_name: string })[];
    associated_addesses: {
      street_1: string;
      street_2: string;
      city: string;
      state: string;
      postal_code: string;
      country_code: string;
    }[];
    roles: {
      start_date: string;
      end_date: string;
      pep_type: string;
      title: string;
      status: string;
    }[];
    weblinks?:
      | {
          has_photo: boolean | null;
          url: string | null;
          date: string | null;
          pep_metadata?: {
            id: string;
            url: string;
            summary_of_the_page: string;
            matching_name_found: boolean;
          };
          metadata?: {
            id: string;
            url: string;
            summary_of_the_page: string;
            matching_name_found: boolean;
          };
          scanned_website?: {
            webpage_title: string;
            webpage_text: string;
            is_valid_url: boolean | null;
          } | null;
        }[]
      | null;
  };
};

const PEPV2PotentialHitV2 = ({ pepProfile }: PEPV2PotentialHitProps) => {
  const [showAllArticles, setShowAllArticles] = useState(false);
  const [showPotentialHitData, setShowPotentialHitData] = useState(true);

  const currentPositions = pepProfile?.roles?.filter((role) => role.end_date === null || role.end_date === "Present");
  const pastPositions = pepProfile?.roles?.filter((role) => role.end_date !== null && role.end_date !== "Present");

  return (
    <div className="w-0 min-w-full flex flex-col gap-y-4 border rounded-lg border-slate-200 text-xs overflow-hidden flex-wrap">
      <div className="w-full flex items-baseline flex-nowrap gap-2 justify-between bg-slate-50 border-b border-slate-200 rounded-t-xl p-4">
        <button className="flex gap-x-2 w-auto" onClick={() => setShowPotentialHitData(!showPotentialHitData)}>
          <div className="rounded-full h-4 w-4">
            {showPotentialHitData ? (
              <ExpandLessRounded sx={{ fontSize: "1rem" }} />
            ) : (
              <ExpandMoreRounded sx={{ fontSize: "1rem" }} />
            )}
          </div>
          <PersonSearchRounded sx={{ fontSize: "1rem" }} className="text-slate-900 w-4 h-4" />
          <div className="flex grow-0 flex-wrap gap-y-1 text-slate-700">
            <div className="flex flex-col">
              <span className="font-semibold capitalize">
                {pepProfile.full_name}
                {pepProfile.age_as_of_today ? `, ${pepProfile.age_as_of_today}` : ""}
              </span>
              <span className="font-light text-slate-500 max-w-[30ch] truncate">
                {pepProfile.associated_countries.length > 0
                  ? `${pepProfile.associated_countries
                      .map((country) =>
                        country !== null && typeof country === "object" && "country_name" in country
                          ? country.country_name
                          : country,
                      )
                      .join(", ")}`
                  : ""}
              </span>
            </div>
          </div>
        </button>
        <WrappedValue text="Potential Match" level="normal" />
      </div>

      {showPotentialHitData && (
        <div className="w-full flex flex-col gap-y-4 px-4 pb-4">
          <span className="font-semibold">PEP Type</span>
          <span className="font-light text-slate-700">{pepProfile.pep_type}</span>
          {currentPositions?.length > 0 && (
            <div className="flex flex-col gap-y-3">
              <span className="font-semibold">Current Position{currentPositions.length > 1 ? "s" : ""}</span>
              <div className="grid grid-cols-2 gap-3">
                {currentPositions.map((position, index) => (
                  <PositionEntry
                    key={index}
                    position={position.title}
                    startDate={
                      position.start_date
                        ? format(new Date(Number(position.start_date.split("-")[0]), 0), "yyyy")
                        : null
                    }
                    endDate={
                      position.end_date
                        ? position.end_date === "Present"
                          ? "Present"
                          : format(new Date(Number(position.end_date.split("-")[0]), 0), "yyyy")
                        : null
                    }
                    duration={
                      position.start_date && position.end_date
                        ? position.end_date === "Present"
                          ? formatDistanceStrict(new Date(position.start_date), new Date())
                          : formatDistanceStrict(new Date(position.start_date), new Date(position.end_date))
                        : null
                    }
                  />
                ))}
              </div>
            </div>
          )}
          {pastPositions?.length > 0 && (
            <div className="flex flex-col gap-y-3 ">
              <span className="font-semibold">Past Position{pastPositions.length > 1 ? "s" : ""}</span>
              <div className="grid grid-cols-2 gap-3">
                {pastPositions.map((position, index) => (
                  <PositionEntry
                    key={index}
                    position={position.title}
                    startDate={
                      position.start_date
                        ? format(new Date(Number(position.start_date.split("-")[0]), 0), "yyyy")
                        : "Not available"
                    }
                    endDate={
                      position.end_date
                        ? position.end_date === "Present"
                          ? "Present"
                          : format(new Date(Number(position.end_date.split("-")[0]), 0), "yyyy")
                        : "Not available"
                    }
                    duration={
                      position.start_date && position.end_date
                        ? position.end_date === "Present"
                          ? formatDistanceStrict(new Date(Number(position.start_date.split("-")[0]), 0), new Date())
                          : formatDistanceStrict(
                              new Date(Number(position.start_date.split("-")[0]), 0),
                              new Date(Number(position.end_date.split("-")[0]), 0),
                            )
                        : null
                    }
                  />
                ))}
              </div>
            </div>
          )}

          {showAllArticles && (
            <div className="w-full flex flex-col gap-y-3 ">
              {pepProfile.weblinks &&
                pepProfile?.weblinks?.length > 0 &&
                pepProfile.weblinks?.map((weblink, index) => (
                  <SourceItem
                    key={index}
                    when={weblink.date ?? undefined}
                    hasPhoto={weblink.has_photo ?? undefined}
                    wasRemoved={Boolean(weblink.scanned_website && !weblink.scanned_website.is_valid_url)}
                    summary={weblink?.metadata?.summary_of_the_page ?? undefined}
                    text={weblink?.scanned_website?.webpage_text ?? undefined}
                    title={weblink?.scanned_website?.webpage_title ?? undefined}
                    url={weblink.url ?? undefined}
                  />
                ))}
            </div>
          )}

          {pepProfile && pepProfile?.weblinks?.length && pepProfile?.weblinks?.length > 0 ? (
            <button
              type="button"
              disabled={!pepProfile.weblinks?.length}
              className="mt-1 box-border inline-flex items-center gap-x-1.5 rounded-lg px-3 py-2 w-full text-center justify-center border border-solid border-slate-300"
              onClick={() => setShowAllArticles(!showAllArticles)}
            >
              {showAllArticles ? (
                <>
                  {`Hide ${pepProfile.weblinks?.length ?? 0} Source${pepProfile.weblinks?.length === 1 ? "" : "s"}`}
                  <ArrowUpwardRounded sx={{ fontSize: "1rem" }} className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                </>
              ) : (
                <>
                  <span>{`Show ${pepProfile.weblinks?.length ?? 0} Source${
                    pepProfile.weblinks?.length === 1 ? "" : "s"
                  }`}</span>
                  <ArrowDownwardRounded sx={{ fontSize: "1rem" }} className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                </>
              )}
            </button>
          ) : null}
        </div>
      )}
    </div>
  );
};

const PositionEntry = ({
  position,
  startDate,
  endDate,
  duration,
}: {
  position: string;
  startDate: string | null;
  endDate: string | null;
  duration: string | null;
}) => {
  return (
    <div className="flex gap-x-2">
      <BusinessCenterRounded sx={{ fontSize: "1rem" }} className="text-slate-700" />
      <div className="flex flex-col gap-y-1">
        <span className="text-slate-700">{position}</span>
        <span className="text-xs text-slate-500">
          {startDate}
          {startDate && endDate ? "-" : null}
          {endDate} {duration ? "•" : null} {duration?.includes("second") ? "about 1 year" : duration}
        </span>
      </div>
    </div>
  );
};

export default PEPV2PotentialHitV2;
