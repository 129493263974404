type Role = "reader" | "analyst" | "developer" | "admin";

const roleHierarchy: Record<Role, number> = {
  reader: 0,
  analyst: 1,
  developer: 2,
  admin: 3,
};

const rolePermissions: Record<Role, string[]> = {
  reader: ["read_reports"],
  analyst: ["read_reports", "write_reports"],
  developer: ["read_reports", "write_reports", "manage_keys"],
  admin: ["read_reports", "write_reports", "manage_keys", "manage_users"],
};

export class RBACService {
  private userRoles: Map<string, Role> = new Map();
  private isTenantAdmin: boolean = false;

  constructor(roles: string[]) {
    this.parseRoles(roles);
  }

  private parseRoles(roles: string[]) {
    roles.forEach((role) => {
      if (role === "Tenant Admin") {
        this.isTenantAdmin = true;
      } else {
        const [agentKey, roleType] = role.split("_");
        if (agentKey && roleType) {
          const currentRole = this.userRoles.get(agentKey);
          if (!currentRole || roleHierarchy[roleType as Role] > roleHierarchy[currentRole]) {
            this.userRoles.set(agentKey, roleType as Role);
          }
        }
      }
    });
  }

  getHighestRole(agentKey: string, selectedEndpoint?: { rbac: boolean } | null): Role | null {
    if (selectedEndpoint && selectedEndpoint.rbac === false) {
      return "developer";
    }

    const role = this.userRoles.get(agentKey);
    if (!role) return null;

    const roleHierarchyArray = Object.entries(roleHierarchy).sort((a, b) => b[1] - a[1]);
    for (const [highestRole, _] of roleHierarchyArray) {
      if (roleHierarchy[role] >= roleHierarchy[highestRole as Role]) {
        return highestRole as Role;
      }
    }

    return role;
  }

  checkIsTenantAdmin(): boolean {
    return this.isTenantAdmin;
  }

  checkDynamicRole(agentKey: string, role: Role): boolean {
    if (this.isTenantAdmin) {
      return true;
    }

    const userRole = this.userRoles.get(agentKey);
    if (!userRole) {
      return false;
    }

    return roleHierarchy[userRole] >= roleHierarchy[role];
  }
}
