import React, { ReactNode } from "react";
import { Box, Typography } from "@mui/material";

interface AdminNavbarProps {
  title: string;
  leftContent?: ReactNode;
  rightContent?: ReactNode;
}

const AdminNavbar: React.FC<AdminNavbarProps> = ({ title, leftContent, rightContent }) => {
  return (
    <Box className="w-full h-[3.80rem] border-b border-slate-200 flex items-center px-4">
      <Box className="flex items-center flex-grow">
        <Typography variant="h6" className="mr-4">
          {title}
        </Typography>
        {leftContent && <Box className="ml-4">{leftContent}</Box>}
      </Box>
      {rightContent && <Box className="flex items-center">{rightContent}</Box>}
    </Box>
  );
};

export default AdminNavbar;
