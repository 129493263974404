import ParchaLogo from "@/assets/Parcha_Logo_With_Name.png";
import { Descope, useUser, useSession, useDescope } from "@descope/react-sdk";
import { useParchaApi } from "@/hooks/useParchaApi";
import {
  AutoAwesome,
  BusinessRounded,
  FactCheckRounded,
  LanguageRounded,
  LockRounded,
  PublicRounded,
  ReadMore,
  LoginRounded,
} from "@mui/icons-material";
import { ChangeEvent, useState, ReactNode, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import COUNTRIES from "@/data/countries";
import { useNavigate, useSearchParams, useLocation, useParams, Outlet } from "react-router-dom";
import { twJoin, twMerge } from "tailwind-merge";
import { isURL } from "validator";
import { CircularProgress } from "@mui/material";

const PublicReportForm = () => {
  const [formData, setFormData] = useState({
    business_name: "",
    registered_business_name: "",
    description: "",
    website: "",
    country_of_operation: "",
  });

  const [formErrors, setFormErrors] = useState<{ website: string | null; country_of_operation: string | null }>({
    website: null,
    country_of_operation: null,
  });

  const parchaApi = useParchaApi();
  const { isUserLoading, user } = useUser();
  const { reportJobId } = useParams();
  const [searchParams] = useSearchParams();
  const session = useSession();
  const sdk = useDescope();
  const navigate = useNavigate();
  const location = useLocation();
  const [stage, setStage] = useState<
    "loading" | "website_only" | "more_info" | "authentication" | "reports_list" | null
  >(reportJobId ? "reports_list" : "loading");
  const [error, setError] = useState<string | null>(null);
  const [isQuotaReached, setIsQuotaReached] = useState<boolean>(false);
  const [isGeneratingReport, setIsGeneratingReport] = useState<boolean>(false);
  const [wasHttpRemoved, setWasHttpRemoved] = useState(false);

  // Check if URL is "/create-report" and user is authenticated, then redirect
  useEffect(() => {
    if (location.pathname === "/create-report" && session.isAuthenticated && !isUserLoading) {
      // First redirect to the labs create report page
      navigate("/labs/create-report");
    } else if (location.pathname === "/create-report" && !session.isAuthenticated && !isUserLoading) {
      navigate("/signin?redirect=labs/create-report");
    }

    if (reportJobId || searchParams.get("new") === "true") {
      return;
    }

    // Then check if the user has any existing reports
    const fetchJobs = async () => {
      try {
        const envTier = import.meta.env.VITE_ENV_TIER || "development";
        const endpointUrl =
          envTier === "development"
            ? "localhost:8001"
            : envTier === "staging"
              ? "staging.parcha.ai"
              : envTier === "coinbase"
                ? "coinbase.parcha.ai"
                : envTier === "sales-demo"
                  ? "sales-demo.parcha.ai"
                  : "demo.parcha.ai";

        const jobs = await parchaApi.getAgentJobHistory(endpointUrl, "public-bdd", 1, 0);

        // If there's at least one job, navigate to the first job's detail page
        if (jobs && jobs.items.length > 0) {
          setStage("reports_list");
          navigate(`/labs/create-report/${jobs.items[0].id}`);
        } else {
          setStage("website_only");
          navigate(`/labs/create-report`);
        }
      } catch (error) {
        console.error("Error fetching job history:", error);
        setStage("website_only");
        navigate(`/labs/create-report`);
      }
    };

    fetchJobs();
  }, [location.pathname, session.isAuthenticated, isUserLoading, navigate, parchaApi, reportJobId, searchParams]);

  useEffect(() => {
    const isDescopeLoginFlow = searchParams.get("descope-login-flow");

    if (isDescopeLoginFlow) {
      setStage("authentication");
    } else if (reportJobId) {
      setStage("reports_list");
    } else if (searchParams.get("new") === "true") {
      setStage("website_only");
    } else {
      setStage("loading");
    }
  }, [searchParams]);

  useEffect(() => {
    if (!session.isAuthenticated || isUserLoading) return;

    const localStorageData = localStorage.getItem("public-bdd-form-data");
    if (localStorageData) {
      setFormData(JSON.parse(localStorageData));
      sdk.refresh().then(() => {
        localStorage.removeItem("public-bdd-form-data");
        generateReport(JSON.parse(localStorageData));
      });
    }
  }, [session.isAuthenticated]);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;

    if (target.id === "parcha-form") {
      generateReport(formData);
    } else {
      window.location.reload();
    }
  };

  const generateReport = (data: any) => {
    if (session.isAuthenticated) {
      setIsGeneratingReport(true);
      const input = {
        agent_key: "public-bdd",
        kyb_schema: {
          id: `parcha-${uuidv4().replace("-", "").substring(0, 8)}`,
          self_attested_data: {
            business_name: data.business_name.length > 0 ? data.business_name : undefined,
            registered_business_name:
              data.registered_business_name.length > 0 ? data.registered_business_name : undefined,
            website: data.website?.length > 0 ? `https://${data.website}` : undefined,
            address_of_operation: {
              country_code: data.country_of_operation,
            },
            description: data.description.length > 0 ? data.description : undefined,
          },
        },
        run_in_parallel: true,
      };

      const envTier = import.meta.env.VITE_ENV_TIER || "development";

      const endpointUrl =
        envTier === "development"
          ? "localhost:8001"
          : envTier === "staging"
            ? "staging.parcha.ai"
            : envTier === "coinbase"
              ? "coinbase.parcha.ai"
              : envTier === "sales-demo"
                ? "sales-demo.parcha.ai"
                : "demo.parcha.ai";

      parchaApi.enqueueAgent(endpointUrl, input, "kyb").then((res) => {
        if (res.job_id) {
          navigate(`/labs/create-report/${res.job_id}`);
          setStage("reports_list");
          // reset form data
          setFormData({
            business_name: "",
            registered_business_name: "",
            description: "",
            website: "",
            country_of_operation: "",
          });
        } else if (res.status === "quota_reached") {
          setIsQuotaReached(true);
        } else {
          setError(res.message);
        }
        setIsGeneratingReport(false);
      });
    } else {
      localStorage.setItem("public-bdd-form-data", JSON.stringify(formData));
      setIsGeneratingReport(false);
      setStage("authentication");
    }
  };

  const onFieldChangeHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const value = e.target.value;
    if (e.target.name === "website" && value && value.length > 0) {
      let websiteValue;

      if (value.startsWith("https://") || value.startsWith("http://")) {
        websiteValue = value;
        setWasHttpRemoved(true);
      } else {
        websiteValue = "https://" + value;
        setWasHttpRemoved(false);
      }

      if (isURL(websiteValue, { protocols: ["https"], require_tld: true, require_protocol: true })) {
        setFormErrors({ ...formErrors, [e.target.name]: null });
      } else {
        setFormErrors({
          ...formErrors,
          [e.target.name]:
            `Invalid URL. Make sure it includes a top-level domain such as .com, .io, or .ai. For example, parcha.com.`,
        });
      }
    } else if (e.target.name === "country_of_operation") {
      if (value === "") {
        setFormErrors({
          ...formErrors,
          [e.target.name]: "Please select a country of operation",
        });
      } else {
        setFormErrors({ ...formErrors, [e.target.name]: null });
      }
    } else {
      setFormErrors({ ...formErrors, [e.target.name]: null });
      setWasHttpRemoved(false);
    }
    setFormData({ ...formData, [e.target.name]: value.replaceAll("https://", "").replaceAll("http://", "") });
  };

  return (
    <div className={`w-0 min-w-full h-full overflow-y-auto`}>
      {/* <div className="h-4 p-8 flex items-center justify-between w-full">
        {location.pathname === "/create-report" && (
          <img src={ParchaLogo} alt="Parcha Logo" className="object-cover h-8 w-fit" />
        )}
        {!user && (
          <a
            href="/signin"
            className="inline-flex items-center gap-x-1 bg-brand-purple text-white px-3 py-1.5 rounded-md text-sm font-medium"
          >
            <LoginRounded sx={{ fontSize: "1rem" }} className="mr-1" />
            Sign In
          </a>
        )}
      </div> */}

      {stage && ["website_only", "more_info", "authentication"].includes(stage) && (
        <div className="flex flex-col gap-y-[1rem] md:gap-y-[2.5rem] items-center mx-auto px-8 max-w-[400px] md:px-0 md:w-[400px] min-h-full py-[3.75rem]">
          {/* {stage && stage === "loading" && <ParchaLoadingScreen message="Loading..." size="large" />} */}
          {stage && ["website_only", "more_info"].includes(stage) && (
            <>
              <div className="flex shrink-0 items-center justify-center rounded-full w-[3rem] h-[3rem] bg-indigo-100">
                <FactCheckRounded sx={{ fontSize: "1.5rem" }} className="text-brand-purple" />
              </div>
              <div className="flex flex-col gap-y-[1.25rem] md:gap-y-[3.75rem]">
                <div className="flex flex-col items-center gap-y-1">
                  <h1 className="text-[1.5rem] md:text-[2rem] leading-tight font-bold text-center">
                    Generate a compliance report in minutes.
                  </h1>
                  <span className="text-sm md:text-base text-slate-500 text-center md:text-left">
                    Provide the business's official website to get started.
                  </span>
                </div>
                <form className="min-w-full" onSubmit={handleFormSubmit} id="parcha-form">
                  {stage === "website_only" && (
                    <div className="flex flex-col gap-y-8 md:gap-y-10">
                      <div className="flex flex-col gap-y-6 md:gap-y-8">
                        <BDDInputWithOverlappingLabel
                          placeholder="www.website.com"
                          label="Website"
                          required
                          fieldName="website"
                          value={formData.website}
                          onChangeHandler={onFieldChangeHandler}
                          icon={
                            <div className="flex gap-x-2 items-center">
                              <LanguageRounded sx={{ fontSize: "1.25rem" }} className="text-slate-500" />
                              <span
                                className={twMerge(
                                  wasHttpRemoved && formData.website?.length === 0
                                    ? "animate-pulse duration-900 linear text-brand-purple"
                                    : formData.website?.length > 0
                                      ? "text-slate-900"
                                      : "text-slate-400",
                                )}
                              >
                                https://
                              </span>
                            </div>
                          }
                          inputClassName="pl-[6.10rem]"
                          error={formErrors?.website ?? null}
                        />
                      </div>
                    </div>
                  )}
                  {stage === "more_info" && (
                    <div className="flex flex-col gap-y-8 md:gap-y-10">
                      <div className="flex flex-col gap-y-6 md:gap-y-8">
                        <BDDInputWithOverlappingLabel
                          label="Business Name"
                          placeholder="Enter a business of interest"
                          required
                          fieldName="business_name"
                          value={formData.business_name}
                          onChangeHandler={onFieldChangeHandler}
                          icon={<BusinessRounded sx={{ fontSize: "1.25rem" }} className="text-slate-500" />}
                        />
                        <BDDInputWithOverlappingLabel
                          label={
                            <>
                              Registered Business Name <span className="font-thin">(Optional)</span>
                            </>
                          }
                          placeholder="Provide the name used for business registration"
                          fieldName="registered_business_name"
                          value={formData.registered_business_name}
                          onChangeHandler={onFieldChangeHandler}
                          icon={<BusinessRounded sx={{ fontSize: "1.25rem" }} className="text-slate-500" />}
                        />
                        <div className="relative">
                          <label
                            htmlFor="description"
                            className="absolute -top-2 left-2 inline-block bg-white px-1 text-sm font-medium text-slate-900 w-auto truncate"
                          >
                            Business Description
                            <span className="text-red-500 ml-0.5">*</span>
                          </label>
                          <textarea
                            rows={4}
                            name="description"
                            id="description"
                            required
                            placeholder="Give a brief explanation of what the business does"
                            onChange={onFieldChangeHandler}
                            className="box-border block w-full rounded-md py-3 text-slate-900 ring-1 ring-inset ring-slate-300 border border-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-brand-purple"
                            value={formData.description}
                          />
                        </div>
                        <div className="relative">
                          <label className="absolute -top-2 left-2 inline-block bg-white px-1 text-sm font-medium text-slate-900 w-auto truncate">
                            Country
                            <span className="text-red-500 ml-0.5">*</span>
                          </label>

                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">
                            <PublicRounded sx={{ fontSize: "1.25rem" }} className="text-slate-500" />
                          </div>

                          <select
                            id="country_of_operation"
                            name="country_of_operation"
                            className={twMerge(
                              "box-border block w-full rounded-md py-3 pl-12 ring-1 ring-inset ring-slate-300 border border-slate-300 focus:ring-2 focus:ring-inset focus:ring-brand-purple",
                              formData.country_of_operation === "" ? "text-slate-400" : "text-slate-900",
                            )}
                            value={formData.country_of_operation}
                            onChange={onFieldChangeHandler}
                            required
                          >
                            <option value="">Select a country of operation</option>
                            {COUNTRIES.map((country) => (
                              <option key={country.value} value={country.value}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                          {formErrors.country_of_operation && (
                            <span className="text-sm text-red-700">{formErrors.country_of_operation}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col gap-y-3 mt-6">
                    {isQuotaReached ? (
                      <a
                        href="https://calendly.com/ajasver/request-a-demo"
                        className="w-fit place-self-center inline-flex items-center gap-x-1.5 rounded-md bg-brand-purple px-3 py-2 text-sm md:text-base font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
                        target="_blank"
                        rel="norefferrer noopener"
                      >
                        <LockRounded
                          className="-ml-0.5 h-4 w-4 text-white"
                          sx={{ fontSize: "1rem" }}
                          aria-hidden="true"
                        />
                        Get Full Access
                      </a>
                    ) : (
                      <button
                        type="submit"
                        className="w-[18rem] place-self-center justify-center inline-flex items-center gap-x-3 rounded-md bg-brand-purple px-3 py-2 text-sm md:text-base font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
                        disabled={
                          (stage === "website_only" && !formData.website.length) ||
                          (stage === "more_info" &&
                            (!formData.business_name.length ||
                              !formData.description.length ||
                              !formData.country_of_operation)) ||
                          isGeneratingReport ||
                          Object.values(formErrors).some(Boolean)
                        }
                      >
                        {isGeneratingReport ? (
                          <>
                            <CircularProgress
                              color="inherit"
                              size="1.25rem"
                              className="-ml-2 animate-spin text-white h-5 w-5"
                            />
                            Generating Report...
                          </>
                        ) : (
                          <>
                            <AutoAwesome className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                            Generate Compliance Report
                          </>
                        )}
                      </button>
                    )}
                    {error && <div className="text-center p-5 rounded-lg bg-red-100 text-red-700">{error}</div>}
                    {isQuotaReached && (
                      <div className="flex flex-col items-center p-5 rounded-lg bg-red-100 text-red-700">
                        <span>{`You have used your ${user.customAttributes?.publicBddQuota} free reports.`}</span>
                        <span className="font-semibold">To get more, request full access.</span>
                      </div>
                    )}
                  </div>

                  {stage === "website_only" && (
                    <div className="flex flex-col gap-y-6 md:gap-y-8 mt-6 md:mt-8">
                      <div className="flex items-center text-base">
                        <div className="flex-grow border-t border-slate-300"></div>
                        <span className="mx-4 text-slate-500">Don't know the website?</span>
                        <div className="flex-grow border-t border-slate-300"></div>
                      </div>

                      <button
                        type="button"
                        onClick={() => {
                          setStage("more_info");
                          setFormData({ ...formData, website: "" });
                        }}
                        className="w-fit place-self-center inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-xs font-medium text-slate-900 border border-slate-300 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple"
                      >
                        <ReadMore className="-ml-0.5 h-3 w-3" aria-hidden="true" />
                        Provide More Information
                      </button>
                    </div>
                  )}
                  {stage === "more_info" && (
                    <div className="flex flex-col gap-y-6 md:gap-y-8 mt-6 md:mt-8">
                      <div className="flex items-center text-base">
                        <div className="flex-grow border-t border-slate-300"></div>
                        <span className="mx-4 text-slate-500">Already have a website?</span>
                        <div className="flex-grow border-t border-slate-300"></div>
                      </div>

                      <button
                        type="button"
                        onClick={() => setStage("website_only")}
                        className="w-fit place-self-center inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-xs font-medium text-slate-900 border border-slate-300 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple"
                      >
                        <LanguageRounded sx={{ fontSize: "1rem" }} className="-ml-0.5 h-3 w-3" aria-hidden="true" />
                        Provide a website
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </>
          )}

          {stage === "authentication" && (
            <Descope
              flowId="public-bdd-flow-2-no-personal-email"
              onSuccess={() => {
                setStage("website_only");
              }}
            />
          )}
        </div>
      )}

      {stage === "reports_list" && <Outlet />}
    </div>
  );
};

interface InputWithOverlappingLabelProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  fieldName: string;
  inputClassName?: string;
  label: ReactNode;
  labelClassName?: string;
  icon: ReactNode;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  required?: boolean;
  error?: string | null;
}

export const BDDInputWithOverlappingLabel = ({
  className,
  fieldName,
  inputClassName,
  labelClassName,
  label,
  value,
  onChangeHandler,
  required = false,
  icon,
  error,
  ...rest
}: InputWithOverlappingLabelProps) => {
  return (
    <div className="flex flex-col gap-y-1">
      <div className={`relative ${className ? className : ""}`}>
        <label
          htmlFor={fieldName}
          className={`absolute -top-2.5 left-2 block bg-white px-[8px] text-sm text-slate-900 w-auto truncate ${
            labelClassName ? labelClassName : ""
          }`}
        >
          {label}
          {required && <span className="text-red-500 ml-0.5">*</span>}
        </label>

        {icon && <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">{icon}</div>}
        <input
          type="text"
          name={fieldName}
          id={fieldName}
          className={twJoin(
            "box-border block w-full rounded-md py-3 pl-12 text-slate-900 ring-1 ring-inset ring-slate-300 border border-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-brand-purple",
            inputClassName ? inputClassName : "",
            error ? "ring-red-700 focus:ring-red-700" : "focus:ring-brand-purple",
          )}
          onChange={onChangeHandler}
          value={value}
          {...rest}
        />
      </div>
      {error && <span className="text-sm text-red-700">{error}</span>}
    </div>
  );
};

export default PublicReportForm;
