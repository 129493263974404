import { UIDocument, ProofOfAddressDocument } from "@/types";
import { AddressValueProps } from "./AddressValue";
import { snakeCaseToProperCase, tryToParseDate } from "@/utils";
import PDFThumbnail from "./PDFThumbnail";
import ValueContainer from "./ValueContainer";
import { WarningRounded } from "@mui/icons-material";

type KYCProofOfAddressCardProps = {
  level: "normal" | "success" | "failure";
  name: string;
  title?: string | null;
  address: AddressValueProps;
  proofOfAddressDocuments: (UIDocument & ProofOfAddressDocument)[];
  handleDocumentValidationClick?: (checkResult: any, document: any) => void;
  checkResult?: any;
};

const KYCProofOfAddressCard = ({
  level,
  address,
  name,
  title,
  proofOfAddressDocuments,
  handleDocumentValidationClick,
  checkResult,
}: KYCProofOfAddressCardProps) => {
  if (!name) return null;

  const { street1, street2, city, state, countryCode, postalCode } = address;
  const addressLine1 = `${street1 ?? ""}${street2 ? `, ${street2}` : ""}`;
  const addressLine2 = `${city ?? ""}${state ? `, ${state}` : ""}${countryCode ? ` ${countryCode}` : ""}${
    postalCode ? ` ${postalCode}` : ""
  }`;

  const document = proofOfAddressDocuments.length > 0 ? proofOfAddressDocuments[0] : null;

  return (
    <div className="grid grid-cols-2 gap-4 text-xs w-[40rem]">
      <div className="flex flex-col gap-y-2">
        <ValueContainer label="Name" value={name} />
        {title && <ValueContainer label="Title" value={title} />}
        {address && !Object.entries(address).every(([k, v]) => k === "type" || v === null || v === "unverified") && (
          <ValueContainer
            label="Address"
            value={
              <>
                <p>{addressLine1}</p>
                <p>{addressLine2}</p>
              </>
            }
          />
        )}
        {document?.documentType && (
          <ValueContainer label="Document Type" value={snakeCaseToProperCase(document.documentType)} />
        )}
        {document?.documentDate && (
          <ValueContainer label="Document Date" value={tryToParseDate(document.documentDate.replaceAll("-", "/"))} />
        )}
      </div>
      {document && (
        <div className="flex flex-col gap-y-2">
          <div className="h-full">
            <PDFThumbnail
              documentURL={document.url}
              documentName={document.fileName}
              onDocumentNameClick={() =>
                handleDocumentValidationClick?.(checkResult, {
                  file_name: document.fileName,
                  url: document.url,
                })
              }
            />
          </div>
          {document.fraudVerificationData &&
            document.fraudVerificationData.verification_data
              .filter((verificationDataEntry) => verificationDataEntry.type === "RISK")
              .map((verificationDataEntry) => (
                <div key={verificationDataEntry.description} className="flex flex-col gap-y-3">
                  <div className="flex gap-x-2 text-yellow-700">
                    <WarningRounded sx={{ fontSize: "1rem", height: "1rem", width: "1rem" }} />
                    <p className="text-xs">
                      <span className="font-semibold">Fraud Risk:</span> {verificationDataEntry.description}
                    </p>
                  </div>
                </div>
              ))}
        </div>
      )}
    </div>
  );
};

export default KYCProofOfAddressCard;
