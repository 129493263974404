import { CalendarTodayRounded, LocationOnRounded, PersonSearchRounded } from "@mui/icons-material";
import { twJoin } from "tailwind-merge";
import { MatchPill } from "./AdverseMediaV2";
import { Tooltip, styled, TooltipProps } from "@mui/material";

const MatchExplanationTooltip = styled(({ className, ...props }: { className?: string } & TooltipProps) => (
  <Tooltip {...props} title={props.title} componentsProps={{ tooltip: { className } }}>
    {props.children}
  </Tooltip>
))(`    
    background-color: rgba(15, 23, 42, 0.8);   
    padding: 8px 16px;

    & .MuiTooltip-arrow {
      color: rgba(15, 23, 42, 0.8);
    }
`);

const BusinessPerpetratorTable = ({
  mediaHit,
  input,
  hitTableLabel,
  greenColor = false,
}: {
  mediaHit: any;
  input: any;
  hitTableLabel: string;
  greenColor?: boolean;
}) => {
  const mediaHitObj = mediaHit.profile_review || mediaHit;
  const businessNameMatch = mediaHitObj.business_name_match;
  const incorporationAddressMatch = mediaHitObj.incorporation_address_match;
  const inputAddressOfIncorporation = `${input.address_of_incorporation?.street_1 ?? ""} ${
    input.address_of_incorporation?.street_2 ?? ""
  } ${input.address_of_incorporation?.city ?? ""} ${input.address_of_incorporation?.state ?? ""} ${
    input.address_of_incorporation?.postal_code ?? ""
  } ${input.address_of_incorporation?.country_code ?? ""}`;
  const inputAddressOfOperation = `${input.address_of_operation?.street_1 ?? ""} ${
    input.address_of_operation?.street_2 ?? ""
  } ${input.address_of_operation?.city ?? ""} ${input.address_of_operation?.state ?? ""} ${
    input.address_of_operation?.postal_code ?? ""
  } ${input.address_of_operation?.country_code ?? ""}`;
  const operationAddressMatch = mediaHitObj.operation_address_match;
  const associatedAddresses = mediaHit.associated_addresses;

  return (
    <table className="w-0 min-w-full table-auto leading-normal text-xs border-b border-slate-300">
      <thead>
        <tr className="text-left bg-slate-50">
          <th className="py-2 px-1.5 pl-5"></th>
          <th className="py-2 px-1.5 text-slate-500">Customer Information</th>
          <th className="py-2 px-1.5">{hitTableLabel}</th>
          <th className="py-2 px-1.5"></th>
        </tr>
      </thead>
      <tbody className="w-full border-separate border-spacing-2 divide-y divide-slate-200">
        <tr className="text-left">
          <td className="whitespace-nowrap px-1.5 py-2 pl-5">
            <div className="flex gap-x-2 items-center">
              <PersonSearchRounded sx={{ fontSize: "1rem" }} />
              <span>Business Name</span>
            </div>
          </td>
          <td className="text-slate-900 truncate px-1.5 py-2">
            <span>{input.business_name}</span>
          </td>
          <td
            className={twJoin(
              "truncate px-1.5 py-2",
              businessNameMatch?.exact_match
                ? "text-red-700 font-semibold"
                : businessNameMatch?.partial_match
                  ? "text-orange-600"
                  : businessNameMatch?.mismatch
                    ? "text-yellow-800"
                    : "text-slate-600 italic",
            )}
          >
            <span className={twJoin(businessNameMatch?.exact_match ? "font-semibold" : "")}>
              {businessNameMatch?.display_name || businessNameMatch?.name}
            </span>
          </td>
          <td className="px-1.5 py-2 text-right">
            <div className="inline-flex">
              <>
                {businessNameMatch?.exact_match ? (
                  <MatchExplanationTooltip
                    className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                    title={businessNameMatch.explanation || ""}
                    placement="top"
                    arrow
                  >
                    <div>
                      <MatchPill matchRating="strong_match" />
                    </div>
                  </MatchExplanationTooltip>
                ) : businessNameMatch?.partial_match ? (
                  <MatchExplanationTooltip
                    className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                    title={businessNameMatch.explanation || ""}
                    placement="top"
                    arrow
                  >
                    <div>
                      <MatchPill matchRating="partial_match" />
                    </div>
                  </MatchExplanationTooltip>
                ) : businessNameMatch?.mismatch ? (
                  <MatchExplanationTooltip
                    className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                    title={businessNameMatch.explanation || ""}
                    placement="top"
                    arrow
                  >
                    <div>
                      <MatchPill matchRating="mismatch" />
                    </div>
                  </MatchExplanationTooltip>
                ) : (
                  <MatchExplanationTooltip
                    className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                    title="No match information available"
                    placement="top"
                    arrow
                  >
                    <div>
                      <MatchPill matchRating="no_match" />
                    </div>
                  </MatchExplanationTooltip>
                )}
              </>
            </div>
          </td>
        </tr>
        <tr>
          <td className="whitespace-nowrap px-1.5 py-2 pl-5">
            <div className="flex gap-x-2 items-center">
              <LocationOnRounded sx={{ fontSize: "1rem" }} />
              <span>Incorporation Address</span>
            </div>
          </td>
          <td className="text-slate-900 truncate px-1.5 py-2">
            <span>{inputAddressOfIncorporation ?? "Unknown"}</span>
          </td>
          <td
            className={twJoin(
              "text-wrap px-1.5 py-2",
              incorporationAddressMatch?.exact_match
                ? "text-red-700 font-semibold"
                : incorporationAddressMatch?.partial_match
                  ? "text-orange-600 font-semibold"
                  : incorporationAddressMatch?.mismatch
                    ? "text-yellow-800"
                    : "text-slate-400 italic",
            )}
          >
            {incorporationAddressMatch ? (
              <span>
                {(() => {
                  const addresses =
                    mediaHit.associated_addresses
                      ?.map((addr: any) =>
                        [addr.city, addr.state, addr.country?.country_name].filter(Boolean).join(", "),
                      )
                      .filter((addr: string) => addr) || [];

                  const countries =
                    mediaHit.associated_countries?.map((country: any) => country.country_name).filter(Boolean) || [];

                  const allLocations = [];
                  if (addresses.length > 0) allLocations.push(...addresses);
                  if (countries.length > 0) allLocations.push(...countries);

                  return allLocations.length > 0 ? allLocations.join("; ") : "Not Found";
                })()}
              </span>
            ) : (
              "Not Found"
            )}
          </td>
          <td className="py-2 px-1.5 text-right ">
            <div className="inline-flex">
              <MatchExplanationTooltip
                className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                title={incorporationAddressMatch?.explanation || "No match information available"}
                placement="top"
                arrow
              >
                <div>
                  <MatchPill
                    matchRating={
                      incorporationAddressMatch?.exact_match
                        ? "strong_match"
                        : incorporationAddressMatch?.partial_match
                          ? "partial_match"
                          : incorporationAddressMatch?.mismatch
                            ? "mismatch"
                            : "no_match"
                    }
                  />
                </div>
              </MatchExplanationTooltip>
            </div>
          </td>
        </tr>
        <tr>
          <td className="whitespace-nowrap px-1.5 py-2 pl-5">
            <div className="flex gap-x-2 items-center">
              <LocationOnRounded sx={{ fontSize: "1rem" }} />
              <span>Operation Address</span>
            </div>
          </td>
          <td className="text-slate-900 truncate px-1.5 py-2">
            <span>{inputAddressOfOperation ?? "Unknown"}</span>
          </td>
          <td
            className={twJoin(
              "text-wrap px-1.5 py-2",
              operationAddressMatch?.exact_match
                ? "text-red-700 font-semibold"
                : operationAddressMatch?.partial_match
                  ? "text-orange-600 font-semibold"
                  : operationAddressMatch?.mismatch
                    ? "text-yellow-800"
                    : "text-slate-400 italic",
            )}
          >
            <span>
              {(() => {
                const addresses =
                  mediaHit.associated_addresses
                    ?.map((addr: any) => [addr.city, addr.state, addr.country?.country_name].filter(Boolean).join(", "))
                    .filter((addr: string) => addr) || [];

                const countries =
                  mediaHit.associated_countries?.map((country: any) => country.country_name).filter(Boolean) || [];

                const allLocations = [];
                if (addresses.length > 0) allLocations.push(...addresses);
                if (countries.length > 0) allLocations.push(...countries);

                return allLocations.length > 0 ? allLocations.join("; ") : "Not Found";
              })()}
            </span>
          </td>
          <td className="py-2 px-1.5 text-right ">
            <div className="inline-flex">
              <MatchExplanationTooltip
                className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                title={operationAddressMatch?.explanation || "No match information available"}
                placement="top"
                arrow
              >
                <div>
                  <MatchPill
                    matchRating={
                      operationAddressMatch?.exact_match
                        ? "strong_match"
                        : operationAddressMatch?.partial_match
                          ? "partial_match"
                          : operationAddressMatch?.mismatch
                            ? "mismatch"
                            : "no_match"
                    }
                  />
                </div>
              </MatchExplanationTooltip>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default BusinessPerpetratorTable;
