import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Button,
  Skeleton,
  Chip,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import { useParchaApi } from "@parcha-src/hooks/useParchaApi";
import { Tenant, Endpoint } from "../../types";
import AddTenantModal from "./AddTenantModal";
import EditTenantAgentsModal from "./EditTenantAgentsModal";
import AdminNavbar from "./AdminNavbar";
import { useUserContext } from "@/contexts/UserContext";

const TenantsList: React.FC = () => {
  const { endpoints, hasRole, rbacService } = useUserContext();
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [availableAgents, setAvailableAgents] = useState<Endpoint[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const parchaApi = useParchaApi();

  const [openModal, setOpenModal] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null);
  const [isAddingTenant, setIsAddingTenant] = useState(false);
  const [addTenantError, setAddTenantError] = useState<string | null>(null);

  const fetchTenants = async () => {
    setIsLoading(true);
    try {
      const fetchedTenants = await parchaApi.getTenants();
      if (Array.isArray(fetchedTenants)) {
        setTenants(fetchedTenants);
        setError(null);
      } else {
        throw new Error("Unexpected tenants data structure");
      }
    } catch (error) {
      console.error("Error fetching tenants:", error);
      setError("Failed to fetch tenants. Please try again later.");
      setTenants([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (endpoints) {
      const filteredAgents = endpoints.filter((agent) => {
        // Check if the user has admin role for this agent
        const isAdmin = rbacService?.checkDynamicRole(agent.agentKey, "admin");
        return isAdmin && agent.rbac === true;
      });

      setAvailableAgents(filteredAgents);
    }
  }, [endpoints, hasRole, rbacService]);

  useEffect(() => {
    fetchTenants();
  }, []);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleAddTenant = async (newTenant: Tenant, adminUser: { email: string }) => {
    setIsAddingTenant(true);
    setAddTenantError(null);
    try {
      await parchaApi.addTenant({ tenant: newTenant, initialAdmin: adminUser });
      await fetchTenants();
      handleCloseModal();
    } catch (error) {
      console.error("Error adding tenant:", error);
      setAddTenantError("Failed to add tenant. Please try again.");
    } finally {
      setIsAddingTenant(false);
    }
  };

  const handleOpenEditModal = (tenant: Tenant) => {
    setSelectedTenant(tenant);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setSelectedTenant(null);
  };

  const handleUpdateTenant = async (updatedTenant: Tenant) => {
    try {
      // Update the tenant in the local state
      setTenants(tenants.map((t) => (t.id === updatedTenant.id ? updatedTenant : t)));
      // Optionally, you can refresh the entire list here
      // await fetchTenants();
    } catch (error) {
      console.error("Error updating tenant:", error);
    }
  };

  const getAgentName = (agentKey: string) => {
    const agent = availableAgents.find((a) => a.agentKey === agentKey);
    return agent ? agent.agentName : null;
  };

  return (
    <>
      <AdminNavbar
        title="Tenants"
        rightContent={
          <Button variant="contained" startIcon={<AddIcon />} onClick={handleOpenModal}>
            Add Tenant
          </Button>
        }
      />

      <TableContainer component={Paper} elevation={0} sx={{ maxWidth: "1200px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="tenants table">
          <TableHead>
            <TableRow className="bg-slate-100" sx={{ height: "62px" }}>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Domains</TableCell>
              <TableCell>Agent Keys</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              // Loading skeleton rows
              [...Array(5)].map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell align="right">
                    <Skeleton variant="circular" width={24} height={24} />
                  </TableCell>
                </TableRow>
              ))
            ) : error ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography color="error">{error}</Typography>
                </TableCell>
              </TableRow>
            ) : tenants.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography>No tenants found.</Typography>
                </TableCell>
              </TableRow>
            ) : (
              // Existing tenant rows
              tenants.map((tenant) => (
                <TableRow key={tenant.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {tenant.id}
                  </TableCell>
                  <TableCell>{tenant.name}</TableCell>
                  <TableCell>{tenant.selfProvisioningDomains.join(", ")}</TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {tenant.customAttributes.agents.map((agentKey) => {
                        const agentName = getAgentName(agentKey);
                        return (
                          <Chip
                            key={agentKey}
                            label={agentName || agentKey}
                            color={agentName ? "primary" : "default"}
                            variant="outlined"
                            size="small"
                            sx={agentName ? undefined : { opacity: 0.6 }}
                          />
                        );
                      })}
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => handleOpenEditModal(tenant)}>
                      <MoreHorizIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedTenant && (
        <EditTenantAgentsModal
          open={editModalOpen}
          onClose={handleCloseEditModal}
          onUpdateTenant={handleUpdateTenant}
          tenant={selectedTenant}
          parchaApi={parchaApi}
          availableAgents={availableAgents}
        />
      )}

      <AddTenantModal
        open={openModal}
        onClose={handleCloseModal}
        onAddTenant={handleAddTenant}
        isLoading={isAddingTenant}
        error={addTenantError}
        availableAgents={availableAgents}
      />
    </>
  );
};

export default TenantsList;
