import ThoughtUI from "../Thought";
import { CommandResult, ErrorMsg, OutputSchema, StatusMessage, Thought } from "../../../types";
import FinalAnswerUI from "../FinalAnswer";
import ErrorMessage from "../ErrorMessage";
import CommandResultsUI from "../CommandResultsUI";
import { isAgentSupervisor } from "@parcha-src/utils";
import FinalOutputEntry from "@/components/TailwindComponents/FinalOutputEntry";

interface MessageUIProps {
  id: string;
  isHighRisk?: boolean;
  isParchaUser?: boolean;
  statusMessage?: StatusMessage;
  appendToPrev?: boolean;
  onPrimaryClick?: VoidFunction;
  onSecondaryClick?: VoidFunction;
  feedbackData?: any;
  isLegacyJob?: boolean;
  caseId?: string;
  endpointUrl?: string;
  showAllContent?: boolean;
}

function MessageUI({
  id,
  isHighRisk = false,
  isParchaUser = false,
  statusMessage,
  appendToPrev,
  feedbackData,
  isLegacyJob = false,
  caseId,
  endpointUrl,
  showAllContent = false,
}: MessageUIProps) {
  // this can be either a thought, a command result, a final answer or an error, display based on that.
  const messageContent = statusMessage?.content;
  const messageType = statusMessage?.event;

  switch (messageType) {
    case "thought":
      return (
        <ThoughtUI
          id={id}
          appendToPrev={
            statusMessage?.content.agent_key !== undefined &&
            !isAgentSupervisor(statusMessage.content.agent_key) &&
            appendToPrev
          }
          messageContents={messageContent as Thought}
        />
      );
    case "command_results": {
      if (isLegacyJob) {
        return (
          <CommandResultsUI
            id={id}
            isParchaUser={isParchaUser}
            appendToPrev={appendToPrev}
            messageContents={messageContent as CommandResult}
            feedbackData={feedbackData}
            caseId={caseId}
            endpointUrl={endpointUrl}
            showAllContent={showAllContent}
          />
        );
      } else {
        const output = (messageContent as CommandResult).output as OutputSchema;
        const passed = ["true", "pass", "passed", "yes"].includes(output?.passed?.toString().toLowerCase());
        return <FinalOutputEntry passed={passed} explanation={output.explanation} stepName="" />;
      }
    }
    case "api_results":
      return (
        <CommandResultsUI
          id={id}
          isParchaUser={isParchaUser}
          appendToPrev={appendToPrev}
          messageContents={messageContent as CommandResult}
          feedbackData={feedbackData}
          caseId={caseId}
          endpointUrl={endpointUrl}
          showAllContent={showAllContent}
        />
      );
    case "final_answer":
      return <FinalAnswerUI id={id} isHighRisk={isHighRisk} messageContents={messageContent as CommandResult} />;

    case "error": {
      let wrappedMessageContent = messageContent;
      const err = messageContent as ErrorMsg;
      const shouldFilterOut = err.full_error_message?.indexOf("AbandonedJobError()") >= 0;
      if (messageContent && "error_type" in messageContent) {
        wrappedMessageContent = {
          command_id: "",
          command_name: "",
          command_desc: "",
          args: {},
          is_agent_status: false,
          output: err,
        };
      }
      return shouldFilterOut ? null : <ErrorMessage id={id} messageContents={wrappedMessageContent as CommandResult} />;
    }
    default: {
      return null;
    }
  }
}

export default MessageUI;
