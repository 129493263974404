import { Outlet, useParams, useNavigate, Link } from "react-router-dom";
import { useContext, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import clsx from "clsx";
import UserContext from "@/contexts/UserContext";
import { ExpandMoreRounded } from "@mui/icons-material";
import { DOCUMENT_VALIDATION_CHECK_IDS } from "@/constants/vars";
import ParchaLoadingScreen from "@/components/V2_Home_Page/ParchaLoadingScreen";

const DocumentVerificationIndex = () => {
  const { agentKey } = useParams();
  const { endpoints, isEndpointsReady } = useContext(UserContext);
  const navigate = useNavigate();

  const filteredEndpoints = endpoints?.filter(
    (endpoint) => endpoint.agentConfig?.steps?.some((step) => DOCUMENT_VALIDATION_CHECK_IDS.includes(step.command_id)),
  );

  useEffect(() => {
    if (!agentKey && filteredEndpoints && filteredEndpoints?.length > 0) {
      navigate(`/document-verification/${filteredEndpoints[0].agentKey}`);
    }
  }, [filteredEndpoints, navigate, agentKey]);

  const endpoint = filteredEndpoints?.find((e) => e.agentKey === agentKey);

  return isEndpointsReady ? (
    <div className="flex flex-col gap-y-2 px-5 py-4 w-full h-full relative">
      <div className="flex items-center gap-x-2 w-full">
        <span className="text-xs font-semibold text-slate-900">Agent:</span>
        {filteredEndpoints && filteredEndpoints?.length > 1 ? (
          <AgentsPicker
            items={
              filteredEndpoints?.map((e) => ({
                label: e.agentName,
                url: `/document-verification/${e.agentKey}`,
              })) || []
            }
            selectedItem={{
              label: endpoint?.agentName || "",
              url: `/document-verification/${endpoint?.agentKey}`,
            }}
          />
        ) : (
          <span className="text-xs font-semibold text-slate-900">{endpoint?.agentName}</span>
        )}
      </div>

      <Outlet />
    </div>
  ) : (
    <ParchaLoadingScreen message="Loading document verification..." />
  );
};

const AgentsPicker = ({
  items,
  selectedItem,
}: {
  items: { label: string; url: string }[];
  selectedItem: { label: string; url: string };
}) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex justify-between w-56 gap-x-1.5 rounded-md bg-white p-2 text-xs md:text-md font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:ring-slate-400">
          <span className="line-clamp-1 text-left">{selectedItem.label}</span>
          <ExpandMoreRounded sx={{ fontSize: "1rem" }} className="-mr-1 h-4 w-4 text-slate-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="h-fit max-h-[70vh] overflow-y-auto absolute left-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {items.map((item) => {
              return (
                <Menu.Item key={item.label}>
                  <Link
                    to={item.url}
                    className={clsx(
                      selectedItem.label === item.label ? "bg-slate-100 text-slate-900" : "text-slate-700",
                      "block px-4 py-2 text-sm",
                    )}
                  >
                    {item.label}
                  </Link>
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DocumentVerificationIndex;
