// src/components/PayloadRenderer/index.tsx
import { Chip, Tooltip, Typography } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import LanguageIcon from "@mui/icons-material/Language";
import { orange } from "@mui/material/colors";
import React from "react";
import { ParchaDocument } from "@parcha-src/types";

const DocumentWithUrl: React.FC<ParchaDocument> = ({ url, name, description, label, type }) => {
  const renderIcon = () => {
    switch (type) {
      case "pdf":
        return <PictureAsPdfIcon />;
      case "image":
        return <ImageIcon />;
      case "website":
      default:
        return <LanguageIcon />;
    }
  };

  return (
    <a
      href={url ? (url.startsWith("http://") ? url : `https://${url}`) : "#"}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Tooltip title={description}>
        <div style={{ display: "flex", alignItems: "center", paddingTop: "4px" }}>
          {renderIcon()}
          <Typography variant="body1" sx={{ fontWeight: "bold", paddingLeft: "4px" }}>
            {name}
          </Typography>
          {label && (
            <Chip size="small" variant="outlined" label={label} sx={{ marginLeft: "4px", color: orange[500] }} />
          )}
        </div>
      </Tooltip>
    </a>
  );
};

export default DocumentWithUrl;
