import InputWithOverlappingLabel from "../TailwindComponents/InputWithOverlappingLabel";
import { DeleteForeverRounded } from "@mui/icons-material";

interface BusinessOwnershipFormProps {
  formData: {
    registered_business_name: string | null;
    associated_individuals: Array<{
      first_name: string;
      middle_name: string;
      last_name: string;
      business_ownership_percentage: string;
    }>;
  };
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  formErrors: string[];
}

const BusinessOwnershipForm: React.FC<BusinessOwnershipFormProps> = ({ formData, setFormData, formErrors }) => {
  const handleIndividualChange = (index: number, field: string, value: string) => {
    const newIndividuals = [...formData.associated_individuals];
    newIndividuals[index] = { ...newIndividuals[index], [field]: value };
    setFormData({ ...formData, associated_individuals: newIndividuals });
  };

  const addBusinessOwner = () => {
    setFormData({
      ...formData,
      associated_individuals: [
        ...formData.associated_individuals,
        { first_name: "", middle_name: "", last_name: "", business_ownership_percentage: "" },
      ],
    });
  };

  const removeBusinessOwner = (index: number) => {
    const newIndividuals = formData.associated_individuals.filter((_, i) => i !== index);
    setFormData({ ...formData, associated_individuals: newIndividuals });
  };

  return (
    <form>
      <div className="grid grid-cols-4 gap-3">
        <InputWithOverlappingLabel
          fieldName="registered_business_name"
          label="Registered Business Name"
          id="registered_business_name"
          name="registered_business_name"
          onChangeHandler={(e) => setFormData({ ...formData, registered_business_name: e.target.value })}
          className="col-span-3"
          value={formData.registered_business_name || ""}
        />
      </div>
      {formData.associated_individuals.map((individual, index) => (
        <div key={index} className="mt-4">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-sm font-semibold">Business Owner {index + 1}</h3>
            {index > 0 && (
              <button
                type="button"
                onClick={() => removeBusinessOwner(index)}
                className="text-red-600 hover:text-red-800"
              >
                <DeleteForeverRounded sx={{ fontSize: "1.25rem" }} className="text-red-600" />
              </button>
            )}
          </div>
          <div className="grid grid-cols-2 gap-4">
            <InputWithOverlappingLabel
              fieldName={`first_name_${index}`}
              label="First Name"
              value={individual.first_name}
              onChangeHandler={(e) => handleIndividualChange(index, "first_name", e.target.value)}
            />
            <InputWithOverlappingLabel
              fieldName={`middle_name_${index}`}
              label="Middle Name"
              value={individual.middle_name}
              onChangeHandler={(e) => handleIndividualChange(index, "middle_name", e.target.value)}
            />
            <InputWithOverlappingLabel
              fieldName={`last_name_${index}`}
              label="Last Name"
              value={individual.last_name}
              onChangeHandler={(e) => handleIndividualChange(index, "last_name", e.target.value)}
            />
            <InputWithOverlappingLabel
              fieldName={`percentage_${index}`}
              label="Ownership (0.0-1.0)"
              type="text"
              value={individual.business_ownership_percentage}
              onChangeHandler={(event) => {
                const newValue = event.target.value;

                // Allow empty input to clear the field
                if (newValue === "") {
                  handleIndividualChange(index, "business_ownership_percentage", "");
                  return;
                }

                // Check if the input is a number and within the 0-100 range
                if (
                  newValue === "" ||
                  newValue === "." ||
                  newValue === "1." ||
                  newValue === "0." ||
                  (isFinite(Number(newValue)) && Number(newValue) >= 0 && Number(newValue) <= 1)
                ) {
                  handleIndividualChange(index, "business_ownership_percentage", newValue);
                }
              }}
            />
          </div>
        </div>
      ))}

      <button
        type="button"
        onClick={addBusinessOwner}
        className="mt-4 inline-flex items-center px-2 py-1 border border-slate-300 rounded-md shadow-sm text-xs font-semibold text-slate-700 bg-white hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-purple"
      >
        + Add Business Owner
      </button>

      {formErrors.length > 0 && (
        <div className="text-red-600 text-sm mt-4">
          <span className="font-semibold">Please correct the following errors:</span>
          <ul className="list-disc list-outside ml-6">
            {formErrors.map((error, index) => (
              <li className="list-item" key={index}>
                {error}
              </li>
            ))}
          </ul>
        </div>
      )}
    </form>
  );
};

export default BusinessOwnershipForm;
