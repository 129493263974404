import UserContext from "@/contexts/UserContext";
import { Endpoint } from "@/types";
import { AutoAwesome, BusinessRounded, DocumentScannerRounded, LockRounded, NoteAddRounded } from "@mui/icons-material";
import { useContext } from "react";
import { Link, Outlet, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

const PlaygroundIndex = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { reportJobId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const isMainLabs = pathname === "/labs";

  const userContext = useContext(UserContext);
  const { endpoints = [] } = userContext;

  const isOnlyPublicEndpoint = endpoints?.every((endpoint: Endpoint) => endpoint.isPublic);

  // Get the current page title based on the path
  const getCurrentPageTitle = () => {
    if (pathname.includes("document-validation")) return "Real-Time Document Validation";
    if (pathname.includes("create-report")) return "Business Due Diligence";
    return "";
  };

  const pageTitle = getCurrentPageTitle();

  const LabsIcon = (
    <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="6.66652" cy="0.740754" rx="0.740741" ry="0.740754" fill="#64748B" />
      <ellipse cx="8.51834" cy="3.33379" rx="1.11111" ry="1.11113" fill="#64748B" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.87654 11.77L14.4362 17.3092C14.6996 17.6302 14.8148 17.9924 14.8148 18.3545C14.8148 19.2023 14.1481 20.0006 13.1687 20.0006H1.64609C0.674897 20.0006 0.00823045 19.2023 0 18.3545C0 17.9924 0.115226 17.6302 0.378601 17.3092L4.93827 11.77V6.83167H4.11523C3.66255 6.83167 3.29218 6.46129 3.29218 6.00861C3.29218 5.55592 3.66255 5.18555 4.11523 5.18555H10.6996C11.1523 5.18555 11.5226 5.55592 11.5226 6.00861C11.5226 6.46129 11.1523 6.83167 10.6996 6.83167H9.87654V11.77ZM7.22213 6.66679C6.66985 6.66679 6.22213 7.1145 6.22213 7.66679V11.9799C6.22213 12.2025 6.14781 12.4188 6.01096 12.5945L4.96165 13.9412C4.44995 14.598 4.91793 15.5558 5.75047 15.5558H9.06416C9.89671 15.5558 10.3647 14.598 9.85299 13.9412L8.80368 12.5945C8.66682 12.4188 8.5925 12.2025 8.5925 11.9799V7.66679C8.5925 7.1145 8.14479 6.66679 7.5925 6.66679H7.22213Z"
        fill="#64748B"
      />
    </svg>
  );

  return (
    <div className="w-full h-screen">
      <div className="border-b border-slate-200 flex items-center px-5 py-[0.8rem] justify-between h-[3.8rem]">
        <div className="flex items-center">
          {isMainLabs ? (
            <div className="flex items-center gap-x-2">
              {LabsIcon}
              <span className="font-semibold">Parcha Labs</span>
            </div>
          ) : (
            <Link to="/labs" className="flex items-center text-slate-900 hover:text-brand-purple">
              <div className="flex items-center gap-x-2">
                {LabsIcon}
                <span className="font-semibold">Parcha Labs</span>
              </div>
            </Link>
          )}

          {!isMainLabs && (
            <>
              <svg
                className="h-4 w-4 mx-2 text-slate-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
              <span className="text-slate-800 font-medium">{pageTitle}</span>
            </>
          )}
        </div>

        <div className="flex items-center gap-x-2 place-self-end">
          {reportJobId && (
            <button
              type="button"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-brand-purple px-3 py-2 text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
              onClick={() => navigate("/labs/create-report?new=true")}
            >
              <AutoAwesome className="-ml-0.5 h-4 w-4" sx={{ fontSize: "1rem" }} aria-hidden="true" />
              Generate New Report
            </button>
          )}
          {isOnlyPublicEndpoint && (
            <a
              href="https://calendly.com/ajasver/request-a-demo"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-white text-slate-900 px-3 py-2 text-xs font-semibold border border-slate-200 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
              target="_blank"
              rel="norefferrer noopener"
            >
              <LockRounded className="-ml-0.5 h-4 w-4 text-slate-900" sx={{ fontSize: "1rem" }} aria-hidden="true" />
              Get Full Access
            </a>
          )}

          {location.pathname.includes("document-validation") && searchParams.get("jobId") && (
            <button
              type="button"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-brand-purple px-3 py-2 text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
              onClick={() => setSearchParams({})}
            >
              <NoteAddRounded className="-ml-0.5 h-4 w-4" sx={{ fontSize: "1rem" }} aria-hidden="true" />
              Validate New Document
            </button>
          )}
        </div>
      </div>

      {isMainLabs && (
        <div className="flex flex-col items-center p-12 bg-slate-50">
          <h1 className="text-xl font-semibold text-center mb-6">Demo some of our tools today.</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-4xl">
            <Link
              to="/labs/document-validation"
              className="bg-white border border-slate-200 rounded-lg p-6 flex hover:ring-2 hover:ring-brand-purple transition-shadow duration-200"
            >
              <div className="bg-indigo-100 p-3 rounded-lg mr-4 flex items-center justify-center h-12 w-12 flex-shrink-0 self-start">
                <DocumentScannerRounded className="text-brand-purple" sx={{ fontSize: "1.5rem" }} aria-hidden="true" />
              </div>
              <div>
                <div className="text-lg font-semibold mb-1">Real-Time Document Validation</div>
                <p className="text-sm text-slate-500">
                  Test Parcha's real-time document validation system before integrating it into your workflow. See
                  exactly how fast validations run and experiment with different pass/fail criteria.
                </p>
              </div>
            </Link>
            <Link
              to="/labs/create-report"
              className="bg-white border border-slate-200 rounded-lg p-6 flex hover:ring-2 hover:ring-brand-purple transition-ring duration-200"
            >
              <div className="bg-indigo-100 p-3 rounded-lg mr-4 flex items-center justify-center h-12 w-12 flex-shrink-0 self-start">
                <BusinessRounded className="text-brand-purple" sx={{ fontSize: "1.5rem" }} aria-hidden="true" />
              </div>
              <div>
                <div className="text-lg font-semibold mb-1">Business Due Diligence</div>
                <p className="text-sm text-slate-500">
                  Parcha synthesizes open-source intelligence and proprietary data to build a comprehensive risk profile
                  of any business in minutes, not hours or days.
                </p>
              </div>
            </Link>
          </div>
        </div>
      )}

      <Outlet />
    </div>
  );
};

export default PlaygroundIndex;
