import { OpenInNewRounded } from "@mui/icons-material";

const FindingsSource = ({
  source,
  showURL = true,
  showIndicator = true,
}: {
  source: {
    doc?: {
      text: string;
    };
    id: number;
    title?: string;
    url?: string;
    summary?: string;
  };
  showIndicator?: boolean;
  showURL?: boolean;
}) => {
  if ("text" in source) {
    return null;
  }

  const getTitle = () => {
    return source.title ?? "";
  };

  const getUrl = () => {
    if (!source.url) return "";

    return source.url?.startsWith("http") ? source.url : `https://${source.url}`;
  };

  const getSummary = () => {
    return source.summary ?? "";
  };

  const title = getTitle();
  const summary = getSummary();

  const url = getUrl();
  const domain = url.length > 0 ? new URL(url).hostname.replace("www.", "") : null;
  const faviconUrl = domain ? `https://www.google.com/s2/favicons?domain=${domain}` : null;
  const sourceNameProperCaseFromURL = url;

  return (
    <div
      id={`source-${source.id}`}
      className="flex flex-col gap-y-2 w-full rounded-lg bg-slate-50 p-3 break-all text-xs"
    >
      <div className="flex justify-between items-center">
        <span className="inline-flex gap-x-2 items-center">
          {showIndicator && (
            <span className="shrink-0 flex items-center justify-center rounded-full w-4 h-4 text-[0.6rem] text-nowrap font-normal bg-slate-200 text-slate-500">
              {source.id}
            </span>
          )}
          {faviconUrl && <img src={faviconUrl} alt="Favicon" className="w-4 h-4 rounded-full" />}
          {sourceNameProperCaseFromURL ? (
            showURL ? (
              <span className="text-slate-500 line-clamp-1">{sourceNameProperCaseFromURL}</span>
            ) : url ? (
              <a href={url} target="_blank" rel="noreferrer" className="text-brand-purple line-clamp-1">
                {sourceNameProperCaseFromURL}
              </a>
            ) : null
          ) : null}
        </span>
        {url && showURL && (
          <a
            href={url.startsWith("http") ? url : `https://${url}`}
            target="_blank"
            rel="norefferer"
            className="ignore-in-pdf print:hidden w-fit text-brand-purple"
          >
            <OpenInNewRounded sx={{ fontSize: "1rem" }} />
          </a>
        )}
      </div>

      {title && <span className="text-sm font-semibold">{title}</span>}

      {summary && <span className="line-clamp-2 text-slate-700">{summary}</span>}
    </div>
  );
};

export default FindingsSource;
