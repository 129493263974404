import { useState, useEffect, useContext, ReactNode } from "react";
import { twJoin, twMerge } from "tailwind-merge";
import { useParchaApi } from "@/hooks/useParchaApi";
import {
  Flag,
  Error,
  Check,
  TrackChanges,
  ArrowBack,
  ArrowForward,
  DownloadRounded,
  CloseRounded,
  ArrowUpward,
  ArrowDownward,
  CheckCircleRounded,
  ErrorRounded,
  HourglassTopRounded,
} from "@mui/icons-material";
import SelectMenu from "../ui/SelectMenu";
import { format } from "date-fns";
import UserContext from "@/contexts/UserContext";
import { Link, useSearchParams } from "react-router-dom";
import numeral from "numeral";

type Timeframe = "today" | "last_7_days" | "last_30_days" | "last_90_days" | "all_time";

interface CaseInfo {
  first_name?: string;
  middle_name?: string;
  last_name?: string;
}

interface CheckResult {
  passed: boolean;
  status: string;
  payload?: {
    verified_adverse_media_hits?: Array<{
      id: string;
      full_name: string;
      profile_review: {
        match_rating: {
          match: string;
        };
      };
    }>;
    verified_pep_hits?: Array<{
      id: string;
      full_name: string;
      profile_review: {
        match_rating: {
          match: string;
        };
      };
    }>;
  };
}

interface Case {
  recommendation: string;
  applicant_id: string;
  case_id: string;
  case_info: CaseInfo;
  check_result: CheckResult;
  created_at: string;
  job_id: string;
  status: string;
}

interface CasesResponse {
  items: Case[];
  total: number;
}

interface Endpoint {
  agentKey: string;
  agentName: string;
  endpointUrl: string;
}

interface JobBatch {
  updated_at: string;
  id: string;
  batch_name: string;
  agent_key: string;
  created_at: string;
}

const ITEMS_PER_PAGE_OPTIONS = [
  { value: "10", label: "10" },
  { value: "20", label: "20" },
  { value: "50", label: "50" },
  { value: "100", label: "100" },
];

type FilterType = "queued" | "in_progress" | "completed" | "error" | "approve" | "review";

type SortDirection = "asc" | "desc" | null;
type SortField = "case_id" | "name" | "results" | "created_at" | null;

const SelectMenuSkeleton = () => <div className="h-7 w-32 bg-slate-200 rounded-md animate-pulse" />;

const CasesOverviewView = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [cases, setCases] = useState<CasesResponse>({ items: [], total: 0 });
  const [isLoadingCases, setIsLoadingCases] = useState(true);
  const [selectedTimeframe, setSelectedTimeframe] = useState<Timeframe>(
    (searchParams.get("timeframe") as Timeframe) || "last_30_days",
  );
  const [aggregatedData, setAggregatedData] = useState<any>(null);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(20);
  const [isLoadingAggregatedData, setIsLoadingAggregatedData] = useState<boolean>(true);
  const [selectedEndpoint, setSelectedEndpoint] = useState<string | null>(null);
  const [toastState, setToastState] = useState<{
    show: boolean;
    message: ReactNode;
    level: "normal" | "success" | "error";
  }>({
    show: false,
    message: "",
    level: "normal",
  });
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);
  const [sortField, setSortField] = useState<SortField>((searchParams.get("sort_field") as SortField) || "created_at");
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    (searchParams.get("sort_direction") as SortDirection) || "desc",
  );
  const [jobBatches, setJobBatches] = useState<Array<{ value: string; label: string }>>([
    { value: "all", label: "All" },
  ]);
  const [selectedBatch, setSelectedBatch] = useState<string | null>(null);

  const parchaApi = useParchaApi();

  const userContext = useContext(UserContext);
  const endpoints = userContext?.endpoints as Endpoint[] | undefined;

  document.title = "Parcha - Cases Overview";

  useEffect(() => {
    if (!endpoints || endpoints.length === 0) return;

    const agentKeyParam = searchParams.get("agent_key");

    // Ensure we have a valid endpoint on initial load
    const validEndpoint = endpoints.find((e) => e.agentKey === agentKeyParam) || endpoints[0];

    if (!validEndpoint) return;

    // Set initial state
    setSelectedEndpoint(validEndpoint.agentKey);

    // Update URL if needed
    if (!agentKeyParam || agentKeyParam === "undefined" || agentKeyParam !== validEndpoint.agentKey) {
      const newParams = new URLSearchParams(searchParams);
      newParams.set("agent_key", validEndpoint.agentKey);

      setSearchParams(newParams);
    }
  }, [endpoints]);

  useEffect(() => {
    if (!selectedEndpoint || !endpoints?.length) return;

    // Validate that selected endpoint exists
    const validEndpoint = endpoints.find((e) => e.agentKey === selectedEndpoint);
    if (!validEndpoint) return;

    // Update URL to match selected endpoint
    const currentAgentKey = searchParams.get("agent_key");
    if (currentAgentKey !== selectedEndpoint) {
      const newParams = new URLSearchParams(searchParams);
      newParams.set("agent_key", selectedEndpoint);
      setSearchParams(newParams);
    }
  }, [selectedEndpoint, endpoints]);

  useEffect(() => {
    if (!selectedEndpoint) return;
    setPage(1);
  }, [size, selectedTimeframe, selectedEndpoint, selectedBatch]);

  useEffect(() => {
    if (!selectedEndpoint) return;

    const endpoint = endpoints?.find((e) => e.agentKey === selectedEndpoint) || null;

    if (endpoint?.endpointUrl && endpoint?.agentKey) {
      parchaApi
        .getJobBatches(endpoint.endpointUrl, endpoint.agentKey)
        .then((response: JobBatch[]) => {
          const newBatches = [
            { value: "all", label: "All" },
            ...response.map((batch) => ({
              value: batch.id,
              label: batch.batch_name,
            })),
          ];
          setJobBatches(newBatches);

          // Validate current selectedBatch against new batch list
          const batchExists = newBatches.some((batch) => batch.value === selectedBatch);
          if (!batchExists) {
            setSelectedBatch("all");
            // Update URL to remove invalid batch_id
            const newParams = new URLSearchParams(searchParams);
            newParams.delete("batch_id");
            setSearchParams(newParams);
          }
        })
        .catch((error) => {
          console.error("Error fetching job batches:", error);
          setJobBatches([{ value: "all", label: "All" }]);
        });
    }
  }, [selectedEndpoint]);

  useEffect(() => {
    if (!selectedEndpoint || !selectedBatch) return;

    const endpoint = endpoints?.find((e) => e.agentKey === selectedEndpoint);
    if (!endpoint?.endpointUrl) return;

    setIsLoadingCases(true);

    parchaApi
      .getCasesOverviewData(
        endpoint.endpointUrl,
        endpoint.agentKey,
        selectedTimeframe,
        page,
        size,
        activeFilters,
        sortField,
        sortDirection,
        selectedBatch,
      )
      .then((casesData) => {
        setCases(casesData);
      })
      .catch((error) => {
        console.error("Failed to fetch cases data:", error);
        setCases({ items: [], total: 0 });
      })
      .finally(() => {
        setIsLoadingCases(false);
      });
  }, [selectedEndpoint, selectedTimeframe, page, size, activeFilters, sortField, sortDirection, selectedBatch]);

  // Separate effect for timeframe changes which affect both datasets
  useEffect(() => {
    if (!selectedEndpoint || selectedBatch === null) return;

    const endpoint = endpoints?.find((e) => e.agentKey === selectedEndpoint);
    if (!endpoint?.endpointUrl) return;

    setIsLoadingCases(true);
    setIsLoadingAggregatedData(true);

    parchaApi
      .getAggregatedData(endpoint.endpointUrl, endpoint.agentKey, selectedTimeframe, selectedBatch)
      .then((aggregatedData) => {
        setAggregatedData(aggregatedData);
      })
      .catch((error) => {
        console.error("Failed to fetch data:", error);
      })
      .finally(() => {
        setIsLoadingAggregatedData(false);
      });
  }, [selectedEndpoint, selectedTimeframe, selectedBatch]);

  const handleDownloadReportClick = async () => {
    if (!selectedEndpoint || !selectedBatch) return;

    const endpoint = endpoints?.find((e) => e.agentKey === selectedEndpoint) || null;
    if (endpoint?.endpointUrl && endpoint?.agentKey) {
      // Show success toast
      setToastState({ show: true, message: "Download in progress...", level: "normal" });
      try {
        const response = await parchaApi.exportCSVBatch(endpoint.endpointUrl, endpoint.agentKey, selectedBatch);

        // Create blob from response and download
        const blob = new Blob([response], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `cases-report-${format(new Date(), "yyyy-MM-dd")}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        setToastState({ show: true, message: "Download complete!", level: "success" });
      } catch (error) {
        console.error("Failed to download CSV:", error);
        setToastState({
          show: true,
          message: (
            <div className="flex flex-col gap-y-2">
              <div className="font-semibold">Error downloading report</div>
              <div>
                Please try again later. If the problem persists, contact us at{" "}
                <a
                  href={`mailto:support@parcha.ai?subject=Error downloading batch ${selectedBatch} report for ${selectedEndpoint}`}
                  className="text-red-700 hover:underline"
                >
                  support@parcha.ai
                </a>
                .
              </div>
            </div>
          ),
          level: "error",
        });
        // Optionally handle error state here
      } finally {
        setTimeout(() => {
          setToastState({ show: false, message: "", level: "normal" });
        }, 7000);
      }
    }
  };

  const Toast = () => {
    if (!toastState.show) return null;

    return (
      <div
        className={twJoin(
          "max-w-[30rem] w-fit h-fit absolute top-[3.5rem] right-[1.5rem] z-50 py-2 px-4 flex items-baseline justify-between shadow-md rounded-lg",
          toastState.level === "normal"
            ? "bg-indigo-100"
            : toastState.level === "success"
              ? "bg-green-100"
              : "bg-red-100",
        )}
      >
        <div className="flex justify-between gap-x-2">
          {toastState.level === "normal" && (
            <HourglassTopRounded sx={{ fontSize: "1rem" }} className="text-brand-purple" />
          )}
          {toastState.level === "success" && (
            <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-700" />
          )}
          {toastState.level === "error" && <ErrorRounded sx={{ fontSize: "1rem" }} className="text-red-700" />}
          <div className="flex flex-col">
            <div className="flex justify-between gap-x-2">
              <h3
                className={twJoin(
                  "text-xs font-semibold",
                  toastState.level === "success"
                    ? "text-green-700"
                    : toastState.level === "error"
                      ? "text-red-700"
                      : "text-brand-purple",
                )}
              >
                {toastState.message}
              </h3>
              <button
                onClick={() => setToastState({ show: false, message: "", level: "normal" })}
                className={twJoin(
                  "py-[0.125rem] px-[0.125rem] rounded-full flex justify-center",
                  toastState.level === "normal"
                    ? "hover:bg-indigo-200"
                    : toastState.level === "success"
                      ? "hover:bg-green-200"
                      : "hover:bg-red-200",
                )}
              >
                <CloseRounded
                  sx={{ fontSize: "1rem" }}
                  className={twJoin(
                    toastState.level === "normal"
                      ? "text-brand-purple"
                      : toastState.level === "success"
                        ? "text-green-700"
                        : "text-red-700",
                  )}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleFilterClick = (filter: FilterType) => {
    const count = aggregatedData
      ? {
          queued: aggregatedData.queued || 0,
          in_progress: aggregatedData.in_progress || 0,
          completed: aggregatedData.completed || 0,
          error: aggregatedData.error || 0,
          approve: aggregatedData.approve || 0,
          review: aggregatedData.review || 0,
        }[filter]
      : 0;

    // If count is 0, don't allow selection
    if (count === 0) return;

    setActiveFilters((current) => {
      const newFilters = current.includes(filter) ? current.filter((f) => f !== filter) : [...current, filter];

      // Update URL params
      const newParams = new URLSearchParams(searchParams);
      if (newFilters.length > 0) {
        newParams.set("filters", newFilters.join(","));
      } else {
        newParams.delete("filters");
      }
      setSearchParams(newParams);

      return newFilters;
    });
    setPage(1);
  };

  const getCardClasses = (filter: FilterType, baseColors: string) => {
    const count = {
      queued: aggregatedData?.queued || 0,
      in_progress: aggregatedData?.in_progress || 0,
      completed: aggregatedData?.completed || 0,
      error: aggregatedData?.error || 0,
      approve: aggregatedData?.approve || 0,
      review: aggregatedData?.review || 0,
    }[filter];

    return twMerge(
      `max-w-xs flex gap-4 items-baseline justify-between ${baseColors} rounded-lg p-4 transition-colors`,
      count > 0 ? "cursor-pointer" : "cursor-not-allowed opacity-50",
      activeFilters.includes(filter) ? "ring-2 ring-brand-purple" : "",
      count > 0
        ? {
            queued: "hover:bg-slate-200",
            in_progress: "hover:bg-indigo-100",
            completed: "hover:bg-green-100",
            error: "hover:bg-red-100",
            approve: "hover:bg-green-100",
            review: "hover:bg-yellow-100",
          }[filter]
        : "",
    );
  };

  const SkeletonCard = ({
    variant,
    hideIconSection = false,
  }: {
    variant: "default" | "green" | "red" | "yellow" | "brand-purple" | "slate";
    hideIconSection?: boolean;
  }) => {
    const colors = {
      default: {
        bg: "bg-white border border-slate-300",
        pulse: "bg-slate-200",
      },
      green: {
        bg: "bg-green-50",
        pulse: "bg-green-100",
      },
      red: {
        bg: "bg-red-50",
        pulse: "bg-red-100",
      },
      yellow: {
        bg: "bg-yellow-50",
        pulse: "bg-yellow-100",
      },
      "brand-purple": {
        bg: "bg-indigo-50",
        pulse: "bg-indigo-200",
      },
      slate: {
        bg: "bg-slate-50",
        pulse: "bg-slate-200",
      },
    }[variant];

    return (
      <div className={`max-w-xs rounded-lg p-4 ${colors.bg} animate-pulse`}>
        <div className="flex items-baseline justify-between">
          <div className={`h-5 w-5 rounded ${hideIconSection ? "" : colors.pulse}`} />
          <div className="flex flex-col items-end gap-3">
            <div className={`h-6 w-8 rounded ${colors.pulse}`} />
            <div className={`h-4 w-24 rounded ${colors.pulse}`} />
          </div>
        </div>
      </div>
    );
  };

  const TableRowSkeleton = () => {
    return (
      <tr className="animate-pulse bg-slate-50">
        <td className="p-4 flex items-center gap-2">
          <div className="h-3 w-32 bg-brand-purple/40 rounded-md"></div>
        </td>
        <td className="p-4">
          <div className="h-3 w-40 bg-slate-200 rounded-md"></div>
        </td>
        <td className="p-4">
          <div className="h-5 w-24 bg-slate-200 rounded-md"></div>
        </td>
        <td className="p-4">
          <div className="h-3 w-36 bg-slate-200 rounded-md"></div>
        </td>
      </tr>
    );
  };

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      if (sortDirection === "asc") {
        setSortDirection("desc");
        updateSortParams(field, "desc");
      } else if (sortDirection === "desc") {
        // Reset to default sorting (created_at desc)
        setSortField("created_at");
        setSortDirection("desc");
        updateSortParams("created_at", "desc");
      }
    } else {
      // New field selected, start with ascending
      setSortField(field);
      setSortDirection("asc");
      updateSortParams(field, "asc");
    }
  };

  const updateSortParams = (field: SortField, direction: SortDirection) => {
    const newParams = new URLSearchParams(searchParams);

    if (field === "created_at" && direction === "desc") {
      // Remove params for default sorting
      newParams.delete("sort_field");
      newParams.delete("sort_direction");
    } else if (field && direction) {
      newParams.set("sort_field", field);
      newParams.set("sort_direction", direction);
    }

    setSearchParams(newParams);
  };

  const SortIndicator = ({ field }: { field: SortField }) => {
    if (sortField !== field) return null;
    return sortDirection === "asc" ? (
      <ArrowUpward sx={{ fontSize: "1rem" }} />
    ) : (
      <ArrowDownward sx={{ fontSize: "1rem" }} />
    );
  };

  const handleBatchChange = (value: string) => {
    setSelectedBatch(value);
    const newParams = new URLSearchParams(searchParams);
    if (value === "all") {
      newParams.delete("batch_id");
    } else {
      newParams.set("batch_id", value);
    }
    setSearchParams(newParams);
  };

  useEffect(() => {
    const filtersParam = searchParams.get("filters");
    if (filtersParam) {
      const filtersArray = filtersParam.split(",") as FilterType[];
      setActiveFilters(filtersArray);
    }
  }, []);

  useEffect(() => {
    const timeframeParam = searchParams.get("timeframe");

    if (timeframeParam && !selectedTimeframe) {
      setSelectedTimeframe(timeframeParam as Timeframe);
    } else if (!selectedTimeframe) {
      // Only set to default if selectedTimeframe is not already set
      setSelectedTimeframe("last_30_days");
    }
  }, [searchParams, selectedTimeframe]);

  useEffect(() => {
    const currentTimeframe = searchParams.get("timeframe");

    // Update URL only if the timeframe is different
    if (currentTimeframe !== selectedTimeframe) {
      const newParams = new URLSearchParams(searchParams);
      newParams.set("timeframe", selectedTimeframe);
      setSearchParams(newParams);
    }
  }, [selectedTimeframe, searchParams, setSearchParams]);

  // Effect to set initial selectedBatch from URL
  useEffect(() => {
    const batchIdParam = searchParams.get("batch_id");

    if (batchIdParam) {
      setSelectedBatch(batchIdParam);
    } else if (!selectedBatch) {
      // Only set to default if selectedBatch is not already set
      setSelectedBatch("all");
    }
  }, [searchParams, selectedBatch]);

  const getStatusBadgeClasses = (status: string, recommendation?: string) => {
    if (status === "complete") {
      switch (recommendation?.toLowerCase()) {
        case "review":
          return "bg-yellow-50 text-yellow-700";
        case "approve":
        default:
          return "bg-green-50 text-green-700";
      }
    }

    switch (status) {
      case "queued":
        return "bg-slate-100 text-slate-700";
      case "in progress":
        return "bg-indigo-50 text-brand-purple";
      case "failed":
      case "error":
        return "bg-red-50 text-red-700";
      default:
        return "bg-slate-100 text-slate-700";
    }
  };

  const getStatusIcon = (status: string, recommendation?: string) => {
    if (status === "complete") {
      switch (recommendation?.toLowerCase()) {
        case "review":
          return <Flag sx={{ fontSize: "1rem" }} />;
        case "approve":
        default:
          return <CheckCircleRounded sx={{ fontSize: "1rem" }} />;
      }
    }

    switch (status) {
      case "queued":
        return <HourglassTopRounded sx={{ fontSize: "1rem" }} />;
      case "in progress":
        return <TrackChanges sx={{ fontSize: "1rem" }} />;
      case "failed":
      case "error":
        return <Error sx={{ fontSize: "1rem" }} />;
      default:
        return <HourglassTopRounded sx={{ fontSize: "1rem" }} />;
    }
  };

  return (
    <>
      <div className="relative p-6 h-screen overflow-y-auto">
        <Toast />
        {/* Header */}
        <div className="flex items-center justify-between flex-wrap gap-4 mb-6">
          <div className="flex items-center gap-2">
            <h1 className="text-lg font-semibold">
              {endpoints?.find((endpoint) => endpoint.agentKey === selectedEndpoint)?.agentName} Cases
            </h1>
            <span className="text-slate-600">
              {(() => {
                const today = new Date();
                let startDate = new Date();

                switch (selectedTimeframe) {
                  case "today":
                    return `for ${format(today, "MMMM d, yyyy")}`;
                  case "last_7_days":
                    startDate.setDate(today.getDate() - 7);
                    break;
                  case "last_30_days":
                    startDate.setDate(today.getDate() - 30);
                    break;
                  case "last_90_days":
                    startDate.setDate(today.getDate() - 90);
                    break;
                  case "all_time":
                    return "for all time";
                }
                return `from ${format(startDate, "MMMM d, yyyy")} - ${format(today, "MMMM d, yyyy")}`;
              })()}
            </span>
          </div>

          <div className="flex items-center gap-2 text-xs text-slate-900">
            {!endpoints ? (
              <>
                <SelectMenuSkeleton />
                <SelectMenuSkeleton />
                <SelectMenuSkeleton />
                {/* Download report button */}
                <div className="h-7 w-36 bg-slate-200 rounded-md animate-pulse flex items-center justify-center" />
              </>
            ) : (
              <>
                {endpoints.length > 0 && (
                  <SelectMenu
                    options={endpoints.map((endpoint) => ({
                      value: endpoint.agentKey,
                      label: endpoint.agentName,
                    }))}
                    onChange={(value) => {
                      setSelectedEndpoint(value);
                      setPage(1);
                    }}
                    selectedValue={selectedEndpoint || ""}
                  />
                )}
                {jobBatches.length > 1 && (
                  <SelectMenu
                    options={jobBatches}
                    onChange={handleBatchChange}
                    selectedValue={selectedBatch || ""}
                    className="max-w-[9rem] text-xs"
                  />
                )}
                <SelectMenu
                  options={[
                    { value: "today", label: "Today" },
                    { value: "last_7_days", label: "Last 7 days" },
                    { value: "last_30_days", label: "Last 30 days" },
                    { value: "last_90_days", label: "Last 90 days" },
                    { value: "all_time", label: "All Time" },
                  ]}
                  onChange={(value) => {
                    setSelectedTimeframe(value as Timeframe);
                    setPage(1);
                  }}
                  selectedValue={selectedTimeframe}
                />
                <button
                  className="text-slate-900 border border-slate-300 rounded-md px-2 py-1 flex items-center gap-1 disabled:opacity-50 disabled:cursor-not-allowed"
                  onClick={handleDownloadReportClick}
                  disabled={isLoadingAggregatedData || isLoadingCases || selectedBatch === "all"}
                >
                  <DownloadRounded sx={{ fontSize: "1rem" }} />
                  Download Report
                </button>
              </>
            )}
          </div>
        </div>

        {/* Stats */}
        {isLoadingAggregatedData ? (
          <div className="grid grid-cols-[repeat(auto-fit,minmax(172px,1fr))] gap-4 mb-6">
            <SkeletonCard variant="default" hideIconSection />
            <SkeletonCard variant="slate" hideIconSection />
            <SkeletonCard variant="brand-purple" />
            <SkeletonCard variant="green" />
            <SkeletonCard variant="green" />
            <SkeletonCard variant="yellow" />
            <SkeletonCard variant="red" />
          </div>
        ) : (
          <div className="grid grid-cols-[repeat(auto-fit,minmax(172px,1fr))] gap-4 mb-6 font-semibold">
            {/* Total Cases Card */}
            <div className="bg-white border border-slate-300 max-w-xs flex gap-4 items-baseline justify-between rounded-lg p-4">
              <Flag sx={{ fontSize: "1rem" }} className="text-slate-700" />
              <div className="flex flex-col items-end gap-2">
                <span className="text-slate-700 text-xl">
                  {aggregatedData?.total ? numeral(aggregatedData.total).format("0,0") : 0}
                </span>
                <span className="truncate text-sm">Total Cases</span>
              </div>
            </div>

            <button
              className={getCardClasses("queued", "bg-slate-100")}
              disabled={aggregatedData?.queued === 0}
              onClick={() => handleFilterClick("queued")}
            >
              <HourglassTopRounded sx={{ fontSize: "1rem" }} />
              <div className="flex flex-col items-end gap-2">
                <span className="text-slate-700 text-xl">
                  {aggregatedData?.queued ? numeral(aggregatedData.queued).format("0,0") : 0}
                </span>
                <span className="truncate text-sm">Queued</span>
              </div>
            </button>

            <button
              className={getCardClasses("in_progress", "bg-indigo-50 text-brand-purple")}
              disabled={aggregatedData?.in_progress === 0}
              onClick={() => handleFilterClick("in_progress")}
            >
              <TrackChanges sx={{ fontSize: "1rem" }} />
              <div className="flex flex-col items-end gap-2">
                <span className="text-xl">
                  {aggregatedData?.in_progress ? numeral(aggregatedData.in_progress).format("0,0") : 0}
                </span>
                <span className="truncate text-sm">In Progress</span>
              </div>
            </button>

            <button
              className={getCardClasses("completed", "bg-green-50 text-green-700")}
              disabled={aggregatedData?.completed === 0}
              onClick={() => handleFilterClick("completed")}
            >
              <Check sx={{ fontSize: "1rem" }} />
              <div className="flex flex-col items-end gap-2">
                <span className="text-xl">
                  {aggregatedData?.completed ? numeral(aggregatedData.completed).format("0,0") : 0}
                </span>
                <span className="truncate text-sm">Completed</span>
              </div>
            </button>

            <button
              className={getCardClasses("approve", "bg-green-50 text-green-700")}
              onClick={() => handleFilterClick("approve")}
              disabled={aggregatedData?.approve === 0}
            >
              <CheckCircleRounded sx={{ fontSize: "1rem" }} />
              <div className="flex flex-col items-end gap-2">
                <span className="text-xl">
                  {aggregatedData?.approve ? numeral(aggregatedData.approve).format("0,0") : 0}
                </span>
                <span className="truncate text-sm">Approve</span>
              </div>
            </button>

            <button
              className={getCardClasses("review", "bg-yellow-50 text-yellow-700")}
              onClick={() => handleFilterClick("review")}
              disabled={aggregatedData?.review === 0}
            >
              <Flag sx={{ fontSize: "1rem" }} />
              <div className="flex flex-col items-end gap-2">
                <span className="text-xl">
                  {aggregatedData?.review ? numeral(aggregatedData.review).format("0,0") : 0}
                </span>
                <span className="truncate text-sm">Review</span>
              </div>
            </button>

            <button
              className={getCardClasses("error", "bg-red-50 text-red-700")}
              onClick={() => handleFilterClick("error")}
              disabled={aggregatedData?.error === 0}
            >
              <Error sx={{ fontSize: "1rem" }} />
              <div className="flex flex-col items-end gap-2">
                <span className="text-xl">
                  {aggregatedData?.error ? numeral(aggregatedData.error).format("0,0") : 0}
                </span>
                <span className="truncate text-sm">Error</span>
              </div>
            </button>
          </div>
        )}

        {/* Table */}
        <div className="h-[calc(100vh-14rem)] flex flex-col">
          {isLoadingCases ? (
            <div className="flex flex-col h-full">
              <div className="flex-1 overflow-y-auto">
                <table className="w-full">
                  <thead className="sticky top-0 border-b bg-slate-100 text-slate-700">
                    <tr className="text-left text-xs">
                      <th className="p-4 pl-6">
                        <div className="flex items-center gap-2">
                          <span className="font-semibold">Case ID</span>
                        </div>
                      </th>
                      <th className="p-4">
                        <div className="flex items-center gap-2">
                          <span className="font-semibold">Name</span>
                        </div>
                      </th>
                      <th className="p-4">
                        <div className="flex items-center gap-2">
                          <span className="font-semibold">Results</span>
                        </div>
                      </th>
                      <th className="p-4">
                        <div className="flex items-center gap-2">
                          <span className="font-semibold">Case Timestamp</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-slate-300">
                    {[...Array(10)].map((_, index) => (
                      <TableRowSkeleton key={index} />
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Pagination Skeleton */}
              <div className="p-4 flex items-center justify-between border-t text-xs mt-auto animate-pulse">
                <div className="flex items-center gap-2">
                  <div className="h-4 w-24 bg-slate-200 rounded"></div>
                  <div className="h-6 w-16 bg-slate-200 rounded"></div>
                </div>
                <div className="flex items-center gap-3">
                  <div className="h-4 w-20 bg-slate-200 rounded"></div>
                  <div className="h-3 w-16 bg-slate-200 rounded"></div>
                  <div className="h-4 w-20 bg-slate-200 rounded"></div>
                </div>
              </div>
            </div>
          ) : cases?.items.length === 0 ? (
            <div className="flex flex-col items-center justify-center min-h-[400px] text-slate-500">
              <p className="text-lg font-medium mb-2">No cases found</p>
              <p className="text-sm">Try adjusting your filters or selecting a different timeframe</p>
            </div>
          ) : (
            <div className="flex flex-col h-full">
              <div className="overflow-y-auto flex-1">
                <table className="w-full">
                  <thead className="sticky top-0 border-b bg-slate-100 text-slate-700">
                    <tr className="text-left text-xs">
                      <th className="p-4 pl-6 cursor-pointer hover:bg-slate-200" onClick={() => handleSort("case_id")}>
                        <div className="flex items-center gap-2">
                          <span className="font-semibold">Case ID</span>
                          <SortIndicator field="case_id" />
                        </div>
                      </th>
                      <th className="p-4 cursor-pointer hover:bg-slate-200" onClick={() => handleSort("name")}>
                        <div className="flex items-center gap-2">
                          <span className="font-semibold">Name</span>
                          <SortIndicator field="name" />
                        </div>
                      </th>
                      <th className="p-4 cursor-pointer hover:bg-slate-200" onClick={() => handleSort("results")}>
                        <div className="flex items-center gap-2">
                          <span className="font-semibold">Results</span>
                          <SortIndicator field="results" />
                        </div>
                      </th>
                      <th className="p-4 cursor-pointer hover:bg-slate-200" onClick={() => handleSort("created_at")}>
                        <div className="flex items-center gap-2">
                          <span className="font-semibold">Case Timestamp</span>
                          <SortIndicator field="created_at" />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-slate-300">
                    {cases?.items.map((case_, index) => {
                      return (
                        <tr className="text-xs" key={`${case_.job_id}-${index}`}>
                          <td className="py-4 flex items-center gap-2">
                            <Link
                              to={`/jobs/${selectedEndpoint}/${case_.job_id}`}
                              className={`font-semibold text-brand-purple hover:underline pl-6`}
                            >
                              {case_.applicant_id}
                            </Link>
                          </td>
                          <td className="p-4">
                            {case_.case_info?.first_name}
                            {case_.case_info?.middle_name ? ` ${case_.case_info.middle_name} ` : ""}
                            {case_.case_info?.last_name ? ` ${case_.case_info.last_name}` : ""}
                          </td>
                          <td className="p-4">
                            <span
                              className={`px-2 py-1 rounded-md text-xs capitalize ${getStatusBadgeClasses(
                                case_.status,
                                case_.recommendation,
                              )} flex items-center gap-1 w-fit`}
                            >
                              {getStatusIcon(case_.status, case_.recommendation)}
                              {case_.status === "complete" ? case_.recommendation : case_.status}
                            </span>
                          </td>
                          <td className="p-4">
                            {case_.created_at ? format(new Date(case_.created_at), "MMM d, yyyy - h:mm a") : null}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              {/* Pagination - now outside the scroll container */}
              <div className="p-4 flex items-center justify-between border-t text-xs mt-auto">
                <div className="flex items-center gap-2">
                  <span>Items per page</span>
                  <SelectMenu
                    options={ITEMS_PER_PAGE_OPTIONS}
                    onChange={(value) => {
                      setSize(Number(value));
                      setPage(1);
                    }}
                    selectedValue={size.toString()}
                  />
                </div>
                <div className="flex items-center gap-3">
                  <button
                    className={`inline-flex items-center gap-2 text-slate-900 px-2 py-1 border border-slate-200 rounded-lg ${
                      page === 1 ? "opacity-50 cursor-not-allowed" : "hover:text-slate-900"
                    }`}
                    onClick={() => setPage((prev) => Math.max(1, prev - 1))}
                    disabled={page === 1}
                  >
                    <ArrowBack sx={{ fontSize: "1rem" }} className="text-slate-700" />
                    Previous
                  </button>
                  <span className="text-slate-700">
                    {cases?.total ? `Page ${page} of ${Math.ceil(cases?.total / size)}` : "1 of 1"}
                  </span>
                  <button
                    className={`inline-flex items-center gap-2 text-slate-900 px-2 py-1 border border-slate-200 rounded-lg ${
                      page * size >= cases?.total ? "opacity-50 cursor-not-allowed" : "hover:text-slate-900"
                    }`}
                    onClick={() => setPage((prev) => prev + 1)}
                    disabled={page * size >= cases?.total}
                  >
                    Next
                    <ArrowForward sx={{ fontSize: "1rem" }} className="text-slate-700" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CasesOverviewView;
