// @ts-nocheck
import { Agent, AgentRun, AgentRuns, Endpoint, Evidence, User } from "@/types";
import { EventSourceMessage } from "@microsoft/fetch-event-source";
import { Fragment, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import JSON5 from "json5";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { formatDistanceToNowStrict } from "date-fns";
import { useParchaApi } from "@/hooks/useParchaApi";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorContext } from "@/contexts/ErrorContext";
import FeedbackContext from "@/contexts/FeedbackContext";
import { Box, Fab, Drawer } from "@mui/material";
import _ from "lodash";

import styles from "../../Chat/StatusUI/styles.module.css";
import ReviewBadge from "@/components/TailwindComponents/ReviewBadge";
import ApproveBadge from "@/components/TailwindComponents/ApproveBadge";
import CaseSearch from "@/components/TailwindComponents/CaseSearch";
import DenyBadge from "@/components/TailwindComponents/DenyBadge";
import NewAgentChatSSE from "@/components/TailwindComponents/NewAgentChatSSE";
import StatusIndicator from "@/components/TailwindComponents/StatusIndicator";
import AgentRunChecksView from "../AgentRunChecksView";
import clsx from "clsx";
import AgentRunItem from "../AgentRunItem";
import {
  AutoAwesome,
  Close,
  CopyAllRounded,
  MoreHoriz,
  Search,
  Info,
  Add,
  ArrowDownwardRounded,
  NightShelter,
  PlayCircleRounded,
  ExpandMoreRounded,
} from "@mui/icons-material";
import ReactMarkdown from "react-markdown";
import ParchaLoadingScreen from "../ParchaLoadingScreen";

const recommendationComponents = {
  Review: <ReviewBadge />,
  Approve: <ApproveBadge />,
  Deny: <DenyBadge />,
};

const JOB_HISTORY_PAGINATION_LIMIT = 25;

const Queue = ({
  agent,
  title,
  endpoints,
  selectedEndpoint,
  setIsAddCaseModalOpen,
  setIsRunAgentCaseModalOpen,
  user,
}: {
  agent: Agent;
  title: string;
  selectedEndpoint: Endpoint;
  setIsAddCaseModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  user: User;
  setIsRunAgentCaseModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const parchaApi = useParchaApi();
  const { routeJobId } = useParams();
  const [orderedAgentRuns, setOrderedAgentRuns] = useState(null);
  const [agentRuns, _setAgentRuns] = useState<AgentRuns>(null);
  const [isLoadingStatusMessages, setIsLoadingStatusMessages] = useState<boolean>(false);
  const [streamRetries, _setStreamRetries] = useState<number>(0);
  const [selectedAgentJobId, setSelectedAgentJobId] = useState<string | null>(null);
  const [stage, setStage] = useState<"loading" | "initial_load_done" | "idle" | "error">("loading");
  const [isGenerateFollowUpOpen, setIsGenerateFollowUpOpen] = useState<boolean>(false);
  const [isDeleteJobDialogOpen, setIsDeleteJobDialogOpen] = useState<boolean>(false);
  const [selectedTabValue, setSelectedTabValue] = useState<string | null>(null);
  const [shouldDisplayTabSelector, setShouldDisplayTabSelector] = useState<boolean>(true);
  const [isLegacyJob, setIsLegacyJob] = useState<boolean>(false);
  const [latestFeedbackData, setLatestFeedbackData] = useState(null);
  const [isLoadingFeedbackData, setIsLoadingFeedbackData] = useState<boolean>(true);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [selectedAgentRun, setSelectedAgentRun] = useState<AgentRun | null>(null);
  const [totalJobsCount, setTotalJobsCount] = useState<number>(0);
  const [hasMoreJobs, setHasMoreJobs] = useState<boolean>(true);
  const [jobHistoryOffset, setJobHistoryOffset] = useState<number>(0);
  const [isLoadingMoreJobs, setIsLoadingMoreJobs] = useState<boolean>(false);
  const [hasScrollBeenProcessed, setHasScrollBeenProcessed] = useState<boolean>(false);
  const [isJobDetailsOpen, setIsJobDetailsOpen] = useState(false);
  const [showTabular, setShowTabular] = useState<boolean>(true);

  const { setError } = useContext(ErrorContext);
  const navigate = useNavigate();
  const isFlutterwaveMCC = selectedEndpoint?.agentKey === "kyb-mcc-flutterwave";

  const isParchaUser = user?.userTenants?.some((tenant) => tenant.tenantName === "Parcha");

  // do the same for streamRetries
  const streamRetriesRef = useRef(streamRetries);
  const setStreamRetries = (retries: number) => {
    streamRetriesRef.current = retries;
    _setStreamRetries(retries);
  };

  const dialogDivRef = useRef();
  const deleteJobDivRef = useRef();

  const agentRunsRef = useRef(agentRuns);
  const setAgentRuns = (runs: AgentRuns) => {
    agentRunsRef.current = runs;
    _setAgentRuns(runs);
  };

  const createAgentRuns = (response: any): AgentRuns => {
    const newAgentRuns: AgentRuns = {};
    response.forEach((job: any) => {
      job.id = job.id.replace(/-/g, "");
      job.status_messages = job.status_messages || [];
      job.needsEnqueueing = false;
      newAgentRuns[job.id] = job;
    });
    return newAgentRuns;
  };

  const handleApiException = (error: Error) => {
    setError(error);
  };

  const fetchAgentJobs = async (endpointUrl: string, agentKey: string) => {
    const res = await parchaApi
      .getAgentJobHistory(endpointUrl, agentKey, JOB_HISTORY_PAGINATION_LIMIT, jobHistoryOffset)
      .catch(handleApiException);

    if (!res || res.total === 0) {
      setHasMoreJobs(false);
      setTotalJobsCount(0);
      setStage("idle");
      return new Promise(() => {});
    }

    const newAgentRuns = createAgentRuns(res.items);
    const newStateValue = { ...agentRuns, ...newAgentRuns };

    if (isFlutterwaveMCC) {
      const jobIds = res.items.filter((item) => item.recommendation === "Review").map((value) => value.id);
      const finalAnswerResults = await parchaApi.getFinalAnswerForJobs(endpointUrl, agentKey, jobIds);

      if (finalAnswerResults) {
        finalAnswerResults.forEach((far) => {
          if (newStateValue[far.job_id]) {
            newStateValue[far.job_id] = {
              ...newStateValue[far.job_id],
              isHighRisk: true,
            };
          }
        });
      }
    }

    setTotalJobsCount(res.total);
    setJobHistoryOffset(jobHistoryOffset + JOB_HISTORY_PAGINATION_LIMIT);
    setAgentRuns(newStateValue);

    if (Object.keys(newStateValue).length >= res.total) {
      setHasMoreJobs(false);
    }
  };

  const getAgentJobHistory = async (endpointUrl: string, agentKey: string) => {
    if (!endpointUrl || !agentKey) return;

    await fetchAgentJobs(endpointUrl, agentKey)
      .then(() => setStage("initial_load_done"))
      .catch(handleApiException);
  };

  function onopen(res: Response): Promise<void> {
    return new Promise((resolve, reject) => {
      if (res.ok && res.status === 200) {
        setStreamRetries(0);
        setSelectedTabValue("inputs");
        //setLoadingMessage("Reaching out to agent...");
        resolve();
      } else if (res.status >= 400 && res.status !== 429) {
        reject();
      }
    });
  }
  function onmessage(message: EventSourceMessage): Promise<void> {
    return new Promise((resolve) => {
      const eventData = message.data;
      if (message.event === "agent_done") {
        resolve();
      } else if (eventData) {
        const agentRun: AgentRun = JSON5.parse(message.data);
        if (!agentRun.id) {
          console.error("Agent run ID not found in message", agentRun);
        }
        agentRun.id = agentRun.id.replace(/-/g, "");
        agentRun.status_messages = agentRun.status_messages || []; // this could be absent if a job has no status messages
        const updatedAgentRuns = {
          ...agentRunsRef.current,
          [agentRun.id]: agentRun,
        };
        setAgentRuns(updatedAgentRuns);
        setIsLoadingStatusMessages(false);
        resolve();
      }
    });
  }
  function onclose() {
    if (Object.keys(agentRuns).length > 0 && streamRetriesRef.current < 3 && selectedEndpoint && selectedAgentJobId) {
      setStreamRetries(streamRetriesRef.current + 1);
      return parchaApi.fetchAgentEvents(
        selectedEndpoint.endpointUrl,
        [selectedAgentJobId],
        onopen,
        onmessage,
        onclose,
        onerror,
      );
    }
  }

  function onerror(err: Error): number {
    if (Object.keys(agentRuns) && streamRetriesRef.current < 3 && selectedEndpoint && selectedAgentJobId) {
      parchaApi.fetchAgentEvents(
        selectedEndpoint.endpointUrl,
        [selectedAgentJobId],
        onopen,
        onmessage,
        onclose,
        onerror,
      );
      setStreamRetries(streamRetriesRef.current + 1);
      return 0;
    } else {
      throw err;
    }
  }

  useLayoutEffect(() => {
    if (!hasScrollBeenProcessed && selectedAgentJobId && stage === "idle" && orderedAgentRuns?.length > 0) {
      const container = document.getElementById("agent-runs-list");
      const element = document.getElementById(selectedAgentJobId);

      if (element && container) {
        container.scrollTo({
          top: element.offsetTop,
          behavior: "smooth",
        });
        setHasScrollBeenProcessed(true);
      }
    }
  }, [orderedAgentRuns?.length]);

  useEffect(() => {
    if (!selectedAgentRun || !selectedEndpoint) return;

    setIsLoadingFeedbackData(true);
    parchaApi.getLatestFinalOutputFeedback(selectedEndpoint.endpointUrl, selectedAgentJobId).then((res) => {
      setLatestFeedbackData(res);
      setIsLoadingFeedbackData(false);
    });

    const hoursAgo = 6;
    if (
      (selectedAgentRun.status === "in progress" || selectedAgentRun.status === "queued") &&
      new Date().getTime() - new Date(selectedAgentRun.created_at + "Z").getTime() < hoursAgo * 60 * 60 * 1000
    ) {
      parchaApi.fetchAgentEvents(
        selectedEndpoint.endpointUrl,
        [selectedAgentJobId],
        onopen,
        onmessage,
        onclose,
        onerror,
      );
      selectedAgentRun.status === "in progress" ? setSelectedTabValue("inputs") : setSelectedTabValue("job_details");
    }
  }, [selectedAgentRun?.id]);

  useEffect(() => {
    if (
      (selectedAgentJobId &&
        selectedEndpoint &&
        agentRuns &&
        agentRuns[selectedAgentJobId] &&
        agentRuns[selectedAgentJobId].status !== "queued" &&
        agentRuns[selectedAgentJobId].status_messages.length === 0) ||
      (selectedAgentJobId && agentRuns && !agentRuns[selectedAgentJobId])
    ) {
      setIsLoadingStatusMessages(true);
      parchaApi.getJobWithStatusMessages(selectedEndpoint.endpointUrl, selectedAgentJobId).then((res) => {
        const updatedAgentRuns = {
          ...agentRunsRef.current,

          [selectedAgentJobId]: {
            ...res,
            id: res.id.replace(/-/g, ""),
            isHighRisk:
              agentRuns[selectedAgentJobId] && typeof agentRuns[selectedAgentJobId].isHighRisk !== "undefined"
                ? agentRuns[selectedAgentJobId].isHighRisk
                : res.status_messages
                    ?.find((message: StatusMessage) => message.event === "final_answer")
                    ?.content?.output?.evidence?.some((evidence: Evidence) => {
                      const commandResult = evidence.command_result;
                      const output = commandResult.output;
                      return Boolean(output?.alerts?.high_risk_mcc_alert);
                    }) || false,
          },
        };

        setAgentRuns(updatedAgentRuns);

        const isThisALegacyJob = determineIfJobIsLegacy(res);

        if (isThisALegacyJob) {
          setIsLegacyJob(true);
          setSelectedTabValue("audit_log");
          setIsLoadingStatusMessages(false);
          return;
        } else {
          setSelectedTabValue("inputs");
          setIsLegacyJob(false);
        }

        const notStatusEventMessages =
          res.status_messages && res.status_messages.filter((message) => message.event !== "status");

        const inputMessages = notStatusEventMessages.filter((message) => {
          const commandResult = message.content as CommandResult;
          const output = commandResult?.output as OutputSchema;
          const payload = output?.payload;
          const type = payload?.type;

          return (message.event === "api_results" && type?.includes("SelfAttestedData")) ?? false;
        });

        if (inputMessages.length > 0) {
          setSelectedTabValue("inputs");
          setShouldDisplayTabSelector(true);
        } else {
          setSelectedTabValue("job_details");
          setShouldDisplayTabSelector(false);
        }
        setIsLoadingStatusMessages(false);
      });
    } else if (selectedAgentJobId && agentRuns && agentRuns[selectedAgentJobId]) {
      const job = agentRuns[selectedAgentJobId];
      const isThisALegacyJob = determineIfJobIsLegacy(job);
      if (isThisALegacyJob) {
        setIsLegacyJob(true);
        setSelectedTabValue("audit_log");
        setIsLoadingStatusMessages(false);
        return;
      } else {
        setSelectedTabValue("inputs");
        setIsLegacyJob(false);
      }

      const notStatusEventMessages =
        job.status_messages && job.status_messages.filter((message) => message.event !== "status");

      const inputMessages = notStatusEventMessages.filter((message) => {
        const commandResult = message.content as CommandResult;
        const output = commandResult?.output as OutputSchema;
        const payload = output?.payload;
        const type = payload?.type;

        return (message.event === "api_results" && type?.includes("SelfAttestedData")) ?? false;
      });

      if (inputMessages.length > 0) {
        setSelectedTabValue("inputs");
        setShouldDisplayTabSelector(true);
      } else {
        setSelectedTabValue("job_details");
        setShouldDisplayTabSelector(false);
      }
    }
  }, [agentRuns, selectedAgentJobId]);

  const determineIfJobIsLegacy = (job: AgentRun) => {
    const isThisALegacyJob =
      job.status === "complete" &&
      job.status_messages.filter((message) => message.event === "thought" && message.content.command_instance_id)
        .length === 0;
    return isThisALegacyJob;
  };

  useEffect(() => {
    if (stage === "initial_load_done" && Object.keys(agentRuns).length > 0) {
      setStage("idle");
    }
  }, [stage, agentRuns]);

  useEffect(() => {
    if (selectedEndpoint) {
      setAgentRuns({});
      getAgentJobHistory(selectedEndpoint.endpointUrl, selectedEndpoint.agentKey);
    }
  }, [selectedEndpoint]);

  useEffect(() => {
    if (!agentRuns) return;

    const filteredAgentRuns = Object.values(agentRuns).filter((run) => run.id);
    const sortedAgentRunValues = _.orderBy(filteredAgentRuns, ["created_at"], ["desc"]);
    setOrderedAgentRuns(sortedAgentRunValues);
    if (agentRuns && selectedAgentJobId) {
      setSelectedAgentRun(agentRuns[selectedAgentJobId]);
    }

    if (sortedAgentRunValues.length > 0 && !selectedAgentJobId) {
      setSelectedAgentJobId(sortedAgentRunValues[0].id);
      navigate(`/jobs/${sortedAgentRunValues[0].agent_id}/${sortedAgentRunValues[0].id}`);
    }
  }, [agentRuns]);

  useEffect(() => {
    if (routeJobId) {
      setSelectedAgentJobId(routeJobId);
    } else if (orderedAgentRuns?.length) {
      setSelectedAgentJobId(orderedAgentRuns[0].id);
      navigate(`/jobs/${agentRun.agent_id}/${orderedAgentRuns[0].id}`);
    }
  }, [routeJobId]);

  useEffect(() => {
    if (selectedAgentJobId && agentRuns[selectedAgentJobId]) {
      setSelectedAgentRun(agentRuns[selectedAgentJobId]);
    }
  }, [selectedAgentJobId]);

  // Handle copy to clipboard event
  const handleCopyToClipboard = () => {
    if (finalAnswer) {
      navigator.clipboard.writeText(finalAnswer?.content?.output?.follow_up);
    }
  };

  const handleRetryJobClick = () => {
    const input = {
      agent_key: selectedEndpoint.agentKey,
      kyb_schema: agent.agent_type === "kyb" ? selectedAgentRun.input_payload : undefined,
      kyc_schema: agent.agent_type === "kyc" ? selectedAgentRun.input_payload : undefined,
      run_in_parallel: true,
    };

    parchaApi
      .enqueueAgent(selectedEndpoint.endpointUrl, input, agent.agent_type)
      .then((res) => {
        window.location.href = `/jobs/${selectedEndpoint.agentKey}/${res.job_id}`;
      })
      .catch(handleApiException);
  };

  const handleDeleteJobActionClick = () => {
    setIsDeleteJobDialogOpen(true);
  };

  const handleShowSearchClick = () => {
    setShowSearch(true);
  };

  const deleteJob = async () => {
    await parchaApi.deleteJob(selectedEndpoint.endpointUrl, selectedAgentRun?.id);
    // using replace:true to avoid returning to a delete job URL when back button is clicked
    // Perform a hard refresh
    //TODO: don't do this if the request above fails. It's annoying to not see the error.
    window.location.href = `/jobs/${selectedEndpoint.agentKey}`;
  };

  const finalAnswer = selectedAgentRun?.status_messages?.find((message) => message.event === "final_answer");

  const loadRemainingJobsCount =
    agentRuns == null
      ? 0
      : totalJobsCount - Object.values(agentRuns).length > JOB_HISTORY_PAGINATION_LIMIT
        ? JOB_HISTORY_PAGINATION_LIMIT
        : totalJobsCount - Object.values(agentRuns).length;

  const publicJobsRemaining = selectedEndpoint.isPublic
    ? user.customAttributes.publicBddQuota - user.customAttributes.publicBddJobsRun
    : 0;

  return stage !== "idle" ? (
    <div className="flex flex-col justify-center items-center text-brand-purple text-xl h-full side-panel">
      <Box
        className={styles.loader}
        component="img"
        src={"/working_loader.svg"}
        alt="logo"
        width={48}
        sx={{
          padding: "0",
          animationName: "spin",
          animationTimingFunction: "linear",
          animationIterationCount: "infinite",
          animationDuration: "3s",
          animationDelay: "0",
          "@keyframes spin": {
            "0%": { transform: "rotate(0deg)" },
            "100%": { transform: "rotate(360deg)" },
          },
        }}
      />
      <span>Loading agent jobs...</span>
    </div>
  ) : (
    <>
      <div className="w-full h-full grid grid-cols-1 md:grid-cols-[_25vw,minmax(0,_1fr)]">
        <div className={twJoin("hidden md:flex-col gap-1 border-r md:flex", selectedEndpoint.isPublic ? "" : "py-3")}>
          {!selectedEndpoint.isPublic && (
            <div className="flex flex-col gap-y-2 md:gap-0 md:flex-row px-3 justify-between md:items-center ">
              {endpoints.length > 1 ? (
                <AgentsDropdownMenu
                  items={endpoints.map((endpoint) => ({
                    label: endpoint?.agentName,
                    url: `/jobs/${endpoint.agentKey}`,
                  }))}
                  selectedItem={{ label: selectedEndpoint?.agentName, url: `/jobs/${selectedEndpoint?.agentKey}` }}
                />
              ) : (
                <div className="text-md font-semibold line-clamp-1">{title}</div>
              )}
              <div className="flex gap-x-1 overflow-hidden">
                {!isFlutterwaveMCC && (
                  <button
                    type="button"
                    className="text-sm relative flex justify-center items-center rounded-md bg-brand-purple p-2 h-4 border border-solid border-brand-purple text-white focus:z-10 "
                    onClick={() => setIsRunAgentCaseModalOpen((prev) => !prev)}
                  >
                    <PlayCircleRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                    <span className="line-clamp-1 text-xs">Run a Job</span>
                  </button>
                )}
                <button
                  onClick={handleShowSearchClick}
                  className="flex justify-center items-center border border-solid border-slate-300 rounded-md w-3 h-3 p-2.5"
                >
                  <Search sx={{ fontSize: "1rem" }} className="text-slate-900" />
                </button>
                {isFlutterwaveMCC && (
                  <button
                    type="button"
                    className="text-sm relative flex justify-center items-center rounded-md bg-brand-purple p-2 h-4 border border-solid border-brand-purple text-white focus:z-10 "
                    onClick={() => setIsAddCaseModalOpen((prev) => !prev)}
                  >
                    <Add sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                    <span className="line-clamp-1">Add Cases</span>
                  </button>
                )}
              </div>
            </div>
          )}
          {orderedAgentRuns && orderedAgentRuns?.length ? (
            <div className="h-0 min-h-full pb-3 overflow-y-auto relative" id="agent-runs-list">
              {orderedAgentRuns.map((ar) => (
                <AgentRunItem id={ar.id} key={ar.id} isSelected={ar.id === selectedAgentJobId} agentRun={ar} />
              ))}
              {hasMoreJobs ? (
                <div className="sticky bottom-5 w-full flex justify-center pb-5">
                  <button
                    className="shadow inline-flex items-center py-2 px-4 border border-solid border-slate-200 rounded-md text-sm font-medium bg-white hover:bg-slate-50"
                    onClick={async () => {
                      if (!isLoadingMoreJobs) {
                        setIsLoadingMoreJobs(true);
                        await fetchAgentJobs(selectedEndpoint.endpointUrl, selectedEndpoint.agentKey);
                        setIsLoadingMoreJobs(false);
                      }
                    }}
                  >
                    {isLoadingMoreJobs ? (
                      <div className="w-[10rem] h-[1.25rem]">
                        <ParchaLoadingScreen size="small" message="" />
                      </div>
                    ) : (
                      <>
                        <span>{`Load ${loadRemainingJobsCount} more jobs...`}</span>
                        <ArrowDownwardRounded sx={{ fontSize: "1rem" }} className="ml-1 h-4 w-4" aria-hidden="true" />
                      </>
                    )}
                  </button>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="text-brand-purple py-3 px-6 italic opacity-75">No jobs for this agent.</div>
          )}
        </div>
        <div className="flex flex-col">
          {selectedAgentRun && !selectedEndpoint.isPublic ? (
            <div className="w-full flex justify-between gap-x-1 md:gap-0 items-center bg-slate-50 border-b border-slate-200 p-5">
              <div className="flex flex-col grow gap-y-2 ">
                <div className="flex items-center gap-x-5">
                  <div className="font-semibold">
                    {selectedAgentRun.input_payload?.self_attested_data?.name ||
                      selectedAgentRun.input_payload?.self_attested_data?.business_name ||
                      selectedAgentRun.input_payload?.self_attested_data?.registered_business_name ||
                      `${selectedAgentRun.input_payload?.self_attested_data?.first_name}${
                        selectedAgentRun.input_payload?.self_attested_data?.middle_name
                          ? ` ${selectedAgentRun.input_payload?.self_attested_data?.middle_name} `
                          : " "
                      }${selectedAgentRun.input_payload?.self_attested_data?.last_name}`}
                  </div>
                  {selectedAgentRun?.recommendation && recommendationComponents[selectedAgentRun?.recommendation]}
                </div>
                <div className="flex items-center gap-1 md:gap-2 text-xs md:text-sm">
                  <div>
                    <StatusIndicator status={selectedAgentRun?.status} />
                  </div>
                  <div className="capitalize">{selectedAgentRun?.status}</div>
                  {selectedAgentRun?.status && selectedAgentRun?.started_at && <div className="hidden md:block">•</div>}
                  <div className="hidden md:block">
                    {selectedAgentRun?.started_at &&
                      formatDistanceToNowStrict(new Date(`${selectedAgentRun?.started_at}Z`), { addSuffix: true })}
                  </div>
                </div>
              </div>
              {!selectedEndpoint.isPublic && (
                <div className="flex gap-1 md:pr-5">
                  <button
                    type="button"
                    className="inline-flex items-center gap-x-1.5 rounded-md bg-brand-purple px-3 py-2 text-xs md:text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
                    onClick={() => setIsGenerateFollowUpOpen(true)}
                    disabled={!finalAnswer?.content?.output?.follow_up}
                  >
                    <AutoAwesome className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Generate Follow-up
                  </button>
                  <Menu as="div" className="text-left">
                    <div>
                      <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-1 md:px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50">
                        <MoreHoriz className="h-3 w-3 md:h-5 md:w-5 text-slate-900" aria-hidden="true" />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-3 z-30 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="flex flex-col  divide-y divide-solid">
                          <Menu.Item>
                            <div className="px-4 py-2 text-sm text-slate-900 hover:bg-slate-300">
                              <button className="w-full" onClick={handleDeleteJobActionClick}>
                                Delete Job
                              </button>
                            </div>
                          </Menu.Item>
                          <Menu.Item>
                            <div className="px-4 py-2 text-sm text-slate-900 hover:bg-slate-300">
                              <button className="w-full" onClick={handleRetryJobClick}>
                                Retry Job
                              </button>
                            </div>
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              )}
            </div>
          ) : null}
          {selectedAgentRun && (
            <FeedbackContext.Provider
              value={{
                feedbackData: {
                  ...latestFeedbackData,
                  endpoint: selectedEndpoint,
                  data: { ...finalAnswer?.content },
                  job_id: selectedAgentRun.id,
                },
                setLatestFeedbackData,
                isLoading: isLoadingFeedbackData,
              }}
            >
              {!isLoadingStatusMessages ? (
                <div className="flex h-full" style={{ zIndex: 1 }}>
                  {selectedAgentRun && (
                    <div
                      className={twJoin(
                        "shrink-0",
                        showTabular || agent.agent_key.includes("new-bdd") || agent.agent_key.includes("brex-kyb")
                          ? "w-full"
                          : "w-[calc(44vw-80px)]",
                      )}
                    >
                      <AgentRunChecksView
                        agent={agent}
                        agentRun={selectedAgentRun}
                        endpoint={selectedEndpoint}
                        isLegacyJob={isLegacyJob}
                        setSelectedSidePanelTabValue={setSelectedTabValue}
                        onTryAgainClick={setIsRunAgentCaseModalOpen}
                        showTabular={showTabular}
                        setShowTabular={setShowTabular}
                      />
                    </div>
                  )}
                  {agent &&
                    selectedEndpoint &&
                    user &&
                    selectedAgentJobId &&
                    !showTabular &&
                    Object.keys(agentRuns).length > 0 && (
                      <div className="px-2 min-w-full md:min-w-[calc(30vw)] grow-0 h-[calc(100%-0.5rem)] overflow-y-auto">
                        <NewAgentChatSSE
                          isLegacyJob={isLegacyJob}
                          isParchaUser={isParchaUser}
                          selectedTabValue={selectedTabValue}
                          setSelectedTabValue={setSelectedTabValue}
                          feedbackData={{ endpoint: selectedEndpoint }}
                          shouldDisplayTabSelector={shouldDisplayTabSelector}
                          agentRun={{ ...agentRuns[selectedAgentJobId] }}
                        />
                      </div>
                    )}
                </div>
              ) : (
                <div className="h-full flex flex-col justify-center items-center text-brand-purple text-xl">
                  <Box
                    className={styles.loader}
                    component="img"
                    src={"/working_loader.svg"}
                    alt="logo"
                    width={48}
                    sx={{
                      padding: "0",
                      animationName: "spin",
                      animationTimingFunction: "linear",
                      animationIterationCount: "infinite",
                      animationDuration: "3s",
                      animationDelay: "0",
                      "@keyframes spin": {
                        "0%": { transform: "rotate(0deg)" },
                        "100%": { transform: "rotate(360deg)" },
                      },
                    }}
                  />
                  <span>Loading job data...</span>
                </div>
              )}
            </FeedbackContext.Provider>
          )}
        </div>
      </div>
      <Transition.Root show={isGenerateFollowUpOpen} as={Fragment}>
        <Dialog as="div" initialFocus={dialogDivRef} className="relative z-20" onClose={setIsGenerateFollowUpOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-slate-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="h-[20rem] min-h-[20rem] relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-slate-400 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-brand-purple focus:ring-offset-2"
                      onClick={() => setIsGenerateFollowUpOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <Close className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start h-full">
                    <div className="mt-3 text-center w-full sm:mt-0 sm:text-left" ref={dialogDivRef}>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-slate-900">
                        Follow-up Message
                      </Dialog.Title>
                      <div className="flex flex-col gap-2 py-4 h-full justify-between ">
                        <p className="p-4 w-full leading-4 text-xs text-slate-700 overflow-x-hidden overflow-y-auto h-[12rem] border-solid border border-slate-300 rounded-md">
                          <ReactMarkdown>{finalAnswer?.content.output.follow_up}</ReactMarkdown>
                        </p>
                        <div>
                          <button
                            tabIndex={1}
                            className="w-6 h-6 p-1 text-center border-solid border border-slate-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-purple focus:ring-offset-2"
                            onClick={handleCopyToClipboard}
                          >
                            <span className="sr-only">Copy to clipboard</span>
                            <CopyAllRounded
                              sx={{ fontSize: "1rem", width: "1rem", height: "1rem" }}
                              className="text-slate-900"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={isDeleteJobDialogOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={setIsDeleteJobDialogOpen} initialFocus={deleteJobDivRef}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-slate-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-slate-900">
                    Are you sure you want to delete this job?
                  </Dialog.Title>
                  <div className="flex flex-col gap-3 mt-3 text-sm" ref={deleteJobDivRef}>
                    <p>Once you delete the job you will no longer have access to it.</p>
                    <div className="flex justify-end gap-2">
                      <button
                        className="border-solid border border-slate-300 rounded-sm h-6 px-2 py-1 focus:outline-none focus:ring-2 focus:ring-brand-purple focus:ring-offset-2"
                        onClick={() => setIsDeleteJobDialogOpen(false)}
                      >
                        Nevermind
                      </button>
                      <button
                        className="bg-brand-purple rounded-sm px-2 py-1 text-white focus:outline-none focus:ring-2 focus:ring-brand-purple focus:ring-offset-2"
                        onClick={deleteJob}
                      >
                        Yes, delete it
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <CaseSearch show={showSearch} setShow={setShowSearch} endpoint={selectedEndpoint} />

      <>
        <>
          <Fab
            color="primary"
            aria-label="View Job Details"
            style={{
              position: "fixed",
              bottom: "2rem",
              right: "2rem",
              zIndex: 1000,
            }}
            onClick={() => setIsJobDetailsOpen(true)}
          >
            <Info />
          </Fab>

          <Drawer
            anchor="right"
            open={isJobDetailsOpen}
            onClose={() => setIsJobDetailsOpen(false)}
            PaperProps={{
              style: {
                width: "25vw",
                minWidth: "400px",
              },
            }}
          >
            <div className="px-2 h-full overflow-y-auto">
              <NewAgentChatSSE
                isLegacyJob={isLegacyJob}
                isParchaUser={isParchaUser}
                selectedTabValue={selectedTabValue}
                setSelectedTabValue={setSelectedTabValue}
                feedbackData={{ endpoint: selectedEndpoint }}
                shouldDisplayTabSelector={shouldDisplayTabSelector}
                agentRun={{ ...agentRuns[selectedAgentJobId] }}
              />
            </div>
          </Drawer>
        </>
      </>
    </>
  );
};

export function AgentsDropdownMenu({ items, selectedItem }) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex w-40 gap-x-1.5 rounded-md bg-white p-2 text-xs md:text-md font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:ring-slate-400">
          <span className="line-clamp-1 text-left">{selectedItem.label}</span>
          <ExpandMoreRounded sx={{ fontSize: "1rem" }} className="-mr-1 h-4 w-4 text-slate-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="h-fit max-h-[70vh] overflow-y-auto absolute left-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {items.map((item) => {
              return (
                <Menu.Item key={item.label}>
                  <a
                    href={item.url}
                    className={clsx(
                      selectedItem.label === item.label ? "bg-slate-100 text-slate-900" : "text-slate-700",
                      "block px-4 py-2 text-sm",
                    )}
                  >
                    {item.label}
                  </a>
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default Queue;
