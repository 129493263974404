// @ts-nocheck
import StatusIndicator from "@/components/TailwindComponents/StatusIndicator";
import useAuth from "@/hooks/useAuth";
import { useParchaApi } from "@/hooks/useParchaApi";
import { AgentRun } from "@/types";
import { useDescope } from "@descope/react-sdk";
import {
  AttractionsRounded,
  ArrowDownwardRounded,
  AutoAwesome,
  LockRounded,
  Menu as MenuIcon,
} from "@mui/icons-material";
import clsx from "clsx";
import { formatDistanceToNowStrict } from "date-fns";
import { useState, useEffect } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { twJoin } from "tailwind-merge";
import ParchaLoadingScreen from "../ParchaLoadingScreen";
import ReportView from "./Report";

const JOB_HISTORY_PAGINATION_LIMIT = 25;

const ReportList = () => {
  const [jobs, setJobs] = useState([]);
  const { reportJobId } = useParams();
  const [isLoadingJobs, setIsLoadingJobs] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { isLoading, user } = useAuth();
  const parchaApi = useParchaApi();
  const descope = useDescope();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      descope.me();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isLoading) return;

    const envTier = import.meta.env.VITE_ENV_TIER;
    const endpointUrl =
      envTier === "development" ? "localhost:8001" : envTier === "staging" ? "staging.parcha.ai" : "demo.parcha.ai";

    parchaApi
      .getAgentJobHistory(endpointUrl, "public-bdd", JOB_HISTORY_PAGINATION_LIMIT, 0)
      .then((res: { items: AgentRun[] }) => {
        setJobs(res.items);

        if (!reportJobId) {
          res.items.length > 0 ? navigate(`/labs/create-report/${res.items[0].id}`) : navigate(`/labs/create-report`);
        }

        setIsLoadingJobs(false);
      });

    const interval = setInterval(() => {
      parchaApi.getAgentJobHistory(endpointUrl, "public-bdd", 50, 0).then((res: any) => {
        setJobs(res.items);
      });
    }, 10000);

    return () => clearInterval(interval);
  }, [isLoading]);

  useEffect(() => {
    if (!reportJobId && jobs.length > 0) {
      navigate(`/labs/create-report/${jobs[0].id}`);
    }
  }, [jobs]);

  const publicJobsRemaining = user?.customAttributes?.publicBddQuota - user?.customAttributes?.publicBddJobsRun ?? 0;

  return (
    <div className="h-full w-full">
      <div className="h-[calc(100vh-4.85rem)] md:h-[calc(100vh-4rem)] overflow-y-auto grid grid-cols-1 md:grid-cols-[25vw,minmax(0,1fr)]">
        {isLoadingJobs ? (
          <div className="col-span-2">
            <ParchaLoadingScreen message="Loading your reports..." size="large" />
          </div>
        ) : (
          <>
            <div className="relative hidden md:block h-0 min-h-full overflow-y-auto border border-r-slate-200">
              {jobs.map((agentRun: AgentRun) => (
                <Link
                  key={agentRun.id}
                  to={`/labs/create-report/${agentRun.id}`}
                  className={clsx("flex flex-col p-2.5 md:p-5 border-b border-b-slate-200 gap-3", {
                    "bg-slate-100": reportJobId === agentRun.id,
                  })}
                  id={agentRun.id}
                >
                  <div className="flex justify-between gap-x-2">
                    <div className="line-clamp-2 md:line-clamp-4 font-inter font-semibold text-sm md:text-md leading-normal">
                      {agentRun.input_payload?.self_attested_data?.name ||
                        agentRun.input_payload?.self_attested_data?.business_name ||
                        agentRun.input_payload?.self_attested_data?.registered_business_name ||
                        agentRun.input_payload?.self_attested_data?.website?.replace(/https?:\/\/(www\.)?|www\./gi, "")}
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center gap-1 md:gap-2 text-xs md:text-sm">
                    <div>
                      <StatusIndicator status={agentRun.status} />
                    </div>
                    <div className="capitalize">{agentRun.status}</div>
                    <div className="hidden md:block">•</div>
                    <div className="hidden md:block">
                      {formatDistanceToNowStrict(new Date(`${agentRun.created_at}Z`), { addSuffix: true })}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
            <ReportView />
          </>
        )}
      </div>
    </div>
  );
};

export default ReportList;
