import { MessageProps, Thought } from "../../../types";
import BaseMessage from "../BaseMessage";
import { getLogoFromAgentKey } from "@parcha-src/utils";
import React from "react";

const ThoughtUI = ({ id, messageContents, appendToPrev }: MessageProps) => {
  const thought = messageContents as Thought;
  let clonedFeedbackData = null;
  const speak = thought.speak;

  let icon: React.ReactNode = null;
  const props = { color: "#FFFFFF", width: "1.5rem", height: "1.5rem" };

  if (thought.agent_key) {
    icon = getLogoFromAgentKey(thought.agent_key, props);
  }

  return speak ? <BaseMessage id={id} icon={!appendToPrev && icon} message={speak} customComponent={null} /> : null;
};

export default ThoughtUI;
