import React from "react";
import { FinishPlanOutputSchema, CommandResult, MessageProps, OutputSchema, Evidence } from "../../../types";
import BaseMessage from "../BaseMessage";
import { getLogoFromAgentKey, getLogoFromCommandId } from "@parcha-src/utils";
import FinalOutputEntry from "@/components/TailwindComponents/FinalOutputEntry";
import FinalOutput from "@/components/TailwindComponents/FinalOutput";

const FinalAnswerUI = ({ id, isHighRisk, messageContents }: MessageProps) => {
  const finalAnswer = messageContents as CommandResult;
  const finalAnswerOutput = finalAnswer.output as FinishPlanOutputSchema;
  const evidence = finalAnswerOutput.evidence;

  let icon: React.ReactNode = null;
  const props = { color: "#FFFFFF", width: "1.5rem", height: "1.5rem" };

  if (finalAnswer.command_id) {
    icon = getLogoFromCommandId(finalAnswer.command_id, props);
  } else if (finalAnswer.agent_key) {
    icon = getLogoFromAgentKey(finalAnswer.agent_key, props);
  }

  const getFinalOutputTitle = () => {
    if (!isFlutterwaveMCC) return finalAnswerOutput.recommendation;

    if (isHighRisk) {
      return "High Risk";
    } else if (finalAnswerOutput.recommendation === "unknown") {
      return "Unknown";
    }
    return finalAnswerOutput.recommendation;
  };

  const isFlutterwaveMCC = finalAnswer?.agent_key === "kyb-mcc-flutterwave";

  const getFinalOutputLevel = () => {
    if (!finalAnswerOutput.recommendation) return "normal";

    if (!isFlutterwaveMCC) {
      if (finalAnswerOutput?.recommendation === "Approve") return "success";
      if (finalAnswerOutput?.recommendation === "Deny") return "failure";
      if (finalAnswerOutput?.recommendation === "Review") return "caution";
      return "normal";
    }

    const mccEvidence = (finalAnswer?.output as FinishPlanOutputSchema).evidence.find(
      (ev: Evidence) => ev.command_result.command_id === "kyb.mcc_code_check.mcc_code_check_tool",
    );
    const inputData = mccEvidence?.command_result?.args?.input_data;

    const inputMccCode = inputData?.mcc_code || null;
    const recommendationMccCode = finalAnswerOutput.recommendation;

    if (isHighRisk || recommendationMccCode === "unknown") {
      return "failure";
    } else if (recommendationMccCode === "unknown") {
      return "failure";
    } else if (inputMccCode && recommendationMccCode && inputMccCode === recommendationMccCode) {
      return "success";
    } else if (
      (inputMccCode && recommendationMccCode && inputMccCode !== recommendationMccCode) ||
      recommendationMccCode === "Review"
    ) {
      return "caution";
    }
    return "normal";
  };

  const customComponent =
    finalAnswerOutput && evidence?.length > 0 ? (
      <div className="flex flex-col gap-4 mb-6">
        {evidence.map((evidenceEntry) => {
          const output = evidenceEntry.command_result?.output as OutputSchema;
          return (
            <FinalOutputEntry
              key={evidenceEntry.step_description}
              explanation={output?.answer}
              passed={Boolean(
                output.passed && ["true", "pass", "passed", "yes"].includes(String(output.passed).toLowerCase()),
              )}
              stepName={evidenceEntry.step_description}
            />
          );
        })}
        <FinalOutput
          id="final-answer-output"
          message={
            <div className="flex flex-col gap-y-2">
              <h2 className="font-semibold">{getFinalOutputTitle()}</h2>
              <div>{finalAnswerOutput.answer}</div>
            </div>
          }
          level={getFinalOutputLevel()}
          feedbackContent={finalAnswer}
        />
      </div>
    ) : null;

  return <BaseMessage id={id} icon={icon} message={finalAnswerOutput.answer} customComponent={customComponent} />;
};

export default FinalAnswerUI;
