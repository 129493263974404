import { ReactNode } from "react";
import AddressValue, { AddressValueProps } from "./AddressValue";
import { format } from "date-fns";
import { UIDocument } from "@/types";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import DocumentValue from "./DocumentValue";

type BankCheckCardProps = {
  endpointUrl: string;
  summary: string;
  date: string | null;
  origination_bank: string;
  payer: string;
  payee: string;
  routing_number: string;
  account_number: string;
  amount_numeric: number;
  amount_text: string;
  address: AddressValueProps;
  check_number: string;
  document: UIDocument | null;
  input: any;
  handleDocumentValidationClick: (checkResult: any, document: any) => void;
  checkResult: any;
  caseId: string;
};

import * as React from "react";
import type { Plugin, RenderViewer } from "@react-pdf-viewer/core";

export interface PageThumbnailPluginProps {
  PageThumbnail: React.ReactElement;
}

export const pageThumbnailPlugin = (props: PageThumbnailPluginProps): Plugin => {
  const { PageThumbnail } = props;

  return {
    renderViewer: (renderProps: RenderViewer) => {
      let { slot } = renderProps;

      slot.children = PageThumbnail;
      // Reset the sub slot if it exists
      if (slot.subSlot) {
        slot.subSlot.attrs = {};
        slot.subSlot.children = <></>;
      } else {
        slot.subSlot = {
          attrs: {},
          children: <></>,
        };
      }

      return slot;
    },
  };
};

export default function BankCheckCard({
  endpointUrl,
  summary,
  check_number,
  date,
  origination_bank,
  payer,
  payee,
  routing_number,
  account_number,
  amount_numeric,
  amount_text,
  address,
  input,
  handleDocumentValidationClick,
  checkResult,
  caseId,
}: BankCheckCardProps) {
  const thumbnailPluginInstance = thumbnailPlugin();
  const { Cover } = thumbnailPluginInstance;
  const pageThumbnailPluginInstance = pageThumbnailPlugin({
    PageThumbnail: <Cover getPageIndex={() => 0} />,
  });
  return (
    <div className="w-full flex justify-center text-xs">
      <div className="grid grid-cols-2 gap-2">
        <ValueContainer label="Bank" value={origination_bank} />
        <div className="py-3 w-full row-span-3 border border-slate-200 rounded-md">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <Viewer
              fileUrl={`${
                window.location.protocol
              }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(
                input?.bank_check_documents[0]?.url,
              )}`}
              plugins={[thumbnailPluginInstance, pageThumbnailPluginInstance]}
            />
          </Worker>
        </div>
        {check_number && <ValueContainer label="Check #" value={check_number} />}
        {input?.bank_check_documents?.length > 0 && (
          <ValueContainer
            label="Document URL"
            value={
              <DocumentValue
                fileName={input?.bank_check_documents[0]?.file_name}
                url={input?.bank_check_documents[0]?.url}
                handleDocumentValidationClick={handleDocumentValidationClick}
                checkResult={checkResult}
              />
            }
          />
        )}

        {date && <ValueContainer label="Date" value={format(new Date(date.replace(/-/g, "/")), "MMM d, yyyy")} />}
        {address && !Object.entries(address).every(([k, v]) => k === "type" || v === null) && (
          <ValueContainer label="Address" value={<AddressValue {...address} />} />
        )}
        <ValueContainer label="Payer" value={payer} />
        <ValueContainer label="Payee" value={payee} />
        <ValueContainer label="Routing #" value={routing_number} />
        <ValueContainer label="Account #" value={account_number} />
        <ValueContainer label="Amount (Numeric)" value={`$${amount_numeric.toFixed(2)}`} />
        <ValueContainer label="Amount (Text)" value={amount_text} />
        <div className="col-span-full">
          <ValueContainer label="Summary" value={summary} />
        </div>
      </div>
    </div>
  );
}

const ValueContainer = ({ label, value }: { label: string; value: ReactNode }) => {
  return (
    <div className="bg-slate-50 py-3 px-5 rounded-md flex flex-col gap-y-1 text-slate-700">
      <span className="font-semibold">{label}</span>
      <span>{value}</span>
    </div>
  );
};
