import { useContext, useEffect } from "react";
import { Link, NavLink, NavLinkProps, Outlet, useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FactCheckIconOutlined from "@mui/icons-material/FactCheckOutlined";
import InsertChartFilled from "@mui/icons-material/InsertChart";
import InsertChartOutlined from "@mui/icons-material/InsertChartOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsIconOutlined from "@mui/icons-material/SettingsOutlined";
import Plagiarism from "@mui/icons-material/Plagiarism";
import PlagiarismOutlined from "@mui/icons-material/PlagiarismOutlined";
import ParchaLogo from "./ParchaLogo";
import { useUser } from "@descope/react-sdk";
import { twJoin } from "tailwind-merge";
import UserContext from "@/contexts/UserContext";
import { Endpoint } from "@/types";
import {
  DynamicFeedOutlined,
  DynamicFeedRounded,
  ThumbsUpDown,
  ThumbsUpDownOutlined,
  OpenInNewRounded,
} from "@mui/icons-material";
import LabsSelected from "/labs_selected.svg";

const LabsIconComponent = ({ className }: { className?: string }) => (
  <svg
    width="18"
    height="24"
    viewBox="0 0 18 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9632 14.1239L17.4348 20.7708C17.7508 21.156 17.8891 21.5906 17.8891 22.0251C17.8891 23.0424 17.0891 24.0004 15.9138 24.0004H2.08664C0.921205 24.0004 0.121205 23.0424 0.111328 22.0251C0.111328 21.5906 0.2496 21.156 0.565649 20.7708L6.03725 14.1239V8.19797H5.0496C4.50639 8.19797 4.06195 7.75352 4.06195 7.21031C4.06195 6.6671 4.50639 6.22266 5.0496 6.22266H12.9508C13.494 6.22266 13.9385 6.6671 13.9385 7.21031C13.9385 7.75352 13.494 8.19797 12.9508 8.19797H11.9632V14.1239ZM8.57789 8C8.0256 8 7.57789 8.44772 7.57789 9V14.4069C7.57789 14.6523 7.48767 14.8891 7.32441 15.0723L4.06678 18.7273L2.03997 21.0013C1.46545 21.6459 1.92301 22.6667 2.78649 22.6667H15.2137C16.0772 22.6667 16.5348 21.6459 15.9602 21.0013L13.9334 18.7273L10.6758 15.0723C10.5125 14.8891 10.4223 14.6523 10.4223 14.4069V9C10.4223 8.44772 9.97462 8 9.42233 8H8.57789Z"
    />
  </svg>
);

const baseLinkClasses = "flex justify-center items-center w-12 h-12 rounded group";

const Layout = () => {
  const { user } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { hasRole, endpoints = [] } = userContext;

  const isUserWithoutTenant = user && user.userTenants?.length === 0;
  const isRemediationRoute = location.pathname.includes("/remediation") || location.pathname.includes("/submit-doc");
  const isOnlyPublicEndpoint = endpoints?.every((endpoint: Endpoint) => endpoint.isPublic);

  useEffect(() => {
    if (location.pathname === "/") {
      isUserWithoutTenant ? navigate("/labs") : navigate("/jobs");
    }
  }, [location.pathname]);

  return (
    <div
      className={twJoin("grid h-full overflow-hidden", isRemediationRoute ? "grid-cols-1" : "grid-cols-[80px,_1fr]")}
    >
      {!isRemediationRoute && (
        <div className="hidden md:flex flex-col">
          <div className="bg-brand-purple text-white" id="logo">
            <Link to={isOnlyPublicEndpoint ? "/labs" : "/jobs"} reloadDocument>
              <ParchaLogo />
            </Link>
          </div>
          <div className="w-full h-full py-5 bg-slate-900 text-white text-[1.5rem] flex flex-col">
            <nav className="flex-grow flex flex-col items-center">
              <ul role="navigation" className="flex flex-col items-center w-full gap-4 text-slate-400">
                {isOnlyPublicEndpoint ? (
                  <li className="w-full flex justify-center">
                    <StyledNavLink to="https://preview.parcha.ai/labs" target="_blank">
                      {({ isActive }: { isActive: boolean }) =>
                        isActive ? (
                          <img src={LabsSelected} className="w-6 h-6" />
                        ) : (
                          <LabsIconComponent className="w-6 h-6 text-slate-400 group-hover:text-white" />
                        )
                      }
                    </StyledNavLink>
                  </li>
                ) : endpoints ? (
                  <>
                    <li className="w-full flex justify-center">
                      <StyledNavLink to="/jobs" reloadDocument>
                        {({ isActive }: { isActive: boolean }) => {
                          return isActive ? (
                            <FactCheckIcon fontSize="inherit" />
                          ) : (
                            <FactCheckIconOutlined fontSize="inherit" />
                          );
                        }}
                      </StyledNavLink>
                    </li>
                    {hasRole("parchaUser") && (
                      <li className="w-full flex justify-center">
                        <StyledNavLink to="/document-requests" reloadDocument>
                          {({ isActive }: { isActive: boolean }) => {
                            return isActive ? (
                              <Plagiarism fontSize="inherit" />
                            ) : (
                              <PlagiarismOutlined fontSize="inherit" />
                            );
                          }}
                        </StyledNavLink>
                      </li>
                    )}
                    <li className="w-full flex justify-center">
                      <StyledNavLink to="/checks-overview">
                        {({ isActive }: { isActive: boolean }) => {
                          return isActive ? (
                            <ThumbsUpDown fontSize="inherit" />
                          ) : (
                            <ThumbsUpDownOutlined fontSize="inherit" />
                          );
                        }}
                      </StyledNavLink>
                    </li>
                    <li className="w-full flex justify-center">
                      <StyledNavLink to="/cases-overview">
                        {({ isActive }: { isActive: boolean }) => {
                          return isActive ? (
                            <InsertChartFilled fontSize="inherit" />
                          ) : (
                            <InsertChartOutlined fontSize="inherit" />
                          );
                        }}
                      </StyledNavLink>
                    </li>
                    {import.meta.env.VITE_ENABLE_JOB_BATCHES === "true" && (
                      <li className="w-full flex justify-center">
                        <StyledNavLink to="/job-batches">
                          {({ isActive }: { isActive: boolean }) => {
                            return isActive ? (
                              <DynamicFeedRounded fontSize="inherit" />
                            ) : (
                              <DynamicFeedOutlined fontSize="inherit" />
                            );
                          }}
                        </StyledNavLink>
                      </li>
                    )}
                    <li className="w-full flex justify-center">
                      <StyledNavLink to="/labs">
                        {({ isActive: _ }: { isActive: boolean }) =>
                          window.location.pathname === "/labs" ? (
                            <img src={LabsSelected} className="w-6 h-6" />
                          ) : (
                            <LabsIconComponent className="w-6 h-6 text-slate-400 group-hover:text-white" />
                          )
                        }
                      </StyledNavLink>
                    </li>
                  </>
                ) : null}
              </ul>
            </nav>
            <div className="mt-auto">
              {hasRole("developer") && (
                <div className="w-full flex justify-center mb-2">
                  <StyledNavLink to="/admin">
                    {({ isActive }: { isActive: boolean }) =>
                      isActive ? <SettingsIcon fontSize="inherit" /> : <SettingsIconOutlined fontSize="inherit" />
                    }
                  </StyledNavLink>
                </div>
              )}
              <div className="w-full flex justify-center">
                <Link to="/signout" className="p-3 text-slate-400 hover:bg-slate-800 hover:text-white">
                  <LogoutIcon className="text-center" fontSize="inherit" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      <main className="overflow-hidden col-span-full md:col-span-1">
        <Outlet />
      </main>
    </div>
  );
};

const StyledNavLink = ({
  to,
  children,
  external = false,
  ...rest
}: {
  to: string;
  external?: boolean;
  children: React.ReactNode | ((props: { isActive: boolean }) => JSX.Element);
} & NavLinkProps) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        isActive
          ? `text-white ${baseLinkClasses} ${external ? "flex items-center justify-center relative" : ""}`
          : `text-slate-500 hover:text-white hover:bg-slate-800 hover:fill-white ${baseLinkClasses} ${
              external ? "flex items-center justify-center relative" : ""
            }`
      }
      {...rest}
    >
      {typeof children === "function" ? children({ isActive: false, isPending: false }) : children}
      {external && (
        <OpenInNewRounded
          sx={{ fontSize: "0.75rem" }}
          className="absolute top-0 right-0 text-slate-400 group-hover:text-slate-400"
        />
      )}
    </NavLink>
  );
};

export default Layout;
