import { AutoAwesomeRounded, CalendarTodayRounded, LocationOnRounded, PersonSearchRounded } from "@mui/icons-material";
import { twJoin } from "tailwind-merge";
import { MatchPill } from "./AdverseMediaV2";
import { Tooltip, styled, TooltipProps } from "@mui/material";

const MatchExplanationTooltip = styled(({ className, ...props }: { className?: string } & TooltipProps) => (
  <Tooltip {...props} title={props.title} componentsProps={{ tooltip: { className } }}>
    {props.children}
  </Tooltip>
))(`    
    background-color: rgba(15, 23, 42, 0.8);   
    padding: 8px 16px;

    & .MuiTooltip-arrow {
      color: rgba(15, 23, 42, 0.8);
    }
`);

const PerpetratorTable = ({
  mediaHit,
  input,
  hitTableLabel,
  type,
  greenColor = false,
}: {
  mediaHit: any;
  input: any;
  hitTableLabel: string;
  greenColor?: boolean;
  type: "source_of_wealth" | "adverse_media" | "pep" | "sanctions";
}) => {
  const mediaHitObj = mediaHit.profile_review || mediaHit;
  const countryMatch = mediaHitObj.country_match;
  const locationMatch = mediaHitObj.location_match;
  const firstNameMatch = mediaHitObj.first_name_match;
  const middleNameMatch = mediaHitObj.middle_name_match;
  const lastNameMatch = mediaHitObj.last_name_match;
  const ageMatch = mediaHitObj.age_match;
  const profileForename = mediaHit.forename;
  const profileMiddleName = mediaHit.middle_name;
  const profileSurname = mediaHit.surname;
  const associatedAddresses = mediaHit.associated_addresses;

  return (
    <table className="w-0 min-w-full table-auto leading-normal text-xs border-b border-slate-300">
      <thead>
        <tr className="text-left bg-slate-50">
          <th className="py-2 px-1.5 pl-5"></th>
          <th className="py-2 px-1.5 text-slate-500">Customer Information</th>
          <th className="py-2 px-1.5">{hitTableLabel}</th>
          <th className="py-2 px-1.5"></th>
        </tr>
      </thead>
      <tbody className="w-full border-separate border-spacing-2 divide-y divide-slate-200">
        <tr className="text-left">
          <td className="whitespace-nowrap px-1.5 py-2 pl-5">
            <div className="flex gap-x-2 items-center">
              <PersonSearchRounded sx={{ fontSize: "1rem" }} />
              <span>First Name</span>
            </div>
          </td>
          <td className="text-slate-900 truncate px-1.5 py-2">
            <span>{input.first_name}</span>
          </td>
          <td
            className={twJoin(
              "truncate px-1.5 py-2",
              firstNameMatch?.exact_match
                ? "text-red-700 font-semibold"
                : firstNameMatch?.partial_match
                  ? "text-orange-600"
                  : firstNameMatch?.mismatch
                    ? "text-yellow-800"
                    : "text-slate-600 italic",
            )}
          >
            <span className={twJoin(firstNameMatch?.exact_match ? "font-semibold" : "")}>
              {firstNameMatch?.display_name
                ? firstNameMatch?.display_name
                : firstNameMatch?.name
                  ? firstNameMatch.name
                  : firstNameMatch.missing
                    ? "-"
                    : profileForename}
            </span>
          </td>
          <td className="px-1.5 py-2 text-right">
            <div className="inline-flex">
              <>
                {firstNameMatch?.exact_match ? (
                  <MatchExplanationTooltip
                    className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                    title={firstNameMatch.explanation || ""}
                    placement="top"
                    arrow
                  >
                    <div>
                      <MatchPill matchRating="strong_match" />
                    </div>
                  </MatchExplanationTooltip>
                ) : firstNameMatch?.partial_match ? (
                  <MatchExplanationTooltip
                    className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                    title={firstNameMatch.explanation || ""}
                    placement="top"
                    arrow
                  >
                    <div>
                      <MatchPill matchRating="partial_match" />
                    </div>
                  </MatchExplanationTooltip>
                ) : firstNameMatch?.mismatch ? (
                  <MatchExplanationTooltip
                    className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                    title={firstNameMatch.explanation || ""}
                    placement="top"
                    arrow
                  >
                    <div>
                      <MatchPill matchRating="mismatch" />
                    </div>
                  </MatchExplanationTooltip>
                ) : (
                  <MatchExplanationTooltip
                    className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                    title="No match information available"
                    placement="top"
                    arrow
                  >
                    <div>
                      <MatchPill matchRating="no_match" />
                    </div>
                  </MatchExplanationTooltip>
                )}
              </>
            </div>
          </td>
        </tr>
        <tr className="text-left">
          <td className="whitespace-nowrap px-1.5 py-2 pl-5">
            <div className="flex gap-x-2 items-center">
              <PersonSearchRounded sx={{ fontSize: "1rem" }} />
              <span>Middle Name</span>
            </div>
          </td>
          <td
            className={twJoin("text-slate-900 truncate px-1.5 py-2", !input.middle_name ? "italic text-slate-400" : "")}
          >
            <span>{input.middle_name || "-"}</span>
          </td>
          <td
            className={twJoin(
              "truncate px-1.5 py-2",
              middleNameMatch?.exact_match
                ? "text-red-700 font-semibold"
                : middleNameMatch?.partial_match
                  ? "text-orange-600"
                  : middleNameMatch?.mismatch
                    ? "text-yellow-800"
                    : "text-slate-400 italic",
            )}
          >
            <span className={middleNameMatch?.exact_match ? "font-semibold" : ""}>
              {middleNameMatch?.display_name
                ? middleNameMatch?.display_name
                : middleNameMatch?.name
                  ? middleNameMatch.name
                  : middleNameMatch.missing
                    ? "-"
                    : profileMiddleName}
            </span>
          </td>
          <td className="px-1.5 py-2 text-right">
            <div className="inline-flex">
              <>
                {middleNameMatch?.exact_match ? (
                  <MatchExplanationTooltip
                    className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                    title={middleNameMatch.explanation || ""}
                    placement="top"
                    arrow
                  >
                    <div>
                      <MatchPill matchRating="strong_match" />
                    </div>
                  </MatchExplanationTooltip>
                ) : middleNameMatch?.partial_match ? (
                  <MatchExplanationTooltip
                    className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                    title={middleNameMatch.explanation || ""}
                    placement="top"
                    arrow
                  >
                    <div>
                      <MatchPill matchRating="partial_match" />
                    </div>
                  </MatchExplanationTooltip>
                ) : middleNameMatch?.mismatch ? (
                  <MatchExplanationTooltip
                    className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                    title={middleNameMatch.explanation || ""}
                    placement="top"
                    arrow
                  >
                    <div>
                      <MatchPill matchRating="mismatch" />
                    </div>
                  </MatchExplanationTooltip>
                ) : (
                  <MatchExplanationTooltip
                    className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                    title="No match information available"
                    placement="top"
                    arrow
                  >
                    <div>
                      <MatchPill matchRating="no_match" />
                    </div>
                  </MatchExplanationTooltip>
                )}
              </>
            </div>
          </td>
        </tr>
        <tr className="text-left">
          <td className="whitespace-nowrap px-1.5 py-2 pl-5">
            <div className="flex gap-x-2 items-center">
              <PersonSearchRounded sx={{ fontSize: "1rem" }} />
              <span>Last Name</span>
            </div>
          </td>
          <td
            className={twJoin("text-slate-900 truncate px-1.5 py-2", !input.last_name ? "italic text-slate-400" : "")}
          >
            <span>{input.last_name || "-"}</span>
          </td>
          <td
            className={twJoin(
              "truncate px-1.5 py-2",
              lastNameMatch?.exact_match
                ? "text-red-700 font-semibold"
                : lastNameMatch?.partial_match
                  ? "text-orange-600"
                  : lastNameMatch?.mismatch
                    ? "text-yellow-800"
                    : "text-slate-600 italic",
            )}
          >
            <span className={twJoin(lastNameMatch?.exact_match ? "font-semibold" : "")}>
              {lastNameMatch?.display_name
                ? lastNameMatch?.display_name
                : lastNameMatch?.name
                  ? lastNameMatch.name
                  : lastNameMatch.missing
                    ? "-"
                    : profileSurname}
            </span>
          </td>
          <td className="py-2 px-1.5 text-right">
            <div className="inline-flex">
              <>
                {lastNameMatch?.exact_match ? (
                  <MatchExplanationTooltip
                    className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                    title={lastNameMatch.explanation || ""}
                    placement="top"
                    arrow
                  >
                    <div>
                      <MatchPill matchRating="strong_match" />
                    </div>
                  </MatchExplanationTooltip>
                ) : lastNameMatch?.partial_match ? (
                  <MatchExplanationTooltip
                    className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                    title={lastNameMatch.explanation || ""}
                    placement="top"
                    arrow
                  >
                    <div>
                      <MatchPill matchRating="partial_match" />
                    </div>
                  </MatchExplanationTooltip>
                ) : lastNameMatch?.mismatch ? (
                  <MatchExplanationTooltip
                    className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                    title={lastNameMatch.explanation || ""}
                    placement="top"
                    arrow
                  >
                    <div>
                      <MatchPill matchRating="mismatch" />
                    </div>
                  </MatchExplanationTooltip>
                ) : (
                  <MatchExplanationTooltip
                    className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                    title="No match information available"
                    placement="top"
                    arrow
                  >
                    <div>
                      <MatchPill matchRating="no_match" />
                    </div>
                  </MatchExplanationTooltip>
                )}
              </>
            </div>
          </td>
        </tr>
        <tr>
          <td className="whitespace-nowrap px-1.5 py-2 pl-5">
            <div className="flex gap-x-2 items-center">
              <CalendarTodayRounded sx={{ fontSize: "1rem" }} />
              <span>Age Today</span>
            </div>
          </td>
          <td
            className={twJoin(
              "text-slate-900 truncate px-1.5 py-2",
              !input.individual_age ? "italic text-slate-400" : "",
            )}
          >
            <span>{input.individual_age ? `${input.individual_age} years old` : "-"}</span>
          </td>
          <td
            className={twJoin(
              "truncate px-1.5 py-2",
              ageMatch?.exact_match
                ? "text-red-700 font-semibold"
                : ageMatch?.partial_match
                  ? "text-orange-600"
                  : ageMatch?.mismatch
                    ? "text-yellow-800"
                    : "text-slate-400 italic",
            )}
          >
            {ageMatch?.age
              ? `${ageMatch?.age} years old`
              : ageMatch?.min_age && ageMatch?.max_age
                ? `${ageMatch.min_age} - ${ageMatch.max_age} years old`
                : ageMatch?.min_age
                  ? `${ageMatch.min_age} years or older`
                  : ageMatch?.max_age
                    ? `${ageMatch.max_age} years or younger`
                    : "Not Found"}
          </td>

          <td className="px-1.5 py-2 text-right">
            <div className="inline-flex">
              <MatchExplanationTooltip
                className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                title={ageMatch?.explanation || "No match information available"}
                placement="top"
                arrow
              >
                <div>
                  <MatchPill
                    matchRating={
                      ageMatch?.exact_match
                        ? "strong_match"
                        : ageMatch?.partial_match
                          ? "partial_match"
                          : ageMatch?.mismatch
                            ? "mismatch"
                            : "no_match"
                    }
                  />
                </div>
              </MatchExplanationTooltip>
            </div>
          </td>
        </tr>
        <tr>
          <td className="whitespace-nowrap px-1.5 py-2 pl-5">
            <div className="flex gap-x-2 items-center">
              <LocationOnRounded sx={{ fontSize: "1rem" }} />
              <span>{type === "sanctions" ? "Location" : "Country"}</span>
            </div>
          </td>
          <td className="text-slate-900 truncate px-1.5 py-2">
            {type === "sanctions" ? (
              <span>
                {[
                  input.address?.city,
                  input.address?.state,
                  input.country_of_residence?.country_name ??
                    input.address?.country?.country_name ??
                    input.country_of_nationality?.country_name,
                ]
                  .filter(Boolean)
                  .join(", ") || "Unknown"}
              </span>
            ) : (
              <span>
                {input.country_of_residence?.country_name ??
                  input.address_country?.country_name ??
                  input.country_of_nationality?.country_name ??
                  "Unknown"}
              </span>
            )}
          </td>
          <td
            className={twJoin(
              "text-wrap px-1.5 py-2",
              type === "sanctions"
                ? locationMatch?.exact_match
                  ? "text-red-700 font-semibold"
                  : locationMatch?.partial_match
                    ? "text-orange-600 font-semibold"
                    : locationMatch?.mismatch
                      ? "text-yellow-800"
                      : "text-slate-400 italic"
                : countryMatch?.exact_match
                  ? "text-red-700 font-semibold"
                  : countryMatch?.partial_match
                    ? "text-orange-600 font-semibold"
                    : countryMatch?.mismatch
                      ? "text-yellow-800"
                      : "text-slate-400 italic",
            )}
          >
            {type === "sanctions" ? (
              <span>
                {(() => {
                  const addresses =
                    mediaHit.associated_addresses
                      ?.map((addr: any) =>
                        [addr.city, addr.state, addr.country?.country_name].filter(Boolean).join(", "),
                      )
                      .filter((addr: string) => addr) || [];

                  const countries =
                    mediaHit.associated_countries?.map((country: any) => country.country_name).filter(Boolean) || [];

                  const allLocations = [];
                  if (addresses.length > 0) allLocations.push(...addresses);
                  if (countries.length > 0) allLocations.push(...countries);

                  return allLocations.length > 0 ? allLocations.join("; ") : "Not Found";
                })()}
              </span>
            ) : countryMatch?.countries?.length > 0 ? (
              countryMatch.countries
                .map((country: string | { country_name: string } | null) =>
                  country !== null && typeof country === "object" && "country_name" in country
                    ? country.country_name
                    : country,
                )
                .join(", ")
            ) : (
              "Not Found"
            )}
          </td>
          <td className="py-2 px-1.5 text-right ">
            <div className="inline-flex">
              <MatchExplanationTooltip
                className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                title={(countryMatch || locationMatch)?.explanation || "No match information available"}
                placement="top"
                arrow
              >
                <div>
                  <MatchPill
                    matchRating={
                      (countryMatch || locationMatch)?.exact_match
                        ? "strong_match"
                        : (countryMatch || locationMatch)?.partial_match
                          ? "partial_match"
                          : (countryMatch || locationMatch)?.mismatch
                            ? "mismatch"
                            : "no_match"
                    }
                  />
                </div>
              </MatchExplanationTooltip>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PerpetratorTable;
