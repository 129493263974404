import { Worker, Viewer } from "@react-pdf-viewer/core";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import { pageThumbnailPlugin } from "@/utils/pageThumbnailPlugin";
import { ErrorRounded } from "@mui/icons-material";

const PDFThumbnail = ({
  documentURL,
  documentName,
  onDocumentNameClick,
}: {
  documentURL: string;
  documentName: string;
  onDocumentNameClick: () => void;
}) => {
  const thumbnailPluginInstance = thumbnailPlugin();
  const { Cover } = thumbnailPluginInstance;
  const pageThumbnailPluginInstance = pageThumbnailPlugin({
    PageThumbnail: <Cover getPageIndex={() => 0} />,
  });

  const truncateMiddle = (str: string, maxLength: number) => {
    if (str.length <= maxLength) return str;
    const midPoint = Math.floor(maxLength / 2);
    return `${str.slice(0, midPoint)}...${str.slice(str.length - midPoint + 1)}`;
  };

  return (
    <div className="flex flex-col gap-y-2 pb-4 h-full">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <button
          onClick={onDocumentNameClick}
          className="w-full h-full flex flex-col items-center justify-center text-center text-brand-purple hover:underline"
          title={documentName}
        >
          <Viewer
            fileUrl={documentURL}
            plugins={[thumbnailPluginInstance, pageThumbnailPluginInstance]}
            renderError={(error) => {
              if (error.message === "Invalid PDF structure.") {
                return <img src={documentURL} alt={documentURL} className="max-h-[15rem] w-full object-contain" />;
              }
              return (
                <div className="flex items-center justify-center h-full">
                  <div className="flex flex-col gap-y-5 p-10 items-center bg-slate-100 rounded-lg max-w-[25rem]">
                    <ErrorRounded sx={{ fontSize: "2rem" }} className="text-red-600" />
                    <div className="flex flex-col justify-center items-center text-slate-700 gap-2">
                      <h3 className="text-center text-xs font-semibold">We were unable to load the document</h3>
                    </div>
                  </div>
                </div>
              );
            }}
          />
          <span className="w-full text-center text-brand-purple hover:underline">
            {truncateMiddle(documentName, 16)}
          </span>
        </button>
      </Worker>
    </div>
  );
};

export default PDFThumbnail;
