const prodAppSubdomain = import.meta.env.VITE_APP_SUBDOMAIN || "https://app.parcha.ai";

export const appUrl = import.meta.env.PROD ? prodAppSubdomain : "http://localhost:5173";

export const DESCOPE_PROJECT_ID = import.meta.env.VITE_DESCOPE_PROJECT_ID || "";

export const BUSINESS_PROFILE_CHECK_ID = "kyb.basic_business_profile_check";
export const WEB_PRESENCE_CHECK_ID = "kyb.web_presence_check";
export const MCC_CHECK_ID = "kyb.mcc_code_check.mcc_code_check_tool";
export const MCC_V2_CHECK_ID = "kyb.mcc_code_check_v2";
export const ADDRESS_CHECK_ID = "kyb.addresses_check";
export const POLICY_CHECK_ID = "kyb.policy_check";
export const HIGH_RISK_COUNTRY_CHECK_ID = "kyb.high_risk_country_tool";
export const HIGH_RISK_INDUSTRY_CHECK_ID = "kyb.high_risk_industry_tool";
export const HIGH_RISK_INDUSTRY_V2_CHECK_ID = "kyb.high_risk_industry_tool_v2";
export const ADVERSE_MEDIA_CHECK_ID = "kyb.screening.adverse_media_tool";
export const SANCTIONS_WATCHLIST_CHECK_ID = "kyb.sanctions_watchlist_check";
export const BUSINESS_OWNERS_CHECK_ID = "kyb.business_owners_check";
export const BUSINESS_REVIEWS_CHECK_ID = "kyb.business_reviews_check";

// Individual Checks
export const PEP_CHECK_ID_V2 = "kyc.pep_screening_check_v2";
export const ADVERSE_MEDIA_CHECK_ID_V2 = "kyc.adverse_media_screening_check_v2";

// Checks with Documents
export const INCORPORATION_DOCUMENT_CHECK_ID = "kyb.incorporation_document_verification";
export const BUSINESS_OWNERSHIP_CHECK_ID = "kyb.business_ownership_verification_tool";
export const SOURCE_OF_FUNDS_CHECK_ID = "kyb.source_of_funds_document_check";
export const PROOF_OF_ADDRESS_CHECK_ID = "kyb.proof_of_address_verification";
export const PROOF_OF_ADDRESS_CLASSIFICATION_CHECK_ID = "kyb.proof_of_address_classification_tool";
export const CANNABIS_LICENSE_CHECK_ID = "kyb.cannabis_license_verification";
export const EIN_DOCUMENT_CHECK_ID = "kyb.ein_document_verification";
export const BANK_CHECK_VERIFICATION_CHECK_ID = "kyb.bank_check_verification";

export const KYC_ADVERSE_MEDIA_CHECK_ID = "kyc.adverse_media_screening_check_v2";
export const KYC_PEP_CHECK_ID = "kyc.pep_screening_check_v2";
export const KYC_SOURCE_OF_WEALTH_CHECK_ID = "kyc.source_of_wealth_check";
export const KYC_REAL_ESTATE_CHECK_ID = "kyc.real_estate_property_check";
export const KYC_OPEN_CORPORATES_BUSINESS_CHECK_ID = "kyc.open_corporates_business_check";

// Individual Checks with Documents
export const KYC_PROOF_OF_ADDRESS_CHECK_ID = "kyc.proof_of_address_verification";
export const KYC_SOURCE_OF_FUNDS_CHECK_ID = "kyc.source_of_funds_verification";

export const shimmerEffectClassNames =
  "animate-shimmer bg-gradient-to-r from-brand-purple to-[#FEFB92] bg-clip-text text-transparent font-semibold";

export const CHECK_ID_TO_TITLE = {
  [EIN_DOCUMENT_CHECK_ID]: "EIN",
  [INCORPORATION_DOCUMENT_CHECK_ID]: "Incorporation",
  [PROOF_OF_ADDRESS_CHECK_ID]: "Proof of Address",
  [CANNABIS_LICENSE_CHECK_ID]: "Cannabis License",
  [BUSINESS_OWNERSHIP_CHECK_ID]: "Business Ownership",
};

export const CHECK_ID_TO_ICON = {
  [EIN_DOCUMENT_CHECK_ID]: "pin",
  [INCORPORATION_DOCUMENT_CHECK_ID]: "description",
  [PROOF_OF_ADDRESS_CHECK_ID]: "markunread_mailbox",
  [CANNABIS_LICENSE_CHECK_ID]: "flower",
  [BUSINESS_OWNERSHIP_CHECK_ID]: "badge",
};

export const DOCUMENT_REQUEST_STATUS_TO_CLASSNAMES = {
  draft: "text-slate-700 bg-slate-100",
  waiting_for_response: "text-slate-700 bg-indigo-100",
  approved: "text-green-700 bg-green-100",
  discarded: "text-slate-500 bg-slate-100",
  review: "text-yellow-900 bg-yellow-100",
  expired: "text-red-700 bg-red-100",
};

export const DOCUMENT_VALIDATION_CHECK_IDS = [
  INCORPORATION_DOCUMENT_CHECK_ID,
  PROOF_OF_ADDRESS_CHECK_ID,
  EIN_DOCUMENT_CHECK_ID,
  CANNABIS_LICENSE_CHECK_ID,
  BUSINESS_OWNERSHIP_CHECK_ID,
];

export const DOCUMENT_TYPES = {
  [INCORPORATION_DOCUMENT_CHECK_ID]: "Incorporation",
  [PROOF_OF_ADDRESS_CHECK_ID]: "Proof of Address",
  [EIN_DOCUMENT_CHECK_ID]: "EIN Document",
  [CANNABIS_LICENSE_CHECK_ID]: "Cannabis License",
  [BUSINESS_OWNERSHIP_CHECK_ID]: "Ownership Document",
};
