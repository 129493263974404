import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Tooltip,
  Button,
  OutlinedInput,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Endpoint } from "../../types";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { ApiKey } from "@parcha-src/types";
import { FileCopy, Delete } from "@mui/icons-material";
import { useParchaApi } from "@parcha-src/hooks/useParchaApi";
import UserContext from "@/contexts/UserContext";
import { ErrorContext } from "@parcha-src/contexts/ErrorContext";
import { useUser } from "@descope/react-sdk";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";

export default function ApiKeysManager() {
  const [open, setOpen] = useState(false);

  const [openRevoke, setOpenRevoke] = useState(false);
  const [keyIdToRevoke, setKeyIdToRevoke] = useState<string>("");
  const [notice, setNotice] = useState<string>("");
  const [endpoints, setEndpoints] = useState<Endpoint[] | undefined>();
  const { user } = useUser();
  const userContext = useContext(UserContext);
  const { hasRole } = userContext;
  const [apiKeys, setApiKeys] = useState<ApiKey[] | undefined>();
  const [apiKeyName, setApiKeyName] = useState<string>("");
  const [selectedEndpoint, setSelectedEndpoint] = useState<Endpoint | undefined>(undefined);

  const parchaApi = useParchaApi();
  const { setError } = useContext(ErrorContext);
  const navigate = useNavigate();

  const getEndpoints = () => {
    parchaApi
      .getCustomerAgents()
      .then((data) => {
        if (hasRole("parchaAdmin")) {
          setEndpoints(data.endpoints);
        } else {
          setEndpoints(data.endpoints.filter((endpoint: Endpoint) => !endpoint.isPublic));
        }
      })
      .catch(handleApiException);
  };

  // if user has no userTenants, then redirect to the home page
  useEffect(() => {
    if (!user) return;

    if (user.userTenants?.length === 0) {
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    getEndpoints();
  }, []);

  const handleApiException = (error: Error) => {
    setError(error);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleRevokeConfirmOpen = (keyId: string) => {
    setKeyIdToRevoke(keyId);
    setOpenRevoke(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseRevoke = () => {
    setOpenRevoke(false);
  };

  const handleCreate = () => {
    createApiKey(apiKeyName);
    setOpen(false);
  };

  useEffect(() => {
    setSelectedEndpoint(endpoints?.[0]);
  }, [endpoints]);

  useEffect(() => {
    if (selectedEndpoint) {
      getApiKeys();
    }
  }, [selectedEndpoint]);

  const getApiKeys = () => {
    if (selectedEndpoint) {
      parchaApi
        .getApiKeys(selectedEndpoint.endpointUrl)
        .then((keys: ApiKey[]) => {
          if (keys.length == 0) {
            setNotice("No API keys found.");
          }
          setApiKeys(keys);
        })
        .catch(handleApiException);
    }
  };

  const handleCopy = (key: ApiKey) => {
    navigator.clipboard.writeText(key.api_secret_key);
    setNotice("Key " + key.key_name + " copied to clipboard.");
  };

  const handleRevoke = () => {
    if (selectedEndpoint) {
      parchaApi
        .revokeApiKey(selectedEndpoint.endpointUrl, keyIdToRevoke)
        .then((key: ApiKey) => {
          setNotice("Key " + key.key_name + " revoked successfully.");
          getApiKeys();
          handleCloseRevoke();
        })
        .catch(handleApiException);
    }
  };

  const createApiKey = (keyName: string) => {
    if (selectedEndpoint) {
      parchaApi
        .createApiKey(selectedEndpoint.endpointUrl, keyName)
        .then((response: any) => {
          setNotice(response.message);
          getApiKeys();
          //if successful, add a message to the user and refresh the api table.
        })
        .catch(handleApiException);
    }
  };

  const openApiDocs = () => {
    if (selectedEndpoint) {
      const url = "https://docs.parcha.ai";
      const a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      a.rel = "noreferrer";
      a.click();
    }
  };

  const onEndpointChange = (e: SelectChangeEvent<string>) => {
    const selectedValue = e.target.value;
    const newEndpoint = endpoints?.find((endpoint) => endpoint.endpointUrl === selectedValue);
    if (newEndpoint) {
      setSelectedEndpoint(newEndpoint);
    }
  };

  const endpointSelector = endpoints && selectedEndpoint && endpoints?.length > 1 && (
    <FormControl size="small" sx={{ minWidth: 120 }}>
      <InputLabel id="endpoint-select-label">Endpoint</InputLabel>
      <Select
        label="Endpoint"
        labelId="endpoint-select-label"
        id="endpoint-select"
        value={selectedEndpoint?.endpointUrl || ""}
        onChange={onEndpointChange}
        size="small"
        input={<OutlinedInput label="Endpoint" />}
      >
        {[...new Set(endpoints?.map((e: Endpoint) => e.endpointUrl))].map((endpointUrl: string) => (
          <MenuItem key={endpointUrl} value={endpointUrl}>
            {endpointUrl}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const actionButtons = (
    <div className="flex items-center gap-2">
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Create New API Token
      </Button>
      {selectedEndpoint && (
        <Button color="secondary" variant="contained" onClick={openApiDocs}>
          Visit API Documentation
        </Button>
      )}
    </div>
  );

  return (
    <Box>
      <Box sx={{ p: 3 }}>
        {notice && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setNotice("")}>
            {notice}
          </Alert>
        )}

        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
          <Typography variant="h5">API Keys</Typography>
          <div className="flex items-center gap-2">
            {actionButtons}
            {endpointSelector}
          </div>
        </Box>

        {apiKeys ? (
          <TableContainer sx={{ maxWidth: "960px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Key Name</TableCell>
                  <TableCell>Key</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {apiKeys.map((key: ApiKey) => (
                  <TableRow key={key.id}>
                    <TableCell>{key.key_name}</TableCell>
                    <TableCell>
                      <Tooltip title="Click to copy the full key to the clipboard">
                        <span onClick={() => handleCopy(key)}>
                          {key.api_secret_key.length > 32
                            ? `${key.api_secret_key.slice(0, 16)}...${key.api_secret_key.slice(-16)}`
                            : key.api_secret_key}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{new Date(key.created_at).toLocaleDateString()}</TableCell>
                    <TableCell>
                      <Tooltip title="Copy API Key">
                        <IconButton color="primary" onClick={() => handleCopy(key)}>
                          <FileCopy />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Revoke API Key">
                        <IconButton color="error" onClick={() => handleRevokeConfirmOpen(key.id)}>
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box style={{ maxWidth: "800px", paddingTop: "16px" }}>
            {[...Array(3)].map((_, index) => (
              <Skeleton
                variant="rectangular"
                height={40}
                style={{ marginBottom: "10px" }}
                animation="wave"
                key={index}
              />
            ))}
          </Box>
        )}
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create API Key</DialogTitle>
        <DialogContent sx={{ width: "480px" }}>
          <DialogContentText>Please enter the key name.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Key Name"
            type="text"
            fullWidth
            value={apiKeyName}
            onChange={(e) => setApiKeyName(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ pb: 2, pr: 2 }}>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleCreate} color="primary" variant="contained">
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openRevoke} onClose={handleCloseRevoke}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please confirm that you want to revoke this key. This is a permanent action.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ pb: 3, pr: 3 }}>
          <Button onClick={handleCloseRevoke} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={handleRevoke}>
            Revoke
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
