//@ts-nocheck
import { useCallback, useState, useEffect } from "react";
import { twJoin, twMerge } from "tailwind-merge";
import MiniStrengthIndicator from "./MiniStrengthIndicator";
import PerpetratorTable from "./PerpetratorTable";
import FeedbackButtons from "./FeedbackButtons";
import FeedbackStatus from "./FeedbackStatus";
import FeedbackInput from "./FeedbackInput";
import { Feedback, Hit } from "@/types";
import defaultProfilePhoto from "../../assets/default_people_profile.png";
import defaultHouseLogo from "../../assets/default_house.png";
import emailSourcePhoto from "../../assets/mark_email_read.png";
import searchSourcePhoto from "../../assets/screen_search_desktop.png";
import BuildingIcon from "../../assets/building.png";
import {
  CircleOutlined,
  CircleRounded,
  ContentCopyRounded,
  ContrastRounded,
  DoNotDisturbOnOutlined,
} from "@mui/icons-material";
import ProfileTable from "./ProfileTable";
import { Tooltip, styled } from "@mui/material";

const SourceOfWealthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`    
    background-color: rgba(15, 23, 42, 0.8);   
    padding: 8px 16px;

    & .MuiTooltip-arrow {
      color: rgba(15, 23, 42, 0.8);
    }
`);

type SourceOfWealthOpenCorporatesOfficersCardProps = {
  hit: Hit;
  input: unknown;
};

const SourceOfWealthOpenCorporatesOfficersCard = ({ hit, input }: SourceOfWealthOpenCorporatesOfficersCardProps) => {
  const [showToast, setShowToast] = useState(false);

  const isPartialMatch = hit.profile_review.match_rating.match === "partial_match";

  const rowId = hit.reference_id ?? hit.id;

  const handleCopyClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      const idToCopy = rowId?.split("_").at(-1) ?? "";
      navigator.clipboard.writeText(idToCopy);
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    },
    [rowId],
  );

  const formatDate = (dateString: string | null): string => {
    if (!dateString) return "Unknown";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", { month: "short", year: "numeric" });
  };

  return (
    <div className={twJoin("flex flex-col overflow-hidden rounded-lg")}>
      {/* Card Header */}
      <div className={twMerge("flex bg-slate-50 justify-between py-3 pr-3 pl-5 gap-x-5 gap-y-4 group w-full")}>
        <div className="flex items-center justify-between w-full">
          <div className="gap-y-1 w-auto">
            <div className="flex items-start gap-x-5">
              <div className="flex flex-col gap-y-1">
                <div className="text-sm font-semibold text-slate-700 inline-flex items-center">
                  {hit.coorperate_officer_position.position}
                  <span className="ml-1 text-xs font-normal text-slate-700 mr-3">
                    at {hit.coorperate_officer_position.open_corporates_company.name}
                  </span>
                  {hit.coorperate_officer_position &&
                    hit.coorperate_officer_position.open_corporates_company &&
                    hit.coorperate_officer_position.open_corporates_company.opencorporates_url && (
                      <a
                        href={hit.coorperate_officer_position.open_corporates_company.opencorporates_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-3.5 h-3.5 rounded-full bg-slate-200 flex items-center justify-center text-[8px] text-slate-500 hover:bg-slate-300"
                        onClick={(e) => e.stopPropagation()}
                      >
                        1
                      </a>
                    )}
                </div>
                <div className="text-xs text-slate-500">
                  {hit.coorperate_officer_position &&
                    hit.coorperate_officer_position.address &&
                    hit.coorperate_officer_position.address}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SourceOfWealthOpenCorporatesOfficersCard;
