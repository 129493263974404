import { MatchRating } from "@/types";
import { format } from "date-fns";
import { twJoin } from "tailwind-merge";

type FeedbackInputProps = {
  isFail: boolean;
  matchRating: MatchRating;
  showEventInfo: boolean;
  feedbackComment: string;
  setFeedbackComment: (value: string) => void;
  feedback: any;
  showFeedbackComment: boolean;
  setShowFeedbackComment: (value: boolean) => void;
  flaggedSelected: boolean;
  setFlaggedSelected: (value: boolean) => void;
  onSubmitFeedbackClick: () => Promise<void>;
};

const FeedbackInput = ({
  isFail,
  matchRating,
  showEventInfo,
  feedbackComment,
  setFeedbackComment,
  feedback,
  showFeedbackComment,
  setShowFeedbackComment,
  setFlaggedSelected,
  onSubmitFeedbackClick,
}: FeedbackInputProps) => {
  return (
    <div
      className={twJoin(
        "flex flex-col pr-3 pl-5 gap-y-2 pt-4",
        isFail ? "bg-red-50" : matchRating === "partial_match" ? "bg-white" : "bg-slate-50",
        showEventInfo ? "" : "rounded-b-lg",
      )}
    >
      <input
        className="w-full p-2 border border-slate-200 rounded-lg text-xs placeholder:text-slate-400"
        type="text"
        value={feedbackComment}
        onChange={(e) => setFeedbackComment(e.target.value)}
        placeholder="Please provide feedback on how we can improve..."
      />
      <div className="flex items-center gap-x-1 justify-between w-full text-xs pb-4">
        {feedback?.score === false && (
          <span className="truncate w-fit text-slate-500">{`Flagged on ${format(
            new Date(`${feedback.created_at}`),
            "MMMM d, yyyy h:mm a",
          )} `}</span>
        )}
        <div className={twJoin("flex gap-x-2 justify-end", feedback?.score === false ? "" : "w-full")}>
          <button
            type="button"
            onClick={() => {
              if (showFeedbackComment) {
                setShowFeedbackComment(false);
              } else {
                setFlaggedSelected(false);
              }
              setFeedbackComment("");
            }}
            className="border border-slate-200 rounded-lg px-3 py-2 text-slate-500 bg-white hover:border-brand-purple hover:text-brand-purple"
          >
            Cancel
          </button>
          <button
            disabled={feedback ? feedback.comment === feedbackComment : feedbackComment.length === 0}
            type="button"
            onClick={onSubmitFeedbackClick}
            className="border border-slate-200 rounded-lg px-3 py-2 bg-brand-purple text-white disabled:bg-slate-200 disabled:text-slate-500 hover:border-white"
          >
            {feedback?.comment ? "Update Feedback" : "Send Feedback"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedbackInput;
