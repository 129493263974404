import { OwnerData, UIDocument } from "@/types";
import PDFThumbnail from "./PDFThumbnail";
import TwoRowInfoValue from "./TwoRowInfoValue";
import ValueContainer from "./ValueContainer";

type OwnershipDocumentProps = {
  level: "success" | "failure" | "normal" | undefined;
  businessOwners: OwnerData[];
  ownershipDocuments: UIDocument[];
  unverifiedBusinessOwners?: OwnerData[];
  handleDocumentValidationClick?: (checkResult: any, document: any) => void;
  checkResult?: any;
};

const OwnershipDocument = ({
  level,
  businessOwners,
  unverifiedBusinessOwners,
  ownershipDocuments,
  handleDocumentValidationClick,
  checkResult,
}: OwnershipDocumentProps) => {
  if (!ownershipDocuments || !ownershipDocuments.length) {
    return null;
  }

  const mergedOwners = [...(businessOwners || []), ...(unverifiedBusinessOwners || [])];

  return (
    <div className="grid grid-cols-2 gap-4 text-xs w-[40rem]">
      <div className="flex flex-col gap-y-2">
        <ValueContainer label="Valid Document" value={ownershipDocuments[0]?.isValidDocument ? "Yes" : "No"} />
        {mergedOwners.length > 0 ? (
          mergedOwners.map((owner) => (
            <div key={owner.fullName} className="flex flex-col gap-3 bg-slate-50 rounded-lg p-4">
              <span className="font-semibold">{owner.fullName}</span>
              {owner.percentage === null ? (
                <TwoRowInfoValue label="Percent Ownership" value="Unknown" />
              ) : typeof owner.percentage === "number" ? (
                <TwoRowInfoValue
                  label="Percent Ownership"
                  value={`${
                    owner.percentage >= 0 && owner.percentage <= 1
                      ? (owner.percentage * 100).toFixed(0)
                      : owner.percentage % 1 === 0
                        ? Math.round(owner.percentage)
                        : owner.percentage.toFixed(2)
                  }%`}
                />
              ) : null}
              {owner.shares === null ? (
                <TwoRowInfoValue label="Shares" value="Unknown" />
              ) : (
                typeof owner.shares === "number" && (
                  <TwoRowInfoValue label="Shares" value={`${owner.shares.toLocaleString()}`} />
                )
              )}
            </div>
          ))
        ) : (
          <div className="flex flex-col gap-3 bg-slate-50 rounded-lg p-4 min-h-[5rem]justify-center">
            <span className="italic text-slate-500">No business owners found</span>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-y-2 min-h-[12rem]">
        {ownershipDocuments.map((document: UIDocument) => (
          <>
            <PDFThumbnail
              key={document.fileName}
              documentURL={document.url}
              documentName={document.fileName}
              onDocumentNameClick={() =>
                handleDocumentValidationClick?.(checkResult, {
                  file_name: document.fileName,
                  url: document.url,
                })
              }
            />
            {document?.documentType && <ValueContainer label="Document Type" value={document?.documentType} />}
          </>
        ))}
      </div>
      <div className="col-span-2">
        <ValueContainer label="Summary" value={ownershipDocuments[0]?.summary} />
      </div>
    </div>
  );
};

export default OwnershipDocument;
