import { useContext, useEffect, useState } from "react";
import useAuth from "@parcha-src/hooks/useAuth";
import { usePostHog } from "posthog-js/react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useKoala } from "@getkoala/react";
import UserContext from "@/contexts/UserContext";
import { useParchaApi } from "@/hooks/useParchaApi";
import { TenantDetails } from "@/types";

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredRole?: string;
}

// Countdown banner component
const RedirectBanner = ({ redirectUrl, secondsLeft }: { redirectUrl: string; secondsLeft: number }) => {
  return (
    <div className="fixed inset-0 bg-brand-purple/80 backdrop-blur-sm text-white p-4 z-50 flex justify-center items-center text-sm gap-2">
      <img className="w-7 h-7 animate-spin" src={"/working_loader_white.svg"} alt="logo" />
      <p className="text-lg">
        Redirecting to <span className="font-semibold"> {redirectUrl || "https://app.parcha.ai"} </span>
        in {secondsLeft} second{secondsLeft > 1 ? "s" : ""}...
      </p>
    </div>
  );
};

const AppBanner = ({ onClose, isVisible }: { onClose: () => void; isVisible: boolean }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <div
      className={`
        overflow-hidden transition-all duration-300 ease-in-out
        ${isVisible ? "h-[60px]" : "h-0"}
        block w-full bg-brand-purple backdrop-blur-sm text-white flex justify-center items-center text-base gap-2
      `}
    >
      <div className="p-4 flex justify-center items-center gap-2">
        <p>This is the new URL for your agent. Please update all internal links and bookmarks.</p>
        <button
          onClick={handleClose}
          className="ml-4 p-1 rounded-full hover:bg-white/20 transition-colors"
          aria-label="Close banner"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default function ProtectedRoute({ children, requiredRole }: ProtectedRouteProps) {
  const posthog = usePostHog();
  const { isLoading, isAuthenticated, user, endpoints } = useAuth();
  const { koala, ready } = useKoala();
  const userContext = useContext(UserContext);
  const { login, hasRole, rbacService, selectedEndpoint, setSelectedEndpoint } = userContext;
  const location = useLocation();
  const { agentKey } = useParams();

  const parchaApi = useParchaApi();

  // States for redirect countdown
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [secondsLeft, setSecondsLeft] = useState(5); // 5 second countdown
  // New state for AppBanner visibility
  const [showAppBanner, setShowAppBanner] = useState(() => {
    // Check if the banner has been shown before
    return window.location.hostname === "app.parcha.ai" && !localStorage.getItem("appBannerDismissed");
  });

  // Countdown effect
  useEffect(() => {
    let timer: number;

    if (isRedirecting && secondsLeft > 0) {
      timer = window.setTimeout(() => {
        setSecondsLeft((prev) => prev - 1);
      }, 1000);
    } else if (isRedirecting && secondsLeft === 0) {
      // Perform the actual redirect when countdown reaches zero
      window.location.href = redirectUrl;
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isRedirecting, secondsLeft, redirectUrl]);

  //Redirect to preview.parcha.ai check effect
  // We need to redirect if the user is not a parchaAdmin and the endpoint is not public
  useEffect(() => {
    if (!user || !endpoints) return;

    // If every endpoint in endpoints isPublic = true, redirect to preview.parcha.ai
    const allPublicEndpoints = endpoints.every((endpoint) => endpoint.isPublic);
    const shouldRedirect =
      !hasRole("parchaAdmin") &&
      window.location.hostname === "app.parcha.ai" &&
      (Boolean(user?.userTenants?.length === 0) || allPublicEndpoints);

    if (!shouldRedirect) return;

    const handleRedirect = async () => {
      // Preserve current path and search parameters
      const pathToPreserve = `${window.location.pathname}${window.location.search}`;

      // Determine target URL based on tenant type
      const targetUrl = `https://preview.parcha.ai${pathToPreserve}`;

      // Start the countdown
      setRedirectUrl(targetUrl);
      setIsRedirecting(true);
      setSecondsLeft(5);
    };
    handleRedirect();
  }, [user, endpoints]);

  // Redirect to app.parcha.ai check effect
  useEffect(() => {
    if (!user || !endpoints || !agentKey || !selectedEndpoint) return;

    const shouldRedirect = window.location.hostname === "preview.parcha.ai" && !hasRole("parchaAdmin");
    if (!shouldRedirect) return;

    const currentEndpoint = endpoints.find((endpoint) => endpoint.agentKey === agentKey);
    const isProdEndpoint = currentEndpoint?.envType === "prod";
    if (!isProdEndpoint) return;

    const userTenants = user?.userTenants;
    const mainTenant = userTenants && userTenants.length > 0 ? userTenants[0] : null;
    if (!mainTenant) return;

    const handleRedirect = async () => {
      try {
        const tenantDetails = await getTenantDetails(mainTenant.tenantId ?? "");
        if (!tenantDetails) return;

        const isEnterpriseTenant = Boolean(tenantDetails.customAttributes?.isEnterprise);

        // Preserve current path and search parameters
        const pathToPreserve = `${window.location.pathname}${window.location.search}`;

        // Determine target URL based on tenant type
        const targetUrl = isEnterpriseTenant
          ? `https://${tenantDetails.name.toLowerCase()}-prod.parcha.ai${pathToPreserve}`
          : `https://app.parcha.ai${pathToPreserve}`;

        // Start the countdown
        setRedirectUrl(targetUrl);
        setIsRedirecting(true);
        setSecondsLeft(5);
      } catch (error) {
        console.error("Error during redirect preparation:", error);
      }
    };

    handleRedirect();
  }, [endpoints, agentKey, user, selectedEndpoint]);

  const getTenantDetails = async (tenantId: string): Promise<TenantDetails | null> => {
    const tenantDetails = await parchaApi.getTenantDetails(selectedEndpoint?.endpointUrl ?? "", tenantId);
    return tenantDetails;
  };

  useEffect(() => {
    if (user && isAuthenticated && ready) {
      const tenant = user.userTenants && user.userTenants.length > 0 ? user.userTenants[0] : null;
      koala.identify({
        email: user.email,
        name: user.name,
        $account: { name: tenant?.tenantName ?? undefined },
      });
    }
  }, [isAuthenticated, ready, user]);

  useEffect(() => {
    if (user && isAuthenticated) {
      posthog?.identify(user.userId, {
        email: user.email,
      });
    }
  }, [user, isAuthenticated, posthog]);

  useEffect(() => {
    if (isAuthenticated && user) {
      login?.(user);
    }
  }, [login, isAuthenticated, user]);

  useEffect(() => {
    if (requiredRole && endpoints && endpoints.length > 0 && !selectedEndpoint) {
      setSelectedEndpoint(endpoints[0]);
    }
  }, [requiredRole, endpoints, selectedEndpoint, setSelectedEndpoint]);

  // Effect to hide AppBanner after 8 seconds and mark as shown
  useEffect(() => {
    if (showAppBanner) {
      const timer = setTimeout(() => {
        const closeSound = new Audio("/sounds/pop.mp3");
        closeSound.play().catch((err) => console.log("Error playing sound:", err));
        setShowAppBanner(false);
        localStorage.setItem("appBannerDismissed", "true");
      }, 8_000);

      return () => clearTimeout(timer);
    }
  }, [showAppBanner]);

  const handleBannerClose = () => {
    setShowAppBanner(false);
    localStorage.setItem("appBannerDismissed", "true");
  };

  if (isLoading) return null;

  if (!isAuthenticated || !user) {
    if (
      (location.pathname === "/" || location.pathname.includes("/reports")) &&
      import.meta.env.VITE_PREVENT_CREATE_REPORT !== "true"
    ) {
      return <Navigate to="/create-report" />;
    }

    return <Navigate to={`/signin?redirectTo=${encodeURIComponent(location.pathname)}`} />;
  }

  if (requiredRole && (!selectedEndpoint || !userContext.isRolesReady)) return null;

  if (requiredRole && selectedEndpoint) {
    if (agentKey && rbacService) {
      // Check dynamic role for specific agent
      if (!rbacService.checkDynamicRole(agentKey, requiredRole as any)) {
        return <Navigate to="/" />;
      }
    } else {
      if (requiredRole === "ParchaAdmin") {
        // Special case for ParchaAdmin as a composite role
        if (!userContext.roles?.parchaAdmin) {
          return <Navigate to="/" />;
        }
      } else if (!hasRole(requiredRole)) {
        return <Navigate to="/" />;
      }
      return <>{children}</>;
    }

    if (requiredRole === "ParchaAdmin" && !userContext.roles?.parchaAdmin) {
      return <Navigate to="/" />;
    }

    if (requiredRole !== "ParchaAdmin" && !hasRole(requiredRole)) {
      return <Navigate to="/" />;
    }
  }

  return (
    <>
      <AppBanner onClose={handleBannerClose} isVisible={showAppBanner} />
      {isRedirecting && <RedirectBanner redirectUrl={redirectUrl} secondsLeft={secondsLeft} />}
      {children}
    </>
  );
}
