import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  OutlinedInput,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParchaApi } from "@/hooks/useParchaApi";
import { ErrorContext } from "@/contexts/ErrorContext";
import { useUser } from "@descope/react-sdk";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import UserContext from "@/contexts/UserContext";

// Import integration logos
import PersonaLogo from "../../../assets/persona-logo.svg";
import ParchaLoadingScreen from "@/components/V2_Home_Page/ParchaLoadingScreen";

interface IntegrationStatus {
  id: string;
  name: string;
  description: string;
  enabled: boolean;
  key_description?: string;
}

interface Integration extends IntegrationStatus {
  logo: string;
}

interface Endpoint {
  endpointUrl: string;
  agentKey: string;
}

const AVAILABLE_INTEGRATIONS: Integration[] = [
  {
    id: "persona",
    name: "Persona",
    description: "Identity verification and KYC solution",
    logo: PersonaLogo,
    enabled: false,
    key_description:
      "To get your Persona API key:\n1. Log in to your [Persona Dashboard](https://withpersona.com/dashboard)\n2. Go to Settings > API Keys\n3. Copy your API key from the dashboard",
  },
];

export default function Integrations() {
  const [open, setOpen] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState<Integration | null>(null);
  const [apiKey, setApiKey] = useState("");
  const [notice, setNotice] = useState<string>("");
  const [integrations, setIntegrations] = useState<Integration[]>(AVAILABLE_INTEGRATIONS);
  const [endpoints, setEndpoints] = useState<Endpoint[] | undefined>();
  const [selectedEndpoint, setSelectedEndpoint] = useState<Endpoint | undefined>(undefined);

  const { user } = useUser();
  const { setError } = useContext(ErrorContext);
  const navigate = useNavigate();
  const parchaApi = useParchaApi();
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  const getEndpoints = () => {
    parchaApi
      .getCustomerAgents()
      .then((data) => {
        setEndpoints(data.endpoints);
      })
      .catch(handleApiException);
  };

  // Set initial endpoint
  useEffect(() => {
    setSelectedEndpoint(endpoints?.[0]);
  }, [endpoints]);

  // Get endpoints on mount
  useEffect(() => {
    getEndpoints();
  }, []);

  // Load enabled integrations when endpoint changes
  useEffect(() => {
    if (selectedEndpoint) {
      loadEnabledIntegrations();
    }
  }, [selectedEndpoint]);

  const handleApiException = (error: Error) => {
    setError(error);
  };

  const loadEnabledIntegrations = async () => {
    if (!selectedEndpoint) return;

    try {
      const enabledIntegrations = await parchaApi.getEnabledIntegrations(selectedEndpoint.endpointUrl);

      // If API call fails or returns undefined, keep existing state
      if (!Array.isArray(enabledIntegrations)) {
        console.error("Invalid response from getEnabledIntegrations:", enabledIntegrations);
        return;
      }

      setIntegrations((prevIntegrations) =>
        prevIntegrations.map((integration) => {
          const enabledIntegration = enabledIntegrations.find((e) => e.id === integration.id);
          return {
            ...integration,
            enabled: enabledIntegration?.enabled || false,
            // Update other fields if they exist in the response
            name: enabledIntegration?.name || integration.name,
            description: enabledIntegration?.description || integration.description,
          };
        }),
      );
    } catch (error) {
      console.error("Error loading integrations:", error);
      handleApiException(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickOpen = (integration: Integration) => {
    setSelectedIntegration(integration);
    setOpen(true);
    if (integration.enabled) {
      loadIntegrationKey(integration.id);
    } else {
      setApiKey(""); // Clear API key when opening dialog for a new integration
    }
  };

  const loadIntegrationKey = async (integrationId: string) => {
    if (!selectedEndpoint) return;

    try {
      const response = await parchaApi.getIntegrationKey(selectedEndpoint.endpointUrl, integrationId);
      if (response?.key) {
        setApiKey(response.key);
      }
    } catch (error) {
      console.error("Failed to get integration key:", error);
      setError(error as Error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedIntegration(null);
    setApiKey("");
  };

  const handleSubmit = async () => {
    if (!selectedIntegration || !selectedEndpoint) {
      return;
    }

    try {
      await parchaApi.createOrUpdateIntegrationKey(selectedEndpoint.endpointUrl, selectedIntegration.id, apiKey);
      setNotice(
        `${selectedIntegration.name} integration ${selectedIntegration.enabled ? "updated" : "enabled"} successfully`,
      );
      setTimeout(() => {
        setNotice("");
      }, 5000);
      loadEnabledIntegrations();
      handleClose();
    } catch (error) {
      console.error("Failed to update integration key:", error);
      setError(error as Error);
    }
  };

  const handleDisableIntegration = async () => {
    if (!selectedIntegration || !selectedEndpoint) {
      return;
    }

    try {
      await parchaApi.createOrUpdateIntegrationKey(selectedEndpoint.endpointUrl, selectedIntegration.id, "");
      setNotice(`${selectedIntegration.name} integration disabled successfully`);
      setTimeout(() => {
        setNotice("");
      }, 5000);
      loadEnabledIntegrations();
      handleClose();
    } catch (error) {
      console.error("Failed to disable integration:", error);
      setError(error as Error);
    }
  };

  return (
    <Box sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2, height: "100%" }}>
      {notice && (
        <Alert severity="success" sx={{ mb: 2 }} onClose={() => setNotice("")}>
          {notice}
        </Alert>
      )}
      {isLoading && <ParchaLoadingScreen message="Loading available integrations..." size="large" />}
      {!isLoading && (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
            <Typography variant="h5">Integrations</Typography>

            {endpoints && selectedEndpoint && endpoints?.length > 1 && (
              <FormControl size="small" sx={{ minWidth: 120 }}>
                <InputLabel id="endpoint-select-label">Endpoint</InputLabel>
                <Select
                  label="Endpoint"
                  labelId="endpoint-select-label"
                  id="endpoint-select"
                  value={selectedEndpoint?.endpointUrl || ""}
                  onChange={(e) => {
                    const newEndpoint = endpoints?.find((endpoint) => endpoint.endpointUrl === e.target.value);
                    if (newEndpoint) {
                      setSelectedEndpoint(newEndpoint);
                    }
                  }}
                  size="small"
                  input={<OutlinedInput label="Endpoint" />}
                >
                  {[...new Set(endpoints?.map((e: Endpoint) => e.endpointUrl))].map((endpointUrl: string) => (
                    <MenuItem key={endpointUrl} value={endpointUrl}>
                      {endpointUrl}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>

          <Grid container spacing={3}>
            {integrations.map((integration) => (
              <Grid item xs={12} sm={6} md={4} key={integration.id}>
                <Box
                  sx={{
                    p: 2,
                    border: 1,
                    borderColor: "divider",
                    borderRadius: 1,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Box
                    component="img"
                    src={integration.logo}
                    alt={integration.name}
                    sx={{ height: 40, width: "auto", mb: 2 }}
                  />
                  <Typography variant="h6" gutterBottom>
                    {integration.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2, flexGrow: 1 }}>
                    {integration.description}
                  </Typography>
                  <Button
                    variant={integration.enabled ? "outlined" : "contained"}
                    onClick={() => handleClickOpen(integration)}
                    fullWidth
                  >
                    {integration.enabled ? "Manage Integration" : "Enable Integration"}
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {selectedIntegration?.enabled
            ? `Manage ${selectedIntegration?.name} Integration`
            : `Enable ${selectedIntegration?.name} Integration`}
        </DialogTitle>
        <DialogContent>
          {!selectedIntegration?.enabled && selectedIntegration?.key_description && (
            <DialogContentText sx={{ mb: 2 }}>
              <ReactMarkdown
                components={{
                  a: ({ href, children }) => (
                    <Link href={href} target="_blank" rel="noopener noreferrer">
                      {children}
                    </Link>
                  ),
                  p: ({ children }) => (
                    <Typography variant="body1" gutterBottom>
                      {children}
                    </Typography>
                  ),
                }}
              >
                {selectedIntegration.key_description}
              </ReactMarkdown>
            </DialogContentText>
          )}
          <DialogContentText>
            {selectedIntegration?.enabled
              ? `Update your ${selectedIntegration?.name} API key or disable the integration.`
              : `Please enter your ${selectedIntegration?.name} API key to enable the integration.`}
          </DialogContentText>
          <Box sx={{ mt: 2, display: "flex", alignItems: "flex-end" }}>
            <TextField
              autoFocus
              margin="dense"
              label="API Key"
              type="text"
              fullWidth
              variant="outlined"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-between", px: 2 }}>
          <Box>
            {selectedIntegration?.enabled && (
              <Button onClick={handleDisableIntegration} color="error">
                Disable Integration
              </Button>
            )}
          </Box>
          <Box>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit} variant="contained" disabled={!apiKey} sx={{ ml: 1 }}>
              {selectedIntegration?.enabled ? "Update" : "Enable"}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
