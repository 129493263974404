// @ts-nocheck
import { ChangeEvent, useRef, useState } from "react";
import { DeleteForeverRounded, PercentRounded, UploadFileRounded } from "@mui/icons-material";
import AddressInput from "../TailwindComponents/AddressInput";
import { useParchaApi } from "@/hooks/useParchaApi";
import DocumentValue from "../TailwindComponents/DocumentValue";
import InputWithOverlappingLabel from "../TailwindComponents/InputWithOverlappingLabel";
import ParchaLoadingScreen from "../V2_Home_Page/ParchaLoadingScreen";
import COUNTRIES from "@/data/countries";

const SEX_OPTIONS = [
  { name: "Male", value: "male" },
  { name: "Female", value: "female" },
  { name: "Other", value: "other" },
];

const IndividualForm = ({
  selectedEndpoint,
  individual,
  formFields,
  isKYCAgent,
  deleteIndividual,
  updateIndividual,
  index,
}) => {
  const proofOfAddressDocsInputRef = useRef(null);
  const parchaApi = useParchaApi();
  const [isUploadingProofOfAddressDocs, setIsUploadingProofOfAddressDocs] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (e.target.type === "checkbox") {
      updateIndividual(index, { ...individual, [name]: e.target.checked });
      return;
    }
    updateIndividual(index, { ...individual, [name]: value });
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    updateIndividual(index, {
      ...individual,
      address: {
        ...individual.address,
        [name]: value,
      },
    });
  };

  const handlePlaceSelectionChange = (address) => {
    updateIndividual(index, {
      ...individual,
      address,
    });
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (!files) return;

    const key = e.currentTarget.querySelector("input")?.name;
    await uploadFiles(key, files);
  };

  const handleProofOfAddressDocumentsClick = (e) => {
    const currentRef = proofOfAddressDocsInputRef.current;
    if (currentRef) {
      currentRef.click();
      currentRef.value = "";
    }
  };

  const uploadFiles = async (key, files) => {
    setIsUploadingProofOfAddressDocs(true);
    [...files].forEach((file) => {
      if (file) {
        const reader = new FileReader();
        reader.onload = async (loadEvent: Event) => {
          const base64String = loadEvent?.target?.result?.split(",")[1];
          const result = await parchaApi.uploadBase64Document(
            selectedEndpoint.endpointUrl,
            base64String,
            "Document Name",
          );
          const newFile = { ...result, file_name: file.name, source_type: "file_url" };
          delete newFile.expiration;
          updateIndividual(index, { ...individual, [key]: [...individual[key], newFile] });
          setIsUploadingProofOfAddressDocs(false);
        };
        reader.readAsDataURL(file);
      }
    });
  };

  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;

    await uploadFiles(event.target.name, files);
  };

  return (
    <div className="space-y-8 px-12">
      <div>
        <div className="mt-4 grid grid-cols-1 gap-x-3 gap-y-8 sm:grid-cols-6">
          <div className="col-span-full flex justify-between items-center">
            <h4 className="col-span-full text-base font-semibold text-slate-800">{`Individual #${index + 1}`}</h4>
            {index > 0 && (
              <button
                type="button"
                className="text-xs relative flex justify-center items-center rounded-md p-2 h-4 border border-solid border-slate-300 text-slate-900 focus:z-10 "
                onClick={() => deleteIndividual(index)}
              >
                <DeleteForeverRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                <span className="line-clamp-1">Delete This Individual</span>
              </button>
            )}
          </div>

          <div className="flex gap-x-3 col-span-full">
            <InputWithOverlappingLabel
              label="First Name"
              fieldName="firstName"
              value={individual.firstName}
              onChangeHandler={handleChange}
              required={isKYCAgent}
              className="grow"
            />
            <InputWithOverlappingLabel
              label="Middle Name"
              fieldName="middleName"
              value={individual.middleName}
              onChangeHandler={handleChange}
              className="grow"
            />
          </div>

          <div className="sm:col-span-full">
            <InputWithOverlappingLabel
              label="Last Name"
              fieldName="lastName"
              value={individual.lastName}
              onChangeHandler={handleChange}
              required={isKYCAgent}
            />
          </div>
          {!formFields || formFields?.fields.includes("linkedin_profile_url") ? (
            <div className="sm:col-span-full">
              <InputWithOverlappingLabel
                label="LinkedIn Profile URL"
                fieldName="linkedinProfileUrl"
                value={individual.linkedinProfileUrl}
                onChangeHandler={handleChange}
              />
            </div>
          ) : null}
          {!formFields || formFields?.fields.includes("employer_industry") ? (
            <div className="sm:col-span-full">
              <InputWithOverlappingLabel
                label="Employer Industry"
                fieldName="employerIndustry"
                value={individual.employerIndustry}
                onChangeHandler={handleChange}
              />
            </div>
          ) : null}
          {!formFields || formFields?.fields.includes("sex") ? (
            <div className="relative grow">
              <label className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-slate-900 w-auto truncate">
                Sex
              </label>
              <select
                id="sex"
                name="sex"
                placeholder="Sex"
                className="block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-6"
                value={individual.sex}
                onChange={handleChange}
              >
                {SEX_OPTIONS.map((sex) => (
                  <option key={`sex_${sex.value}`} value={sex.value}>
                    {sex.name}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
          {!formFields || formFields?.fields.includes("date_of_birth") ? (
            <div className="sm:col-span-full">
              <InputWithOverlappingLabel
                label="Date of Birth"
                fieldName="dateOfBirth"
                value={individual.dateOfBirth}
                onChangeHandler={handleChange}
                onFocus={(e) => {
                  e.currentTarget.type = "date";
                  e.currentTarget.min = "1900-01-01";
                  e.currentTarget.max = new Date().toISOString().split("T")[0];
                }}
                onBlur={(e) => {
                  e.currentTarget.type = "text";

                  const selectedDate = new Date(e.target.value);
                  const minDate = new Date("1900-01-01");
                  const maxDate = new Date();

                  if (selectedDate >= minDate && selectedDate <= maxDate) {
                    return;
                  }

                  updateIndividual(index, {
                    ...individual,
                    dateOfBirth: "",
                  });
                }}
              />
            </div>
          ) : null}

          {!formFields ||
            (formFields?.fields.some((field) =>
              ["place_of_birth", "country_of_nationality", "country_of_residence"].includes(field),
            ) && (
              <div className="flex gap-x-3 col-span-full">
                {!formFields || formFields?.fields.includes("place_of_birth") ? (
                  <InputWithOverlappingLabel
                    label="Place of Birth"
                    fieldName="placeOfBirth"
                    value={individual.placeOfBirth}
                    onChangeHandler={handleChange}
                    className="grow"
                  />
                ) : null}
                {!formFields || formFields?.fields.includes("country_of_nationality") ? (
                  <div className="relative grow">
                    <label className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-slate-900 w-auto truncate">
                      Country of Nationality
                    </label>
                    <select
                      id="countryOfNationality"
                      name="countryOfNationality"
                      placeholder="Country of Nationality"
                      className="block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-6"
                      value={individual.countryOfNationality}
                      onChange={handleChange}
                    >
                      {COUNTRIES.map((country) => (
                        <option key={`nationality_${country.value}`} value={country.value}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
                {!formFields || formFields?.fields.includes("country_of_residence") ? (
                  <div className="relative grow">
                    <label className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-slate-900 w-auto truncate">
                      Country of Residence
                    </label>
                    <select
                      id="countryOfResidence"
                      name="countryOfResidence"
                      placeholder="Country of Residence"
                      className="block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-6"
                      value={individual.countryOfResidence}
                      onChange={handleChange}
                    >
                      {COUNTRIES.map((country) => (
                        <option key={`residence_${country.value}`} value={country.value}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
              </div>
            ))}

          {!formFields || formFields?.fields.includes("address") ? (
            <div className="col-span-full">
              <h4 className="text-base font-semibold text-slate-800 mb-4">Address</h4>
              <AddressInput
                address={individual.address}
                onAddressChange={handleAddressChange}
                onPlaceChange={handlePlaceSelectionChange}
              />
            </div>
          ) : null}

          {!formFields || formFields?.fields.includes("proof_of_address_documents") ? (
            <div className="col-span-full">
              <h4 className="text-md font-semibold text-slate-800 mb-4">Proof of Address Documents</h4>
              <div className="flex flex-col gap-y-2">
                {individual.proofOfAddressDocs.map((doc: { file_name?: string; url?: string }) => (
                  <div className="flex items-center gap-x-1" key={doc.url}>
                    <DocumentValue fileName={doc.file_name || ""} url={doc.url || ""} />
                    <button
                      onClick={() => {
                        updateIndividual(index, {
                          ...individual,
                          proofOfAddressDocs: individual.proofOfAddressDocs.filter((d) => d.url !== doc.url),
                        });
                      }}
                    >
                      <DeleteForeverRounded
                        sx={{ fontSize: "2rem", height: "2rem", width: "1rem" }}
                        className="text-red-600"
                      />
                    </button>
                  </div>
                ))}
              </div>
              <div
                className="border-dashed border-2 border-slate-300 p-4"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                {isUploadingProofOfAddressDocs ? (
                  <ParchaLoadingScreen message="Uploading Incorporation documents..." size="small" />
                ) : (
                  <div className="flex flex-col items-center gap-y-1 justify-center h-full text-xs">
                    <UploadFileRounded sx={{ fontSize: "1.25rem" }} className="text-slate-400" />
                    <p className="text-slate-700">
                      Drag & Drop or{" "}
                      <span
                        className="underline text-brand-purple"
                        role="button"
                        onClick={handleProofOfAddressDocumentsClick}
                      >
                        Choose company documents
                      </span>{" "}
                      to upload
                    </p>
                    <input
                      type="file"
                      name="proofOfAddressDocs"
                      multiple
                      accept="image/jpg,image/jpeg,image/png,application/pdf"
                      onChange={handleFileSelect}
                      ref={proofOfAddressDocsInputRef}
                      className="hidden"
                    />
                  </div>
                )}
              </div>
            </div>
          ) : null}

          {!formFields ||
          formFields?.fields.some((field) =>
            ["email", "phone", "title", "is_applicant", "is_business_owner", "business_ownership_percentage"].includes(
              field,
            ),
          ) ? (
            <>
              <div className="col-span-full my-1">
                <hr />
              </div>
              <div className="col-span-full">
                <h3 className="font-bold text-base">Business Details</h3>
              </div>
              {(!formFields || formFields?.fields.includes("email") || formFields?.fields.includes("phone")) && (
                <div className="flex gap-x-3 col-span-full">
                  {!formFields || formFields?.fields.includes("email") ? (
                    <InputWithOverlappingLabel
                      fieldName="email"
                      label="Email"
                      value={individual.email}
                      onChangeHandler={handleChange}
                      className="grow"
                    />
                  ) : null}
                  {!formFields || formFields?.fields.includes("phone") ? (
                    <InputWithOverlappingLabel
                      fieldName="phone"
                      label="Phone"
                      value={individual.phone}
                      onChangeHandler={handleChange}
                      className="grow"
                    />
                  ) : null}
                </div>
              )}
              {!formFields || formFields?.fields.includes("title") ? (
                <div className="col-span-full">
                  <InputWithOverlappingLabel
                    fieldName="title"
                    label="Title"
                    value={individual.title}
                    onChangeHandler={handleChange}
                  />
                </div>
              ) : null}
              {!formFields || formFields?.fields.includes("is_applicant") ? (
                <div className="col-span-1 relative flex gap-x-2 items-center">
                  <div className="flex h-4 items-center">
                    <input
                      id="isApplicant"
                      name="isApplicant"
                      type="checkbox"
                      onChange={handleChange}
                      checked={individual.isApplicant}
                      value={individual.isApplicant}
                      className="h-3 w-3 rounded border border-solid border-slate-300 text-brand-purple focus:ring-brand-purple"
                    />
                  </div>
                  <div className="text-xs flex gap-x-3 items-center">
                    <label htmlFor="isApplicant" className="font-medium text-slate-900">
                      Applicant
                    </label>
                  </div>
                </div>
              ) : null}
              {!formFields || formFields?.fields.includes("is_business_owner") ? (
                <div className="col-span-1 relative flex gap-x-2 items-center">
                  <div className="flex h-4 items-center">
                    <input
                      id="isBusinessOwner"
                      name="isBusinessOwner"
                      type="checkbox"
                      onChange={handleChange}
                      checked={individual.isBusinessOwner}
                      value={individual.isBusinessOwner}
                      className="h-3 w-3 rounded border border-solid border-slate-300 text-brand-purple focus:ring-brand-purple"
                    />
                  </div>
                  <label htmlFor="isBusinessOwner" className="text-xs font-medium text-slate-900 w-full">
                    Owner
                  </label>
                </div>
              ) : null}
              {!formFields || formFields?.fields.includes("business_ownership_percentage") ? (
                <div className="col-start-4 col-span-3 relative flex gap-x-2 items-center">
                  <label htmlFor="percentage" className="font-medium text-xs w-full text-right">
                    Ownership Percentage:
                  </label>
                  <div className="relative rounded-md shadow-sm flex justify-between w-[100px] items-center">
                    <input
                      type="text"
                      name="businessOwnershipPercentage"
                      id="businessOwnershipPercentage"
                      className="block w-full rounded-md border-0 py-1.5 text-slate-900 ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-brand-purple sm:text-xs sm:leading-6"
                      placeholder="0.0-1.0"
                      aria-describedby="businessOwnershipPercentage"
                      value={individual.businessOwnershipPercentage}
                      onChange={(event) => {
                        const newValue = event.target.value;

                        // Allow empty input to clear the field
                        if (newValue === "") {
                          handleChange({
                            ...event,
                            target: { ...event.target, name: "businessOwnershipPercentage", value: "" },
                          });
                          return;
                        }

                        // Check if the input is a number and within the 0-100 range
                        if (
                          newValue === "" ||
                          newValue === "." ||
                          newValue === "1." ||
                          newValue === "0." ||
                          (isFinite(newValue) && newValue >= 0 && newValue <= 1)
                        ) {
                          handleChange({
                            ...event,
                            target: { ...event.target, name: "businessOwnershipPercentage", value: newValue },
                          });
                        }
                      }}
                    />
                    {/* <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center justify-center px-2 rounded-r-md">
                      <PercentRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                    </div> */}
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default IndividualForm;
