//@ts-nocheck
import { useCallback, useState, useEffect } from "react";
import { twJoin, twMerge } from "tailwind-merge";
import MiniStrengthIndicator from "./MiniStrengthIndicator";
import BusinessPerpetratorTable from "./BusinessPerpetratorTable";
import FeedbackButtons from "./FeedbackButtons";
import FeedbackStatus from "./FeedbackStatus";
import FeedbackInput from "./FeedbackInput";
import { Feedback, Hit } from "@/types";
import defaultProfilePhoto from "../../assets/default_people_profile.png";
import emailSourcePhoto from "../../assets/mark_email_read.png";
import searchSourcePhoto from "../../assets/screen_search_desktop.png";
import {
  CircleOutlined,
  CircleRounded,
  ContentCopyRounded,
  ContrastRounded,
  DoNotDisturbOnOutlined,
} from "@mui/icons-material";
import ProfileTable from "./ProfileTable";
import { Tooltip, styled } from "@mui/material";

const SourceOfWealthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`    
    background-color: rgba(15, 23, 42, 0.8);   
    padding: 8px 16px;

    & .MuiTooltip-arrow {
      color: rgba(15, 23, 42, 0.8);
    }
`);

type BusinessRiskCardV1Props = {
  type: "pep" | "adverse_media" | "source_of_wealth" | "sanctions";
  hit: Hit;
  input: unknown;
  handleSubmitFeedback: (isValid: boolean, rowId: string, comment: string) => Promise<void>;
  feedback?: Feedback;
  expandByDefault: boolean;
  isLoadingFeedback: boolean;
  renderContent: () => React.ReactNode;
  showAllContent: boolean;
  showVendorId?: boolean;
  greenColor?: boolean;
  displayType?: boolean;
};

const BusinessRiskCardV1 = ({
  type,
  hit,
  input,
  handleSubmitFeedback,
  feedback,
  expandByDefault,
  isLoadingFeedback,
  renderContent,
  showAllContent,
  showVendorId = true,
  displayType = false,
  greenColor = false,
}: BusinessRiskCardV1Props) => {
  const [showEventInfo, setShowEventInfo] = useState(expandByDefault);
  const [previousShowEventInfo, setPreviousShowEventInfo] = useState(expandByDefault);

  const [showFeedbackComment, setShowFeedbackComment] = useState(false);
  const [flaggedSelected, setFlaggedSelected] = useState(false);
  const [feedbackComment, setFeedbackComment] = useState("");

  const [showToast, setShowToast] = useState(false);

  const isFail = Boolean(hit.escalate_for_review);
  const isPartialMatch = hit.profile_review.match_rating.match === "partial_match";

  const rowId = hit.reference_id ?? hit.id;

  useEffect(() => {
    if (showAllContent) {
      // Store current value before forcing it to true
      setPreviousShowEventInfo(showEventInfo);
      setShowEventInfo(true);
    } else {
      // Restore previous value when showAllContent becomes false
      setShowEventInfo(previousShowEventInfo);
    }
  }, [showAllContent]);

  const onSubmitFeedbackClick = async () => {
    await handleSubmitFeedback(false, rowId ?? "", feedbackComment);
  };

  const handleCopyClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      const idToCopy = rowId?.split("_").at(-1) ?? "";
      navigator.clipboard.writeText(idToCopy);
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    },
    [rowId],
  );

  const businessNameMatch = hit.profile_review.business_name_match;
  const incorporationAddressMatch = hit.profile_review.incorporation_address_match;

  useEffect(() => {
    if (showAllContent) {
      setShowEventInfo(true);
    }
  }, [showAllContent]);

  function formatNumberToCurrency(num: number | null | undefined): string {
    // Return early if num is null/undefined
    if (num === null || num === undefined) {
      return "$0";
    }

    // Convert to number if it's a string
    const value = Number(num);

    // Check if conversion resulted in a valid number
    if (isNaN(value)) {
      return "$0";
    }

    if (value >= 1e9) {
      return `$${(value / 1e9).toFixed(value % 1e9 === 0 ? 0 : 2)}B`; // Billion
    } else if (value >= 1e6) {
      return `$${(value / 1e6).toFixed(value % 1e6 === 0 ? 0 : 2)}M`; // Million
    } else if (value >= 1e3) {
      return `$${(value / 1e3).toFixed(value % 1e3 === 0 ? 0 : 2)}K`; // Thousand
    } else {
      return `$${value.toFixed(value % 1 === 0 ? 0 : 2)}`; // Less than thousand
    }
  }

  return (
    <div className={twJoin("flex flex-col overflow-hidden", showEventInfo ? "rounded-t-lg" : "rounded-lg")}>
      {/* Card Header */}
      <div
        className={twMerge(
          "flex border border-transparent justify-between py-3 pr-3 pl-5 gap-x-5 gap-y-4 group w-full",
          isFail ? "bg-red-50" : isPartialMatch ? "bg-white" : "bg-slate-50",
          feedback?.score === false ? "items-baseline" : "items-center",
          showEventInfo || showFeedbackComment || flaggedSelected ? "rounded-t-lg border-b-0" : "rounded-lg",
          !showEventInfo
            ? isFail
              ? "hover:border-red-200"
              : isPartialMatch
                ? "border-slate-200 hover:border-slate-300"
                : "hover:border-slate-300"
            : isFail
              ? "hover:border-red-200"
              : "border-slate-200 hover:border-slate-300",
          showAllContent ? "pointer-events-none" : "",
        )}
        role={showAllContent ? undefined : "button"}
        onClick={() => !showAllContent && setShowEventInfo(!showEventInfo)}
      >
        <div className="flex items-center w-full gap-x-5">
          {(hit.profile_photo_url || defaultProfilePhoto) && (
            <img
              src={hit.profile_photo_url || defaultProfilePhoto}
              alt={`Profile image`}
              className="w-10 h-10"
              style={{ borderRadius: "4px" }}
            />
          )}
          <div className="flex items-center gap-x-2 flex-nowrap font-semibold text-sm">
            <span className="flex items-center gap-x-1">
              {hit?.business_name ?? ""}
              {businessNameMatch?.exact_match ? (
                <CircleRounded
                  sx={{ fontSize: "0.625rem" }}
                  className={greenColor ? "text-green-700" : "text-red-600"}
                />
              ) : businessNameMatch?.partial_match ? (
                <ContrastRounded
                  sx={{ fontSize: "0.625rem" }}
                  className={greenColor ? "text-green-500" : "text-orange-600"}
                />
              ) : businessNameMatch?.mismatch ? (
                <DoNotDisturbOnOutlined
                  sx={{ fontSize: "0.625rem" }}
                  className={greenColor ? "text-slate-500" : "text-yellow-700"}
                />
              ) : null}
            </span>
          </div>
          {incorporationAddressMatch ? (
            <div className="text-xs text-slate-700 inline-flex flex-nowrap items-baseline gap-x-1">
              <span>
                {[
                  incorporationAddressMatch.matched_city,
                  incorporationAddressMatch.matched_state,
                  incorporationAddressMatch.matched_country,
                ]
                  .filter(Boolean) // Remove null/undefined/empty values
                  .join(", ")}

                {incorporationAddressMatch?.exact_match ? (
                  <CircleRounded
                    sx={{ fontSize: "0.625rem" }}
                    className={greenColor ? "ml-1 mb-0.5 text-green-700" : "ml-1 mb-0.5 text-red-600"}
                  />
                ) : incorporationAddressMatch?.partial_match ? (
                  <ContrastRounded
                    sx={{ fontSize: "0.625rem" }}
                    className={greenColor ? "ml-1 mb-0.5 text-green-500" : "ml-1 mb-0.5 text-orange-600"}
                  />
                ) : incorporationAddressMatch?.mismatch ? (
                  <DoNotDisturbOnOutlined sx={{ fontSize: "0.625rem" }} className="ml-1 mb-0.5 text-slate-500" />
                ) : null}
              </span>
            </div>
          ) : null}
        </div>
        {/* Feedback Controls */}
        <div className="flex flex-col w-fit items-end gap-2 shrink-0">
          <div className="flex items-center gap-x-5 w-fit text-xs flex-1 shrink-0">
            {type === "adverse_media" && hit.is_perpetrator === false && (
              <div className="px-2 py-1 rounded-full bg-slate-500 text-xs text-white font-semibold">
                Not a Perpetrator
              </div>
            )}
            <div className="flex items-center">
              {type === "source_of_wealth" && hit.linkedin_source && (
                <SourceOfWealthTooltip
                  className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                  title={
                    hit.linkedin_source === "pipl"
                      ? "Match found with email lookup"
                      : "Match found by open source search"
                  }
                  placement="top"
                  arrow
                >
                  <img
                    src={
                      hit.linkedin_source === "pipl"
                        ? emailSourcePhoto
                        : hit.linkedin_source === "google_search"
                          ? searchSourcePhoto
                          : searchSourcePhoto
                    }
                    alt={
                      hit.linkedin_source === "pipl"
                        ? "Email source"
                        : hit.linkedin_source === "google_search"
                          ? "Search source"
                          : "Search source"
                    }
                    className="w-5 h-5 mr-2"
                  />
                </SourceOfWealthTooltip>
              )}
              <MiniStrengthIndicator matchRating={hit.profile_review.match_rating.match} greenColor={greenColor} />
            </div>

            {type === "source_of_wealth" &&
              hit.source_of_wealth_estimation &&
              hit.source_of_wealth_estimation.current_annual_salary_low != null &&
              hit.source_of_wealth_estimation.current_annual_salary_high != null && (
                <div className="text-xs text-slate-700 font-semibold">
                  Annual Salary:{" "}
                  <span className="text-xs text-green-700 font-semibold">
                    {formatNumberToCurrency(hit.source_of_wealth_estimation.current_annual_salary_low)} -{" "}
                    {formatNumberToCurrency(hit.source_of_wealth_estimation.current_annual_salary_high)}
                  </span>
                </div>
              )}

            {/* Feedback Buttons */}
            {type !== "source_of_wealth" && (
              <FeedbackButtons
                className="ignore-in-pdf"
                isLoadingFeedback={isLoadingFeedback}
                isFail={isFail}
                flaggedSelected={flaggedSelected}
                setFlaggedSelected={setFlaggedSelected}
                feedback={feedback}
                handleSubmitFeedback={handleSubmitFeedback}
                rowId={rowId ?? ""}
                setFeedbackComment={setFeedbackComment}
                setShowFeedbackComment={setShowFeedbackComment}
              />
            )}
          </div>

          {/* Feedback Status */}
          {!isLoadingFeedback && feedback && feedback.score === false && (
            <FeedbackStatus
              feedback={feedback}
              showEventInfo={showEventInfo}
              showFeedbackComment={showFeedbackComment}
              setShowFeedbackComment={setShowFeedbackComment}
              flaggedSelected={flaggedSelected}
              setFlaggedSelected={setFlaggedSelected}
              setFeedbackComment={setFeedbackComment}
            />
          )}
        </div>
        {showVendorId && (
          <div
            className={twJoin(
              "min-w-[6rem] w-fit text-xs text-slate-700 w-fit flex flex-col",
              feedback?.score === false ? "self-baseline" : "self-end",
            )}
          >
            <span className="font-semibold truncate">Vendor ID</span>

            <button
              onClick={handleCopyClick}
              className="relative inline-flex gap-1 items-center w-fit hover:text-brand-purple transition-colors"
              title="Copy to clipboard"
            >
              <span className="truncate text-sm">{rowId?.split("_").at(-1)}</span>
              <span className="ignore-in-pdf print:hidden">
                <ContentCopyRounded sx={{ fontSize: "1rem" }} />
              </span>
            </button>
            {/* Toast Notification */}
            {showToast && (
              <div className="fixed top-5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-brand-purple text-white px-4 py-2 rounded-md z-50 animate-fade-in-out w-fit truncate">
                Vendor ID copied to clipboard
              </div>
            )}
          </div>
        )}
        {displayType && (
          <div className="flex items-center py-0.5 px-1 w-fit text-xs flex-1 shrink-0 rounded-full border border-red-200">
            <span className="capitalize text-xs text-white bg-red-600 px-2 py-1 rounded-full truncate">
              {type.replaceAll("_", " ")}
            </span>
          </div>
        )}
      </div>

      {/* Feedback Input */}
      {(flaggedSelected || showFeedbackComment) && (
        <FeedbackInput
          isFail={isFail}
          matchRating={hit.profile_review.match_rating.match}
          showEventInfo={showEventInfo}
          feedbackComment={feedbackComment}
          setFeedbackComment={setFeedbackComment}
          feedback={feedback}
          showFeedbackComment={showFeedbackComment}
          setShowFeedbackComment={setShowFeedbackComment}
          flaggedSelected={flaggedSelected}
          setFlaggedSelected={setFlaggedSelected}
          onSubmitFeedbackClick={onSubmitFeedbackClick}
        />
      )}

      {/* Content Section - Always show when downloading */}
      {(showEventInfo || showAllContent) && (
        <div className="flex flex-col gap-y-2 border-x border-b rounded-b-lg border-slate-200">
          <BusinessPerpetratorTable
            mediaHit={hit}
            input={input}
            hitTableLabel={type === "pep" ? "Found PEP" : "Found Perpetrator"}
          />
          <div className="px-5 pt-2.5">{renderContent()}</div>
        </div>
      )}
    </div>
  );
};

export default BusinessRiskCardV1;
