import { Descope, getRefreshToken } from "@descope/react-sdk";
import { Grid, Snackbar } from "@mui/material";
import useAuth from "../hooks/useAuth";
import { Navigate, useSearchParams } from "react-router-dom";
import { useState } from "react";

export default function SignIn() {
  const { isLoading, isAuthenticated, user } = useAuth();
  const [searchParams] = useSearchParams();
  const [showNotAllowedMsg, setShowNotAllowedMsg] = useState(searchParams.get("authorized") === "false");

  if (isLoading) return null;

  if (isAuthenticated) {
    const isUserWithoutTenant = user && user.userTenants?.length === 0;

    if (isUserWithoutTenant) {
      const redirectTo = searchParams.get("redirectTo");

      if (redirectTo) {
        window.location.replace(redirectTo);
        return null;
      }
      // If user has no tenant, redirect to reports
      return <Navigate to="/reports" replace />;
    } else {
      // If user has a tenant, redirect to jobs
      return <Navigate to="/jobs" replace />;
    }
  }

  return (
    <Grid container justifyContent="center" mt={8}>
      <Grid item xs={11} md={4}>
        <Snackbar
          message="You don't have access, please contact support@parcha.ai"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showNotAllowedMsg}
          onClose={() => setShowNotAllowedMsg(false)}
          autoHideDuration={2500}
        />
        <Descope flowId="sign-up-or-in-magic-link-no-free-email-v2" />
      </Grid>
    </Grid>
  );
}
