import { ErrorContext } from "@parcha-src/contexts/ErrorContext";
import ParchaApi from "@parcha-src/libs/ParchaApi";
import { useContext, useMemo } from "react";

export function useParchaApi() {
  const { setError } = useContext(ErrorContext);
  const parchaApi = useMemo(() => new ParchaApi(setError), [setError]);

  return parchaApi;
}
