// @ts-nocheck
import React, { useState, useCallback, useMemo, useEffect } from "react";
import { twJoin } from "tailwind-merge";
import { useDropzone } from "react-dropzone";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import {
  WarningRounded,
  DescriptionRounded,
  Check,
  RadioButtonUnchecked,
  RadioButtonChecked,
  UploadFileRounded,
  KeyboardArrowUpRounded,
  KeyboardArrowDownRounded,
} from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import {
  FlashLoaderResponse,
  DocumentFile,
  DocumentTypeValue,
  VALIDITY_PERIODS,
  ValidityPeriod,
  FlashLoaderType,
  FLASH_LOADER_CONFIGS,
  JurisdictionOption,
  IncorporationFlashCheckResult,
  ProofOfAddressFlashCheckResult,
  EinFlashCheckResult,
  KYCProofOfAddressFlashCheckResult,
} from "../types/flash";
import { fileToBase64, isValidFile, SUPPORTED_FILE_TYPES } from "../utils/file";
import { checkDocument } from "../services/flashLoader";
import { log } from "../utils/logger";
import ParchaLoadingScreen from "../../../../src/components/V2_Home_Page/ParchaLoadingScreen";

const WORKER_URL = "https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js";

// Check if we're in development mode
const isDevelopment = import.meta.env.DEV;

const formatDate = (dateString: string | null) => {
  if (!dateString) return null;
  return new Date(dateString).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export interface DocsPlaygroundProps {
  type: FlashLoaderType;
  descope_user_id?: string;
  apiKey?: string;
  endpointUrl?: string;
  initialResponse?: FlashLoaderResponse;
  onResponse?: (response: FlashLoaderResponse) => void;
  onReset?: () => void;
  sampleDocumentUrls?: Partial<Record<FlashLoaderType, string>>;
  setSelectedJobId: (jobId: string | null) => void;
  document: DocumentFile | null;
  setDocument: (document: DocumentFile | null) => void;
}

const COMMAND_TO_TAB_TYPE: Record<string, DocumentType> = {
  "kyb.proof_of_address_verification": "business_proof_of_address",
  "kyb.ein_document_verification": "ein",
  "kyb.incorporation_document_verification": "incorporation",
  "kyc.proof_of_address_verification": "individual_proof_of_address",
};

// Add Skeleton component for loading states
const Skeleton = ({ className = "", ...props }: { className?: string; [key: string]: any }) => (
  <div className={`animate-pulse bg-slate-200 rounded ${className}`} {...props} />
);

export const DocsPlayground: React.FC<DocsPlaygroundProps> = ({
  apiKey = import.meta.env.VITE_API_KEY,
  descope_user_id,
  document,
  endpointUrl = import.meta.env.VITE_API_ENDPOINT_URL,
  initialResponse,
  sampleDocumentUrls,
  setDocument,
  onResponse,
}) => {
  const initialSelectedTab = initialResponse
    ? COMMAND_TO_TAB_TYPE[initialResponse?.check_results?.[0].command_id]
    : "business_proof_of_address";

  const [selectedTab, setSelectedTab] = useState<FlashLoaderType>(initialSelectedTab);

  const config = useMemo(() => FLASH_LOADER_CONFIGS[selectedTab], [selectedTab]);

  const initialAcceptedTypes = config?.documentTypes
    ? new Set(
        config?.documentTypes?.filter(
          (docType) => initialResponse?.check_results?.[0]?.check_args?.accepted_documents?.includes(docType.value),
        ),
      )
    : new Set();

  const [response, setResponse] = useState<FlashLoaderResponse | null>(initialResponse);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [timeSpent, setTimeSpent] = useState<number | null>(null);
  const [searchParams] = useSearchParams();

  const [acceptedTypes, setAcceptedTypes] = useState<Set<DocumentTypeValue>>(initialAcceptedTypes);
  const [selectedJurisdiction, setSelectedJurisdiction] = useState<JurisdictionOption>(
    config.jurisdictions?.[0] || { label: "", state: "", country: "" },
  );
  const [showDocumentTypes, setShowDocumentTypes] = useState(false);
  const [validityPeriod, setValidityPeriod] = useState<ValidityPeriod>(VALIDITY_PERIODS[0]);
  const [isDocTypeDropdownOpen, setIsDocTypeDropdownOpen] = useState(false);
  // const [einNumber, setEinNumber] = useState<string>("");

  const effectiveBaseUrl = `${window.location.protocol}//${endpointUrl}/auth/runFlashCheck`;
  const isConfigured = !!apiKey;
  const agentKey =
    selectedTab === "individual_proof_of_address"
      ? import.meta.env.VITE_KYC_AGENT_KEY || "public-docs-kyc-v1"
      : import.meta.env.VITE_AGENT_KEY || "public-docs-v1";

  useEffect(() => {
    if (response) {
      setSelectedTab(COMMAND_TO_TAB_TYPE[response?.check_results?.[0].command_id]);
    }
  }, [response]);

  useEffect(() => {
    if (!config) return;

    if (config.documentTypes && response) {
      const acceptedDocumentTypes = config.documentTypes?.filter(
        (docType) => response?.check_results?.[0]?.check_args?.accepted_documents?.includes(docType.value),
      );
      setAcceptedTypes(new Set(acceptedDocumentTypes?.map((dt) => dt.value)));
    } else if (config.documentTypes) {
      setAcceptedTypes(new Set(config.documentTypes.map((dt) => dt.value)) || new Set());
    }

    if (config.showValidityPeriod) {
      const validityPeriod = response?.check_results?.[0]?.check_args?.validity_period;
      if (validityPeriod) {
        setValidityPeriod(VALIDITY_PERIODS.find((p) => p.days === validityPeriod) || VALIDITY_PERIODS[0]);
      } else {
        setValidityPeriod(VALIDITY_PERIODS[0]);
      }
    }

    // if (config && selectedTab === "ein") {
    //   const einNumber = (response?.input_payload?.self_attested_data?.tin_number || "") as string;
    //   if (einNumber) {
    //     setEinNumber(einNumber);
    //   } else {
    //     setEinNumber("");
    //   }
    // }
  }, [response, config, searchParams]);

  useEffect(() => {
    const jobId = searchParams.get("jobId");
    if (!jobId) {
      setDocument(null);
      setResponse(null);
    }
  }, [searchParams]);

  // Force update response when initialResponse changes
  useEffect(() => {
    if (initialResponse) {
      setResponse(initialResponse);
      // Calculate time spent from job timestamps
      if (initialResponse.started_at && initialResponse.completed_at) {
        const startTime = new Date(initialResponse.started_at).getTime();
        const endTime = new Date(initialResponse.completed_at).getTime();
        setTimeSpent((endTime - startTime) / 1000);
      }
      if (initialResponse.check_results[0]?.input_data?.document?.url) {
        const fileName = initialResponse.check_results[0].input_data.document.file_name || "document.pdf";
        const fileExtension = fileName.split(".").pop()?.toLowerCase();

        // Determine file type based on extension
        let fileType = "application/pdf"; // default
        if (fileExtension) {
          if (["png"].includes(fileExtension)) {
            fileType = "image/png";
          } else if (["jpg", "jpeg"].includes(fileExtension)) {
            fileType = "image/jpeg";
          } else if (["tif", "tiff"].includes(fileExtension)) {
            fileType = "image/tiff";
          } else if (["webp"].includes(fileExtension)) {
            fileType = "image/webp";
          }
        }

        if (initialResponse.input_payload.self_attested_data.address_of_incorporation) {
          const label = config.jurisdictions?.find(
            (j) => j.state === initialResponse.input_payload.self_attested_data.address_of_incorporation.state,
          )?.label;
          setSelectedJurisdiction({
            label: label || "",
            state: initialResponse.input_payload.self_attested_data.address_of_incorporation.state,
            country: initialResponse.input_payload.self_attested_data.address_of_incorporation.country_code,
          });
        }

        setDocument({
          file: new File([], fileName, { type: fileType }),
          base64: "",
          url: initialResponse.check_results[0].input_data.document.url,
        });
      }
    }
  }, [initialResponse]);

  // Reset state when type changes
  useEffect(() => {
    if (!initialResponse) {
      setDocument(null);
      setResponse(null);
    }
  }, [selectedTab, initialResponse]);

  const checkDocumentWithApi = useCallback(
    async (doc: DocumentFile) => {
      if (!isConfigured) {
        return;
      }

      if (config.documentTypes && acceptedTypes.size === 0) {
        setError("Please select at least one document type");
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const checkArgs = {
          ...config.checkArgs,
          ...(config.showValidityPeriod ? { validity_period: validityPeriod.days } : {}),
          ...(config.documentTypes ? { accepted_documents: Array.from(acceptedTypes) } : {}),
        };

        const kybSchema = {
          id: "parcha-latest",
          self_attested_data: {
            business_name: "Parcha",
            registered_business_name: "Parcha Labs Inc",
            ...(selectedTab === "incorporation" &&
              selectedJurisdiction.state && {
                address_of_incorporation: {
                  state: selectedJurisdiction.state,
                  country_code: "US",
                },
              }),
            // ...(selectedTab === "ein" && einNumber ? { tin_number: einNumber } : {}),
            [config.documentField]: [
              {
                b64_document: doc.base64 ?? undefined,
                url: doc.url ?? undefined,
                file_name: doc.file.name,
                source_type: "file_url",
              },
            ],
          },
        };

        const result = await checkDocument(
          {
            apiKey,
            baseUrl: effectiveBaseUrl,
            agentKey,
          },
          doc.base64,
          doc.file.name,
          checkArgs,
          config.checkId,
          config.documentField,
          descope_user_id,
          kybSchema,
          selectedTab,
        );

        // Calculate time spent from job timestamps
        if (result.started_at && result.completed_at) {
          const startTime = new Date(result.started_at).getTime();
          const endTime = new Date(result.completed_at).getTime();
          setTimeSpent((endTime - startTime) / 1000);
        }

        setResponse(result);
        onResponse?.(result);
      } catch (err) {
        console.error("Error in checkDocumentWithApi:", err);
        setError((err as Error).message);
        setTimeSpent(null);
      } finally {
        setLoading(false);
      }
    },
    [
      config,
      acceptedTypes,
      validityPeriod,
      selectedJurisdiction,
      isConfigured,
      apiKey,
      effectiveBaseUrl,
      agentKey,
      descope_user_id,
      selectedTab,
      // einNumber,
      onResponse,
    ],
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (!file) return;

      if (!isValidFile(file)) {
        setError("Please upload a valid file");
        return;
      }

      try {
        const base64 = await fileToBase64(file);
        const newDoc = { file, base64 };
        setDocument(newDoc);
        setError(null);
        await checkDocumentWithApi(newDoc);
      } catch (err) {
        setError("Error processing file");
        log.error("file_processing_error", err as Error);
      }
    },
    [checkDocumentWithApi],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: SUPPORTED_FILE_TYPES,
    maxFiles: 1,
    disabled: !isConfigured || loading || !!document || !!response,
  });

  const getFileUrl = (response: FlashLoaderResponse) => {
    const url = response.check_results[0].input_data.document.url;
    if (!url) return null;

    const proxyUrl = new URL(`${window.location.protocol}//${endpointUrl}/getDocument`);
    proxyUrl.searchParams.set("case_id", response.check_results[0].command_instance_id);
    proxyUrl.searchParams.set("expired_url", url);

    return proxyUrl.toString();
  };

  const fileUrl = response ? getFileUrl(response) : null;

  const renderFilePreview = () => {
    if (!document || !fileUrl) return null;

    const fileType = document.file.type;

    if (fileType === "application/pdf") {
      return (
        <Worker workerUrl={WORKER_URL}>
          <Viewer
            fileUrl={fileUrl}
            renderLoader={() => <ParchaLoadingScreen size="large" message="Loading PDF..." />}
          />
        </Worker>
      );
    } else if (fileType.startsWith("image/")) {
      return (
        <div
          className="image-preview-container"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={fileUrl}
            alt="Document preview"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}
          />
        </div>
      );
    }

    return <div>Unsupported file type</div>;
  };

  const toggleDocumentType = (value: DocumentTypeValue) => {
    setAcceptedTypes((prevTypes) => {
      const currentTypes = Array.from(prevTypes);
      const isCurrentlyChecked = currentTypes.includes(value);

      // If trying to uncheck and it would leave us with no checkboxes, prevent it
      if (isCurrentlyChecked && currentTypes.length <= 1) {
        return prevTypes;
      }

      // Otherwise update the types
      const newTypes = isCurrentlyChecked ? currentTypes.filter((t) => t !== value) : [...currentTypes, value];

      // Double check we're not somehow ending up with no checkboxes
      if (newTypes.length === 0) {
        return prevTypes;
      }

      return new Set(newTypes);
    });
  };

  const loadSampleDocument = useCallback(async () => {
    try {
      const response = await fetch(sampleDocumentUrls?.[selectedTab] || SAMPLE_DOCUMENTS[selectedTab]);

      if (!response.ok) {
        throw new Error(`Failed to fetch sample document: ${response.statusText}`);
      }

      const contentType = response.headers.get("content-type");

      if (!contentType?.includes("application/pdf")) {
        const error = new Error(`Expected PDF but got ${contentType}`);
        console.error("invalid_content_type", error);
        setError("Error: Sample document not found or invalid format");
        return;
      }

      const arrayBuffer = await response.arrayBuffer();

      const file = new File(
        [arrayBuffer],
        sampleDocumentUrls?.[selectedTab]?.split("/").pop() ||
          SAMPLE_DOCUMENTS[selectedTab].split("/").pop() ||
          "sample.pdf",
        { type: "application/pdf" },
      );

      if (!isValidFile(file)) {
        setError("Invalid PDF file");
        return;
      }

      const base64 = await fileToBase64(file);

      const newDoc = { file, base64 };
      setDocument(newDoc);
      setError(null);

      // Ensure state is updated before calling checkDocumentWithApi
      await new Promise((resolve) => setTimeout(resolve, 0));
      await checkDocumentWithApi(newDoc);
    } catch (err) {
      console.error("Error in loadSampleDocument:", err);
      log.error("sample_document_error", err as Error);
      setError("Error loading sample document. Please try uploading a file manually.");
    }
  }, [selectedTab, checkDocumentWithApi, sampleDocumentUrls]);

  // Update the SAMPLE_DOCUMENTS constant to use props if available
  const SAMPLE_DOCUMENTS: Record<FlashLoaderType, string> = {
    incorporation:
      sampleDocumentUrls?.incorporation || (isDevelopment ? "/parcha-inc.pdf" : "assets/sample-docs/parcha-inc.pdf"),
    business_proof_of_address:
      sampleDocumentUrls?.business_proof_of_address ||
      (isDevelopment ? "/parcha-poa.pdf" : "assets/sample-docs/parcha-poa.pdf"),
    individual_proof_of_address:
      sampleDocumentUrls?.individual_proof_of_address ||
      (isDevelopment ? "/customer-poai.pdf" : "assets/sample-docs/customer-poai.pdf"),
    ein: sampleDocumentUrls?.ein || (isDevelopment ? "/parcha_ein.pdf" : "assets/sample-docs/parcha_ein.pdf"),
  };

  return (
    <div
      key={`${selectedTab}-${response?.check_results[0].command_instance_id || "new"}`}
      className="w-full h-full bg-white"
      data-response-id={response?.check_results[0].command_instance_id}
      data-has-response={!!response}
      data-type={selectedTab}
    >
      {!isConfigured && (
        <div
          className="mx-8 bg-amber-100 border border-amber-200 text-amber-800 p-4 rounded-lg flex items-center gap-2"
          data-testid="env-error-banner"
        >
          <span>⚠️</span>
          <p className="m-0">
            Missing required configuration: {!apiKey && "API key"}
            {!apiKey && !agentKey && " and "}
            {!agentKey && (selectedTab === "individual_proof_of_address" ? "KYC agent key" : "agent key")} are required
          </p>
        </div>
      )}

      <div
        className={`px-8 py-4 grid gap-8 h-full overflow-hidden relative transition-all duration-300 ease-in-out ${
          loading ? "pointer-events-none" : ""
        } ${document ? "grid-cols-[25rem_1fr]" : "grid-cols-1"}`}
        data-testid="main-content"
      >
        <div
          className={`flex flex-col gap-4 overflow-y-auto w-full transition-all duration-600 ease-in-out ${
            !document ? "items-center" : ""
          }`}
        >
          <div
            className={`bg-white border-none flex flex-col gap-4 transition-all duration-300 ease-in-out origin-center ${
              !document ? "min-w-[25rem]" : ""
            }`}
          >
            <div className="flex flex-col gap-4">
              <div className="bg-slate-50 rounded-xl p-5">
                <div className="flex flex-col gap-3 flex-1">
                  <h2 className="text-slate-700 text-sm font-semibold">Document Type</h2>
                  <div className="relative">
                    <button
                      type="button"
                      onClick={() => setIsDocTypeDropdownOpen(!isDocTypeDropdownOpen)}
                      className="text-left rounded-lg flex items-center justify-between min-w-[13rem] hover:border-brand-purple"
                    >
                      <span className="font-semibold text-slate-900">
                        {selectedTab === "business_proof_of_address"
                          ? "Business Proof of Address"
                          : selectedTab === "incorporation"
                            ? "Incorporation Documents"
                            : selectedTab === "ein"
                              ? "EIN Documents"
                              : "Individual Proof of Address"}
                      </span>

                      <svg
                        className={twJoin(
                          "w-4 h-4 ml-2 transition-transform text-slate-500",
                          isDocTypeDropdownOpen ? "rotate-180" : "",
                        )}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                    {isDocTypeDropdownOpen && (
                      <div className="absolute z-10 mt-1 bg-white rounded-lg shadow-lg border border-slate-200 py-1 min-w-[200px]">
                        <button
                          className={twJoin(
                            "w-full px-2 py-1 text-left text-base hover:bg-slate-50 flex items-center gap-2",
                            selectedTab === "business_proof_of_address"
                              ? "bg-indigo-50 text-brand-purple"
                              : "text-slate-900",
                          )}
                          onClick={() => {
                            setSelectedTab("business_proof_of_address");
                            setAcceptedTypes(
                              new Set(
                                FLASH_LOADER_CONFIGS["business_proof_of_address"].documentTypes.map((dt) => dt.value),
                              ),
                            );
                            setValidityPeriod(VALIDITY_PERIODS[0]);
                            setDocument(null);
                            setResponse(null);
                            setIsDocTypeDropdownOpen(false);
                          }}
                        >
                          {selectedTab === "business_proof_of_address" && (
                            <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          )}
                          <span className={selectedTab === "business_proof_of_address" ? "ml-1" : "ml-6"}>
                            Business Proof of Address
                          </span>
                        </button>
                        <button
                          className={twJoin(
                            "w-full pl-2 pr-5 py-1 text-left text-base hover:bg-slate-50 flex items-center gap-2",
                            selectedTab === "individual_proof_of_address"
                              ? "bg-indigo-50 text-brand-purple"
                              : "text-slate-900",
                          )}
                          onClick={() => {
                            setSelectedTab("individual_proof_of_address");
                            setAcceptedTypes(
                              new Set(
                                FLASH_LOADER_CONFIGS["individual_proof_of_address"].documentTypes.map((dt) => dt.value),
                              ),
                            );
                            setValidityPeriod(VALIDITY_PERIODS[0]);
                            setDocument(null);
                            setResponse(null);
                            setIsDocTypeDropdownOpen(false);
                          }}
                        >
                          {selectedTab === "individual_proof_of_address" && (
                            <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          )}
                          <span className={selectedTab === "individual_proof_of_address" ? "" : "ml-6"}>
                            Individual Proof of Address
                          </span>
                        </button>
                        <button
                          className={twJoin(
                            "w-full px-2 py-1 text-left text-base hover:bg-slate-50 flex items-center gap-2",
                            selectedTab === "incorporation" ? "bg-indigo-50 text-brand-purple" : "text-slate-900",
                          )}
                          onClick={() => {
                            setSelectedTab("incorporation");
                            setSelectedJurisdiction(FLASH_LOADER_CONFIGS["incorporation"].jurisdictions[0]);
                            setDocument(null);
                            setResponse(null);
                            setIsDocTypeDropdownOpen(false);
                          }}
                        >
                          {selectedTab === "incorporation" && (
                            <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          )}
                          <span className={selectedTab === "incorporation" ? "" : "ml-6"}>Incorporation Documents</span>
                        </button>
                        <button
                          className={twJoin(
                            "w-full px-2 py-1 text-left text-base hover:bg-slate-50 flex items-center gap-2",
                            selectedTab === "ein" ? "bg-indigo-50 text-brand-purple" : "text-slate-900",
                          )}
                          onClick={() => {
                            setSelectedTab("ein");
                            setDocument(null);
                            setResponse(null);
                            setIsDocTypeDropdownOpen(false);
                          }}
                        >
                          {selectedTab === "ein" && (
                            <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          )}
                          <span className={selectedTab === "ein" ? "" : "ml-6"}>EIN Documents</span>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {(config.documentTypes || config.showValidityPeriod || config.jurisdictions) && (
                <>
                  <div className="bg-slate-50 rounded-xl p-4">
                    <button
                      className="flex items-center text-slate-700 w-full"
                      onClick={() => setShowDocumentTypes(!showDocumentTypes)}
                      disabled={loading}
                    >
                      {showDocumentTypes ? (
                        <KeyboardArrowDownRounded sx={{ fontSize: "1rem" }} className="mr-2" />
                      ) : (
                        <KeyboardArrowUpRounded sx={{ fontSize: "1rem" }} className="mr-2" />
                      )}
                      <span className="text-sm font-semibold">Validation Options</span>
                    </button>
                    {showDocumentTypes && (
                      <div className="py-4 flex flex-col gap-4">
                        {config.jurisdictions && (
                          <div className="flex flex-col gap-2">
                            <h3 className="text-sm text-slate-600 font-semibold">Jurisdiction</h3>
                            <div>
                              {!document && !loading ? (
                                <select
                                  value={`${selectedJurisdiction.state},${selectedJurisdiction.country}`}
                                  onChange={(e) => {
                                    const [state, country] = e.target.value.split(",");
                                    const jurisdiction = config.jurisdictions?.find(
                                      (j) => j.state === state && j.country === country,
                                    );
                                    if (jurisdiction) {
                                      setSelectedJurisdiction(jurisdiction);
                                    }
                                  }}
                                  disabled={loading || document}
                                  className="border-0 w-fit text-sm text-slate-700 cursor-pointer rounded-lg focus:outline-none focus:ring-1 focus:ring-brand-purple transition-all hover:bg-slate-50 disabled:bg-slate-100"
                                >
                                  {config.jurisdictions.map((jurisdiction) => (
                                    <option
                                      key={`${jurisdiction.state},${jurisdiction.country}`}
                                      value={`${jurisdiction.state},${jurisdiction.country}`}
                                      className="text-slate-700 bg-slate-50"
                                    >
                                      {jurisdiction.label}
                                    </option>
                                  ))}
                                </select>
                              ) : selectedJurisdiction ? (
                                <span className="text-sm text-slate-900">{selectedJurisdiction.label}</span>
                              ) : (
                                <span className="text-sm text-slate-500">Loading...</span>
                              )}
                            </div>
                          </div>
                        )}

                        {/* {selectedTab === "ein" && (
                          <div className="flex flex-col gap-2">
                            <h3 className="text-xs text-slate-700 font-semibold">EIN Number (Optional)</h3>
                            <input
                              type="text"
                              value={einNumber}
                              disabled={loading || document}
                              onChange={(e) => setEinNumber(e.target.value)}
                              placeholder="Enter EIN number to validate"
                              className="w-full px-2 py-1 border border-slate-300 rounded-md text-sm disabled:text-slate-500 disabled:cursor-default disabled:bg-slate-50"
                            />
                          </div>
                        )} */}

                        {config.documentTypes && (
                          <div className="flex flex-col gap-3">
                            <h3 className="text-sm text-slate-600 font-semibold">Acceptable Document Types</h3>
                            <div className="grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-2">
                              {config.documentTypes.map((type) => (
                                <label
                                  key={type.value}
                                  aria-disabled={
                                    loading || document || (acceptedTypes.size === 1 && acceptedTypes.has(type.value))
                                  }
                                  className="flex items-center rounded-lg transition-all hover:border-brand-purple disabled:hover:cursor-default"
                                >
                                  <input
                                    type="checkbox"
                                    checked={acceptedTypes.has(type.value)}
                                    onChange={() => {
                                      toggleDocumentType(type.value);
                                    }}
                                    disabled={
                                      loading || document || (acceptedTypes.size === 1 && acceptedTypes.has(type.value))
                                    }
                                    data-testid={`checkbox-${type.value}`}
                                    className="appearance-none w-4 h-4 border-2 border-slate-300 rounded checked:bg-brand-purple relative cursor-pointer transition-all disabled:bg-slate-300 disabled:border-slate-300  after:content-['✓'] after:absolute after:text-white after:font-bold after:text-xs after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:opacity-0 checked:after:opacity-100 disabled:hover:bg-slate-300 disabled:hover:border-slate-300 disabled:cursor-default checked:hover:bg-brand-purple checked:focus:bg-brand-purple checked:active:bg-brand-purple focus:ring-brand-purple"
                                  />
                                  <span className="ml-3 text-sm text-slate-700 font-medium">{type.label}</span>
                                </label>
                              ))}
                            </div>
                          </div>
                        )}

                        {config.showValidityPeriod && (
                          <div className="flex flex-col gap-2">
                            <h3 className="text-sm text-slate-600 font-semibold">Document Maximum Age Limit</h3>
                            <div className="grid grid-rows-1 gap-3">
                              {VALIDITY_PERIODS.map((period) => (
                                <button
                                  key={period.days}
                                  className="flex items-center gap-2 text-sm group"
                                  onClick={() => setValidityPeriod(period)}
                                  disabled={loading || document}
                                >
                                  {period.days === validityPeriod.days ? (
                                    <RadioButtonChecked
                                      sx={{ fontSize: "1.25rem" }}
                                      className={document ? `text-slate-400` : `text-brand-purple`}
                                    />
                                  ) : (
                                    <RadioButtonUnchecked
                                      sx={{ fontSize: "1.25rem" }}
                                      className={`text-slate-500 group-hover:text-brand-purple group-disabled:group-hover:text-slate-500 ${
                                        document ? "text-slate-400" : ""
                                      }`}
                                    />
                                  )}
                                  <span className="group-hover:text-brand-purple group-disabled:group-hover:text-slate-900 group-disabled:group-hover:font-normal">
                                    {period.label}
                                  </span>
                                </button>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}

              <div className="flex flex-col gap-4 flex-1 bg-slate-50 transition-all p-4">
                <div
                  {...getRootProps()}
                  className={`w-full flex-1 flex ${isDragActive ? "bg-indigo-50" : ""} ${document ? "has-file" : ""} ${
                    !isConfigured || loading || document ? "" : "cursor-pointer"
                  }`}
                >
                  <input {...getInputProps()} data-testid="file-input" />
                  {document ? (
                    <div className="flex flex-col gap-4 w-full">
                      <div className="flex items-center justify-between w-full p-2 border border-slate-200 rounded-lg">
                        <div className="flex items-center gap-2">
                          <DescriptionRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                          <span className="text-sm text-slate-700 font-semibold">{document.file.name}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div
                        className={`border-2 border-dashed ${
                          isDragActive ? "border-brand-purple" : "border-slate-300"
                        } rounded-lg flex items-center justify-center p-6 w-full focus:outline-none hover:border-brand-purple focus:border-brand-purple`}
                      >
                        <div className="flex flex-col items-center gap-2">
                          <div className="flex items-center gap-2">
                            <UploadFileRounded sx={{ fontSize: "1.5rem" }} className="text-slate-400" />
                            <p className="font-semibold text-slate-700">
                              Drag & drop or <span className="text-brand-purple cursor-pointer">choose documents</span>{" "}
                              to upload
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {!document && !response && (
                  <button
                    onClick={loadSampleDocument}
                    className="text-xs font-semibold text-brand-purple hover:underline inline-flex items-center gap-2 place-self-center"
                    disabled={loading || !!(document || response)}
                  >
                    <DescriptionRounded sx={{ fontSize: "1rem" }} className="text-brand-purple" />
                    Sample Document
                  </button>
                )}
              </div>
            </div>
          </div>

          {loading && document && (!response || !response.check_results || response.check_results.length === 0) && (
            <div className="mt-4 space-y-4">
              {/* Skeleton for verification result */}
              <div className="flex flex-col gap-2 p-5 rounded-md bg-slate-50 border border-slate-200">
                <div className="flex items-center justify-between gap-2 flex-wrap">
                  <div className="flex items-center gap-2">
                    <Skeleton className="w-5 h-5 rounded-full" />
                    <Skeleton className="w-16 h-6" />
                  </div>
                  <div className="flex items-center gap-2">
                    <Skeleton className="w-32 h-5" />
                  </div>
                </div>
                <Skeleton className="w-full h-4 mt-2" />
                <Skeleton className="w-3/4 h-4" />
              </div>

              {/* Skeleton for document details */}
              <div className="mt-4">
                <Skeleton className="w-48 h-5 mb-4" />
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-2 bg-slate-50 rounded-lg p-4">
                    <Skeleton className="w-32 h-4" />
                    <Skeleton className="w-48 h-5 mt-1" />
                  </div>
                  <div className="flex flex-col gap-2 bg-slate-50 rounded-lg p-4">
                    <Skeleton className="w-32 h-4" />
                    <Skeleton className="w-48 h-5 mt-1" />
                  </div>
                  <div className="flex flex-col gap-2 bg-slate-50 rounded-lg p-4">
                    <Skeleton className="w-32 h-4" />
                    <Skeleton className="w-48 h-5 mt-1" />
                  </div>
                </div>
              </div>
            </div>
          )}

          {response && response.check_results.length > 0 && response.check_results[0].answer && (
            <div className="flex flex-col gap-4 relative" data-testid="results-container">
              <div>
                {response.check_results[0].passed !== null && (
                  <div
                    className={`flex flex-col gap-2 p-5 rounded-md ${
                      response.check_results[0].passed
                        ? "bg-green-100 text-green-700 border border-green-200"
                        : "bg-red-100 text-red-700 border border-red-200"
                    }`}
                  >
                    <div className="flex items-center justify-between gap-2 flex-wrap">
                      <div className="flex items-center gap-2 text-base font-semibold">
                        {response.check_results[0].passed ? (
                          <Check sx={{ fontSize: "1.25rem" }} className="text-green-700" />
                        ) : (
                          <WarningRounded sx={{ fontSize: "1.25rem" }} className="text-red-700" />
                        )}
                        <span className="font-semibold">{response.check_results[0].passed ? "Pass" : "Fail"}</span>
                      </div>
                      {timeSpent !== null && (
                        <div className="flex items-center gap-2 text-sm">
                          <span className="text-slate-700 font-semibold">Processing Time:</span>
                          <span className="text-slate-900">{timeSpent.toFixed(2)} seconds</span>
                        </div>
                      )}
                    </div>
                    <span className="text-sm">{response.check_results[0].answer}</span>
                  </div>
                )}

                {response && response.check_results.length > 0 && response.check_results[0].answer && (
                  <div className="mt-4" data-testid="document-details">
                    <h4 className="text-sm font-semibold text-slate-900 mb-4">Document Information</h4>
                    <div>
                      {selectedTab === "incorporation" ? (
                        <div className="flex flex-col gap-4">
                          <div className="flex flex-col gap-2 bg-slate-50 rounded-lg p-4">
                            <span className="text-xs text-slate-900 font-semibold">Company Name</span>
                            <span className="text-sm">
                              {(response.check_results[0]?.payload as IncorporationFlashCheckResult)?.company_name ||
                                "Not available"}
                            </span>
                          </div>
                          <div className="flex flex-col gap-2 bg-slate-50 rounded-lg p-4">
                            <span className="text-xs text-slate-900 font-semibold">Jurisdiction</span>
                            <span className="text-sm">
                              {(response.check_results[0].payload as IncorporationFlashCheckResult)?.jurisdiction
                                ?.state || "Not available"}
                            </span>
                          </div>
                          <div className="flex flex-col gap-2 bg-slate-50 rounded-lg p-4">
                            <span className="text-xs text-slate-900 font-semibold">Document Date</span>
                            <span className="text-sm">
                              {formatDate(response.check_results[0]?.payload?.document_date) || "Not available"}
                            </span>
                          </div>
                        </div>
                      ) : selectedTab === "ein" ? (
                        <div className="flex flex-col gap-4">
                          <div className="flex flex-col gap-2 bg-slate-50 rounded-lg p-4">
                            <span className="text-xs text-slate-900 font-semibold">Company Name</span>
                            <span className="text-sm">
                              {(response.check_results[0].payload as EinFlashCheckResult)?.company_name ||
                                "Not available"}
                            </span>
                          </div>
                          <div className="flex flex-col gap-2 bg-slate-50 rounded-lg p-4">
                            <span className="text-xs text-slate-900 font-semibold">Document Date</span>
                            <span className="text-sm">
                              {formatDate(response.check_results[0].payload.document_date) || "Not available"}
                            </span>
                          </div>
                          <div className="flex flex-col gap-2 bg-slate-50 rounded-lg p-4" data-testid="ein-number">
                            <span className="text-xs text-slate-900 font-semibold">EIN Number</span>
                            <span className="text-sm">
                              {(response.check_results[0].payload as EinFlashCheckResult).ein || "Not available"}
                            </span>
                          </div>
                        </div>
                      ) : selectedTab === "individual_proof_of_address" ? (
                        <div className="flex flex-col gap-4">
                          <div className="flex flex-col gap-2 bg-slate-50 rounded-lg p-4">
                            <span className="text-xs text-slate-900 font-semibold">Individual Name</span>
                            <span className="text-sm">
                              {(response.check_results[0].payload as KYCProofOfAddressFlashCheckResult)
                                .individual_name || "Not available"}
                            </span>
                          </div>
                          <div className="flex flex-col gap-2 bg-slate-50 rounded-lg p-4">
                            <span className="text-xs text-slate-900 font-semibold">Document Type</span>
                            <span className="text-sm">
                              {(response.check_results[0].payload as KYCProofOfAddressFlashCheckResult).document_type ||
                                "Not available"}
                            </span>
                          </div>
                          <div className="flex flex-col gap-2 bg-slate-50 rounded-lg p-4">
                            <span className="text-xs text-slate-900 font-semibold">Document Date</span>
                            <span className="text-sm">
                              {formatDate(response.check_results[0]?.payload?.document_date) || "Not available"}
                            </span>
                          </div>

                          {(response.check_results[0]?.payload as KYCProofOfAddressFlashCheckResult)
                            ?.document_address && (
                            <div className="flex flex-col gap-2 bg-slate-50 rounded-lg p-4 text-slate-900">
                              <p className="text-xs font-semibold text-slate-900">Address</p>
                              <div className="text-sm">
                                {(response.check_results[0].payload as KYCProofOfAddressFlashCheckResult)
                                  .document_address.street_1 && (
                                  <p className="my-1 text-slate-900">
                                    {
                                      (response.check_results[0].payload as KYCProofOfAddressFlashCheckResult)
                                        .document_address.street_1
                                    }
                                  </p>
                                )}
                                {(response.check_results[0].payload as KYCProofOfAddressFlashCheckResult)
                                  .document_address.street_2 && (
                                  <p className="my-1 text-slate-900">
                                    {
                                      (response.check_results[0].payload as KYCProofOfAddressFlashCheckResult)
                                        .document_address.street_2
                                    }
                                  </p>
                                )}
                                {((response.check_results[0].payload as KYCProofOfAddressFlashCheckResult)
                                  .document_address.city ||
                                  (response.check_results[0].payload as KYCProofOfAddressFlashCheckResult)
                                    .document_address.state ||
                                  (response.check_results[0].payload as KYCProofOfAddressFlashCheckResult)
                                    .document_address.postal_code) && (
                                  <p className="my-1 text-slate-900">
                                    {[
                                      (response.check_results[0].payload as KYCProofOfAddressFlashCheckResult)
                                        .document_address.city,
                                      (response.check_results[0].payload as KYCProofOfAddressFlashCheckResult)
                                        .document_address.state,
                                      (response.check_results[0].payload as KYCProofOfAddressFlashCheckResult)
                                        .document_address.postal_code,
                                    ]
                                      .filter(Boolean)
                                      .join(", ")}
                                  </p>
                                )}
                                {(response.check_results[0].payload as KYCProofOfAddressFlashCheckResult)
                                  .document_address.country_code && (
                                  <p className="my-1 text-slate-900">
                                    {
                                      (response.check_results[0].payload as KYCProofOfAddressFlashCheckResult)
                                        .document_address.country_code
                                    }
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="flex flex-col gap-4">
                          <div className="flex flex-col gap-2 bg-slate-50 rounded-lg p-4">
                            <span className="text-xs text-slate-900 font-semibold">Company</span>
                            <span className="text-sm">
                              {(response.check_results[0]?.payload as ProofOfAddressFlashCheckResult)?.company_name ||
                                "Not available"}
                            </span>
                          </div>
                          {response.check_results[0]?.payload?.type === "ProofOfAddressFlashCheckResult" && (
                            <>
                              <div className="flex flex-col gap-2 bg-slate-50 rounded-lg p-4">
                                <span className="text-xs text-slate-900 font-semibold">Document Type</span>
                                <span className="text-sm">
                                  {(response.check_results[0]?.payload as ProofOfAddressFlashCheckResult)
                                    ?.document_type || "Not available"}
                                </span>
                              </div>
                              <div className="flex flex-col gap-2 bg-slate-50 rounded-lg p-4">
                                <span className="text-xs text-slate-900 font-semibold">Document Date</span>
                                <span className="text-sm">
                                  {formatDate(response.check_results[0]?.payload?.document_date) || "Not available"}
                                </span>
                              </div>

                              {(response.check_results[0]?.payload as ProofOfAddressFlashCheckResult)
                                ?.document_address && (
                                <div className="flex flex-col gap-2 bg-slate-50 rounded-lg p-4 text-sm">
                                  <p className="text-xs font-semibold text-slate-900">Address</p>
                                  <div>
                                    {(response.check_results[0].payload as ProofOfAddressFlashCheckResult)
                                      .document_address.street_1 && (
                                      <p className="my-1 text-slate-900">
                                        {
                                          (response.check_results[0].payload as ProofOfAddressFlashCheckResult)
                                            .document_address.street_1
                                        }
                                      </p>
                                    )}
                                    {(response.check_results[0].payload as ProofOfAddressFlashCheckResult)
                                      .document_address.street_2 && (
                                      <p className="my-1 text-slate-900">
                                        {
                                          (response.check_results[0].payload as ProofOfAddressFlashCheckResult)
                                            .document_address.street_2
                                        }
                                      </p>
                                    )}
                                    {((response.check_results[0].payload as ProofOfAddressFlashCheckResult)
                                      .document_address.city ||
                                      (response.check_results[0].payload as ProofOfAddressFlashCheckResult)
                                        .document_address.state ||
                                      (response.check_results[0].payload as ProofOfAddressFlashCheckResult)
                                        .document_address.postal_code) && (
                                      <p className="my-1 text-slate-900">
                                        {[
                                          (response.check_results[0].payload as ProofOfAddressFlashCheckResult)
                                            .document_address.city,
                                          (response.check_results[0].payload as ProofOfAddressFlashCheckResult)
                                            .document_address.state,
                                          (response.check_results[0].payload as ProofOfAddressFlashCheckResult)
                                            .document_address.postal_code,
                                        ]
                                          .filter(Boolean)
                                          .join(", ")}
                                      </p>
                                    )}
                                    {(response.check_results[0].payload as ProofOfAddressFlashCheckResult)
                                      .document_address.country_code && (
                                      <p className="my-1 text-slate-900">
                                        {
                                          (response.check_results[0].payload as ProofOfAddressFlashCheckResult)
                                            .document_address.country_code
                                        }
                                      </p>
                                    )}
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div
          className={`flex flex-col h-full overflow-hidden min-w-0 relative ${
            document || response?.check_results[0].input_data?.document?.url
              ? "opacity-100 translate-x-0 w-auto"
              : "opacity-0 translate-x-[100px] w-0"
          } transition-all duration-600 ease-in-out`}
          data-testid="preview-section"
        >
          {error && (
            <div
              className="bg-red-100 text-red-600 py-3 px-4 rounded-md mb-4 font-medium text-sm flex items-center justify-center text-center border border-red-200"
              data-testid="error-message"
            >
              {error}
            </div>
          )}

          {loading && (
            <div
              className="absolute inset-0 flex flex-col items-center justify-center gap-4 z-10"
              data-testid="loading-spinner"
            >
              <ParchaLoadingScreen message="Processing document..." size="large" />
            </div>
          )}

          <div
            className={`bg-white border border-slate-200 rounded-xl py-5 overflow-auto flex-1 min-h-0 flex relative ${
              document || response?.check_results[0].input_data?.document?.url
                ? "opacity-100 translate-x-0"
                : "opacity-0 translate-x-[40px]"
            } transition-all duration-600 ease-in-out delay-200`}
          >
            {renderFilePreview()}
          </div>
        </div>
      </div>
    </div>
  );
};
