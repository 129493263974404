import { Dispatch, Key, SetStateAction, useState } from "react";
import ReactMarkdown from "react-markdown";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  styled,
} from "@mui/material";
import type { Endpoint, JobMetadata } from "@parcha-src/types";
import JobInfoCard from "../JobInfo";
import BusinessSelfAttestedDataCard from "./BusinessSelfAttestedDataCard";
import IndividualSelfAttestedDataCard from "./IndividualSelfAttestedDataCard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";

interface AgentChatProps {
  endpoint: Endpoint;
  jobMetadata: any;
  isParchaUser?: boolean;
  selectedTabValue: string;
  setSelectedTabValue: Dispatch<SetStateAction<string>>;
}

interface StyledTabProps {
  label: string;
  value: string;
  disabled?: boolean;
}

export const AntTabList = styled(TabList)({
  borderBottom: "1px solid #e8e8e8",

  "& .MuiTabs-flexContainer": {
    justifyContent: "center",
  },

  "& .MuiTabs-indicator": {
    backgroundColor: "#5D5FEF",
    width: "100%",
  },
});

export const AntTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,

  // marginRight: theme.spacing(1),
  color: "#0F172A",
  "&:hover": {
    color: "#475569",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#5D5FEF",
    fontWeight: theme.typography.fontWeightMedium,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#5D5FEF",
    color: "#fff",
  },
}));

//TODO @miguel: add messages and a job id as optional props,
// if messages is in, just display the messages, if job id is in,
// then gather the chat messages from the web server using the job (or the status of the job)
export default function AgentChatSSE({
  endpoint,
  isParchaUser,
  jobMetadata,
  selectedTabValue,
  setSelectedTabValue,
}: AgentChatProps) {
  const [generatedRequest, setGeneratedRequest] = useState<string | undefined>(); //TODO: set schema

  const [prevStatus, setPrevStatus] = useState<string | undefined>(undefined);
  const [copyButtonText, setCopyButtonText] = useState<string>("Copy to Clipboard");
  const [isGeneratedRequestDialogOpen, setIsGeneratedRequestDialogOpen] = useState<boolean>(false);
  const [hasCopied, setHasCopied] = useState(false);
  const job = jobMetadata?.job;

  // Handle close event for Generated Request dialog
  const handleCloseGeneratedRequestDialog = () => {
    setIsGeneratedRequestDialogOpen(false);
  };

  // Handle copy to clipboard event
  const handleCopyToClipboard = () => {
    if (generatedRequest) {
      navigator.clipboard.writeText(generatedRequest).then(() => {
        setCopyButtonText("Copied!");
        setTimeout(() => {
          setCopyButtonText("Copy to Clipboard");
        }, 3e3);
      });
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue !== selectedTabValue) {
      setSelectedTabValue(newValue);
    }
  };

  const createMetadata = (): JobMetadata => {
    // const documents =
    //   agentRun?.status_messages &&
    //   agentRun?.status_messages
    //     .map((message) => {
    //       if (message.event == "command_results") {
    //         const commandResults = message.content as CommandResult;
    //         const output = commandResults.output as OutputSchema;
    //         if (output && output.url && output.button_label) {
    //           const fileName = output.url.split("/").pop()?.split("?")[0];
    //           const docType = getFileType(output.url);
    //           return {
    //             url: output.url,
    //             name: output.button_label,
    //             description: fileName,
    //             type: docType,
    //           } as ParchaDocument;
    //         }
    //       }
    //       return undefined;
    //     })
    //     .filter((document): document is ParchaDocument => document !== undefined);
    const metadata: JobMetadata = {
      agentRun: job,
      recommendation: job?.recommendation ?? "",
      documents: [],
    };
    return metadata;
  };

  const handleCopyPayload = () => {
    if (job?.input_payload) {
      navigator.clipboard.writeText(JSON.stringify(job.input_payload, null, 2));
      setHasCopied(true);
      setTimeout(() => {
        setHasCopied(false);
      }, 2000);
    }
  };

  return (
    <Box height={"100%"}>
      <Dialog
        open={isGeneratedRequestDialogOpen}
        onClose={handleCloseGeneratedRequestDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Customer Request</DialogTitle>
        <DialogContent>
          <DialogContentText>{generatedRequest && <ReactMarkdown>{generatedRequest}</ReactMarkdown>}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "0 24px 24px 0" }}>
          <Button onClick={handleCloseGeneratedRequestDialog} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleCopyToClipboard} color="primary" variant="contained">
            {copyButtonText}
          </Button>
        </DialogActions>
      </Dialog>

      <TabContext value={selectedTabValue}>
        <>
          <AntTabList
            sx={{ display: "flex", justifyContent: "center", height: "55px", width: "100%" }}
            onChange={handleTabChange}
          >
            <AntTab sx={{ flex: "1 1 auto" }} label="Inputs" value="inputs" />
            <AntTab sx={{ flex: "1 1 auto" }} label="Job Details" value="job_details" />
          </AntTabList>
        </>

        <TabPanel
          value="inputs"
          sx={{
            height: "calc(100vh - 55px)",
            overflowY: "auto",
          }}
        >
          <div className="flex flex-col gap-y-4">
            <>
              <BusinessSelfAttestedDataCard
                caseId={job?.input_payload.id}
                endpointUrl={endpoint.endpointUrl}
                isParchaUser={isParchaUser}
                level={"normal"}
                payload={job.input_payload.self_attested_data}
                individuals={job.input_payload.associated_individuals}
              />
              <IndividualSelfAttestedDataCard
                caseId={job?.input_payload.id}
                endpointUrl={endpoint.endpointUrl}
                isParchaUser={isParchaUser}
                level={"normal"}
                payload={job.input_payload.self_attested_data}
              />
              {job.input_payload.associated_entities &&
                job.input_payload.associated_entities.length > 0 &&
                job.input_payload.associated_entities.map(
                  (
                    entity: {
                      self_attested_data: { [key: string]: any };
                      associated_individuals: { [key: string]: any } | undefined;
                    },
                    index: Key | null | undefined,
                  ) => (
                    <BusinessSelfAttestedDataCard
                      key={index}
                      caseId={job?.id}
                      endpointUrl={endpoint.endpointUrl}
                      isParchaUser={isParchaUser}
                      level={"normal"}
                      payload={entity.self_attested_data}
                      individuals={entity.associated_individuals}
                    />
                  ),
                )}
            </>
            {isParchaUser && (
              <div className="bg-slate-50 p-4 rounded-lg">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="font-semibold text-sm">Raw Input Payload</h3>
                  <button
                    onClick={handleCopyPayload}
                    className={`inline-flex items-center gap-1 px-3 py-1 rounded text-xs transition-colors ${
                      hasCopied
                        ? "bg-green-100 text-green-700 hover:bg-green-200"
                        : "bg-slate-200 text-slate-700 hover:bg-slate-300"
                    }`}
                  >
                    {hasCopied ? (
                      <>
                        <CheckIcon sx={{ fontSize: "0.875rem" }} />
                        Copied!
                      </>
                    ) : (
                      <>
                        <ContentCopyIcon sx={{ fontSize: "0.875rem" }} />
                        Copy
                      </>
                    )}
                  </button>
                </div>
                <pre className="whitespace-pre-wrap text-xs leading-relaxed overflow-auto max-h-96 bg-white p-4 rounded border border-slate-200 font-mono">
                  {JSON.stringify(job.input_payload, null, 2)}
                </pre>
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel value="job_details" sx={{ padding: "8px" }}>
          <div className="flex flex-col gap-4">
            <JobInfoCard jobMetadata={createMetadata()} />
          </div>
        </TabPanel>
      </TabContext>
    </Box>
  );
}
