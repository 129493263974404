import UserContext from "@/contexts/UserContext";
import { useParchaApi } from "@/hooks/useParchaApi";
import { AgentRun } from "@/types";
import { useContext, useEffect, useState, useRef, useCallback } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import AgentRunItem from "./V2_Home_Page/AgentRunItem";
import ParchaLoadingScreen from "./V2_Home_Page/ParchaLoadingScreen";
import { MenuOpenRounded, MenuRounded } from "@mui/icons-material";

const AGENT_JOBS_LIMIT = 25;
const POLLING_INTERVAL = 10_000;
const MAX_POLL_LIMIT = 100;

const getJobId = (job: AgentRun) => {
  const rawId = job?.id || job?.job_id;
  if (!rawId) return null;

  return rawId.replaceAll("-", "").toLowerCase();
};

const NewJobsList = () => {
  const { agentKey, jobId } = useParams();
  const parchaApi = useParchaApi();
  const userContext = useContext(UserContext);
  const endpoints = userContext?.endpoints;
  const [isLoadingJobs, setIsLoadingJobs] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [jobs, setJobs] = useState<AgentRun[]>([]);
  const [isJobsListVisible, setIsJobsListVisible] = useState<boolean>(true);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [pollLimit, setPollLimit] = useState(AGENT_JOBS_LIMIT);
  const jobsMapRef = useRef(new Map<string, AgentRun>());
  const navigate = useNavigate();

  const endpoint = endpoints?.find((endpoint) => endpoint.agentKey === agentKey);
  const isMCCOutputAgent = endpoint?.agentConfig?.output_type === "mcc_code";

  const observer = useRef<IntersectionObserver>();

  const loadMoreJobs = useCallback(async () => {
    if (!agentKey || !endpoint || isLoadingMore || !hasMore) return;

    // Store the current agentKey to check if it changes during loading
    const currentAgentKey = agentKey;
    const currentEndpoint = endpoint;

    setIsLoadingMore(true);
    try {
      // Keep using the existing offset for pagination
      const res = await parchaApi.getAgentJobHistory(
        currentEndpoint.endpointUrl,
        currentEndpoint.agentKey,
        AGENT_JOBS_LIMIT,
        offset,
      );

      // Check if agentKey has changed during loading
      if (currentAgentKey !== agentKey || currentEndpoint !== endpoint) {
        setIsLoadingMore(false);
        return;
      }

      if (!res || res.items.length === 0) {
        setHasMore(false);
        setIsLoadingMore(false);
        return;
      }

      // Check if the first item of the new page is the same as the last item of the current page
      const currentJobs = Array.from(jobsMapRef.current.values());
      const lastCurrentJob = currentJobs[currentJobs.length - 1];
      const firstNewJob = res.items[0];

      let itemsToProcess = res.items;
      if (lastCurrentJob && firstNewJob && getJobId(lastCurrentJob) === getJobId(firstNewJob)) {
        // Remove the duplicate first item from the new page
        itemsToProcess = res.items.slice(1);
      }

      // Check if agentKey has changed before processing jobs
      if (currentAgentKey !== agentKey || currentEndpoint !== endpoint) {
        setIsLoadingMore(false);
        return;
      }

      if (itemsToProcess.length > 0) {
        await processJobs(itemsToProcess);
        setOffset(offset + itemsToProcess.length);
      }

      setHasMore(jobsMapRef.current.size < res.total);
    } catch (error) {
      console.error("Error loading more jobs:", error);
    } finally {
      setIsLoadingMore(false);
    }
  }, [agentKey, endpoint, isLoadingMore, hasMore, offset, parchaApi]);

  const lastJobRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore && !isLoadingMore) {
            loadMoreJobs();
          }
        },
        {
          rootMargin: "100px",
          threshold: 0.1,
        },
      );
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, hasMore, loadMoreJobs],
  );

  const updateJobsMap = (newJobs: AgentRun[], isPolling: boolean = false) => {
    const currentAgentKey = agentKey;

    if (!currentAgentKey) return;

    // Create a map of new jobs by ID for easier lookup
    const newJobsMap = new Map(
      newJobs
        .filter((job) => {
          const id = getJobId(job);
          return id !== null;
        })
        .map((job) => [getJobId(job)!, job]),
    );

    newJobsMap.forEach((newJob, normalizedId) => {
      // Skip if the agentKey has changed during processing
      if (currentAgentKey !== agentKey) {
        return;
      }

      const existingJob = jobsMapRef.current.get(normalizedId);

      if (isPolling && existingJob) {
        // During polling, update everything except created_at
        jobsMapRef.current.set(normalizedId, {
          ...newJob,
          created_at: existingJob.created_at,
          // Ensure these fields are explicitly updated
          status: newJob.status,
          recommendation: newJob.recommendation,
        });
      } else if (!existingJob) {
        // New job, add it with all its original data
        jobsMapRef.current.set(normalizedId, newJob);
      }
    });
  };

  const processJobs = async (newJobs: AgentRun[], isPolling: boolean = false) => {
    const currentAgentKey = agentKey;
    const currentEndpoint = endpoint;

    if (!newJobs?.length) return;

    let processedJobs = [...newJobs];

    if (isMCCOutputAgent) {
      const jobIds = processedJobs
        .filter((item): item is AgentRun & { id: string } => item.recommendation === "Review" && item.id !== undefined)
        .map((value) => value.id);

      if (jobIds.length > 0) {
        const finalAnswerResults = await parchaApi.getFinalAnswerForJobs(
          currentEndpoint!.endpointUrl,
          currentAgentKey!,
          jobIds,
        );

        if (currentAgentKey !== agentKey || currentEndpoint !== endpoint) {
          return;
        }

        if (finalAnswerResults?.length) {
          finalAnswerResults.forEach((far: { job_id: string; mcc_code: string }) => {
            const normalizedFarJobId = far.job_id.replaceAll("-", "").toLowerCase();
            const jobIndex = processedJobs.findIndex((job) => getJobId(job) === normalizedFarJobId);
            if (jobIndex !== -1) {
              processedJobs[jobIndex] = {
                ...processedJobs[jobIndex],
                isHighRisk: true,
                highRiskCode: far.mcc_code,
              };
            }
          });
        }
      }
    }

    if (currentAgentKey !== agentKey || currentEndpoint !== endpoint) {
      return;
    }

    updateJobsMap(processedJobs, isPolling);

    const allJobs = Array.from(jobsMapRef.current.values());
    allJobs.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
    setJobs(allJobs);
  };

  const pollForUpdates = useCallback(async () => {
    if (!agentKey || !endpoint) return;

    // Store the current agentKey to check if it changes during polling
    const currentAgentKey = agentKey;
    const currentEndpoint = endpoint;

    // Only poll for the jobs that are already loaded
    const loadedJobsCount = jobsMapRef.current.size;

    try {
      // Calculate number of requests needed and any remainder
      // Calculate number of requests needed and any remainder based on loaded jobs
      const numFullRequests = Math.floor(loadedJobsCount / MAX_POLL_LIMIT);
      const remainingItems = loadedJobsCount % MAX_POLL_LIMIT;
      let allPolledJobs: AgentRun[] = [];
      let lastTotal = 0;

      // Make requests for each full batch of MAX_POLL_LIMIT
      for (let i = 0; i < numFullRequests; i++) {
        // Check if agentKey has changed during polling
        if (currentAgentKey !== agentKey || currentEndpoint !== endpoint) {
          return;
        }

        const res = await parchaApi.getAgentJobHistory(
          currentEndpoint.endpointUrl,
          currentAgentKey,
          MAX_POLL_LIMIT,
          i * MAX_POLL_LIMIT,
        );
        if (res?.items) {
          allPolledJobs = [...allPolledJobs, ...res.items];
          lastTotal = res.total; // Keep track of the latest total
        }
      }

      // Make one final request for remaining items if needed
      if (remainingItems > 0) {
        // Check if agentKey has changed during polling
        if (currentAgentKey !== agentKey || currentEndpoint !== endpoint) {
          return;
        }

        const res = await parchaApi.getAgentJobHistory(
          currentEndpoint.endpointUrl,
          currentAgentKey,
          remainingItems,
          numFullRequests * MAX_POLL_LIMIT,
        );
        if (res?.items) {
          allPolledJobs = [...allPolledJobs, ...res.items];
          lastTotal = res.total; // Update with the final total
        }
      }

      // Check if agentKey has changed during polling before processing jobs
      if (currentAgentKey !== agentKey || currentEndpoint !== endpoint) {
        return;
      }

      // Process all collected jobs together
      if (allPolledJobs.length > 0) {
        await processJobs(allPolledJobs, true);
        setHasMore(jobsMapRef.current.size < lastTotal);
      }
    } catch (error) {
      console.error("Error polling for updates:", error);
    }
  }, [agentKey, endpoint, pollLimit, parchaApi]);

  // Ref to track the current polling interval
  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!agentKey || !endpoint) return;

    // Clear any existing polling interval
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = null;
    }

    // Reset state when agentKey changes
    setJobs([]);
    setOffset(0);
    setHasMore(true);
    setIsLoadingJobs(true);
    setPollLimit(AGENT_JOBS_LIMIT);

    // Clear the jobs map to ensure all jobs are removed
    jobsMapRef.current.clear();

    // Store current agentKey to check if it changes during async operations
    const currentAgentKey = agentKey;
    const currentEndpoint = endpoint;

    // Initial load - only get the first set of jobs
    parchaApi
      .getAgentJobHistory(currentEndpoint.endpointUrl, currentAgentKey, AGENT_JOBS_LIMIT, 0)
      .then(async (res) => {
        // Ignore response if agentKey has changed during the request
        if (currentAgentKey !== agentKey || currentEndpoint !== endpoint) return;

        if (res?.items?.length > 0) {
          await processJobs(res.items);
          setOffset(res.items.length);
          setHasMore(res.items.length < res.total);

          // After loading jobs, handle job selection
          const allJobs = Array.from(jobsMapRef.current.values());

          if (jobId) {
            // Check if the jobId exists in the loaded jobs
            const jobExists = allJobs.some((job) => getJobId(job) === jobId);

            if (jobExists) {
              // If job exists in the loaded jobs, keep it selected
              // No need to navigate as the URL already has this jobId
            } else {
              // If jobId doesn't exist in loaded jobs, try to fetch it
              try {
                const jobData = await parchaApi.getJobWithoutStatusMessages(currentEndpoint.endpointUrl, jobId);

                // Check if agentKey has changed during the request
                if (currentAgentKey !== agentKey || currentEndpoint !== endpoint) return;

                // If job exists and belongs to current agent, add it to our list
                if (jobData && jobData.agent_id === agentKey) {
                  await processJobs([jobData]);
                } else {
                  // If job doesn't exist or doesn't belong to current agent, select first job
                  selectFirstJob();
                }
              } catch (error) {
                console.error("Error fetching job:", error);
                // If error occurs, select first job
                selectFirstJob();
              }
            }
          } else {
            // If no jobId in URL, always select the first job
            selectFirstJob();
          }
        }
        setIsLoadingJobs(false);
      })
      .catch((error) => {
        // Ignore errors if agentKey has changed
        if (currentAgentKey !== agentKey || currentEndpoint !== endpoint) return;

        console.error("Error loading initial jobs:", error);
        setIsLoadingJobs(false);
      });

    // Set up new polling with the new agentKey
    // Initial poll after a short delay to ensure initial load completes first
    const initialPollTimeout = setTimeout(() => {
      if (currentAgentKey === agentKey && currentEndpoint === endpoint) {
        pollForUpdates();
      }
    }, 2000);

    // Set up new polling interval
    pollingIntervalRef.current = setInterval(() => {
      // Only poll if the agentKey hasn't changed
      if (currentAgentKey === agentKey && currentEndpoint === endpoint) {
        pollForUpdates();
      } else if (pollingIntervalRef.current) {
        // If agentKey has changed, clear the interval
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
    }, POLLING_INTERVAL);

    // Clean up when component unmounts or agentKey changes
    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
      clearTimeout(initialPollTimeout);
    };
  }, [agentKey, endpoint]);

  // Extract job selection logic to a separate function for clarity
  const selectFirstJob = useCallback(() => {
    if (agentKey) {
      // Get the latest jobs from the map
      const allJobs = Array.from(jobsMapRef.current.values());

      // Sort jobs by creation date (newest first) to ensure we select the newest job
      allJobs.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

      if (allJobs.length > 0) {
        const firstJobId = getJobId(allJobs[0]);
        if (firstJobId) {
          // Navigate to the first job
          navigate(`/jobs/${agentKey}/${firstJobId}`);
        }
      } else {
        // If no jobs, just navigate to the agent route without a job ID
        navigate(`/jobs/${agentKey}`, { replace: true });
      }
    }
  }, [agentKey, navigate]);

  const toggleJobsList = () => {
    setIsJobsListVisible(!isJobsListVisible);
  };

  return (
    <div className="h-[calc(100vh-3.75rem)]">
      <div className={`grid ${isJobsListVisible ? "grid-cols-[350px_1fr]" : "grid-cols-[auto_1fr]"} h-full w-full`}>
        {isLoadingJobs ? (
          <div className="col-span-2 w-full h-full">
            <ParchaLoadingScreen message="Loading agent jobs..." size="large" />
          </div>
        ) : (
          <>
            {isJobsListVisible && (
              <div className="h-full overflow-auto border border-r-slate-200 relative">
                <div className="fixed top-[5.75rem] left-[21.125rem] md:left-[26.125rem] z-10 -translate-y-1/2 shadow-md bg-white rounded-full">
                  <button
                    onClick={toggleJobsList}
                    className="flex items-center justify-center w-5 h-5 rounded-full hover:bg-slate-100 transition-colors overflow-hidden"
                    aria-label="Collapse jobs list"
                    title="Collapse jobs list"
                  >
                    <div className="flex items-center justify-center w-full h-full">
                      <MenuOpenRounded sx={{ fontSize: "1rem" }} className="text-brand-purple" />
                    </div>
                  </button>
                </div>
                {jobs.length > 0 ? (
                  <>
                    {jobs.map((job, index) => (
                      <div
                        key={getJobId(job)}
                        ref={index === jobs.length - 1 ? lastJobRef : undefined}
                        data-is-last-job={index === jobs.length - 1 ? "true" : "false"}
                      >
                        <AgentRunItem
                          id={getJobId(job) || ""}
                          isSelected={Boolean(jobId && getJobId(job) === jobId)}
                          agentRun={job}
                          outputType={endpoint?.agentConfig?.output_type}
                          setSelectedAgentJobId={() => navigate(`/jobs/${agentKey}/${getJobId(job)}`)}
                        />
                      </div>
                    ))}
                    {isLoadingMore && (
                      <div className="flex justify-center py-4">
                        <ParchaLoadingScreen message="Loading more jobs..." size="small" />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="text-brand-purple py-3 px-6 italic opacity-75">No jobs for this agent.</div>
                )}
              </div>
            )}
            {!isJobsListVisible && (
              <div className="h-full relative">
                <div className="fixed top-[5.75rem] z-10 left-0 md:left-[4.25rem] shadow-md bg-white rounded-full transform -translate-y-1/2">
                  <button
                    onClick={toggleJobsList}
                    className="flex items-center justify-center w-5 h-5 rounded-full hover:bg-slate-100 transition-colors overflow-hidden"
                    aria-label="Expand jobs list"
                    title="Expand jobs list"
                  >
                    <div className="flex items-center justify-center w-full h-full">
                      <MenuRounded sx={{ fontSize: "1rem" }} className="text-brand-purple" />
                    </div>
                  </button>
                </div>
              </div>
            )}
            {!isLoadingJobs && (
              <div className="h-full overflow-auto">
                <Outlet />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default NewJobsList;
