import numeral from "numeral";
import { tryToParseDate } from "@/utils";
import { UIDocument } from "@/types";
import ValueContainer from "./ValueContainer";
import PDFThumbnail from "./PDFThumbnail";

type SourceOfFundsDocument = UIDocument & {
  date?: string;
  sourceOfFunds?: {
    display_type?: string;
  };
  summary?: string;
  fileName?: string;
  url?: string;
};

type SourceOfFundsCardProps = {
  level: "success" | "failure" | "normal" | undefined;
  amount: string;
  businessName: string;
  documents: SourceOfFundsDocument[];
  checkResult?: any;
  handleDocumentValidationClick?: (checkResult: any, document: any) => void;
};

const SourceOfFundsCard = ({
  level,
  amount,
  businessName,
  documents,
  handleDocumentValidationClick,
  checkResult,
}: SourceOfFundsCardProps) => {
  if (!documents?.length) return null;

  const document = documents.length > 0 ? documents[0] : null;

  return (
    <div className="grid grid-cols-2 gap-4 text-xs w-[40rem]">
      <div className="flex flex-col gap-y-2">
        {businessName && <ValueContainer label="Business Name" value={businessName} />}
        {amount && <ValueContainer label="Amount" value={numeral(amount).format("$0,0.00")} />}
        {document?.date && <ValueContainer label="Document Date" value={tryToParseDate(document.date)} />}
        {document?.sourceOfFunds?.display_type && (
          <ValueContainer label="Document Type" value={document.sourceOfFunds.display_type} />
        )}
        {document?.summary && <ValueContainer label="Document Summary" value={document.summary} />}
      </div>
      {document && (
        <div className="flex flex-col gap-y-2">
          <div className="h-full">
            <PDFThumbnail
              documentURL={document.url}
              documentName={document.fileName}
              onDocumentNameClick={() =>
                handleDocumentValidationClick?.(checkResult, {
                  file_name: document.fileName,
                  url: document.url,
                })
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SourceOfFundsCard;
