import { useEffect, useState } from "react";
import { ArrowDownwardRounded, ArrowUpwardRounded, BusinessRounded, PersonRounded } from "@mui/icons-material";
import SourceItem from "./SourceItem";
import { PositionEntry } from "./PEPCardV2";
import { format, formatDistanceStrict } from "date-fns";
import { snakeCaseToProperCase } from "@/utils";
import WrappedValue from "./WrappedValue";
import { useParchaApi } from "@/hooks/useParchaApi";
import BusinessRiskCardV1 from "./BusinessRiskCardV1";

const BusinessSanctionsCardV1 = ({
  apiKey,
  verifiedSanctionsHits,
  input,
  agentInstanceId,
  jobId,
  endpointUrl,
  agentKey,
  commandId,
  commandInstanceId,
  displayOnlyMatches,
  showAllContent,
  allCollapsed,
  displayType,
  showVendorId,
}: {
  apiKey: string;
  verifiedSanctionsHits: any;
  input: any;
  agentInstanceId: string;
  jobId: string;
  endpointUrl: string;
  agentKey: string;
  commandId: string;
  commandInstanceId: string;
  displayOnlyMatches: boolean;
  showAllContent: boolean;
  allCollapsed: boolean;
  displayType: boolean;
  showVendorId: boolean;
}) => {
  const parchaApi = useParchaApi();
  const [feedbackInputs, setFeedbackInputs] = useState<any[]>([]);
  const [isLoadingFeedback, setIsLoadingFeedback] = useState(true);

  useEffect(() => {
    const getFeedbackInput = async () => {
      let feedbackInputs;
      if (apiKey) {
        feedbackInputs = await parchaApi.getFeedbackInputsByKeyFromAPI(endpointUrl, jobId, "pep", apiKey);
      } else {
        feedbackInputs = await parchaApi.getFeedbackInputsByKey(endpointUrl, jobId, "pep");
      }

      if (feedbackInputs) {
        setFeedbackInputs(feedbackInputs);
      }
      setIsLoadingFeedback(false);
    };
    getFeedbackInput();
  }, [apiKey]);

  const handleSubmitFeedback = async (isValid: boolean, rowId: string, comment: string) => {
    if (apiKey) {
      await parchaApi.sendFeedbackToAPI(
        endpointUrl,
        jobId,
        "pep",
        isValid,
        undefined,
        {
          world_check_id: rowId,
          endpoint: {
            agentName: agentKey,
            endpointUrl,
          },
          job_id: jobId,
          job_url: window.location.href.replace(/([?&])api_key=[^&]*(&|$)/, "$1"),
          feedback_key: `${agentInstanceId}${commandInstanceId}`,
          agent_instance_id: agentInstanceId,
          command_instance_id: commandInstanceId,
          command_id: commandId,
          agent_key: agentKey,
          command_result: {
            content: {
              output: {
                verified_sanctions_hits: verifiedSanctionsHits,
              },
            },
          },
        },
        comment,
        rowId,
        apiKey,
      );
    } else {
      await parchaApi.sendFeedback(
        endpointUrl,
        jobId,
        "pep",
        isValid,
        undefined,
        {
          world_check_id: rowId,
          endpoint: {
            agentName: agentKey,
            endpointUrl,
          },
          job_id: jobId,
          job_url: window.location.href.replace(/([?&])api_key=[^&]*(&|$)/, "$1"),
          feedback_key: `${agentInstanceId}${commandInstanceId}`,
          agent_instance_id: agentInstanceId,
          command_instance_id: commandInstanceId,
          command_id: commandId,
          agent_key: agentKey,
          command_result: {
            content: {
              output: {
                verified_sanctions_hits: verifiedSanctionsHits,
              },
            },
          },
        },
        comment,
        rowId,
      );
    }
    setFeedbackInputs([
      ...feedbackInputs.filter((feedback) => feedback.row_id !== rowId),
      {
        score: isValid,
        data: {
          world_check_id: rowId,
        },
        row_id: rowId,
        comment,
        created_at: new Date(),
      },
    ]);
  };

  const groupedByWorldCheckId = feedbackInputs.reduce((acc, feedback) => {
    const rowId = feedback.row_id;

    if (!acc[rowId] || new Date(acc[rowId].created_at) < new Date(feedback.created_at)) {
      acc[rowId] = feedback;
    }
    return acc;
  }, {});

  const filteredHits = verifiedSanctionsHits.filter((hit: any) =>
    displayOnlyMatches ? hit.escalate_for_review : true,
  );

  if (filteredHits.length === 0) {
    return <div className="flex flex-col gap-y-2 text-slate-500 text-sm italic">No PEP hits found</div>;
  }

  return (
    <div className="flex flex-col gap-y-2">
      {filteredHits.map((hit: any, index: number) => (
        <BusinessRiskCardV1
          type="sanctions"
          key={`sanctions-hit-${hit.reference_id}-${index}`}
          hit={hit}
          input={input}
          handleSubmitFeedback={handleSubmitFeedback}
          feedback={groupedByWorldCheckId[hit.reference_id]}
          expandByDefault={index === 0 && !allCollapsed}
          isLoadingFeedback={isLoadingFeedback}
          renderContent={() => (
            <BusinessSanctionsEventCard
              sanctionsReview={hit.profile_review}
              sanctionsProfile={hit}
              showAllContent={showAllContent}
            />
          )}
          showAllContent={showAllContent}
          displayType={displayType}
        />
      ))}
    </div>
  );
};

type SanctionsEventCardProps = {
  sanctionsReview: {
    sanctions_level: {
      level: number | null;
      reason: string | null;
    } | null;
    is_foreign: boolean;
    match_rating: {
      match: string;
      reason: string;
    } | null;
    business_name_match: {
      exact_match: boolean;
      partial_match: boolean;
      mismatch?: boolean;
      name: string;
      explanation: string;
    };
    full_name: string;
    risk_rating: string;
    weblinks?:
      | {
          url: string | null;
          date: string | null;
          has_photo: boolean | null;
          scanned_website: {
            webpage_text: string | null;
            webpage_title: string | null;
            is_valid_url: boolean | null;
          };
          pep_metadata?: {
            id: string;
            matching_name_found?: boolean | null;
            matching_name?: string | null;
            summary_of_text_content?: string | null;
            source_url?: string | null;
            associated_countries?: string | null;
            professional_affiliations_found?: string | null;
          };
          metadata?: {
            id: string;
            matching_name_found?: boolean | null;
            matching_name?: string | null;
            summary_of_text_content?: string | null;
            source_url?: string | null;
            associated_countries?: string | null;
            professional_affiliations_found?: string | null;
          };
        }[]
      | null;
  } | null;
  sanctionsProfile: {
    full_name: string;
    associated_countries: (
      | {
          original_country_input: string;
          country_name: string;
          alpha_2_country_code: string;
          alpha_3_country_code: string;
          numeric_country_code: string;
        }
      | string
    )[];
    associated_addresses: {
      street_1: string;
      street_2: string;
      city: string;
      state: string;
      postal_code: string;
      country_code: string;
    }[];
    reference_id: string;
    aliases: string[] | null;
    native_character_names: string[] | null;
    identification_info: string;
    biography_info: string;
    known_associates: {
      association_type: string;
      name: string;
      entity_type: "ORGANISATION" | "INDIVIDUAL";
      category: string;
    }[];
    sanction_details: {
      title: string;
      detail: string;
      jurisdiction: string;
      list_date: string;
    }[];
    reports: string[][] | null;
    weblinks?:
      | {
          url: string | null;
          date: string | null;
          has_photo: boolean | null;
          scanned_website: {
            webpage_text: string | null;
            webpage_title: string | null;
            is_valid_url: boolean | null;
          };
          pep_metadata?: {
            id: string;
            matching_name_found?: boolean | null;
            matching_name?: string | null;
            summary_of_text_content?: string | null;
            source_url?: string | null;
            associated_countries?: string | null;
            professional_affiliations_found?: string | null;
          };
          metadata?: {
            id: string;
            matching_name_found?: boolean | null;
            matching_name?: string | null;
            summary_of_text_content?: string | null;
            source_url?: string | null;
            associated_countries?: string | null;
            professional_affiliations_found?: string | null;
          };
        }[]
      | null;
  };
  showAllContent: boolean;
};

const BusinessSanctionsEventCard = ({ sanctionsReview, sanctionsProfile, showAllContent }: SanctionsEventCardProps) => {
  const [weblinksCount, setWeblinksCount] = useState(
    showAllContent ? sanctionsProfile?.weblinks?.length : Math.min(3, sanctionsProfile?.weblinks?.length ?? 0),
  );

  useEffect(() => {
    if (showAllContent && sanctionsProfile?.weblinks) {
      setWeblinksCount(sanctionsProfile.weblinks.length);
    }
  }, [showAllContent, sanctionsProfile?.weblinks]);

  const weblinks = sanctionsProfile?.weblinks;

  return (
    <div className="flex flex-col gap-y-5 text-xs">
      <div className="grid grid-cols-2 gap-5">
        <div className="flex flex-col gap-y-5">
          {sanctionsProfile?.known_associates && sanctionsProfile.known_associates?.length > 0 && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Known Associations</span>
              <div className="flex flex-col gap-y-2 text-slate-700">
                {sanctionsProfile.known_associates.map((associate, index) => {
                  return (
                    <div
                      className="flex items-center gap-x-2"
                      key={`sanctions-known-associate-${associate.name}-${index}`}
                    >
                      {associate.entity_type === "ORGANISATION" ? (
                        <BusinessRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                      ) : (
                        <PersonRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                      )}
                      <div className="text-slate-500">
                        <span className="text-sm text-slate-700">{associate.name}</span> /{" "}
                        <span>{snakeCaseToProperCase(associate.association_type)}</span> /{" "}
                        <span>{snakeCaseToProperCase(associate.category)}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {sanctionsProfile?.biography_info && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Biography Information</span>
              <div className="flex flex-col gap-y-2 text-slate-700">
                {sanctionsProfile.biography_info.split(".").map((bioInfo) => (
                  <span className="break-keep">{bioInfo}</span>
                ))}
              </div>
            </div>
          )}
          {sanctionsProfile?.sanction_details?.length > 0 && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Sanctions</span>
              <div className="flex flex-col gap-y-2 text-slate-700">
                {sanctionsProfile.sanction_details.map((detail) => (
                  <div>
                    <span className="font-semibold">
                      Sanction - ({detail.title}, listed on {detail.list_date} by {detail.jurisdiction})
                    </span>
                    <div className="flex flex-col gap-y-2 text-slate-700">{detail.detail}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-y-5">
          {sanctionsProfile?.reference_id && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Vendor ID</span>
              <div className="flex flex-col gap-y-2 text-slate-700">
                {sanctionsProfile.reference_id.split("_").at(-1)}
              </div>
            </div>
          )}
          {/* <div className="flex flex-col gap-y-1">
            <span className="font-semibold">PEP Metadata</span>
            <div className="flex gap-x-2 items-center">
              {sanctionsReview?.is_foreign && <WrappedValue text="Foreign" level="normal" className="rounded-full" />}
              {sanctionsReview?.sanctions_level && (
                <WrappedValue
                  text={`Level ${sanctionsReview?.sanctions_level.level}`}
                  level={
                    (sanctionsReview?.sanctions_level.level ?? 0) < 2
                      ? "failure"
                      : (sanctionsReview?.sanctions_level.level ?? 0) < 4
                        ? "caution"
                        : "success"
                  }
                  className="rounded-full"
                />
              )}
              {sanctionsReview?.risk_rating && (
                <WrappedValue
                  text={`${sanctionsReview?.risk_rating
                    .replace("_", " ")
                    .toLowerCase()
                    .replace(/\b\w/g, (char) => char.toUpperCase())}`}
                  level={
                    sanctionsReview?.risk_rating === "high_risk"
                      ? "failure"
                      : sanctionsReview?.risk_rating === "medium_risk"
                        ? "caution"
                        : sanctionsReview?.risk_rating === "low_risk"
                          ? "success"
                          : sanctionsReview?.risk_rating === "no_risk"
                            ? "normal"
                            : "normal"
                  }
                  className="rounded-full"
                />
              )}
            </div>
          </div> */}
          {sanctionsProfile?.aliases && sanctionsProfile.aliases.length > 0 && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Aliases</span>
              <div className="flex flex-col gap-y-1 text-slate-700">
                {sanctionsProfile.aliases.map((alias) => (
                  <div className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>{alias}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {sanctionsProfile?.native_character_names && sanctionsProfile.native_character_names.length > 0 && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Native Character Names</span>
              <div className="flex flex-col gap-y-1 text-slate-700">
                {sanctionsProfile.native_character_names.map((name) => (
                  <div className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>{name}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {sanctionsProfile?.associated_addresses?.length > 0 && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Related Addresses</span>
              <div className="flex flex-col gap-y-1 text-slate-700">
                {sanctionsProfile.associated_addresses.map((address) => (
                  <div className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>
                      {[address.street_1, address.city, address.state, address.country_code, address.postal_code]
                        .filter(Boolean)
                        .join(", ")}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {sanctionsProfile?.associated_countries?.length > 0 && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Related Countries</span>
              <div className="flex gap-x-2 items-center text-slate-700">
                {sanctionsProfile.associated_countries
                  .map((country) =>
                    typeof country === "object" && country.country_name ? country.country_name : country,
                  )
                  .join(", ")}
              </div>
            </div>
          )}
          {sanctionsProfile?.identification_info && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Identification Information</span>
              <div className="flex flex-col gap-y-2 text-slate-700">
                {sanctionsProfile.identification_info.split(".").map((idInfo) => (
                  <span>{idInfo}</span>
                ))}
              </div>
            </div>
          )}
          {sanctionsProfile?.reports && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Reports</span>
              <div className="flex flex-col gap-y-2 text-slate-700">
                {sanctionsProfile.reports.map((reportGroup) => (
                  <div className="flex flex-col gap-y-1">
                    {reportGroup.map((report) => (
                      <div className="flex items-start">
                        <span className="mr-2">•</span>
                        <span>{report}</span>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {weblinks && weblinks.length > 0 && (
        <>
          <h4 className="text-sm font-semibold">Sources ({weblinks.length})</h4>
          <div className="flex flex-col gap-y-2">
            {weblinks
              ?.sort(
                (a, b) =>
                  (b.metadata?.summary_of_text_content || "").length -
                  (a.metadata?.summary_of_text_content || "").length,
              )
              ?.slice(0, weblinksCount)
              ?.map((weblink, index) => (
                <SourceItem
                  key={`pep-source-item-${weblink.url}-${index}`}
                  url={weblink.url ?? undefined}
                  sourceName={weblink.url ? new URL(weblink.url).hostname : undefined}
                  hasPhoto={weblink.has_photo ?? undefined}
                  text={weblink.scanned_website?.webpage_text ?? undefined}
                  title={weblink.scanned_website?.webpage_title ?? undefined}
                  summary={weblink.metadata?.summary_of_text_content ?? undefined}
                  wasRemoved={!weblink.scanned_website?.is_valid_url ?? undefined}
                  who={weblink.metadata?.matching_name ?? undefined}
                  when={weblink.date ?? undefined}
                  where={weblink.metadata?.associated_countries}
                />
              ))}
          </div>
          {!showAllContent && (
            <div className="flex flex-col gap-2 mb-4">
              {weblinksCount && weblinksCount > Math.min(3, weblinks.length) && (
                <button
                  type="button"
                  className="box-border inline-flex items-center gap-x-1.5 rounded-lg px-3 py-2 w-full text-center justify-center border border-solid border-slate-300 hover:bg-slate-50"
                  onClick={() => setWeblinksCount(Math.min(3, weblinks.length))}
                >
                  <div className="flex items-center gap-x-1 print:hidden">
                    Hide Sources
                    <ArrowUpwardRounded sx={{ fontSize: "1rem" }} />
                  </div>
                </button>
              )}
              {weblinksCount && weblinksCount < weblinks.length && (
                <button
                  type="button"
                  className="box-border inline-flex items-center gap-x-1.5 rounded-lg px-3 py-2 w-full text-center justify-center border border-solid border-slate-300 hover:bg-slate-50"
                  onClick={() => setWeblinksCount(Math.min(weblinksCount + 5, weblinks.length))}
                >
                  <div className="flex items-center gap-x-1 print:hidden">
                    Show More Sources
                    <ArrowDownwardRounded sx={{ fontSize: "1rem" }} />
                  </div>
                </button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BusinessSanctionsCardV1;
